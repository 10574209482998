var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gds-space-stack-l devices-max-width-my-account" },
    [
      _vm.config.formType == _vm.FORM_TYPES.ADD_DEVICE_BYOD
        ? [
            _c("SelectedDeviceSummary", {
              attrs: { device: _vm.config.selectedDevice }
            }),
            _vm._v(" "),
            _vm.config.formData.deviceType === "home-battery"
              ? [
                  _vm.config.formData.deviceType
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Device Type",
                          value: _vm.toTitleCaseNoHyphens(
                            _vm.config.formData.deviceType
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.preferredEnrollment
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Preferred Enrollment",
                          value: _vm.toTitleCaseNoHyphens(
                            _vm.config.formData.preferredEnrollment
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.batteryBrandAndModel
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Battery brand and model",
                          value: _vm.toTitleCaseNoHyphens(
                            _vm.config.formData.batteryBrandAndModel
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.totalBatteryCapacity
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Total battery capacity",
                          value: _vm.config.formData.totalBatteryCapacity + "%"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.batterySharePercentage
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Battery share percentage",
                          value:
                            _vm.config.formData.batterySharePercentage + "%"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.totalEnrolledCapacity
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Total Enrolled Capacity",
                          value: _vm.config.formData.totalEnrolledCapacity
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.estimatedIncentive
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Estimated Incentive",
                          value: _vm.config.formData.estimatedIncentive
                        }
                      })
                    : _vm._e()
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.config.formData.deviceType === "ev-charger"
              ? [
                  _vm.config.formData.deviceType
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Device Type",
                          value: _vm.toTitleCaseNoHyphens(
                            _vm.config.formData.deviceType
                          )
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.evChargerBrand
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "EV Charger Brand",
                          value: _vm.config.formData.evChargerBrand
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.vehicleMakeAndModel
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Vehicle Make and Model",
                          value: _vm.config.formData.vehicleMakeAndModel
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.vehicleYear
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Vehicle Year",
                          value: _vm.config.formData.vehicleYear
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.config.formData.discountChargingRate
                    ? _c("SimpleKeyValueText", {
                        attrs: {
                          label: "Discount Charging Rate",
                          value: _vm.config.formData.discountChargingRate
                        }
                      })
                    : _vm._e()
                ]
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.config.formType == _vm.FORM_TYPES.ADD_DEVICE_NEW
        ? [
            _vm.config.formData.deviceType
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "Device Type",
                    value: _vm.toTitleCaseNoHyphens(
                      _vm.config.formData.deviceType
                    )
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.selectedDeviceTypeLabel
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "Selected Product",
                    value: _vm.config.selectedDeviceTypeLabel
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.formData.installer
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "Selected Installer",
                    value: _vm.config.formData.installer
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.formData.contactName
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "Contact Name",
                    value: _vm.config.formData.contactName
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.formData.contactPhone
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "Contact Phone",
                    value: _vm.config.formData.contactPhone
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.formData.contactEmail
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "Contact Email",
                    value: _vm.config.formData.contactEmail
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.formData.batteryInterests &&
            _vm.config.formData.batteryInterests.length
              ? _c("div", { staticClass: "gds-space-stack-l" }, [
                  _c(
                    "div",
                    { staticClass: "gds-font-demi gds-space-stack-s" },
                    [
                      _vm.config.formData.batteryInterests.length == 1
                        ? _c("span", [_vm._v("Reason")])
                        : _c("span", [_vm._v("Reasons")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("you’re interested in a home battery system")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    _vm._l(_vm.config.formData.batteryInterests, function(
                      interest,
                      index
                    ) {
                      return _c(
                        "li",
                        { key: index, staticClass: "gds-font-book" },
                        [
                          _vm._v(
                            "\n          " + _vm._s(interest) + "\n        "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ])
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.config.formType == _vm.FORM_TYPES.ADD_DEVICE_BYOD ||
      _vm.config.formType == _vm.FORM_TYPES.ADD_DEVICE_NEW
        ? [
            _vm.config.formData.deviceType === "home-battery"
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "Solar installed at this location",
                    value: _vm.config.formData.installationHasSolar
                      ? "Yes"
                      : "No"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.config.accountNumber
              ? _c("SimpleKeyValueText", {
                  attrs: {
                    label: "GMP Account Number",
                    value: _vm.config.accountNumber
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gds-space-stack-l" },
              [
                _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
                  _vm._v("Service Location")
                ]),
                _vm._v(" "),
                _vm.config.formData.installationLocation ==
                _vm.INSTALLATION_LOCATION_KEYS.SOMEWHERE_ELSE
                  ? [
                      _c("div", { staticClass: "gds-font-book" }, [
                        _vm._v("TBD")
                      ])
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.config.formData.installationLocation ==
                  _vm.INSTALLATION_LOCATION_KEYS.PRIMARY_RESIDENCE &&
                _vm.config.primaryResidenceHtml
                  ? [
                      _c("div", {
                        staticClass: "gds-font-book",
                        domProps: {
                          innerHTML: _vm._s(_vm.config.primaryResidenceHtml)
                        }
                      })
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _vm.config.reviewAndSubmitMessage
              ? _c("SimpleKeyValueText", {
                  attrs: { value: _vm.config.reviewAndSubmitMessage }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.config.formType == _vm.FORM_TYPES.SERVICE_REQUEST
        ? _c(
            "div",
            [
              _c("SelectedDeviceSummary", {
                attrs: { device: _vm.config.selectedDevice }
              }),
              _vm._v(" "),
              _vm.config.formData.problemSummary
                ? _c("SimpleKeyValueText", {
                    attrs: {
                      label: "Summarize the issue you’re having",
                      value: _vm.config.formData.problemSummary
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "gds-space-stack-s gds-font-demi gds-font-size-xl"
                },
                [_vm._v("Your Contact Information")]
              ),
              _vm._v(" "),
              _vm.config.formData.contactPhone
                ? _c("SimpleKeyValueText", {
                    attrs: {
                      label: "Phone Number (10-Digit)",
                      value: _vm.config.formData.contactPhone
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.config.formData.contactEmail
                ? _c("SimpleKeyValueText", {
                    attrs: {
                      label: "Email Address",
                      value: _vm.config.formData.contactEmail
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.config.reviewAndSubmitMessage
                ? _c("SimpleKeyValueText", {
                    attrs: { value: _vm.config.reviewAndSubmitMessage }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }