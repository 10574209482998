import formConfigurations from './FormConfigurationsByod.json';
import AddDeviceFormViewModel from '../shared/AddDeviceFormViewModel';

const EV_CHARGER = 'ev-charger';
const HOME_BATTERY = 'home-battery';

export default class ByodViewModel extends AddDeviceFormViewModel {
  constructor() {
    super();

    Object.assign(this, formConfigurations);

    this.formData = {
      deviceType: undefined,
      requirementsEvCharger: undefined,
      requirementsHomeBattery: undefined,
    };

    this.selectedDeviceType = undefined;
    this.deviceTypeLastSelected = undefined;
    this.requirementsEvChargerLastSelected = undefined;
    this.requirementsHomeBatteryLastSelected = undefined;
    this.supportedDeviceTypes = [EV_CHARGER, HOME_BATTERY];

    this.headerConfig = { ...formConfigurations.headerConfigDefault };
  }

  get isSupportedDevice() {
    const { selectedDeviceType } = this;
    return AddDeviceFormViewModel.isDefined(selectedDeviceType) && this.supportedDeviceTypes.includes(selectedDeviceType);
  }

  get evChargerLinkUrl() {
    return this.evChargerLinkUrlPath;
  }

  get homeBatteryLinkUrl() {
    return this.homeBatteryLinkUrlPath;
  }

  get evChargerSelected() {
    return this.isSupportedDevice && this.selectedDeviceType === EV_CHARGER;
  }

  get homeBatterySelected() {
    return this.isSupportedDevice && this.selectedDeviceType === HOME_BATTERY;
  }

  get checklistCompleteHomeBatteryEvCharger() {
    const requirements = this.formData.requirementsEvCharger;

    return requirements && requirements.length === this.checkboxGroupConfigRequirementsEvCharger.checkboxes.length;
  }

  get checklistCompleteHomeBattery() {
    const requirements = this.formData.requirementsHomeBattery;

    return requirements && requirements.length === this.checkboxGroupConfigRequirementsHomeBattery.checkboxes.length;
  }

  updatePageHeader() {
    this.headerConfig = { ...formConfigurations.headerConfigDefault };
  }

  formDataWatchHandler(newValue) {
    const data = this.formData;

    Object.keys(newValue).forEach((key) => {
      const updatedValue = newValue[key];

      if (key === 'requirementsEvCharger' && (updatedValue !== this.requirementsEvChargerLast)) {
        data.requirementsEvCharger = updatedValue;
        this.requirementsEvChargerLast = data.requirementsEvCharger;
      }

      if (key === 'requirementsHomeBattery' && (updatedValue !== this.requirementsHomeBatteryLast)) {
        data.requirementsHomeBattery = updatedValue;
        this.requirementsHomeBatteryLast = data.requirementsHomeBattery;
      }

      if (key === 'deviceType' && (updatedValue !== this.deviceTypeLastSelected)) {
        data.requirementsEvCharger = undefined;
        data.requirementsHomeBattery = undefined;
        this.selectedDeviceType = updatedValue;
        this.deviceTypeLastSelected = this.selectedDeviceType;
      }
    });
  }
}
