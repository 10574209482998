var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.viewModel.FF_Devices
    ? _c(
        "div",
        { staticClass: "gmp-devices-page__device-detail" },
        [
          _c(
            "div",
            { staticClass: "device-detail-grid" },
            [
              _c(
                "div",
                { staticClass: "gds-space-stack-m" },
                [
                  _c("BackButton", {
                    staticClass: "device-detail-back-link",
                    attrs: { route: "/devices" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-space-stack-xl" },
                [
                  _vm.viewModel.device
                    ? _c("DeviceDetailSummary", {
                        attrs: { device: _vm.viewModel.device }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              !_vm.viewModel.loadState
                ? _c("div", { staticClass: "table-loading" })
                : _vm._e(),
              _vm._v(" "),
              _vm.viewModel.loadState
                ? [
                    _vm.peakEventsShouldShow
                      ? _c(
                          "h3",
                          {
                            staticClass:
                              "device-detail-section-title gds-space-stack-ml"
                          },
                          [_vm._v("\n        Peak Events\n      ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.peakEventsShouldShow
                      ? _c(
                          "div",
                          { staticClass: "gds-space-stack-xl" },
                          [
                            _c("PeakEvents", {
                              attrs: {
                                device: _vm.viewModel.device,
                                "energy-events": _vm.viewModel.device.events,
                                "on-manage-participation-update":
                                  _vm.onManageParticipationUpdate
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.quickStatsShouldShow
                      ? _c(
                          "h3",
                          {
                            staticClass:
                              "device-detail-section-title gds-space-stack-ml"
                          },
                          [_vm._v("Quick Stats")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.quickStatsShouldShow
                      ? _c(
                          "div",
                          { staticClass: "gds-space-stack-xl" },
                          [
                            _c("ImpactCardGrid", {
                              attrs: { impacts: _vm.viewModel.device.stats }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.evChargingHistoryShouldShow
                      ? _c(
                          "h3",
                          {
                            staticClass:
                              "device-detail-section-title gds-space-stack-ml"
                          },
                          [_vm._v("Charging History Preview")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.evChargingHistoryShouldShow
                      ? _c(
                          "div",
                          { staticClass: "gds-space-stack-xl" },
                          [
                            _c("EvChargingHistory", {
                              attrs: {
                                "ev-charging-history-data":
                                  _vm.viewModel.evHistoryChargeData
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.batteryChargeLevelShouldShow
                      ? _c(
                          "h3",
                          {
                            staticClass:
                              "device-detail-section-title gds-space-stack-ml"
                          },
                          [_vm._v("Battery Charge Level")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.batteryChargeLevelShouldShow
                      ? _c(
                          "div",
                          { staticClass: "gds-space-stack-xl" },
                          [
                            _c("BatteryChargeLevel", {
                              attrs: {
                                "battery-charge-data":
                                  _vm.viewModel.batteryChargeData
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.peakRateScheduleShouldShow
                      ? _c(
                          "h3",
                          {
                            staticClass:
                              "evice-detail-section-title gds-space-stack-ml"
                          },
                          [_vm._v("Peak Rate Schedule")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.peakRateScheduleShouldShow
                      ? _c(
                          "div",
                          { staticClass: "gds-space-stack-xl" },
                          [
                            _c("PeakRateSchedule", {
                              attrs: {
                                "peak-rate-schedule":
                                  _vm.viewModel.device.peakRateSchedule
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "device-detail-section-title gds-space-stack-ml"
                      },
                      [_vm._v("Device Info")]
                    ),
                    _vm._v(" "),
                    _vm.viewModel.deviceInfo
                      ? _c(
                          "div",
                          { staticClass: "gds-space-stack-xl" },
                          [
                            _c("DeviceInfo", {
                              attrs: { "device-info": _vm.viewModel.deviceInfo }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "device-detail-section-title gds-space-stack-ml"
                      },
                      [_vm._v("Request Service")]
                    ),
                    _vm._v(" "),
                    _c("DevicesServiceRequestLink")
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("AutoTimer", {
            attrs: {
              "repeat-mode": true,
              "timer-seconds": 60,
              "on-timer-execution": _vm.refreshDeviceDetail
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }