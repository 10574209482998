<template>
  <section
    class="gds-flex-container gds-flex-container--top gds-flex-container--left devices-column-gap-xxl"
    :class="{ 'gds-flex-container--column' : !isDesktopView }"
  >
    <section>
      <div class="gds-space-stack-ml devices-width-100">
        <div class="gds-font-demi gds-space-stack-s">Program</div>
        <div class="gds-font-book gds-font-size-m"> {{ deviceInfo.program }}</div>
      </div>
      <div class="gds-space-stack-ml devices-width-100">
        <div class="gds-font-demi gds-space-stack-s">Enrollment Date</div>
        <div class="gds-font-book gds-font-size-m"> {{ deviceInfo.enrollmentDate }}</div>
      </div>
      <div class="gds-space-stack-ml devices-width-100">
        <div class="gds-font-demi gds-space-stack-s">Device Owner</div>
        <div class="gds-font-book gds-font-size-m">{{ deviceInfo.deviceOwner }}</div>
      </div>
      <div class="gds-space-stack-ml devices-width-100">
        <div class="gds-font-demi gds-space-stack-s">Rate</div>
        <div class="gds-font-book gds-font-size-m">
          <a href="">{{ deviceInfo.rateInfo.link }}</a>
        </div>
      </div>
    </section>
    <section class="devices-width-80">
      <div class="gds-space-stack-ml devices-max-width-300">
        <div class="gds-font-demi gds-space-stack-s">Program Information</div>
        <div class="gds-font-book gds-font-size-m">
          {{ deviceInfo.programInformation }}
        </div>
      </div>
      <div class="gds-space-stack-ml devices-width-100">
        <div v-if="deviceInfo.documents.length" class="gds-font-demi gds-space-stack-s">Documents & Links</div>
        <div
          v-for="(document, index) in deviceInfo.documents"
          :key="index"
          class="gds-flex-container gds-flex-container--left gds-space-stack-s"
        >
          <img
            class="gds-space-inline-m"
            src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-document.svg"
            alt="Grey document icon"
          >
          <div class="gds-font-book gds-font-size-m">
            <a :href="document.link">{{ document.name }}</a>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';

export default {
  name: 'DeviceInfo',
  mixins: [MediaQueryMixin],
  props: {
    deviceInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
};
</script>

<style scoped></style>
