<template>
  <div v-if="viewModel.FF_Devices" class="gmp-devices-page__device-detail">
    <div class="device-detail-grid">
      <div class="gds-space-stack-m">
        <BackButton class="device-detail-back-link" :route="'/devices'" />
      </div>
      <div class="gds-space-stack-xl">
        <DeviceDetailSummary v-if="viewModel.device" :device="viewModel.device" />
      </div>
      <div v-if="!viewModel.loadState" class="table-loading" />
      <template v-if="viewModel.loadState">
        <h3
          v-if="peakEventsShouldShow"
          class="device-detail-section-title gds-space-stack-ml"
        >
          Peak Events
        </h3>
        <div v-if="peakEventsShouldShow" class="gds-space-stack-xl">
          <PeakEvents
            :device="viewModel.device"
            :energy-events="viewModel.device.events"
            :on-manage-participation-update="onManageParticipationUpdate"
          />
        </div>
        <h3 v-if="quickStatsShouldShow" class="device-detail-section-title gds-space-stack-ml">Quick Stats</h3>
        <div v-if="quickStatsShouldShow" class="gds-space-stack-xl">
          <ImpactCardGrid :impacts="viewModel.device.stats" />
        </div>
        <h3 v-if="evChargingHistoryShouldShow" class="device-detail-section-title gds-space-stack-ml">Charging History Preview</h3>
        <div v-if="evChargingHistoryShouldShow" class="gds-space-stack-xl">
          <EvChargingHistory :ev-charging-history-data="viewModel.evHistoryChargeData" />
        </div>
        <h3 v-if="batteryChargeLevelShouldShow" class="device-detail-section-title gds-space-stack-ml">Battery Charge Level</h3>
        <div v-if="batteryChargeLevelShouldShow" class="gds-space-stack-xl">
          <BatteryChargeLevel :battery-charge-data="viewModel.batteryChargeData" />
        </div>
        <h3 v-if="peakRateScheduleShouldShow" class="evice-detail-section-title gds-space-stack-ml">Peak Rate Schedule</h3>
        <div v-if="peakRateScheduleShouldShow" class="gds-space-stack-xl">
          <PeakRateSchedule :peak-rate-schedule="viewModel.device.peakRateSchedule" />
        </div>
        <h3 class="device-detail-section-title gds-space-stack-ml">Device Info</h3>
        <div v-if="viewModel.deviceInfo" class="gds-space-stack-xl">
          <DeviceInfo :device-info="viewModel.deviceInfo" />
        </div>
        <h3 class="device-detail-section-title gds-space-stack-ml">Request Service</h3>
        <DevicesServiceRequestLink />
      </template>
    </div>
    <AutoTimer
      :repeat-mode="true"
      :timer-seconds="60"
      :on-timer-execution="refreshDeviceDetail"
    />
  </div>
</template>

<script>
import MediaQueryMixin from '../../../mixins/MediaQueryMixin';
import DeviceDetailViewModel from './viewmodels/DeviceDetailViewModel';

import DeviceInfo from './deviceinfo/DeviceInfo.vue';
import PeakEvents from './peakevents/PeakEvents.vue';
import AutoTimer from '../../../shared/autotimer/AutoTimer.vue';
import EvChargingHistory from './EvChargingHistory/EvChargingHistory.vue';
import BackButton from '../shared/components/formcomponents/BackButton.vue';
import BatteryChargeLevel from './batterychargelevel/BatteryChargeLevel.vue';
import DeviceDetailSummary from './devicedetailsummary/DeviceDetailSummary.vue';
import ImpactCardGrid from '../shared/components/impactcard/ImpactCardGrid.vue';

import PeakRateSchedule from './peakrateschedule/PeakRateSchedule.vue';
import DevicesServiceRequestLink from '../shared/components/DevicesServiceRequestLink.vue';

export default {
  name: 'DeviceDetail',
  components: {
    AutoTimer,
    BackButton,
    PeakEvents,
    DeviceInfo,
    ImpactCardGrid,
    PeakRateSchedule,
    EvChargingHistory,
    BatteryChargeLevel,
    DeviceDetailSummary,
    DevicesServiceRequestLink,
  },
  mixins: [MediaQueryMixin],
  props: {
    device: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      viewModel: new DeviceDetailViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    batteryChargeLevelShouldShow() {
      const vm = this.viewModel;
      return vm.deviceType === vm.constants.DEVICE_TYPE_BATTERY && vm.batteryChargeData.length;
    },
    evChargingHistoryShouldShow() {
      const vm = this.viewModel;
      return vm.deviceType === vm.constants.DEVICE_TYPE_EV_CHARGER && vm.evHistoryChargeData.length;
    },
    peakRateScheduleShouldShow() {
      const vm = this.viewModel;
      return Boolean(vm.device && vm.device.peakRateSchedule);
    },
    peakEventsShouldShow() {
      const vm = this.viewModel;
      return Boolean(vm.device && vm.device.displayEvents && vm.device.events);
    },
    quickStatsShouldShow() {
      const vm = this.viewModel;
      return Boolean(vm.device && vm.device.stats && vm.device.stats.length);
    },
  },
  watch: {
    currentAccount() {
      this.$router.push('/devices');
    },
  },
  async mounted() {
    const vm = this.viewModel;
    await vm.checkFeatureFlag();

    vm.deviceId = this.$route.params.id;

    if (this.device) {
      vm.device = this.device;
      vm.getChartData(this.currentAccount.accountNumber);

      if (vm.device.status && vm.device.status.operationalMode) {
        vm.device.status.operationalMode = DeviceDetailViewModel.FormatOperationalMode(vm.device.status.operationalMode);
      }
    }

    await vm.getDeviceDetail(this.currentAccount.accountNumber, this.GetDeviceDetailQueryString(), false);
    await vm.getDeviceInfo(this.currentAccount.accountNumber, vm.deviceId, true);

    if (!vm.chartDataLoaded) {
      await this.viewModel.getChartData(this.currentAccount.accountNumber);
    }
  },
  methods: {
    onManageParticipationUpdate() {
      this.refreshDeviceDetail();
    },
    GetDeviceDetailQueryString() {
      return DeviceDetailViewModel.GetDeviceDetailQueryString(this.currentAccount);
    },
    refreshDeviceDetail() {
      this.viewModel.getDeviceDetail(this.currentAccount.accountNumber, this.GetDeviceDetailQueryString(), true);
    },
  },
};
</script>
