var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-account__login-page-wrap" }, [
    _c(
      "section",
      { staticClass: "my-account__login-form-wrap" },
      [
        _c("div", { staticClass: "gds-display-2 gds-space-stack-m" }, [
          _vm._v(_vm._s(_vm.destinationTitle))
        ]),
        _vm._v(" "),
        _vm.loadState === undefined
          ? _c("div", { staticClass: "table-loading" })
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "error"
          ? _c("flow-error", {
              attrs: {
                name: "Logging in",
                state: "error",
                phonetext:
                  "To report an outage or make a payment by phone call us at"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "maintenance"
          ? _c("flow-error", {
              attrs: {
                name: "Logging in",
                state: "maintenance",
                phonetext:
                  "To report an outage or make a payment by phone call us at"
              }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "complete" && _vm.userIsLoggedIn
          ? _c("div", [
              _c("p", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(
                  "You are already logged in. Do you want to log out, or continue?"
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "gds-button gds-secondary gds-space-inline-m",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.Logout()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "gds-button__text" }, [
                    _vm._v("Log out")
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "gds-button gds-space-inline-m",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.Continue()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "gds-button__text" }, [
                    _vm._v("Continue")
                  ])
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.loadState === "complete" && !_vm.userIsLoggedIn
          ? _c("div", [
              _c("div", { staticClass: "gds-font-size-l gds-space-stack-m" }, [
                !_vm.isGuest
                  ? _c("div", {
                      staticClass: "gds-space-stack-m",
                      domProps: {
                        innerHTML: _vm._s(_vm.destinationDescription)
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isGuest
                  ? _c("div", {
                      staticClass: "gds-space-stack-m",
                      domProps: {
                        innerHTML: _vm._s(_vm.destinationDescriptionGuestLogin)
                      }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.allowGuestLogin
                ? _c(
                    "div",
                    {
                      staticClass:
                        "my-account__login-form-wrap__guest-login-toggle-container"
                    },
                    [
                      !_vm.isGuest
                        ? _c(
                            "div",
                            {
                              staticClass: "gds-font-size-l gds-space-stack-m"
                            },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.renderGuestForm()
                                    }
                                  }
                                },
                                [
                                  _vm._v("No account? "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "link-appearance",
                                      attrs: {
                                        title:
                                          "Login using your account number and phone last four"
                                      }
                                    },
                                    [_vm._v("Log in as a guest!")]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isGuest
                        ? _c(
                            "div",
                            {
                              staticClass: "gds-font-size-l gds-space-stack-m"
                            },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.renderLoginForm()
                                    }
                                  }
                                },
                                [
                                  _vm._v("Have an account? "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "link-appearance",
                                      attrs: {
                                        title:
                                          "Login using your email and password"
                                      }
                                    },
                                    [_vm._v("Log in!")]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showPayByPhoneAndMail
                ? _c(
                    "div",
                    { staticClass: "gds-font-size-s gds-space-stack-s" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", [_vm._v("Green Mountain Power")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("P.O. Box 1325")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("Williston VT 05495-1325")])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isGuest || _vm.isOnetimePaymentRoute
                ? _c(
                    "form",
                    {
                      staticClass: "global-panel__form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.GuestLogin()
                        }
                      }
                    },
                    [
                      _c(
                        "fieldset",
                        {
                          staticClass: "gds-fieldset gds-space-stack-m",
                          class: { "gds-has-error": _vm.errorWrongNumber }
                        },
                        [
                          _c("label", { staticClass: "gds-input-field" }, [
                            _c(
                              "span",
                              { staticClass: "gds-input-field__label" },
                              [_vm._v("Account Number")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.accountNumber,
                                  expression: "accountNumber"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "###########",
                                  expression: "'###########'"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|numeric|min:10|max:11",
                                  expression: "'required|numeric|min:10|max:11'"
                                }
                              ],
                              key: "account number",
                              staticClass: "gds-input-field__input",
                              attrs: { name: "account number", type: "tel" },
                              domProps: { value: _vm.accountNumber },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.accountNumber = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.first("account number")
                              ? _c("div", { staticClass: "validation-error" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("account number"))
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        {
                          staticClass: "gds-fieldset gds-space-stack-m",
                          class: { "gds-has-error": _vm.errorWrongNumber }
                        },
                        [
                          _c("label", { staticClass: "gds-input-field" }, [
                            _c(
                              "span",
                              { staticClass: "gds-input-field__label" },
                              [_vm._v("Phone Number (Last 4 Digits)")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.phone,
                                  expression: "phone"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "####",
                                  expression: "'####'"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|numeric|min:4|max:4",
                                  expression: "'required|numeric|min:4|max:4'"
                                }
                              ],
                              key: "phone number",
                              staticClass: "gds-input-field__input",
                              attrs: { name: "phone number", type: "tel" },
                              domProps: { value: _vm.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.phone = $event.target.value
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.errors.first("phone number")
                              ? _c("div", { staticClass: "validation-error" }, [
                                  _vm._v(
                                    _vm._s(_vm.errors.first("phone number"))
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "gds-space-stack-l" }, [
                        _vm.errorloginGuest
                          ? _c(
                              "div",
                              { staticClass: "gds-helper-text-error" },
                              [_vm._v(_vm._s(_vm.errorloginGuest))]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "gds-space-stack-l" }, [
                        _c(
                          "button",
                          {
                            staticClass: "gds-button",
                            class: { "gds-loading": _vm.pendingLogin },
                            attrs: {
                              disabled: _vm.pendingLogin,
                              type: "submit"
                            }
                          },
                          [
                            _c("span", { staticClass: "gds-button__text" }, [
                              _vm._v("Log In")
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(2)
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isGuest && !_vm.isOnetimePaymentRoute
                ? _c(
                    "form",
                    {
                      staticClass: "global-panel__form",
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.Login()
                        }
                      }
                    },
                    [
                      _c(
                        "fieldset",
                        {
                          staticClass: "gds-fieldset gds-space-stack-m",
                          class: { "gds-has-error": _vm.errorlogin }
                        },
                        [
                          _c("label", { staticClass: "gds-input-field" }, [
                            _c(
                              "span",
                              { staticClass: "gds-input-field__label" },
                              [_vm._v("Username or Email")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.username,
                                  expression: "username"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "gds-input-field__input",
                              attrs: {
                                id: "username",
                                type: "text",
                                spellcheck: "false",
                                autocapitalize: "none",
                                autocomplete: "username",
                                required: "",
                                "aria-required": "true"
                              },
                              domProps: { value: _vm.username },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.username = $event.target.value
                                }
                              }
                            })
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "fieldset",
                        {
                          staticClass: "gds-fieldset gds-space-stack-m",
                          class: { "gds-has-error": _vm.errorlogin }
                        },
                        [
                          _c(
                            "label",
                            { staticClass: "gds-input-field" },
                            [
                              _c(
                                "span",
                                { staticClass: "gds-input-field__label" },
                                [_vm._v("Password")]
                              ),
                              _vm._v(" "),
                              _c("widget-password-input", {
                                attrs: { autocomplete: "current-password" },
                                model: {
                                  value: _vm.password,
                                  callback: function($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "global-login-panel__form-helper" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "gds-checkbox gds-checkbox-thin"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.stayloggedin,
                                        expression: "stayloggedin"
                                      }
                                    ],
                                    attrs: {
                                      type: "checkbox",
                                      id: "stay-logged-in"
                                    },
                                    domProps: {
                                      checked: Array.isArray(_vm.stayloggedin)
                                        ? _vm._i(_vm.stayloggedin, null) > -1
                                        : _vm.stayloggedin
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = _vm.stayloggedin,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.stayloggedin = $$a.concat([
                                                $$v
                                              ]))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.stayloggedin = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.stayloggedin = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "gds-checkbox__faux"
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "gds-checkbox__label gds-display-00 gds-text-grey"
                                    },
                                    [_vm._v("Stay Logged In")]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(3)
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "gds-space-stack-l" }, [
                        _vm.errorlogin
                          ? _c(
                              "div",
                              {
                                staticClass: "gds-helper-text-error",
                                attrs: { id: "submission_error_non_guest" }
                              },
                              [_vm._v(_vm._s(_vm.errorlogin))]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "gds-space-stack-l" }, [
                        _c(
                          "button",
                          {
                            staticClass: "gds-button",
                            class: { "gds-loading": _vm.pendingLogin },
                            attrs: {
                              disabled: _vm.pendingLogin,
                              type: "submit"
                            }
                          },
                          [
                            _c("span", { staticClass: "gds-button__text" }, [
                              _vm._v("Log In")
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm._m(4)
                    ]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("strong", [_vm._v("Pay By Phone")]),
      _vm._v(" (844) 551-4550")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("Pay By Mail")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-panel__form-helpers" }, [
      _c(
        "p",
        {
          staticClass:
            "gds-align--text-center gds-body-small gds-link gds-link--bold"
        },
        [
          _vm._v("\n            Don't have an account?\n            "),
          _c("a", { attrs: { href: "/account/create" } }, [
            _vm._v("Get Started")
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-login-panel__form-helper" }, [
      _c(
        "a",
        {
          staticClass: "gds-display-00 gds-link gds-link--bold",
          attrs: { href: "/account/forgot" }
        },
        [_vm._v("Forgot Password?")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-panel__form-helpers" }, [
      _c(
        "p",
        {
          staticClass:
            "gds-align--text-center gds-body-small gds-link gds-link--bold"
        },
        [
          _vm._v("\n            Don't have an account?\n            "),
          _c("a", { attrs: { href: "/account/create" } }, [
            _vm._v("Get Started")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }