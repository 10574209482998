<template>
  <div class="gmp-devices-text-area-input-with-html devices-max-width-600">
    <label
      class="gds-space-stack-s gds-font-demi gds-font-size-xl"
      :for="config.name"
    >
      {{ config.title || '' }}
    </label>
    <div
      v-if="config.html"
      class="v-html gds-space-stack-s"
      v-html="config.html"
    />
    <div class="v-html gds-space-stack-s">
      <textarea
        :id="config.name"
        :rows="config.rows || 5"
        :maxlength="config.maxlength || 100"
        class="text-input"
        :placeholder="config.placeholder || ''"
        :value="config.value"
        :name="config.name"
        :class="{ 'is-desktop-view': isDesktopView }"
        @input="updateValue"
      />
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../../mixins/MediaQueryMixin';

export default {
  name: 'TextInputWithHtml',
  mixins: [MediaQueryMixin],
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
