<template>
  <div class="devices-service-request-link">
    <div class="devices-max-width-600">
      <div class="devices-gap-1" :class="{ 'gds-flex-container': isDesktopView }">
        <img
          class="devices-service-request-link__image"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-installer-van.svg"
          alt="Service Truck"
        >
        <div class="gds-space-stack-m gds-font-book gds-font-size-m">
          <p>
            If you’re having issues with a device, we’re here to help. Click below
            or contact us at <a href="tel:8888354672">(888) 835-4672</a>.
          </p>
          <router-link to="/devices/service-request">
            <button class="gds-button gds-button-outline gds-compact">Request Service</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';

export default {
  name: 'DevicesServiceRequestLink',
  mixins: [MediaQueryMixin],
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
        || this.deviceIsExtraExtraLarge
        || this.deviceExceedsExtraExtraLarge;
    },
  },
};
</script>
