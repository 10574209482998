<template>
  <div class="usage-dashboard__widget-usage-trend">
    <div class="header">
      <h2>Usage Trend</h2>
      <a
        href="/account/usage/detail/graph"
        class="usage-dashboard__circle-arrow-right gds-button gds-button-circle gds-button-outline"
      >
        <svg class="gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"/>
          </svg
      ></a>
    </div>
    <div class="body">
      <div v-if="subtextKwh && subtext">
        <span class="usage-dashboard__widget-usage-trend--subtextKwh">{{
          subtextKwh
        }}</span>
        <span class="usage-dashboard__widget-usage-trend--subtext">{{
          subtext
        }}</span>

        <span
          v-if="selectedBarMonth"
          class="usage-dashboard__widget-usage-trend--subtext"
        >
          <a
            :href="
              '/account/usage/detail/graph' +
                '?startDate=' +
                selectedBarMonth +
                '&resolution=daily'
            "
            >View</a>
        </span>
      </div>
    </div>
    <div v-if="loadState === 'complete'">
      <div
        ref="widgetUsageTrend"
        id="usage-dashboard__widget-usage-trend--chart"
        class="gds-position--absolute"
      />
    </div>
    <div v-if="loading" class="my-account__usage-loading" />
    <div v-if="loadState === 'unavailable'" class="error-message">
      Usage information is not available for this account.
    </div>
    <div v-if="loadState === 'error'" class="error-message">
      Unable to load, please try again later.
    </div>
  </div>
</template>

<script>
import Highcharts from "highcharts";
import { DumpError, ToServerDateTruncate } from "../../../../utilities";
import GMPAPI from "../../../../services/gmpapi";

export default {
  name: "WidgetUsageTrend",
  data() {
    return {
      loading: undefined,
      loadState: undefined,
      chart: undefined,
      displaySubtext: false,
      subtextKwh: undefined,
      subtext: undefined,
      averageUsage: undefined,
      selectedBarMonth: undefined,
      HOVER_COLOR: '#be6c24',
      DEFAULT_COLOR: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1, },
        stops: [
          [0, "#fd9b30"],
          [1, "#fe812f"],
        ]
      }
    };
  },
  watch: {
    currentAccount() {
      this.refreshChart();
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    this.refreshChart()
  },
  methods: {
    async refreshChart() {
      this.resetStateVariables();

      try {
        const data = await this.getMonthlyUsageData();
        if (!data && !data.intervals && !data.intervals[0] && !data.intervals[0].values.length) {
          this.loadState = 'unavailable';
          return;
        }
        const [months, usageValues] = this.getRecentUsageData(data.intervals[0].values);
          this.averageUsage = this.getAverageUsage(usageValues);
          this.setDefaultSubtext(this.averageUsage);

          this.drawChart(this.$refs.widgetUsageTrend, months, usageValues);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          this.loadState = "unavailable";
          return;
        }
        this.loadState = 'error';
        DumpError("Error getting usage data.", err);
      } finally {
        this.loading = false;
      }
    },

    async getMonthlyUsageData() {
      const startDate = new Date();
      const endDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - 1);

        this.loading = true;
        const response = await GMPAPI.GetPeriodUsage(this.currentAccount.accountNumber, "monthly", ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
        this.loadState = 'complete';

        return response;
    },

    getRecentUsageData(data) {
      const today = new Date();
      const monthsBack = 6;
      const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - monthsBack, 1);
      const lastSixMonths = [];
      const consumedTotals = [];

      data.forEach(data => {
        const month = new Date(data.date);
        const consumedTotal = data.consumedTotal;

        if (month > sixMonthsAgo && month <= today && data.consumedTotal > 0) {
          lastSixMonths.push({
            monthShort: month.toLocaleString('en-US', { month: 'short' }),
            monthFull: month.toISOString(),
            year: month.getFullYear()
          });
          consumedTotals.push(consumedTotal);
        }
      });

      return [lastSixMonths, consumedTotals];
    },

    drawChart(renderingDiv, months, usageValues) {
      var vm = this;

      this.chart = Highcharts.chart(renderingDiv, {
        chart: {
          type: 'column',
          style: {
            fontFamily: 'MaisonNeue, Helvetica, sans-serif',
            fontWeight: '800',
            padding: '0.5rem'
          },
        },
        tooltip: {
          enabled: false
        },
        title: {
          text: null,
        },
        credits: false,
        xAxis: {
          categories: months,
          lineWidth: 0,
          labels: {
            formatter: function() {
              return this.value.monthShort;
            },
            style: {
              fontSize: '1rem',
              fontWeight: '800',
              color: '#6F7383'
            }
          }
        },
        yAxis: {
          min: 0,
          labels: { enabled: false },
          title: { enabled: false },
          gridLineWidth: 0,
          plotLines: [{
            value: vm.averageUsage,
            color: 'rgb(32, 32, 32, 0.2)',
            zIndex: 5,
            label: {
              useHTML: true,
              formatter: function() {
                return `<div class='usage-trend-plotline-label'><span><i class='value'>Avg</i></span></div>`;
              },
              align: 'left',
              x: -19,
              y:5
            }
        }]
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            cursor: 'pointer',

            point: {
              events: {
                mouseOver: function() { vm.onBarMouseOver(this, usageValues); },
                mouseOut: function() { vm.onBarMouseOut(this); },
                click: function() { vm.onBarClick(this, usageValues); }
              }
            }
          }
        },
        series: [{
          name: 'Monthly Usage',
          showInLegend: false,
          data: usageValues,
          groupPadding: 0.1,
          borderRadius: 4,
          color: this.DEFAULT_COLOR,
          states: {
            hover: {
              enabled: true,
              color: this.HOVER_COLOR
            },
            inactive: {
              enabled: false
            }
          }
        },]
      });
    },

    onBarMouseOver(point, usageValues) {
      //If there are no selected bars then show this bars usage value
      let clicked = false;
      point.series.data.forEach(p => {
        if(p.options.clicked) {
          clicked = true;
        }
      });

      if(!clicked) {
        this.setSubtext(usageValues[point.index], point.category);
      }
    },

    onBarMouseOut(point) {
      //If there are no currently selected bars, reset the subtext to the averag usage
      let clicked = false;
      point.series.data.forEach(p => {
        if(p.options.clicked) {
          clicked = true;
        }
      });

      if(!clicked) {
        this.setDefaultSubtext(this.averageUsage);
      }
    },

    onBarClick(point, usageValues) {
      //Mark this bar as selected and updae the subtext

      this.setSubtext(usageValues[point.index], point.category);

      const index = point.index;

      point.series.data.forEach(p => {
        p.color = this.DEFAULT_COLOR;

        if (p.index !== index) {
          p.options.clicked = false;
        }
      });

      if(point.options.clicked) {
        //Point was previously picked, set it back to default color and mark it was not clicked
        point.color = this.DEFAULT_COLOR;
        point.options.clicked = false;

        this.setSelectedMonth(undefined);
      } else {
        //Point being clicked from default state, update color and also set ability to drill into detail
        point.color = this.HOVER_COLOR;
        point.options.clicked = true;

        this.setSelectedMonth(point.category.monthFull);
      }

      this.chart.redraw();
    },

    getAverageUsage(usageValues) {
      const avg = usageValues.reduce((a, b) => a + b, 0) / usageValues.length;
      return avg.toFixed(0);
    },
    
    setSubtext(usageValue, month) {
      this.subtextKwh = usageValue.toFixed(0) + " kWh";
      this.subtext = " in " + month.monthShort + (month.monthShort === 'May' ? " " : ". ") + month.year;
    },

    setDefaultSubtext(averageUsage) {
      this.subtextKwh = averageUsage + " kWh";
      this.subtext = " monthly average";
    },

    setSelectedMonth(month) {
      this.selectedBarMonth = month;
    },

    resetStateVariables() {
      if (this.averageUsage) { this.averageUsage = undefined; }
      if (this.subtext) { this.subtext = undefined; }
      if (this.subtextKwh) { this.subtextKwh = undefined; }
      this.loadState = undefined;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>