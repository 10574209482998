var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-usage--dashboard" },
    [
      _vm.testDriveUsage
        ? _c("banner-dual-action", {
            staticClass: "gds-flex-container",
            attrs: {
              bannerColor: "blue",
              message:
                "You are trying out our new usage section and we’d love to hear what you think. Click this link to provide feedback: ",
              linkOneText: "Provide Feedback.",
              messageTwo: "Or you can use this link to: ",
              linkTwoText: "Switch Back to the Previous Usage View"
            },
            on: {
              linkOneClicked: _vm.showProvideFeedBackModal,
              linkTwoClicked: _vm.goToLegacyUsage
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("h1", { staticClass: "my-account__title gds-space-stack-default" }, [
        _vm._v("Usage")
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "usage-dashboard___sub-title gds-space-stack-l" },
        [_vm._v("\n      How clean electricity is powering your life.\n    ")]
      ),
      _vm._v(" "),
      _vm.loadState === undefined || _vm.loadState === "loading"
        ? _c("div", { staticClass: "table-loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState !== "complete"
        ? _c(
            "div",
            { staticClass: "usage-page--interior-status-container" },
            [
              _vm.loadState === "error"
                ? _c("flow-error", {
                    attrs: { name: "Usage information", state: "error" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loadState === "maintenance"
                ? _c("flow-error", {
                    attrs: { name: "Usage information", state: "maintenance" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.loadState === "unavailable"
                ? _c("flow-error", {
                    attrs: {
                      name: "Usage information",
                      state: "unavailable",
                      img:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c(
            "div",
            [
              _c("div", { staticClass: "usage-dashboard__jump-nav" }, [
                _c(
                  "nav",
                  { staticClass: "usage-detail__router-link-container" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "usage-detail__nav-item router-link-active",
                        attrs: { href: "/account/usage" }
                      },
                      [
                        _c("div", { staticClass: "usage-detail__nav-img" }, [
                          _c(
                            "div",
                            { staticClass: "gds-icon gds-flex-container" },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "15",
                                    height: "15",
                                    viewBox: "0 0 15 15",
                                    fill: "none"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z",
                                      fill: "#00704A"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "usage-detail__nav-item__text" },
                            [_vm._v("Dashboard")]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    !_vm.isNetMetered
                      ? _c(
                          "a",
                          {
                            staticClass: "usage-detail__nav-item",
                            attrs: { href: "/account/usage/detail/time-of-day" }
                          },
                          [_vm._m(2)]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.FF_usageEvCharging &&
                    _vm.currentAccount &&
                    _vm.currentAccount.evCharger
                      ? _c(
                          "a",
                          {
                            staticClass: "usage-detail__nav-item",
                            attrs: { href: "/account/usage/detail/ev-charging" }
                          },
                          [_vm._m(3)]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(4),
                    _vm._v(" "),
                    _vm._m(5)
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "usage-dashboard-grid",
                  class: { "is-net-metered": _vm.isNetMetered }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "usage-dashboard__widget usage-widget__graph"
                    },
                    [
                      _vm.bothFeatureFlagAndIsNetmeteredConditionsChecked ===
                        2 &&
                      (!_vm.FF_NetMeteredDashboard ||
                        (_vm.FF_NetMeteredDashboard && !_vm.isNetMetered))
                        ? _c("div", [_c("widget-monthly-consumption")], 1)
                        : _c(
                            "div",
                            {
                              staticClass:
                                "usage-dashboard-widget-usage-trend-wrapper usage-dashboard-grid-short--height-auto"
                            },
                            [
                              _c("widget-monthly-consumption", {
                                attrs: { isNetMetered: _vm.isNetMetered }
                              })
                            ],
                            1
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "usage-dashboard__widget usage-widget__table"
                    },
                    [_c("widget-daily-consumption")],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isNetMetered
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "usage-dashboard__widget usage-widget__net-metering-diagram",
                          attrs: {
                            id: "usage-dashboard__widget-net-metering-container"
                          }
                        },
                        [
                          _vm.bothFeatureFlagAndIsNetmeteredConditionsChecked ===
                            2 &&
                          _vm.FF_NetMeteredDashboard &&
                          _vm.isNetMetered
                            ? _c("widget-net-metering", {
                                attrs: {
                                  hasNarrowContainer:
                                    _vm.widgetNetMeteringHasNarrowContainer,
                                  loadState: _vm.loadState,
                                  billingSummary: _vm.billingSummary,
                                  isDashboardView: _vm.isDashboardView,
                                  showDetailArrow: _vm.showDetailArrow,
                                  summaryGeneration: _vm.summaryGeneration,
                                  summaryTextGeneration:
                                    _vm.summaryTextGenerationForNetMeteringWidget,
                                  summaryIconTotalGeneration:
                                    _vm.summaryIconTotalConsumptionForNetMeteringWidget
                                },
                                on: {
                                  "change-options-event":
                                    _vm.handleOptionChangeEvent
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isNetMetered
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "usage-dashboard__widget usage-widget__performance-graph"
                        },
                        [
                          _vm.bothFeatureFlagAndIsNetmeteredConditionsChecked ===
                            2 &&
                          (!_vm.FF_NetMeteredDashboard ||
                            (_vm.FF_NetMeteredDashboard && !_vm.isNetMetered))
                            ? _c("widget-current-usage", {
                                on: {
                                  "change-options-event":
                                    _vm.handleOptionChangeEvent
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isNetMetered
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "usage-dashboard__widget usage-widget__time-of-day usage-dashboard-widget-time-of-day-wrapper"
                        },
                        [_c("widget-time-of-day")],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-space-stack-l" },
                [
                  _c("UsageVisualizationAndTools", {
                    attrs: { isNonNetMeteredAccount: !_vm.isNetMetered }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-space-stack-l" },
                [_c("usage-notification")],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-space-stack-l" },
                [_c("usage-export")],
                1
              ),
              _vm._v(" "),
              _c("modal-floating-feedback", {
                attrs: { visible: _vm.showFeedback },
                on: {
                  close: function($event) {
                    _vm.showFeedback = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-detail__nav-item",
        attrs: { href: "/account/usage/detail/graph" }
      },
      [
        _c("div", { staticClass: "usage-detail__nav-img" }, [
          _c("img", {
            staticClass: "non-green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg"
            }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "usage-detail__nav-item__text" }, [
            _vm._v("Graph")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-detail__nav-item",
        attrs: { href: "/account/usage/detail/table" }
      },
      [
        _c("div", { staticClass: "usage-detail__nav-img" }, [
          _c("img", {
            staticClass: "non-green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg"
            }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "usage-detail__nav-item__text" }, [
            _vm._v("Table")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usage-detail__nav-img" }, [
      _c("img", {
        staticClass: "non-green-svg",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "green-svg",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg"
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "usage-detail__nav-item__text" }, [
        _vm._v("Time Of Day")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "usage-detail__nav-img" }, [
      _c("img", {
        staticClass: "non-green-svg",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon.svg"
        }
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "green-svg",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon-green.svg"
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "usage-detail__nav-item__text ev-charging" }, [
        _vm._v("EV Charging")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-detail__nav-item",
        attrs: { href: "/account/usage/detail/performance" }
      },
      [
        _c("div", { staticClass: "usage-detail__nav-img" }, [
          _c("img", {
            staticClass: "non-green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg"
            }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "usage-detail__nav-item__text" }, [
            _vm._v("Performance")
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-detail__nav-item",
        attrs: { href: "/account/usage/detail/compare" }
      },
      [
        _c("div", { staticClass: "usage-detail__nav-img" }, [
          _c("img", {
            staticClass: "non-green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg"
            }
          }),
          _vm._v(" "),
          _c("img", {
            staticClass: "green-svg",
            attrs: {
              src:
                "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "usage-detail__nav-item__text" }, [
            _vm._v("Compare")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }