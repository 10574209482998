const gmpConstants = gmp_constants;
const gmpSettings = gmp_settings;

const  stormModeEnabled = gmpSettings.gmp_use_stand_alone_api === "1";
let marketingCards = [];
// Not available on all pages
if (typeof marketing_cards === "object" && Array.isArray(marketing_cards)) {
  marketingCards = marketing_cards;
  for (const card of marketingCards) {
    if (card.link === "") card.link = undefined;
    if (card.link && card.link.target === "") card.link.target = undefined;
    if (card.link && card.link.url === "") card.link.url = undefined;
    if (card.link && card.link.title === "") card.link.title = undefined;
    if (card.image === "") card.image = undefined;
  }
}

let environmentConstants;
if (gmpConstants && gmpConstants.dev_mode === "0") {
  // PRODUCTION constants
  environmentConstants = {
    DevMode: false,
    GmpApiURL: "https://api.greenmountainpower.com/api/v2",
    GmpPayApiURL: "https://api.greenmountainpower.com/api/v2",
    HomePageURL: "/",
    BillReportURL: "https://billreport.greenmountainpower.com",
    SpeedpaySSOURL: "https://internet.speedpay.com/api/greenmountain/sso?cid=greenmountain",
    StatusURL: "https://greenmountainpower.statuspage.io/api/v2",
    OutageRefreshIntervalMS: 120000,
    ConfigCatSDKKey: "fuLZCAMsA0iA9mBd-KBdnw/SBaiiu1KvUSngl-PcdGXDQ",
    CaptchaPublicKey: '0x4AAAAAAAEdl7B05T316ROS',
    ConfigCatSDKKey: "configcat-sdk-1/fuLZCAMsA0iA9mBd-KBdnw/2X3y8TPbSkOIrXWhKM5bDA",
    GmpStandAloneApiURL: stormModeEnabled ? "https://api2.greenmountainpower.com" : "https://api.greenmountainpower.com/api/v2"
  };
} else {
  // DEV constants
  environmentConstants = {
    DevMode: true,
    GmpApiURL: "https://apitst.greenmountainpower.com/api/v2",
    GmpPayApiURL: "https://apitst.greenmountainpower.com/api/v2",
    HomePageURL: "/",
    BillReportURL: "https://billreportdev.greenmountainpower.com",
    SpeedpaySSOURL: "https://batinternet.speedpay.com/api/greenmountain/sso?cid=greenmountain",
    StatusURL: "https://gmpstaging.statuspage.io/api/v2",
    OutageRefreshIntervalMS: 120000,
    ConfigCatSDKKey: "configcat-sdk-1/fuLZCAMsA0iA9mBd-KBdnw/7HaOuoIu_UazJURLVMrw8Q",

    LocalGmpAPIURL: "http://localhost:9000/api/v2",
    CaptchaPublicKey: '0x4AAAAAAAEdl7B05T316ROS',
    //CaptchaPublicKey: '2x00000000000000000000BB' //Always fail (for testing)
    GmpStandAloneApiURL: stormModeEnabled ? "https://api2.greenmountainpower.com" : "https://apitst.greenmountainpower.com/api/v2"
  };
}

console.log((environmentConstants.DevMode ? "Development" : "Production") + " mode");

export const EnvironmentConstants = environmentConstants;

// Note: if phone number / email are blank in your local dev environment, make sure you have
// set them in the wordpress admin panel: Theme Settings > Contact Information
export const GMPPhoneNumber = gmpSettings.phone_number;

export const EICEmail = "energyinnovationteam@greenmountainpower.com"

export const GMPEmail = gmpSettings.email_address;

export const MarketingCards = marketingCards;

export const DefaultChatQueue = "MYACCOUNT";

export const GitHash = (gmpConstants && gmpConstants.theme_version) ? gmpConstants.theme_version : "unknown";

export const AccountWarningCount = 75;
