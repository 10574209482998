<template>
  <div class="gmp-devices-text-input-with-html">
    <label
      class="gds-space-stack-s gds-font-demi gds-font-size-xl"
      :for="config.name"
    >
      {{ config.title || '' }}
    </label>
    <div
      v-if="config.html"
      class="v-html gds-space-stack-s"
      v-html="config.html"
    />
    <input
      :id="config.name"
      class="text-input"
      :placeholder="config.placeholder || ''"
      :value="config.value"
      :name="config.name"
      :class="{ 'is-desktop-view': isDesktopView }"
      @input="updateValue"
    >
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../../mixins/MediaQueryMixin';

export default {
  name: 'TextInputWithHtml',
  mixins: [MediaQueryMixin],
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
