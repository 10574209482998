<template>
  <div class="eicproduct-container">
    <div v-if="loadState === undefined" class="eicproduct__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="eicproduct__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error"
      phonetext="For further help, please call us at" closepath="/ebike-rebate" />
    <div v-if="loadState === 'complete'">
      <!-- SLAT 1 Verify Customer Information -->
      <section id="ebike-slat-1" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="eicproduct-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Customer Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="!userIsOTP" class="gds-body-normal gds-space-inline-m">Account: {{ currentAccount.nickname }}
                </div>
                <div v-if="userIsOTP" class="gds-body-normal gds-space-inline-m">Account Number: {{
                currentAccount.accountNumber
                }}</div>
                <button @click="gotoPage(1)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <!-- SLAT 1 Edit Screen For Account Information -->

          <!-- User Is Guest -->
          <div v-if="currentPage === 1 && userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                eBike rebate.</legend>
              <label :key="currentAccount.accountNumber"
                class="eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m">
                <div class="eicproduct__account-box gds-space-stack-l">
                  <span v-if="currentAccount">
                    <div class="row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName? currentAccount.lastName: ''}${currentAccount.firstName
                        && currentAccount.lastName? ',':
                        ''} ${currentAccount.firstName? currentAccount.firstName : ''}` | titlecase() }}</span>
                      </div>

                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </label>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- User Has WWW Account -->
          <div v-if="currentPage === 1 && !userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                eBike rebate.</legend>
              <div class="eicproduct__account-box gds-space-stack-l">
                <span v-if="currentAccount">
                  <div class="row-m">
                    <div class="account-info-row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName? currentAccount.lastName: ''}${currentAccount.firstName
                        && currentAccount.lastName? ',':
                        ''} ${currentAccount.firstName? currentAccount.firstName : ''}` | titlecase() }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                    <div v-if="accounts.length > 1" @click="chooseAccount()"
                      class="inline accountInformation eicproduct__edit-account-details">
                      <button class="gds-button gds-text-button choose-account-button">
                        <svg class="eicproduct__edit-icon-inline gds-icon">
                          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                        </svg>
                        <span v-if="accounts.length > 1">Switch Account</span>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 Product Rebate Details -->
      <section id="ebike-slat-2" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="eicproduct-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your eBike Details</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-m">Total Savings: ${{ totalSavings }}</div>
                <button @click="gotoPage(2)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 2" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 2" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="rebate-slap__inner-row">
                <div class="row-s">
                  <form data-vv-scope="page2">
                    <fieldset class="gds-fieldset gds-space-stack-s">
                      <label>
                        <span class="gds-input-field__label">How many bike or retrofit kit rebates are you applying
                          for?</span>
                        <select @change="selectNumOfBikes(numberOfBikes)" v-model="numberOfBikes"
                          v-validate="'required'" name="number of bikes" key="number of bikes"
                          class="eicproduct-input-field-l__select gds-input-field" autofocus>
                          <option :value="undefined" disabled>Select number of bikes or retrofit kits</option>
                          <option :value="1">1</option>
                          <option :value="2">2</option>
                          <option :value="3">3</option>
                          <option :value="4">4</option>
                          <option :value="5">5</option>
                          <option :value="6">6</option>
                          <option :value="7">7</option>
                          <option :value="8">8</option>
                          <option :value="9">9</option>
                        </select>
                      </label>
                      <div v-for="(bike, i) in productSubmission.electricBikes" :key="i" class="gds-space-stack-l">
                        <div class="flex-item margin-right">
                          <div style="display: flex; justify-content: space-between;">
                            <h2 style="align-self: center;">Bike / Retrofit #{{ i + 1 }}</h2>
                            <button v-if="numberOfBikes > 1" @click="removeBike(i)"
                              class="gds-button gds-button-circle" style="margin: 3rem;"><svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg></button>
                          </div>
                          <legend class="gds-display-0 gds-space-stack-ml">Is this a new bike or Retrofit kit? (Used
                            bikes are ineligible)
                          </legend>
                          <label class="gds-radio">
                            <input v-model="productSubmission.electricBikes[i].newOrRetrofit" v-validate="'required'"
                              value="new" type="radio" key="New eBike or Retrofit kit"
                              :name="`New eBike or Retrofit kit #${i + 1}`" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">New eBike</span>
                          </label>
                          <label class="gds-radio">
                            <input v-validate="'required'" v-model="productSubmission.electricBikes[i].newOrRetrofit"
                              value="retrofit" type="radio" key="New eBike or Retrofit kit"
                              :name="`New eBike or Retrofit kit #${i + 1}`" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">Retrofit Kit</span>
                          </label>
                          <div v-if="errors.first(`New eBike or Retrofit kit #${i + 1}`, 'page2')"
                            class="eicproduct-validation-error">
                            {{
                            errors.first(`New eBike or Retrofit kit #${i + 1}`, "page2")
                            }}</div>


                          <label>
                            <span class="gds-input-field__label">Dealer name</span>
                            <input v-model="productSubmission.electricBikes[i].dealerName" v-validate="'required'"
                              :name="`dealer name for bike ${i + 1}`" key="dealer name"
                              class="gds-input-field__input gds-space-stack-m" type="text" autocomplete="off"
                              placeholder="Type Here..." />
                            <div v-if="errors.first(`dealer name for bike ${i + 1}`, 'page2')"
                              class="eicproduct-validation-error">{{
                              errors.first(`dealer name for bike ${i + 1}`, "page2")
                              }}</div>
                          </label>

                          <label @click.prevent class="gds-input-field">
                            <span class="gds-input-field__label">Purchase Date</span>
                            <div class="eicproduct-purchasedate__date-box gds-space-stack-m">
                              <div class="my-account-billing-report__filtration-date-icon">
                                <svg class="gds-icon">
                                  <use
                                    xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                                </svg>
                              </div>
                              <datepicker ref="datePicker" v-model="productSubmission.electricBikes[i].dateOfPurchase"
                                :name="`date of purchase of bike ${i + 1}`" key="date of purchase"
                                v-validate="'required'" format="MMMM d, yyyy" :typeable="true"
                                placeholder="Select Date..." :disabled-dates="disableDates"
                                input-class="export-data__date my-account-billing-report__filtration-date-text" />
                            </div>
                            <div v-if="errors.first(`date of purchase of bike ${i + 1}`, 'page2')"
                              class="eicproduct-validation-error">{{
                              errors.first(`date of purchase of bike ${i + 1}`, "page2")
                              }}</div>
                          </label>

                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Pre-tax purchase price of
                              {{ productSubmission.electricBikes[i].newOrRetrofit === 'new' ? 'eBike' : 'Retrofit Kit'
                              }}?</span>
                            <div class="gds-position--relative gds-space-stack-m">
                              <span class="gds-input-field__prefix gds-text">$</span>
                              <money v-model="productSubmission.electricBikes[i].purchasePrice"
                                v-validate="'required|min_value:500|max_value:9999999'"
                                :name="`eBike purchase price of bike ${i + 1}`"
                                :key="`eBike purchase price of bike ${i + 1}`"
                                class="bill-pay-input-field__input gds-input-field__input">
                              </money>
                            </div>
                            <div v-if="errors.first(`eBike purchase price of bike ${i + 1}`, 'page2')"
                              class="eicproduct-validation-error">{{
                              "The minimum purchase price must be $500"
                              }}</div>
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <button v-if="productSubmission.electricBikes.length > 0"
                  @click="finishPage('page2', 3)" class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 3 Upload Documents -->
      <section id="ebike-slat-3" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="eicproduct-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Upload Documents</div>
                <ModalEICProducts modalName="missingDocument" v-if="showMissingDocumentsModal" :close="closeModal"
                  modalTitle="You’re missing a required document" modalMessage="Please upload a copy of the following:"
                  :listItems="missingDocuments" />
                <ModalEICProducts modalName="fileLoadTooBig" v-if="showFileLoadTooBigModal" :close="closeModal"
                  :modalMessage="`Your total file size is ${this.$options.filters.fileSizeFormat(totalFileSize)}. Please make sure the combined size of all uploaded files is less than ${this.$options.filters.fileSizeFormat(maxFileLoadSize)}.`" />
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="documents.length > 1" class="gds-body-normal gds-space-inline-s">{{ `${this.documents.length}
                Files Uploaded`}}</div>
                <div v-if="documents.length === 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.documents.length} File Uploaded` }}</div>
                <button @click="gotoPage(3)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div class="slat-title-wrapper">
                  <form>
                    <div id="fileUploader">
                      <File-Uploader :uploadDocumentList="uploadDocumentList" :docTypes="documentTypes"
                        @file-list-changed="updateFileList"></File-Uploader>
                    </div>
                  </form>
                </div>
                <button @click="finishPage('page3', 4)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 Contact & Mailing Address -->
      <section id="ebike-slat-4" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 4" class="eicproduct-slat__number-with-circle">
                <span>4</span>
              </div>
              <div v-if="currentPage > 4" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Contact & Mailing Address</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 4" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-s">Mailing Address: {{
                productSubmission.rebate.rebateMailingAddress | addressFormat({ seperator: ", " })
                }}</div>
                <button @click="gotoPage(4)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 4" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page4">
                  <div class="gds-space-stack-l">
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Please make my check out to:</span>
                      <input v-model="productSubmission.rebate.rebatePayeeName" @input="$emit('touched', true)"
                        v-validate="'required'" name="rebate payee name" key="rebate payee name"
                        class="eicproduct-input-field gds-input-field__input" type="text" ref="focuser4" />
                      <div v-if="errors.first('rebate payee name', 'page4')" class="eicproduct-validation-error">{{
                      errors.first("rebate payee name", "page4")
                      }}</div>
                    </label>
                  </div>
                  <div class="slat-title-wrapper">
                    <div class="eicproduct-title">Where should we send your rebate check?</div>
                  </div>
                  <label class="gds-input-field gds-space-stack-l">
                    <span class="gds-input-field__label">Country</span>
                    <select v-model="productSubmission.rebate.rebateMailingAddress.country"
                      :class="productSubmission.rebate.rebateMailingAddress.country === 'USA' ? 'eicproduct-input-field__select-country-us gds-input-field gds-space-stack-m' : productSubmission.rebate.rebateMailingAddress.country === 'CAN' ? 'eicproduct-input-field__select-country-ca gds-input-field' : 'eicproduct-input-field__select-s gds-input-field'"
                      autofocus>
                      <option :value="'USA'" selected
                        data-thumbnail="/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg">United States
                      </option>
                      <option value="CAN">Canada</option>
                      <option value="FOR">Foreign</option>
                    </select>
                    <div v-if="!productSubmission.rebate.rebateMailingAddress.country" class="eicproduct-validation-error">
                      {{ errors.first("country", "page4") }}</div>
                  </label>
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Street Address</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street1"
                      @input="$emit('touched', true)" v-validate="'required'" name="street address" key="street address"
                      class="eicproduct-input-field gds-input-field__input gds-space-stack-m" type="text" placeholder="Type Here..." />
                    <div v-if="errors.first('street address', 'page4')" class="eicproduct-validation-error">{{
                    errors.first("streetaddress", "page4")
                    }}</div>
                  </label>
                  <label class="gds-input-field">
                    <span class="gds-input-field__label">Suite Apt</span>
                    <input v-model="productSubmission.rebate.rebateMailingAddress.street2"
                      @input="$emit('touched', true)" name="street 2" key="street 2"
                      class="eicproduct-input-field gds-input-field__input gds-space-stack-m" type="text" placeholder="Type Here..." />
                  </label>
                  <div class="row-l">
                    <div class="flex-item-l">
                      <label class="gds-input-field-flex">
                        <span class="gds-input-field__label">City</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.city"
                          @input="$emit('touched', true)" v-validate="'required'" name="city" key="city"
                          class="gds-input-field__input" type="text" placeholder="Type Here..." />
                        <div v-if="errors.first('city', 'page4')" class="eicproduct-validation-error">{{ errors.first("city",
                        "page4")
                        }}</div>
                      </label>
                    </div>
                    <div class="flex-item-s gds-space-stack-m">
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">State</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select State...</option>
                          <option v-for="state of stateList" :key="state" :value="state">{{ state }}</option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("state", "page4") }}</div>
                      </label>
                      <label v-show="productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="gds-input-field">
                        <span class="gds-input-field__label">Province</span>
                        <select v-model="productSubmission.rebate.rebateMailingAddress.state"
                          @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                          <option value disabled selected>Select Province...</option>
                          <option v-for="province of provinceList" :key="province" :value="province">{{ province }}
                          </option>
                        </select>
                        <div v-show="!productSubmission.rebate.rebateMailingAddress.state"
                          class="eicproduct-validation-error">{{ errors.first("province", "page4") }}</div>
                      </label>
                    </div>
                    <div></div>
                    <div class="flex-item-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Zipcode</span>
                        <input v-model="productSubmission.rebate.rebateMailingAddress.zip"
                          @input="$emit('touched', true)" v-validate="'required|numeric|length:5'" name="zipcode"
                          key="zipcode" class="gds-input-field__input eicproduct-mailing-zip" type="text"
                          placeholder="Type Here..." autocomplete="postal-code" />
                        <div v-if="errors.first('zipcode', 'page4')" class="eicproduct-validation-error">{{ errors.first("zipcode",
                        "page4")
                        }}</div>
                      </label>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                        class="eicproduct-validation-error">{{ errors.first("zip", "page4") }}</div>
                      <div
                        v-show="!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                        class="eicproduct-validation-error">{{ errors.first("canadianZip", "page4") }}</div>
                    </div>
                  </div>
                  <div>
                    <div class="eicproduct-title gds-space-stack-l">How should we contact you if we need additional information to process
                      your rebate:</div>
                    <div class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Email Address</span>
                        <input v-model="productSubmission.rebate.confirmationEmailAddress"
                          @input="$emit('touched', true)" v-validate="'required|email'" name="email address"
                          key="email address" class="eicproduct-input-field gds-input-field__input" type="email"
                          placeholder="Your email" autocomplete="email" ref="email" />
                        <div v-if="errors.first('email address', 'page4')" class="eicproduct-validation-error">{{
                        errors.first("email address", "page4")
                        }}</div>
                      </label>
                    </div>
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Phone Number</span>
                      <div class="gds-space-stack-xl">
                        <the-mask v-model="productSubmission.rebate.contactPhoneNumber" :mask="'+1 (###) ###-####'"
                          @input.native="touched = true" v-validate="'required|min:10'" data-vv-validate-on="input"
                          :name="'phone number'" :key="'phone number'" class="eicproduct-input-field gds-input-field__input"
                          type="tel" placeholder="Your phone number" />
                        <div v-if="errors.first('phone number', 'page4')" class="eicproduct-validation-error">{{
                        errors.first("phone number", "page4")
                        }}</div>
                      </div>
                    </label>
                    <div v-if="!isAlreadyEbillEnrolled">
                      <div class="gds-body-small gds-space-stack-l">
                        <span class="gds-display-1 gds-space-stack-s">Would you like to go paperless?</span>
                      </div>
                      <div class="gds-body-small gds-space-stack-l slat3-body">
                        You can reduce your carbon footprint even more,
                        by switching to paperless billing! Paperless monthly energy
                        statements cut carbon and clutter while saving trees.
                      </div>
                      <div class="gds-space-stack-ml">
                        <!-- Faux file picker - wraps it in a label and hides the input -->
                        <label class="gds-checkbox gds-checkbox-thin">
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEnroll" type="checkbox" name />
                          <span class="gds-checkbox__faux"></span>
                          <span class="gds-font-size-ml">
                            <b>Yes!</b> - Enroll me in paperless billing so I can reduce my carbon footprint even more.
                          </span>
                        </label>
                      </div>
                      <div v-if="productSubmission.programEnrollment.ebill.ebillEnroll" class="gds-space-stack-ml">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Account email address</span>
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEmailAddress"
                            v-validate="'required|email'" @input="$emit('touched', true)" name="account email address"
                            key="account email address" class="eicproduct-input-field gds-input-field__input" type="email"
                            placeholder="Your email" autocomplete="email" ref="email" />
                          <div v-if="errors.first('account email address', 'page4')" class="eicproduct-validation-error">{{
                          errors.first("account email address", "page4")
                          }}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
                <button @click="finishPage('page4', 5)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 5 Review & Submit-->
      <section id="ebike-slat-5" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 5" class="eicproduct-slat__number-with-circle">
                <span>5</span>
              </div>
              <div v-if="currentPage > 5" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Review & Submit</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage < 5" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 5" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div class="gds-display-00 gds-space-stack-s" ref="focuser6">Your rebate will be mailed to:</div>
                <div class="gds-body-small gds-space-stack-s">{{ productSubmission.rebate.rebatePayeeName | titlecase()
                }}</div>
                <div class="gds-body-small gds-space-stack-l">{{ productSubmission.rebate.rebateMailingAddress |
                addressFormat({ separator: ", " })
                }}</div>
                <div class="gds-space-stack-l">
                  <div class="gds-display-00 gds-space-stack-s">Your Rebate Information:</div>
                  <div class="gds-body-small gds-space-stack-s">Total incentive of ${{ this.totalSavings }}</div>
                  <div v-for="(bike, i) in productSubmission.electricBikes" :key="i"
                    class="gds-body-small gds-space-stack-s">
                    <div>Type: {{ toTitleCase(productSubmission.electricBikes[i].newOrRetrofit) }}</div>
                    <div>Bike #{{ i + 1 }} in the amount of ${{ eBikeRebateAmount }}</div>
                  </div>
                </div>
              <div>
              <Household-Income 
                :productSubmission="productSubmission" 
                :metadata="metadata"
                :lastYear="lastYear"
                @household-income-changed="updateHouseholdIncome"
                ref="householdIncome">
              </Household-Income>
            </div>
                <div class="gds-space-stack-m">
                  <label class="gds-checkbox gds-checkbox-thin">
                    <input v-model="regulatoryDataSharing" v-validate="'required'" type="checkbox"
                      key="regulatory data sharing" name="regulatory data sharing" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-font-size-ml regulatory-text1">
                      {{ REGULATORY_REBATE_SHARING_TEXT1  }}
                    </span>
                    <p class="gds-font-size-ml regulatory-text2">
                      {{ REGULATORY_REBATE_SHARING_TEXT2  }}
                    </p>
                    <div v-if="errors.first('regulatory data sharing', 'page5')"
                      class="eicproduct-validation-error">{{
                      errors.first("regulatory data sharing", "page5")
                      }}</div>
                  </label>
                </div>
                <div class="gds-space-stack-l">
                  <label class="gds-checkbox gds-checkbox-thin">
                    <input v-model="fossilFuelReplacementAgreement" v-validate="'required'" type="checkbox"
                      key="fossil fuel replacement agreement" name="fossil fuel replacement agreement" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-font-size-ml">
                      <b>Yes!</b> Electric {{ numberOfBikes > 1 ? 'bikes' : 'bike' }} will be used as a replacement
                      for
                      fossil fuel vehicle commuting.
                    </span>
                    <div v-if="errors.first('fossil fuel replacement agreement', 'page5')"
                      class="eicproduct-validation-error">{{
                      errors.first("fossil fuel replacement agreement", "page5")
                      }}</div>
                  </label>
                </div>
                <button v-if="!pendingSubmit" 
                  @click="handleSubmit" type="submit" 
                  :disabled="pendingSubmit || !fossilFuelReplacementAgreement || !regulatoryDataSharing"
                  class="gds-button gds-compact">
                  Submit Rebate
                </button>
                <button v-if="pendingSubmit" 
                  class="gds-button [modifier class] gds-loading">
                  <span class="gds-button__text">Submit Rebate</span>
                  <figure class="gds-button__icon"></figure>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ErrorBag } from "vee-validate";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import Datepicker from 'vuejs-datepicker';
import { proofOfPurchase, w9, eBikeRebateAmount, regulatoryRebateSharingText1, regulatoryRebateSharingText2 } from "../constants.js";
import ModalEICProducts from "../ModalEICProducts.vue";
import FileUploader from "../FileUploader.vue";
import HouseholdIncome from "../HouseholdIncome.vue";
import EICProductsMixin from "../EICProductsMixin";
import { getConfigValue } from "../../../services/featureflags";

const date = new Date();
date.setDate(date.getDate() + 1);

export default {
  name: "eBikeRebateFlow",
  mixins: [
    EICProductsMixin
  ],
  components: {
    FileUploader,
    Datepicker,
    ModalEICProducts,
    HouseholdIncome
  },
  data() {
    return {
      disableDates: {
        to: new Date(2022, 5, 1),
        from: date
      },
      metadata: undefined,
      isKeyboarding: false,
      loadState: undefined,
      lastYear: new Date().getFullYear() - 1,
      pendingSubmit: undefined,
      touched: false,
      pendingSave: undefined,
      isAlreadyEbillEnrolled: undefined,
      fossilFuelReplacementAgreement: undefined,
      regulatoryDataSharing: undefined,
      householdIncomeDropdown: [],
      eBikeRebateAmount: eBikeRebateAmount,

      totalFileSize: undefined,
      maxFileLoadSize: undefined,

      stateList: ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"],
      provinceList: ["QC", "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "SK", "NT", "NU", "YT"],

      proofOfPurchase: proofOfPurchase,
      w9: w9,
      REGULATORY_REBATE_SHARING_TEXT1: undefined,
      REGULATORY_REBATE_SHARING_TEXT1_DEFAULT: regulatoryRebateSharingText1,
      REGULATORY_REBATE_SHARING_TEXT2: undefined,
      REGULATORY_REBATE_SHARING_TEXT2_DEFAULT: regulatoryRebateSharingText2,
      showDocumentChecklist: false,
      showMissingDocumentsModal: false,
      showFileLoadTooBigModal: false,
      selectedFile: undefined,
      file: undefined,
      documents: [],
      documentTypes: [],
      selectedDocumentType: undefined,
      missingDocuments: [],
      uploadDocumentList: [{
        documentName: w9,
        displayName: 'IRS W-9',
        error: undefined,
        file: null,
        fileName: null,
        fileSize: null,
      }],
      numberOfBikes: undefined,
      productSubmission: {
        submissionType: "electricBikeRebate",
        account: {
          accountNumber: undefined,
          firstName: undefined,
          lastName: undefined,
          premiseAddress: {
            street1: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined,
          },
          mailingAddress: {
            city: undefined,
            country: undefined,
            state: undefined,
            street1: undefined,
            zip: undefined
          }
        },
        electricBikes: [],
        rebate: {
          rebatePayeeName: undefined,
          confirmationEmailAddress: undefined,
          contactPhoneNumber: undefined,
          rebateDeliveryMethod: "Mailed Check",
          householdIncome: {
            willShareIncome: '',
            grossHouseholdIncomeRange: '',
            numberInHousehold: ''
          },
          rebateMailingAddress: {
            street1: undefined,
            street2: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined,
          },
          rebateMailingAddressOverride: false,
        },
        programEnrollment: {
          ebill: {
            ebillEnroll: undefined,
            ebillEmailAddress: undefined,
          },
        },
      }
    }
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    totalSavings() {
      if (this.eBikeRebateAmount && this.productSubmission.electricBikes.length) {
        return  this.eBikeRebateAmount * this.productSubmission.electricBikes.length;
      }
    }
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.currentPage === 2) {
        this.isKeyboarding = true;
      } else {
        this.isKeyboarding = false;
      }
    });
  },
  async mounted() {
    this.initializePage();
    this.registerEventListeners();

    try {
      this.loadState = undefined;
      await this.loadProductSubmission();
      const formattedDate = this.getCurrentFormattedDate();
      await this.fetchInitialData(formattedDate);
      this.setupHouseholdIncomeDropdown();
      this.setRebateSubmissionAccount();
      this.loadState = "complete";
    } catch (err) {
      this.handleLoadingError(err);
    }

    this.setupTimers();
  },
  methods: {
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },

    initializePage() {
      this.$store.commit("setLastValidPage", 'page1');
    },

    registerEventListeners() {
      document.addEventListener("click", this.hideTypeAhead);
    },

    async loadProductSubmission() {
      const localStorageProductSubmissionObj = this.getWithExpiry("localProductSubmission") || undefined;

      if (localStorageProductSubmissionObj?.submissionType === 'electricBikeRebate') {
        this.productSubmission = localStorageProductSubmissionObj;
        this.numberOfBikes = this.productSubmission.electricBikes.length;
        this.selectNumOfBikes(this.numberOfBikes);
        this.buildUploadDocumentList();
      } else {
        this.addNewBike();
        this.numberOfBikes = this.productSubmission.electricBikes.length;
      }
    },

    async fetchInitialData(formattedDate) {
      await Promise.all([
        this.checkIfOnEBill(),
        this.getEICMeta(this.productSubmission.submissionType, formattedDate),
        this.REGULATORY_REBATE_SHARING_TEXT1 = await getConfigValue(
          'TXT_RebateRegulatorySharingText1',
          this.REGULATORY_REBATE_SHARING_TEXT1_DEFAULT,
          null
        ),
        this.REGULATORY_REBATE_SHARING_TEXT2 = await getConfigValue(
          'TXT_RebateRegulatorySharingText2',
          this.REGULATORY_REBATE_SHARING_TEXT2_DEFAULT,
          null
        )
      ]);
    },

    setupHouseholdIncomeDropdown() {
      this.householdIncomeDropdown = this.buildIncomeDropDown(
        this.metadata.incomeIncentives,
        this.productSubmission.rebate.householdIncome.numberInHousehold
      );
    },

    handleLoadingError(err) {
      DumpError("eBike loggedin load error", err);
      this.loadState = "error";
    },

    setupTimers() {
      setTimeout(() => {
        this.slatFocus(1);
      }, 1000);

      setTimeout(() => {
        this.resetState();
      }, 1000 * 60 * 60 * 6); // flush local storage & reload page after 6 hours
    },

    getCurrentFormattedDate() {
      return new Date().toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric"
      });
    },

    buildUploadDocumentList() {
      let bikeNumber = 1;
      for (let i = 0; i < this.uploadDocumentList.length; i++) {
        if (this.uploadDocumentList[i].documentName.includes('proofOfPurchase')) {
          this.uploadDocumentList[i].displayName = `Proof of Purchase for condenser ${bikeNumber}`
          bikeNumber++;
        }
      } 
    },

    selectNumOfBikes(value) {
      if (value < this.productSubmission.electricBikes.length) {
        const diff = this.productSubmission.electricBikes.length - value
        for (let i = 0; i < diff; i++) {
          this.productSubmission.electricBikes.pop();
          this.uploadDocumentList.pop();
        }
      } else if (value > this.productSubmission.electricBikes.length) {
        const diff = value - this.productSubmission.electricBikes.length
        for (let i = 0; i < diff; i++) {
          this.addNewBike();
        }
      } else if (value === this.productSubmission.electricBikes.length) {
        for (let i = 0; i < value; i++) {
          this.uploadDocumentList.push(
            {
              UUID: this.productSubmission.electricBikes[i].UUID,
              documentName: 'proofOfPurchase ' + this.productSubmission.electricBikes[i].UUID,
              error: undefined,
              file: null,
              fileName: null,
              fileSize: null,
            }
          );
        }
      }
    },
    addNewBike() {
      const UUID = this.generateUUID();
      const emptyNewBikeObject = {
        dateOfPurchase: undefined,
        newOrRetrofit: 'new',
        purchasePrice: undefined,
        dealerName: undefined,
        UUID: UUID,
      };
      this.productSubmission.electricBikes.push(emptyNewBikeObject);
      this.uploadDocumentList.push(
        {
          UUID: UUID,
          documentName: 'proofOfPurchase ' + UUID,
          displayName: `Proof of Purchase for bike ${this.uploadDocumentList.length}`,
          error: undefined,
          file: null,
          fileName: null,
          fileSize: null,
        }
      );
    },
    removeBike(i) {
      for (let index = 0; index < this.uploadDocumentList.length; index++) {
        if (this.productSubmission.electricBikes[i].UUID === this.uploadDocumentList[index].UUID) {
          this.uploadDocumentList.splice(index, 1);
        }
      }
      this.productSubmission.electricBikes.splice(i, 1);
      this.numberOfBikes = this.productSubmission.electricBikes.length;
    },
    toTitleCase(str) {
      if(!str) { return str; }
      return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },
    async checkIfOnEBill() {
      const status = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber);
      if (status.billRouteInformation.isEBill) {
        this.isAlreadyEbillEnrolled = true;
      }
    },

    /** Go to a page number without validation */
    gotoPage(page) {
      this.isKeyboarding = false;
      this.$router.push({ path: "/form/" + `${page}/` });
      this.slatFocus(page);
    },
    chooseAccount() {
      this.isKeyboarding = false;
      this.$router.push({ path: "/account" });
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        var elem = document.getElementById(`ebike-slat-${page}`);
        elem.scrollIntoView();
      }, 100);
    },
    setRebateSubmissionAccount() {
      this.productSubmission.rebate.rebatePayeeName = [
        this.toTitleCase(this.currentAccount.firstName?.trim()),
        this.toTitleCase(this.currentAccount.lastName?.trim())
      ].filter(Boolean).join(' ');
      
      this.productSubmission.account.accountNumber = this.currentAccount.accountNumber;
      this.productSubmission.account.firstName = `${this.toTitleCase(this.currentAccount.firstName)}`;
      this.productSubmission.account.lastName = `${this.toTitleCase(this.currentAccount.lastName)}`;

      this.productSubmission.account.mailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.account.premiseAddress = this.currentAccount.address;
      this.productSubmission.rebate.rebateMailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.rebate.contactPhoneNumber = this.currentAccount.phone;
      this.productSubmission.rebate.confirmationEmailAddress = this.currentAccount.emailAddress;
    },
    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      localStorage.removeItem("localProductSubmission")
      this.setWithExpiry("localProductSubmission", this.productSubmission, (1000 * 60 * 60 * 6)); // 6 hours
      let currentPage = nextPage - 1;
      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            break;
          case "page2":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();

            let bikeNum = 1;
            for (let i = 0; i < this.uploadDocumentList.length; i++) {
              if (this.uploadDocumentList[i].documentName.includes('proofOfPurchase')) {
                this.uploadDocumentList[i].displayName = `Proof of Purchase for bike ${bikeNum}`
                bikeNum++;
              }
            }
            break;
          case "page3":
            this.totalFileSize = 0;
            this.missingDocuments.length = 0;
            if (this.documents && this.documents.length > 0) {
              let file;
              for (let i = 0; i < this.documents.length; i++) {
                if (this.documents[i].file) {
                  if (file != this.documents[i].file) {
                    this.totalFileSize += this.documents[i].file.size;
                  }
                  file = this.documents[i].file;
                }
                if (this.documents[i].file === null || this.documents[i].file === undefined) {
                  this.modalMessage = "You’re missing a required document";
                  this.showMissingDocumentsModal = true;
                  if (this.documents[i].documentName === w9) {
                    this.missingDocuments.push('IRS-W9');
                  } else if (this.documents[i].documentName.includes(proofOfPurchase)) {
                    this.missingDocuments.push(`Proof of purchase for bike ${i}`);
                  }
                }
              }
              if (this.totalFileSize > this.maxFileLoadSize) {
                this.showFileLoadTooBigModal = true;
                this.modalMessage = `Your total file size is ${this.totalFileSize}. Please make sure the file size is less than ${this.maxFileLoadSize}.`;
                break;
              }
            } else {
              for (let i = 0; i < this.numberOfBikes; i++) {
                this.missingDocuments.push(`Proof of Purchase for bike ${i + 1}`);
              }
              this.missingDocuments.push('IRS-W9');
              this.modalMessage = "You’re missing a required document";
              this.showMissingDocumentsModal = true;
            }
            break;
          case "page4":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;

          case "page5":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;
        }
        if (this.errors.any(pageName)) {
          this.slatFocus(currentPage);
        } else {
          this.$store.commit("setLastValidPage", pageName);
          this.gotoPage(nextPage);
        }
      }
    },
    closeModal(modalName) {
      if (modalName === 'missingDocument') {
        this.showMissingDocumentsModal = false;
        this.gotoPage(3);
      }
      if (modalName === 'documentChecklist') {
        if (this.selectedDocumentTypes) {
          const array = this.documentTypes.filter(type => !this.selectedDocumentTypes.includes(type));
          this.documentTypes = array;
        }
        this.selectedFile = undefined;
        this.showDocumentChecklist = false;
      }
      if (modalName === 'fileLoadTooBig') {
        this.showFileLoadTooBigModal = false;
        this.gotoPage(3);
      }
    },
    async handleSubmit() {
      const validator = this.$refs.householdIncome;
      if (await validator.validateAllFields('householdincome')) {
        try {
          this.pendingSubmit = true;

          if (!this.documents) {
            this.pendingSubmit = false;
            this.showMissingDocumentsModal = true;
            return;
          }

          // Filter file list so that duplicate files are not included in request
          const uniqueFiles = this.documents.filter((document, index, self) =>
            index === self.findIndex((d) => (
              d.file.name === document.file.name
            ))
          );

          const formdata = new FormData();
          // Append JSON data to FormData instance

          formdata.append('productSubmission', new Blob([JSON.stringify(this.productSubmission)], { type: "application/json" }));
          // Append uploaded files to FormData instance
          for (let i = 0; i < uniqueFiles.length; i++) {
            formdata.append('files', uniqueFiles[i].file);
          }

          localStorage.removeItem("localProductSubmission")
          await GMPAPI.SubmitEBikeRebate(this.productSubmission.account.accountNumber, formdata);
          this.$router.push({ path: "/complete" });
        } catch (err) {
          DumpError("eBike Rebate Submission error", err);
          if (err.response && err.response.data) {
            let code = err.response.data.status;
            if (err.response.data.message) {
              console.error(err.response.data.message);
              code = err.response.data.code;
              this.$router.push({ path: "/error/" + code });
            } else {
              this.$router.push({ path: "/error/" + code });
            }
          } else {
            this.$router.push({ path: "/error/" });
          }
        }
      }
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    resetState() {
      localStorage.removeItem("localProductSubmission");
      window.location.reload(true);
    },
    updateFileList(evt) {
      this.documents = evt;
    },
    async getEICMeta(productType, incentiveDate) {
      this.metadata = await GMPAPI.GetEICMeta(productType, incentiveDate);
      this.maxFileLoadSize = this.metadata.maxFileUploadSize;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>