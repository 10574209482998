<template>
  <div>
    <div v-if="!$attrs.url && $attrs.route && !$attrs.buttonClicked">
      <router-link
        :to="route"
        class="gds-button gds-button-text devices-back-button"
      >
        <ActionButton
          :text="$attrs.text"
          :css-class="$attrs.cssClass"
          :is-custom-secondary="$attrs.isCustomSecondary"
          :loading="$attrs.loading"
          :disabled="$attrs.disabled"
          :button-clicked="() => {}"
        />
      </router-link>
    </div>
    <div v-if="!$attrs.url & !$attrs.route && $attrs.buttonClicked">
      <div
        @click="$attrs.buttonClicked"
      >
        <ActionButton
          :text="$attrs.text"
          :css-class="$attrs.cssClass"
          :is-custom-secondary="$attrs.isCustomSecondary"
          :loading="$attrs.loading"
          :disabled="$attrs.disabled"
          :button-clicked="$attrs.buttonClicked"
        />
      </div>
    </div>
    <div v-if="$attrs.url && !$attrs.route && !$attrs.buttonClicked">
      <a :href="$attrs.url">
        <ActionButton
          :text="$attrs.text"
          :css-class="$attrs.cssClass"
          :is-custom-secondary="$attrs.isCustomSecondary"
          :loading="$attrs.loading"
          :disabled="$attrs.disabled"
          :button-clicked="() => {}"
        />
      </a>
    </div>
  </div>
</template>

<script>
import ActionButton from './formcomponents/ActionButton.vue';

export default {
  name: 'LinkButton',
  components: {
    ActionButton,
  },
};
</script>
