<template>
  <div class="gmp-modal">
    <div class="my-account__modal-box no-scroll-modal manage-participation-modal">
      <div class="event-participation">
        <h3 class="device-detail-section-title gds-space-stack-ml">Event Participation</h3>
        <button
          type="button"
          class="close gds-button gds-button-circle gds-secondary"
          @click="closeModal()"
        >
          <svg class="gds-icon close-icon">
            <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
          </svg>
        </button>
        <div class="event-details gds-flex-container gds-flex-container--left gds-flex-container--top">
          <img
            v-if="!vm.event.optedOut"
            class="participation-icon"
            src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-lg.svg"
            alt="Peak event icon"
          >
          <div>
            <div class="data-point gds-space-stack-m"><strong>Device:</strong>{{ device.deviceName }}</div>
            <div v-if="event.inProgress && event.timeRemaining" class="data-point gds-space-stack-m"><strong>Time Remaining:</strong>{{ event.timeRemaining }}</div>
            <div v-if="event.dateRangeForManageParticipationModal" class="data-point gds-space-stack-m"><strong>Scheduled Time:</strong>{{ event.dateRangeForManageParticipationModal }}</div>
            <div class="data-point gds-space-stack-m">
              <strong>Event Participation:</strong>
              <p>{{ vm.enrollmentStatus }}</p>
            </div>
          </div>
        </div>
        <div class="gds-space-stack-m">
          <div class="data-point gds-space-stack-m">Change Event Participation</div>
          <DevicesSelectControl
            :config="vm.selectConfigEnrollment"
            :option-selected="onOptionSelectedEnrollment"
          />
        </div>
        <ActionButton
          :disabled="!vm.newOptOutDefined"
          text="Save"
          css-class="manage-participation-modal"
          :loading="vm.pendingSave"
          :button-clicked="onSaveButtonClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '../components/formcomponents/ActionButton.vue';
import DevicesSelectControl from '../components/DevicesSelectControl.vue';
import ManageParticipationModalViewModel from './ManageParticipationModalViewModel';

export default {
  name: 'ManagParticipationModal',
  components: {
    ActionButton,
    DevicesSelectControl,
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
    closeModal: {
      type: Function,
      required: true,
      default: () => {},
    },
    onManageParticipationUpdate: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      vm: new ManageParticipationModalViewModel(this.event, this.device, this.closeModal, this.onManageParticipationUpdate),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  methods: {
    onOptionSelectedEnrollment($event) {
      this.vm.onOptionSelectedEnrollment($event);
    },
    onSaveButtonClicked() {
      this.vm.UpdateDeviceEventEnrollment(this.currentAccount.accountNumber);
    },
  },
};
</script>

<style scoped>
</style>
