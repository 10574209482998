import DataStore from "../../store";
import GMPAPI from "../../services/gmpapi";
import EICProductsLogin from "./EICProductsLogin.vue";

export function createLoginRoute() {
  return {
    path: "/login",
    component: EICProductsLogin,
    beforeEnter: async (to, from, next) => {
      try {
        DataStore.commit("setRouteLoading", true);
        await GMPAPI.GetCurrentUser();

        // We have auth, but may not have user info
        if (!DataStore.state.user.userinfo) {
          try {
            await DataStore.dispatch("AttemptCookieLogin");
            next();
          } catch (err) {
            next();
          }
        } else {
          next("/");
        }
      } catch (err) {
        next();
      } finally {
        DataStore.commit("setRouteLoading", false);
      }
    }
  };
}
