<template>
  <div class="hybrid-date-select-control">
    <div
      class="dropdown-container"
      :style="{ width: config.width || 'auto', borderRadius: config.borderRadius || borderRadiusDefault}"
      @click.stop="toggleDropdown"
    >
      <div
        class="dropdown-selected"
        :style="{ borderRadius: config.borderRadius || borderRadiusDefault }"
      >
        {{ selectedLabel }}
        <span :class="['dropdown-caret', { 'caret-open': dropdownIsOpen }]" />
      </div>
      <div
        v-if="dropdownIsOpen"
        class="dropdown-menu"
        :class="{ 'custom-date-range-visible': customDateRangeVisible }"
        :style="{ borderRadius: config.borderRadius || borderRadiusDefault }"
        @click.stop
      >
        <template v-if="customDateRangeVisible">
          <date-picker
            v-model="dateRange"
            v-click-outside="closeDropdown"
            value-type="format"
            :range="useDateRange"
            :multiple="multiSelctDates"
            :open="showDateRangePicker"
            :confirm="useConfirmButton"
            :append-to-body="appendToBody"
            @change="updateValues"
          />
        </template>
        <template v-if="!customDateRangeVisible">
          <div
            v-for="(option, index) in config.options"
            :key="index"
            :class="['dropdown-option', { 'selected-option': option.value === selectedValue }]"
            @click="selectOption(option.value)"
          >
            {{ option.label }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

const CUSTOM_VALUE = 'custom';
const RESIZE_EVENT_NAME = 'resize';
const ORIENTATION_CHANGE_EVENT_NAME = 'orientationchange';

export default {
  name: 'HybridDateSelectControl',
  components: { DatePicker },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    optionSelected: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      dateRange: [],
      dropdownIsOpen: false,
      showDateRangePicker: true,
      appendToBody: false,
      useDateRange: true,
      multiSelctDates: true,
      useConfirmButton: true,
      customDateRangeVisible: false,
      borderRadiusDefault: '4px',
      selectedValue: this.config.selectedValue,
      orientation: this.getCurrentOrientation(),
      orientationLast: '',
    };
  },
  computed: {
    selectedLabel() {
      const selectedOption = this.config.options.find((option) => option.value === this.selectedValue);
      return selectedOption ? selectedOption.label : 'Select Option';
    },
  },
  watch: {
    dropdownIsOpen() {
      this.dateRange = [];
    },
    config: {
      deep: true,
      immediate: true,
      handler(newConfig) {
        this.selectedValue = newConfig.selectedValue;
      },
    },
    'config.selectedValue': {
      immediate: true,
      handler(newVal) {
        this.selectedValue = newVal;
      },
    },
  },
  mounted() {
    this.orientationLast = this.orientation;
    window.addEventListener(RESIZE_EVENT_NAME, this.handleOrientationChange);
    window.addEventListener(ORIENTATION_CHANGE_EVENT_NAME, this.handleOrientationChange);
  },
  beforeDestroy() {
    window.removeEventListener(RESIZE_EVENT_NAME, this.handleOrientationChange);
    window.removeEventListener(ORIENTATION_CHANGE_EVENT_NAME, this.handleOrientationChange);
  },
  methods: {
    toggleDropdown() {
      this.dropdownIsOpen = !this.dropdownIsOpen;
      this.customDateRangeVisible = false;
    },
    closeDropdown() {
      this.dropdownIsOpen = false;
      this.customDateRangeVisible = false;
    },
    updateValues(value) {
      this.selectedValue = CUSTOM_VALUE;
      this.optionSelected(value);
      this.closeDropdown();
    },
    getCurrentOrientation() {
      return window.innerHeight > window.innerWidth ? 'Portrait' : 'Landscape';
    },
    selectOption(value) {
      if (value === CUSTOM_VALUE) {
        this.customDateRangeVisible = true;
        return;
      }

      this.selectedValue = value;
      this.customDateRangeVisible = false;
      this.dropdownIsOpen = false;
      this.optionSelected(value);
    },
    handleOrientationChange() {
      this.orientation = this.getCurrentOrientation();

      if (this.orientation !== this.orientationLast || window.innerWidth < 352) {
        this.$nextTick(() => this.closeDropdown());
      }

      this.orientationLast = this.orientation;
    },
  },
};
</script>

<style scoped>

</style>
