<template>
  <div class="device-peak-events-list">
    <ManageParticipationModal
      v-if="selectedEvent"
      :event="selectedEvent"
      :device="device"
      :close-modal="hideManageParticipationModal"
      :on-manage-participation-update="handleManageParticipationUpdate"
    />
    <template v-if="!hasFuturePeakEventsScheduled">
      <div class="gds-font-book gds-font-size-m gds-text-grey">There are no peak events scheduled for this device.</div>
    </template>
    <ul v-if="hasFuturePeakEventsScheduled">
      <li
        v-for="(event) of futureEnergyEvents"
        :key="event.id"
        class="device-peak-event-item gds-flex-container gds-flex-container--top gds-flex-container--left"
      >
        <template v-if="!event.expired">
          <img
            v-if="!event.optedOut"
            class="gds-space-inline-m"
            src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-lg.svg"
            alt="Purple leaf icon"
          >
          <img
            v-if="event.optedOut"
            class="gds-space-inline-m"
            src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-opt-out-lg.svg"
            alt="Purple leaf with minus sign icon"
          >
          <div>
            <span class="gds-font-demi">{{ event.inProgress ? 'Energy Event In Progress' : 'Energy Event Scheduled' }}</span>
            <span v-if="event.dateRange" class="gds-font-book">{{ event.dateRange }}</span>
            <span
              v-if="event.canOptOut"
              class="devices-underline devices-hover-cursor"
              @click="showManageParticipationModal(event)"
            >
              Manage participation.
            </span>
            <div class="gds-flex-container gds-flex-container--left">
              <span
                v-if="event.optedOut"
                class="gds-tag gds-secondary"
              >Opted Out</span>
              <span
                v-if="event.inProgress"
                class="event-in-progress-timer"
              >
                <event-in-progress-indicator />
                <span>{{ event.timeRemaining }}</span>
              </span>
            </div>
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import ManageParticipationModal from '../../shared/modals/ManageParticipationModal.vue';
import EventInProgressIndicator from '../../shared/components/EventInProgressIndicator.vue';

export default {
  name: 'PeakEvents',
  components: {
    ManageParticipationModal,
    EventInProgressIndicator,
  },
  props: {
    energyEvents: {
      type: Array,
      required: true,
      default: () => [],
    },
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
    onManageParticipationUpdate: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      selectedEvent: undefined,
    };
  },
  computed: {
    hasFuturePeakEventsScheduled() {
      return this.energyEvents && this.energyEvents.some((event) => !event.expired);
    },
    futureEnergyEvents() {
      return this.energyEvents.filter((event) => !event.expired);
    },
  },
  methods: {
    showManageParticipationModal(event) {
      this.selectedEvent = event;
    },
    hideManageParticipationModal() {
      this.selectedEvent = undefined;
    },
    handleManageParticipationUpdate() {
      this.hideManageParticipationModal();
      this.onManageParticipationUpdate();
    },
  },
};
</script>
