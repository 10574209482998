var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "devices-column-gap-xxl" },
    _vm._l(_vm.peakRateSchedule, function(schedeule, schedeuleIndex) {
      return _c("div", { key: schedeuleIndex }, [
        _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
          _vm._v(_vm._s(schedeule.rateScheduleDescription))
        ]),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(schedeule.scheduleLines, function(
            scheduleLine,
            scheduleLineIndex
          ) {
            return _c(
              "li",
              {
                key: scheduleLineIndex,
                staticClass:
                  "gds-font-book gds-space-inset-squish-m devices-padding-left-half"
              },
              [_vm._v("\n        " + _vm._s(scheduleLine) + "\n      ")]
            )
          }),
          0
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }