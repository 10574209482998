<template>
  <div class="make-payment-container">
    <div id="top-target" class="eicproduct-success-fail">
      <div class="gds-flex-container gds-space-stack-s">
        <div class="card__data-container--large-text">
          <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-plan.svg" alt="" />
        </div>
      </div>
      <div class="gds-space-stack-l">
        <div class="gds-align--text-center">
          This account is not eligible to participate in the EV charger program.
        <a
          class="greenBoldText"
          rel="noreferrer noopener"
          href="https://static.greenmountainpower.com/rebate-forms/GMP-EV-Rebate-Form.pdf"
          target="_blank"
        >Please fill out this form here</a> or call {{GMPPhoneNumber || '1-(888)-835-4672'}} with any questions.
        </div>
      </div>
      <div class="bill-pay-success__button-wrap gds-align--text-center">
        <button @click="ReturnToSender('/')" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../../environment";
import { ReturnToSender } from "../../../utilities";

export default {
  name: "EVChargerNotEligible",
  props: {
    currentAccount: Object,
  },
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  async mounted() {
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>