var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "gds-space-stack-l gds-flex-container gds-flex-container--left devices-gap-2 devices-background-off-white devices-border-radius devices-padding-m devices-max-width-my-account"
    },
    [
      _c("img", {
        staticClass: "gds-logo devices-border-radius devices-width-25",
        attrs: {
          src: _vm.device.imageURL,
          alt: _vm.device.deviceTypeDescription
        }
      }),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "gds-font-demi gds-font-size-xl gds-space-stack-s" },
          [
            _vm._v(
              _vm._s(_vm.device.deviceName) +
                _vm._s(_vm.device.deviceTypeDescription)
            )
          ]
        ),
        _vm._v(" "),
        _vm.device.deviceId
          ? _c("div", { staticClass: "gds-font-book" }, [
              _vm._v("Serial No. " + _vm._s(_vm.device.deviceId))
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }