export default {
  methods: {
    generateAltText(device) {
      const { deviceName, deviceTypeDescription, statusMessage } = device;

      let altText = `${deviceName} - ${deviceTypeDescription}`;

      if (statusMessage && statusMessage.message) {
        altText += ` (${statusMessage.message})`;
      }

      return altText;
    },
  }
};
