var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.formVM.pageNumber === 1
        ? _c("BackButton", { attrs: { route: "/devices/add-device" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.formVM.pageNumber > 1 && !_vm.formVM.deadEndBrandAndModel
        ? _c("BackButton", {
            attrs: { "on-link-clicked": _vm.onBackButtonClicked }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-container gds-space-stack-l" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.preventFormSubmission.apply(null, arguments)
              }
            }
          },
          [
            _vm.formVM.headerConfig
              ? _c("FormHeader", { attrs: { config: _vm.formVM.headerConfig } })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formVM.isPageOneVisible,
                    expression: "formVM.isPageOneVisible"
                  }
                ]
              },
              [
                _c("RadioGroupDeviceType", {
                  attrs: { config: _vm.formVM.radioButtonConfigDeviceType },
                  model: {
                    value: _vm.formVM.formData.deviceType,
                    callback: function($$v) {
                      _vm.$set(_vm.formVM.formData, "deviceType", $$v)
                    },
                    expression: "formVM.formData.deviceType"
                  }
                }),
                _vm._v(" "),
                _vm.formVM.selectedDeviceType
                  ? _c("div", [
                      _vm.formVM.evChargerSelected
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "gds-font-book gds-space-stack-m"
                                },
                                [_vm._v(_vm._s(_vm.formVM.evChargerInfoText))]
                              ),
                              _vm._v(" "),
                              _c("LinkButton", {
                                attrs: {
                                  text: _vm.formVM.evChargerLinkText,
                                  url: _vm.formVM.evChargerLinkUrl
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formVM.homeBatterySelected
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "gds-font-book gds-space-stack-m"
                                },
                                [_vm._v(_vm._s(_vm.formVM.homeBatteryInfoText))]
                              ),
                              _vm._v(" "),
                              _c("LinkButton", {
                                attrs: {
                                  text: _vm.formVM.homeBatteryLinkText,
                                  url: _vm.formVM.homeBatteryLinkUrl
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.formVM.isSupportedDevice
                        ? _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formVM.nonSupportedDeviceTypeWarning
                              )
                            }
                          })
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }