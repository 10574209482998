<template>
  <div class="gds-space-stack-l">
    <div
      class="
      gds-space-stack-l
      gds-flex-container
      devices-max-width-600
      gds-flex-container--left
      devices-service-request-form__success-message"
    >
      <span class="devices-service-request-form__success-message__success-icon">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 14L8 16L13.5 22L24 12L22 10L13.5 18L10 14Z"
            fill="#004737"
            style="fill:#004737;fill:color(display-p3 0.0000 0.2784 0.2157);fill-opacity:1;"
          />
        </svg>
      </span>
      <span class="gds-font-book">{{ config.successMessage }}</span>
    </div>
    <div class="gds-space-stack-s gds-font-demi gds-font-size-xl">{{ config.header }}</div>
    <div class="gds-space-stack-s devices-max-width-600">{{ config.subHeader }}</div>
  </div>
</template>

<script>
export default {
  name: 'FormSubmitSuccessMessage',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
};
</script>
