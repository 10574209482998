var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass:
        "gds-flex-container gds-flex-container--top gds-flex-container--left devices-column-gap-xxl",
      class: { "gds-flex-container--column": !_vm.isDesktopView }
    },
    [
      _c("section", [
        _c("div", { staticClass: "gds-space-stack-ml devices-width-100" }, [
          _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
            _vm._v("Program")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-book gds-font-size-m" }, [
            _vm._v(" " + _vm._s(_vm.deviceInfo.program))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gds-space-stack-ml devices-width-100" }, [
          _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
            _vm._v("Enrollment Date")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-book gds-font-size-m" }, [
            _vm._v(" " + _vm._s(_vm.deviceInfo.enrollmentDate))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gds-space-stack-ml devices-width-100" }, [
          _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
            _vm._v("Device Owner")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-book gds-font-size-m" }, [
            _vm._v(_vm._s(_vm.deviceInfo.deviceOwner))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gds-space-stack-ml devices-width-100" }, [
          _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
            _vm._v("Rate")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-book gds-font-size-m" }, [
            _c("a", { attrs: { href: "" } }, [
              _vm._v(_vm._s(_vm.deviceInfo.rateInfo.link))
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "devices-width-80" }, [
        _c("div", { staticClass: "gds-space-stack-ml devices-max-width-300" }, [
          _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
            _vm._v("Program Information")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-book gds-font-size-m" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.deviceInfo.programInformation) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gds-space-stack-ml devices-width-100" },
          [
            _vm.deviceInfo.documents.length
              ? _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
                  _vm._v("Documents & Links")
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.deviceInfo.documents, function(document, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "gds-flex-container gds-flex-container--left gds-space-stack-s"
                },
                [
                  _c("img", {
                    staticClass: "gds-space-inline-m",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-document.svg",
                      alt: "Grey document icon"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "gds-font-book gds-font-size-m" }, [
                    _c("a", { attrs: { href: document.link } }, [
                      _vm._v(_vm._s(document.name))
                    ])
                  ])
                ]
              )
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }