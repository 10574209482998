<template>
  <div class="usage-detail__container">
    <banner-dual-action
      class="gds-flex-container"
      v-if="testDriveUsage" bannerColor="blue"
      message='You are trying out our new usage section and we’d love to hear what you think. Click this link to provide feedback: '
      linkOneText="Provide Feedback."
      @linkOneClicked="showProvideFeedBackModal"
      messageTwo="Or you can use this link to: "
      linkTwoText="Switch Back to the Previous Usage View"
      @linkTwoClicked="goToLegacyUsage"
    />
    <div class="usage-detail__nav-container">
      <!--<a href="/account/usage-new" class="usage-detail__usage-detail-nav-back-button gds-button gds-text-button gds-compact">
      
        <svg class="gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-left" />
        </svg>Usage Dashboard</a>-->
        
      <!-- GRID-084 - FOR ROLLBACK, SWAP THE TWO-LINE COMMENTED NAV-A SECTIONS BELOW -->
      <nav class="usage-detail__router-link-container">
        <a href="/account/usage" class="usage-detail__nav-item">
      <!-- <nav class="usage-detail__router-link-container">
 <a href="/account/usage-new" class="usage-detail__nav-item"> -->
          <div class="usage-detail__nav-img">
            <div class="gds-icon green-svg gds-flex-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z" fill="#00704A" />
              </svg>
            </div>
              <div class="gds-icon non-green-svg gds-flex-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z" fill="#6F7383" />
              </svg>
            </div>
            <span class="usage-detail__nav-item__text">Dashboard</span>
          </div>
        </a>
        <router-link to="/graph" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg" />
            <span class="usage-detail__nav-item__text">Graph</span>
          </div>
        </router-link>
        <router-link to="/table" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg" />
            <span class="usage-detail__nav-item__text">Table</span>
          </div>
        </router-link>
        <router-link v-if="isNetMeteredAccountChecked && typeof isNetMeteredAccount != 'undefined' && !isNetMeteredAccount" to="/time-of-day" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg" />
            <span class="usage-detail__nav-item__text">Time Of Day</span>
          </div>
        </router-link>
        <!-- <router-link to="/seasonal" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal-green.svg" />
            <span class="usage-detail__nav-item__text">Seasonal</span>
          </div>
        </router-link> -->
        <!--
        <router-link to="/heating-cooling" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img
              src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-heating-cooling.svg" />
            Heating/Cooling
          </div>
        -->
        <!-- </router-link> -->
        <router-link to="/ev-charging" class="usage-detail__nav-item" v-if="FF_usageEvCharging && currentAccount && currentAccount.evCharger">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon-green.svg" />
            <span class="usage-detail__nav-item__text ev-charging">EV Charging</span>
          </div>
        </router-link>
        <router-link to="/performance" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg" />
            <span class="usage-detail__nav-item__text">Performance</span>
          </div>
        </router-link>
        <router-link to="/compare" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg" />
            <span class="usage-detail__nav-item__text">Compare</span>
          </div>
        </router-link>
      </nav>
    </div>
    <router-view />
    <modal-floating-feedback :visible="showFeedback" @close="showFeedback = false" />
  </div>
</template>

<script>
import MediaQueryMixin from "../../mixins/MediaQueryMixin";
import BannerDualAction from "../../BannerDualAction.vue";
import ModalFloatingFeedback from "../../ModalFloatingFeedback.vue";
import GMPAPI from '../../../services/gmpapi';
import { isFeatureEnabled } from "../../../services/featureflags";

const SET_NM_ACCT_STORE_KEY = 'setIsNetMeteredAccount';

export default {
  name: "UsageDetail",
  components: {
    BannerDualAction,
    ModalFloatingFeedback
  },
  mixins: [
    MediaQueryMixin
  ],
  async mounted() {
    this.$store.dispatch(SET_NM_ACCT_STORE_KEY);
    this.FF_usageEvCharging = await isFeatureEnabled('FF_UsageEVCharging', false);
  },
  watch: {
    isNetMeteredAccount() {
      this.isNetMeteredAccountChecked = true;
    },
    currentAccount() {
      this.$store.dispatch(SET_NM_ACCT_STORE_KEY);
    }
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    testDriveUsage() {
      // WEB-084 - for rollback, swap commenting below
      return false;
      // return this.$store.state.user.testDriveUsage;
    },
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
    isTabletView() {
      return this.deviceIsMedium
      || this.deviceIsLarge;
    },
    isMobbileView() {
      return this.deviceIsSmall;
    },
    isNetMeteredAccount() {
      return this.$store.state.user.isNetMeteredAccount;
    }
  },
  data() {
    return {
      showFeedback: false,
      FF_usageEvCharging: undefined,
      isNetMeteredAccountChecked: false
    }
  },
  methods: {
    async goToLegacyUsage() {
      this.$store.commit("setTestDriveUsage", false);
      window.location.href = '/account/usage';

      await GMPAPI.SubmitCSSLogging(this.currentAccount.accountNumber, this.$store.state.user.userinfo.username, "SWITCH_TO_LEGACY_USAGE", "Switched back to legacy usage.", true);
    },
    showProvideFeedBackModal() {
      this.showFeedback = true;
    },
  }
};
</script>
