var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-peak-events-list" },
    [
      _vm.selectedEvent
        ? _c("ManageParticipationModal", {
            attrs: {
              event: _vm.selectedEvent,
              device: _vm.device,
              "close-modal": _vm.hideManageParticipationModal,
              "on-manage-participation-update":
                _vm.handleManageParticipationUpdate
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasFuturePeakEventsScheduled
        ? [
            _c(
              "div",
              { staticClass: "gds-font-book gds-font-size-m gds-text-grey" },
              [_vm._v("There are no peak events scheduled for this device.")]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.hasFuturePeakEventsScheduled
        ? _c(
            "ul",
            _vm._l(_vm.futureEnergyEvents, function(event) {
              return _c(
                "li",
                {
                  key: event.id,
                  staticClass:
                    "device-peak-event-item gds-flex-container gds-flex-container--top gds-flex-container--left"
                },
                [
                  !event.expired
                    ? [
                        !event.optedOut
                          ? _c("img", {
                              staticClass: "gds-space-inline-m",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-lg.svg",
                                alt: "Purple leaf icon"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        event.optedOut
                          ? _c("img", {
                              staticClass: "gds-space-inline-m",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-opt-out-lg.svg",
                                alt: "Purple leaf with minus sign icon"
                              }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _c("span", { staticClass: "gds-font-demi" }, [
                            _vm._v(
                              _vm._s(
                                event.inProgress
                                  ? "Energy Event In Progress"
                                  : "Energy Event Scheduled"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          event.dateRange
                            ? _c("span", { staticClass: "gds-font-book" }, [
                                _vm._v(_vm._s(event.dateRange))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          event.canOptOut
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "devices-underline devices-hover-cursor",
                                  on: {
                                    click: function($event) {
                                      return _vm.showManageParticipationModal(
                                        event
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Manage participation.\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "gds-flex-container gds-flex-container--left"
                            },
                            [
                              event.optedOut
                                ? _c(
                                    "span",
                                    { staticClass: "gds-tag gds-secondary" },
                                    [_vm._v("Opted Out")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              event.inProgress
                                ? _c(
                                    "span",
                                    { staticClass: "event-in-progress-timer" },
                                    [
                                      _c("event-in-progress-indicator"),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(event.timeRemaining))
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            }),
            0
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }