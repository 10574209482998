<template>
  <div class="make-payment-container">
    <div id="top-target" class="bill-pay-success-fail">
      <div class="gds-flex-container gds-space-stack-s">
        <div class="card__data-container--large-text">
          <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-plan.svg" alt=" " />
        </div>
      </div>
      <div class="gds-space-stack-l">
        <div class="gds-align--text-center">
          <!-- Eligible, not enrolled, show button -->
          <a
            v-if="arrangementStatus && arrangementStatus.isEligible && !arrangementStatus.isEnrolled"
            href="/arrange-payment"
            class="gds-card__button gds-button gds-round gds-secondary"
          >Set Up Payment Arrangement</a>
          <!-- Not eligible (no message) -->
          <div
            v-if="arrangementStatus && !arrangementStatus.isEligible && !arrangementStatus.isEnrolled && !arrangementStatus.ineligibleReason"
            class="gds-text-grey gds-font-size-m"
          >You are not eligible for payment arrangements at this time.</div>
          <!-- Not eligible (with message) -->
          <div
            v-if="arrangementStatus && !arrangementStatus.isEligible && !arrangementStatus.isEnrolled && arrangementStatus.ineligibleReason"
            class="gds-text-grey gds-font-size-m"
          >{{arrangementStatus.ineligibleReason}}</div>
          <!-- Already enrolled -->
          <div
            v-if="arrangementStatus && arrangementStatus.isEnrolled"
            class="gds-text-grey gds-font-size-m"
          >Thank you for using the Past Due Quick Setup. Your payment arrangement installments are already included in your monthly bill.</div>
        </div>
      </div>
      <div class="bill-pay-success__button-wrap gds-align--text-center">
        <button @click="ReturnToSender('/account')" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
  <!-- Eligible, not enrolled, show message -->
</template>

<script>
import { GMPPhoneNumber } from "../../environment";
import { ReturnToSender } from "../../utilities";

export default {
  name: "PastDueNotEligible",
  props: {
    currentAccount: Object,
    arrangementStatus: Object,
  },
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
  async mounted() {
    this.formatLanguage();
  },
  methods: {
    formatLanguage() {
      if (this.arrangementStatus.ineligibleReason.includes("Please contact customer service at (888) 835-4672")) {
        const formattedIneligibleReason = this.arrangementStatus.ineligibleReason.slice(0, -1);
        this.arrangementStatus.ineligibleReason = `${formattedIneligibleReason} and we can help you!`
      } else {
        return;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>