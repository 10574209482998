<template>
  <div class="gds-space-stack-l">
    <DeviceStatusHistoryHeader
      :device="device"
      :device-status-history="deviceStatusHistory"
    />
    <div class="device-status-history__status-cards devices-horizontal-scroll">
      <DeviceDailyStatusCard
        v-for="(dailyStatus, index) in (deviceStatusHistory.history || [])"
        :key="index"
        :status="dailyStatus"
      />
    </div>
  </div>
</template>

<script>

import DeviceDailyStatusCard from '../devicedailystatuscard/DeviceDailyStatusCard.vue';
import DeviceStatusHistoryHeader from './DeviceStatusHistoryHeader.vue';

export default {
  name: 'DeviceStatusHistory',
  components: {
    DeviceStatusHistoryHeader,
    DeviceDailyStatusCard,
  },
  props: {
    deviceStatusHistory: {
      type: Object,
      required: true,
    },
    device: {
      type: Object,
      required: true,
    },
  },
};
</script>
