<template>
  <div>
    <div class="gds-space-stack-m">
      <BackButton v-if="formVM.pageNumber === 1" :route="'/devices/add-device'" />
      <BackButton v-if="formVM.pageNumber > 1 && !formVM.deadEndBrandAndModel" :on-link-clicked="onBackButtonClicked" />
    </div>
    <div class="form-container gds-space-stack-l">
      <form @submit.prevent="preventFormSubmission">
        <FormHeader :config="formVM.headerConfig" />
        <div v-show="formVM.isPageOneVisible">
          <RadioGroupDeviceType
            v-model="formVM.formData.deviceType"
            :config="formVM.radioButtonConfigDeviceType"
          />
          <div v-if="formVM.selectedDeviceType">
            <div v-if="formVM.evChargerSelected">
              <div class="gds-font-book gds-space-stack-m">{{ formVM.evChargerInfoText }}</div>
              <div v-if="formVM.selectedDeviceType" class="gds-space-stack-m">
                <CheckboxInputGroup
                  v-model="formVM.formData.requirementsEvCharger"
                  :config="formVM.checkboxGroupConfigRequirementsEvCharger"
                />
              </div>
            </div>
            <div v-if="formVM.homeBatterySelected">
              <div class="gds-font-book gds-space-stack-m">{{ formVM.homeBatteryInfoText }}</div>
              <div v-if="formVM.selectedDeviceType" class="gds-space-stack-m">
                <CheckboxInputGroup
                  v-model="formVM.formData.requirementsHomeBattery"
                  :config="formVM.checkboxGroupConfigRequirementsHomeBattery"
                />
              </div>
            </div>
            <div v-if="!formVM.isSupportedDevice" v-html="formVM.nonSupportedDeviceTypeWarning" />
          </div>
          <div v-if="formVM.selectedDeviceType">
            <div v-if="formVM.evChargerSelected">
              <LinkButton
                :text="formVM.evChargerLinkText"
                :url="formVM.evChargerLinkUrl"
                :disabled="!formVM.checklistCompleteHomeBatteryEvCharger"
              />
            </div>
            <div v-if="formVM.homeBatterySelected">
              <LinkButton
                :text="formVM.homeBatteryLinkText"
                :url="formVM.homeBatteryLinkUrl"
                :disabled="!formVM.checklistCompleteHomeBattery"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ByodViewModel from './ByodViewModel';
import LinkButton from '../../shared/components/LinkButton.vue';
import BackButton from '../../shared/components/formcomponents/BackButton.vue';
import FormHeader from '../../shared/components/formcomponents/FormHeader.vue';
import CheckboxInputGroup from '../../shared/components/formcomponents/CheckboxInputGroup.vue';
import RadioGroupDeviceType from '../../shared/components/formcomponents/RadioGroupDeviceType.vue';

export default {
  name: 'AddDeviceByod',
  components: {
    BackButton,
    FormHeader,
    LinkButton,
    CheckboxInputGroup,
    RadioGroupDeviceType,
  },

  data() {
    return {
      formVM: new ByodViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    currentAccount() {
      this.$router.push('/devices');
    },
    'formVM.formData': {
      handler(newValue) {
        this.formVM.formDataWatchHandler(newValue);
      },
      deep: true,
    },
  },
  methods: {
    preventFormSubmission() {
      // no op
    },
  },
};
</script>
