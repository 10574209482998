import {
  format,
  subDays,
  parseISO,
  endOfDay,
  startOfDay,
} from 'date-fns';

import GMPAPI from '../../../../../services/gmpapi';
import { DumpError } from '../../../../../utilities';
import { isFeatureEnabled } from '../../../../../services/featureflags';
import ChartDataViewModel from './ChartDataViewModel';
import PeakEventsViewModel from './PeakEventsViewModel';
import { API_DATE_FORMAT } from '../../constants';

export default class DeviceDetailViewModel {
  constructor() {
    this.device = undefined;
    this.deviceId = undefined;
    this.deviceInfo = undefined;
    this.loadState = undefined;
    this.FF_Devices = undefined;
    this.deviceType = undefined;
    this.batteryChargeData = [];
    this.evHistoryChargeData = [];
    this.chartDataLoaded = false;
    this.constants = {
      DEVICE_TYPE_EV_CHARGER: 'evse',
      DEVICE_TYPE_BATTERY: 'battery',
    };

    this.apiStartDate = format(
      startOfDay(subDays(new Date(), 6)),
      API_DATE_FORMAT,
    );

    this.apiEndDate = format(
      endOfDay(new Date()),
      API_DATE_FORMAT,
    );
  }

  async checkFeatureFlag() {
    this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

    if (!this.FF_Devices) {
      window.location = '/account';
    }
  }

  static GetDeviceDetailQueryString(currentAccount) {
    const { serviceAgreements } = currentAccount;

    if (serviceAgreements && serviceAgreements.length) {
      return `?rates=${currentAccount.serviceAgreements.map((sa) => sa.rate).join(',')}`;
    }

    return '';
  }

  static FormatOperationalMode(operationalMode) {
    return operationalMode.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  async getDeviceInfo(accountNumber, deviceId, surpressSpinner) {
    if (!surpressSpinner) {
      this.loadState = undefined;
    }

    try {
      this.deviceInfo = await GMPAPI.GetDeviceInfo(accountNumber, deviceId);

      if (this.deviceInfo && this.deviceInfo.enrollmentDate) {
        const denrollmentDateate = parseISO(this.deviceInfo.enrollmentDate);
        this.deviceInfo.enrollmentDate = format(denrollmentDateate, 'MMMM d, yyyy');
      }

      this.loadState = 'complete';
    } catch (err) {
      DumpError('getDeviceInfo');
      this.loadState = 'error';
    }
  }

  async getDeviceDetail(accountNumber, queryString, surpressSpinner) {
    if (!surpressSpinner) {
      this.loadState = undefined;
    }

    try {
      const httpResponse = await GMPAPI.GetDeviceDetail(accountNumber, this.deviceId, queryString);

      this.loadState = 'complete';

      [this.device] = [httpResponse];
      this.deviceType = this.device.deviceType;
      this.device.events = this.device.events || [];

      if (this.device.status && this.device.status.operationalMode) {
        this.device.status.operationalMode = DeviceDetailViewModel.FormatOperationalMode(this.device.status.operationalMode);
      }

      PeakEventsViewModel.setAllEventProgressProperties(this.device.events);
    } catch (err) {
      DumpError('getDeviceDetail');
      this.loadState = 'error';
    }
  }

  async getChartData(accountNumber) {
    const endDate = this.apiEndDate;
    const startDate = this.apiStartDate;

    if (!this.device || !this.device.deviceType) {
      return;
    }

    if (this.device.deviceType === this.constants.DEVICE_TYPE_EV_CHARGER) {
      this.evHistoryChargeData = await ChartDataViewModel.getFormattedEvChargerHistory(accountNumber, this.deviceId, startDate, endDate);
      this.chartDataLoaded = true;
    } else if (this.device.deviceType === this.constants.DEVICE_TYPE_BATTERY) {
      this.batteryChargeData = await ChartDataViewModel.getBatteryChargeData(accountNumber, this.deviceId, 'daily', startDate, endDate);
      this.chartDataLoaded = true;
    }
  }
}
