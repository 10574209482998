<template>
  <div class="device-details-summary">
    <div class="device-detail-summary__image-holder devices-background-blue-gradient">
      <router-link to="/devices" class="device-detail-back-link-circle gds-button gds-secondary gds-button-circle">
        <svg class="gds-icon black">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left" />
        </svg>
      </router-link>
      <img
        class="device-detail-summary__image"
        :src="imageUrl"
        :alt="generateAltText(device)"
      >
    </div>
    <div class="device-details-summary__content">
      <section class="device-detail-summary__title-wrapper">
        <div>
          <h2 class="device-detail-title gds-space-stack-s">{{ device.deviceName }}</h2>
          <p class="device-detail-summary__type-description gds-space-stack-s">{{ device.deviceTypeDescription }}</p>
        </div>
        <div v-if="operationalModeMessage">
          <span class="device-operational-mode-badge">{{ operationalModeMessage }}</span>
        </div>
      </section>
      <ul class="device-detail-summary__status-messages">
        <li v-if="powerMessage" class="powerMessage">
          <img src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-power.svg" alt="Power button icon">
          <div>
            <div class="label">Power</div>
            <div class="value">{{ powerMessage }}</div>
          </div>
        </li>
        <li v-if="wifiMessage" class="wifiMessage">
          <img src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-connection.svg" alt="WiFi connection icon">
          <div>
            <div class="label">Wi-Fi</div>
            <div class="value">{{ wifiMessage }}</div>
          </div>
        </li>
        <li v-if="touMessage" class="touMessage">
          <img src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-rate-status.svg" alt="Clock icon">
          <div>
            <div class="label">Current Rate Status</div>
            <div class="value">{{ touMessage }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../mixins/MediaQueryMixin';
import DevicesMixin from '../../../../mixins/DevicesMixin';

export default {
  name: 'DeviceDetailSummary',
  mixins: [
    MediaQueryMixin,
    DevicesMixin,
  ],
  props: {
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    imageUrl() {
      return this.device.imageURL || null;
    },
    status() {
      return this.device && this.device.status ? this.device && this.device.status : undefined;
    },
    operationalModeMessage() {
      return this.status && this.status.operationalMode ? this.status.operationalMode : false;
    },
    powerMessage() {
      if (!this.status || this.status.power === undefined) {
        return false;
      }
      return this.status.power ? 'On' : 'Off';
    },
    wifiMessage() {
      if (!this.status || this.status.wifiConnected === undefined) {
        return false;
      }
      return this.status.wifiConnected ? 'Connected' : 'Disconnected';
    },
    touMessage() {
      return this.status && this.status.tou ? this.status.tou : false;
    },
  },
};
</script>
