import { FORM_TYPES } from '../constants';
import GMPAPI from '../../../../services/gmpapi';
import { DumpError } from '../../../../utilities';
import FormViewModel from '../shared/viewmodels/FormViewModel';
import formConfigurations from './FormConfigurationsDevicesServiceRequest.json';

export default class DevicesServiceRequestViewModel extends FormViewModel {
  constructor() {
    super();
    this.errorState = 'error';
    this.pageNumberLast = 3;
    this.pageNumberPenultimate = this.pageNumberLast - 1;
    this.selectedDevice = {};
    this.devices = undefined;
    this.loadState = undefined;
    this.radioButtonConfigDevices = undefined;
    this.reviewAndSubmitConfig = { formData: {}, selectedDevice: {} };

    Object.assign(this, formConfigurations);

    this.formData = DevicesServiceRequestViewModel.getBaseFormData();
    this.headerConfig = { ...formConfigurations.headerConfig[1] };
  }

  get isErrorLoadState() {
    return this.loadState === this.errorState;
  }

  get isNextButtonVisible() {
    return this.pageNumber < this.pageNumberPenultimate;
  }

  get isSubmitButtonVisible() {
    return this.pageNumber === this.pageNumberPenultimate;
  }

  get isNextButtonDisabled() {
    const data = this.formData;
    const vm = DevicesServiceRequestViewModel;

    const contactEmailDefined = vm.stringIsNotEmpty(data.contactEmail);
    const contactPhoneDefined = vm.stringIsNotEmpty(data.contactPhone);
    const problemSummaryDefined = vm.stringIsNotEmpty(data.problemSummary);
    const selectedDeviceIdDefined = vm.stringIsNotEmpty(data.selectedDeviceId);

    return !contactEmailDefined || !contactPhoneDefined || !problemSummaryDefined || !selectedDeviceIdDefined;
  }

  static findDeviceById(devices, deviceId) {
    return devices.find((device) => device.deviceId === deviceId) || null;
  }

  static getBaseFormData() {
    return {
      contactPhone: undefined,
      contactEmail: undefined,
      problemSummary: undefined,
      selectedDeviceId: undefined,
    };
  }

  static getConfigCheckedForOnlyOneOption(config) {
    if (config.options && config.options.length === 1) {
      config.selectedValue = config.options[0].value;
    }

    return window.structuredClone(config);
  }

  static buildRadioButtonConfigDevices(devices) {
    const config = {
      options: [],
      title: formConfigurations.radioButtonConfigDevicesTitle,
    };

    devices.forEach((device) => {
      config.options.push({
        value: device.deviceId,
        text: `${device.deviceName} ${device.deviceTypeDescription}`,
        html: `<div class="gds-font-book">Serial No: ${device.deviceId}</div>`,
      });
    });

    return config;
  }

  updatePageHeader() {
    this.headerConfig = { ...formConfigurations.headerConfig[this.pageNumber] };
  }

  resetForm() {
    this.pageNumber = 1;
    this.formData = DevicesServiceRequestViewModel.getBaseFormData();
    this.reviewAndSubmitConfig = { formData: {}, selectedDevice: {} };
  }

  updateReviewAndSubmitConfig(currentAccount) {
    this.reviewAndSubmitConfig = this.getReviewAndSubmitConfig(currentAccount);
  }

  pageNumberWatchHandler(newValue, oldValue, currentAccount) {
    if (newValue === oldValue) return;
    this.updatePageHeader();
    if (newValue === this.pageNumberLast) {
      this.updateReviewAndSubmitConfig(currentAccount);
    }
  }

  getReviewAndSubmitConfig(currentAccount) {
    return {
      formData: this.formData,
      selectedDevice: this.selectedDevice,
      formType: FORM_TYPES.SERVICE_REQUEST,
      accountNumber: currentAccount.accountNumber,
      reviewAndSubmitMessage: this.reviewAndSubmitMessage,
    };
  }

  formDataWatchHandler(newValue) {
    if ('selectedDeviceId' in newValue) {
      this.selectedDevice = DevicesServiceRequestViewModel.findDeviceById(this.devices, newValue.selectedDeviceId);
    }
  }

  async getMyDevices(accountNumber) {
    this.loadState = undefined;
    const vm = DevicesServiceRequestViewModel;

    try {
      this.devices = await GMPAPI.GetDevices(accountNumber);
      this.radioButtonConfigDevices = vm.buildRadioButtonConfigDevices(this.devices);
      this.radioButtonConfigDevices = vm.getConfigCheckedForOnlyOneOption(this.radioButtonConfigDevices);

      this.loadState = 'complete';
    } catch (err) {
      DumpError('getMyDevices error', err);
      this.loadState = this.errorState;
    }
  }
}
