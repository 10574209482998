var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device-details-summary" }, [
    _c(
      "div",
      {
        staticClass:
          "device-detail-summary__image-holder devices-background-blue-gradient"
      },
      [
        _c(
          "router-link",
          {
            staticClass:
              "device-detail-back-link-circle gds-button gds-secondary gds-button-circle",
            attrs: { to: "/devices" }
          },
          [
            _c("svg", { staticClass: "gds-icon black" }, [
              _c("use", {
                attrs: {
                  "xlink:href":
                    "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-left"
                }
              })
            ])
          ]
        ),
        _vm._v(" "),
        _c("img", {
          staticClass: "device-detail-summary__image",
          attrs: { src: _vm.imageUrl, alt: _vm.generateAltText(_vm.device) }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "device-details-summary__content" }, [
      _c("section", { staticClass: "device-detail-summary__title-wrapper" }, [
        _c("div", [
          _c("h2", { staticClass: "device-detail-title gds-space-stack-s" }, [
            _vm._v(_vm._s(_vm.device.deviceName))
          ]),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass:
                "device-detail-summary__type-description gds-space-stack-s"
            },
            [_vm._v(_vm._s(_vm.device.deviceTypeDescription))]
          )
        ]),
        _vm._v(" "),
        _vm.operationalModeMessage
          ? _c("div", [
              _c("span", { staticClass: "device-operational-mode-badge" }, [
                _vm._v(_vm._s(_vm.operationalModeMessage))
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "device-detail-summary__status-messages" }, [
        _vm.powerMessage
          ? _c("li", { staticClass: "powerMessage" }, [
              _c("img", {
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-power.svg",
                  alt: "Power button icon"
                }
              }),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "label" }, [_vm._v("Power")]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.powerMessage))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.wifiMessage
          ? _c("li", { staticClass: "wifiMessage" }, [
              _c("img", {
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-connection.svg",
                  alt: "WiFi connection icon"
                }
              }),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "label" }, [_vm._v("Wi-Fi")]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.wifiMessage))
                ])
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.touMessage
          ? _c("li", { staticClass: "touMessage" }, [
              _c("img", {
                attrs: {
                  src:
                    "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-rate-status.svg",
                  alt: "Clock icon"
                }
              }),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "label" }, [
                  _vm._v("Current Rate Status")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.touMessage))
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }