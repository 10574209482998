import {
  parseISO,
  startOfDay,
  endOfDay,
  subYears,
  subDays,
  format,
  startOfMonth,
  differenceInYears,
} from 'date-fns';
import GMPAPI from '../../../../../services/gmpapi';
import config from './MyImpactListViewModelConfig.json';
import { DumpError, ToServerDateTruncate } from '../../../../../utilities';

const CUSTOM_DATE_RANGE_FORMAT = 'MMM d, yyyy';

export default class MyImpactListViewModel {
  constructor() {
    this.impacts = undefined;
    this.loadState = undefined;
    this.currentAccount = undefined;
    this.customDateRangeError = false;
    this.customDateRangeLabel = undefined;
    this.endDate = endOfDay(new Date());
    this.startDate = startOfDay(subYears(new Date(), 1));

    Object.assign(this, config);
  }

  setEndDateToEndOfToday() {
    this.endDate = endOfDay(new Date());
  }

  setCustomDateRangeLabel(startDate, endDate) {
    const formattedEndDate = format(endDate, CUSTOM_DATE_RANGE_FORMAT);
    const formattedStartDate = format(startDate, CUSTOM_DATE_RANGE_FORMAT);

    this.customDateRangeLabel = `${formattedStartDate} to ${formattedEndDate}`;
  }

  getQueryString() {
    return MyImpactListViewModel.buildDateRangeQueryString(this.startDate, this.endDate);
  }

  async onDateRangeSelected($event) {
    this.customDateRangeError = false;
    this.customDateRangeLabel = undefined;

    if (!Array.isArray($event) || $event.length !== 2) {
      this.setEndDateToEndOfToday();
      await this.onConfiguredDateRangeSelected($event, this.currentAccount.accountNumber);
      return;
    }

    const [startDate, endDate] = $event;
    this.startDate = startOfDay(parseISO(startDate));
    this.endDate = endOfDay(parseISO(endDate));

    if (MyImpactListViewModel.exceedsOneYearRange(this.startDate, this.endDate)) {
      this.customDateRangeError = true;
      return;
    }

    this.setCustomDateRangeLabel(this.startDate, this.endDate);
    this.getImpacts(this.currentAccount.accountNumber, this.getQueryString());
  }

  async getImpacts(accountNumber, queryString) {
    this.loadState = undefined;

    try {
      this.impacts = await GMPAPI.GetImpact(accountNumber, queryString);
      this.loadState = 'complete';
    } catch (err) {
      DumpError('getImpacts error', err);
      this.loadState = 'error';
    }

    return this.impacts;
  }

  onConfiguredDateRangeSelected($event, accountNumber) {
    const today = new Date();

    switch ($event) {
    case 'last-7-days':
      this.startDate = startOfDay(subDays(today, 7));
      break;
    case 'last-2-weeks':
      this.startDate = startOfDay(subDays(today, 14));
      break;
    case 'last-30-days':
      this.startDate = startOfDay(subDays(today, 30));
      break;
    case 'current-month':
      this.startDate = startOfMonth(today);
      break;
    case 'last-6-months':
      this.startDate = startOfDay(subDays(today, 180));
      break;
    case 'year-to-date':
      this.startDate = startOfDay(new Date(today.getFullYear(), 0, 1));
      break;
    default:
      this.startDate = startOfDay(subYears(today, 1));
    }

    this.getImpacts(accountNumber, this.getQueryString());
  }

  static exceedsOneYearRange(date1, date2) {
    return Math.abs(differenceInYears(date1, date2)) > 0;
  }

  static buildDateRangeQueryString(startDate, endDate) {
    const startDateFormatted = ToServerDateTruncate(startDate);
    const endDateFormatted = ToServerDateTruncate(endDate).replace('T00:00:00', 'T23:59:59');

    return `?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;
  }
}
