var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-devices-text-input-with-html" }, [
    _c(
      "label",
      {
        staticClass: "gds-space-stack-s gds-font-demi gds-font-size-xl",
        attrs: { for: _vm.config.name }
      },
      [_vm._v("\n    " + _vm._s(_vm.config.title || "") + "\n  ")]
    ),
    _vm._v(" "),
    _vm.config.html
      ? _c("div", {
          staticClass: "v-html gds-space-stack-s",
          domProps: { innerHTML: _vm._s(_vm.config.html) }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      staticClass: "text-input",
      class: { "is-desktop-view": _vm.isDesktopView },
      attrs: {
        id: _vm.config.name,
        placeholder: _vm.config.placeholder || "",
        name: _vm.config.name
      },
      domProps: { value: _vm.config.value },
      on: { input: _vm.updateValue }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }