<template>
  <div>
    <div v-if="config.title" class="gds-font-demi gds-font-size-xl gds-space-stack-m">{{ config.title }}</div>
    <div
      class="gds-flex-container gds-flex-container--column gds-flex-container--stretch devices-row-gap devices-max-width-600"
    >
      <label
        v-for="(checkbox, index) in config.checkboxes"
        :key="index"
        :for="`checkbox-${index}`"
        class="gds-flex-container gds-flex-container--left gds-font-demi gds-font-size-m devices-border-grey devices-padding-m"
      >
        <input
          :id="`checkbox-${index}`"
          v-model="localValues[index]"
          type="checkbox"
          class="gds-checkbox gds-secondary-checkbox"
          :value="checkbox.value"
          @change="emitUpdatedValues"
        >
        {{ checkbox.label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxInputGroup',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      localValues: [],
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.initializeCheckboxes();
      },
    },
  },
  mounted() {
    this.initializeCheckboxes();
    this.emitUpdatedValues();
  },
  methods: {
    initializeCheckboxes() {
      this.localValues = this.config.checkboxes.map((checkbox) => !!checkbox.checked);
    },
    emitUpdatedValues() {
      const checkedValues = this.config.checkboxes
        .filter((_, index) => this.localValues[index])
        .map((checkbox) => checkbox.value);

      this.$emit('input', checkedValues);
    },
  },
};
</script>

<style scoped>
</style>
