var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gds-space-stack-l devices-radio-group-formatted-html" },
    [
      _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--column gds-flex-container--stretch devices-row-gap devices-max-width-my-account"
        },
        [
          _c(
            "div",
            { staticClass: "gds-font-demi gds-font-size-l gds-space-stack-m" },
            [_vm._v("\n      " + _vm._s(_vm.config.title || "") + "\n    ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "devices-radio-group-formatted-html__labels-container gds-flex-container gds-flex-container--stretch",
          class: _vm.isDesktopView
            ? "gds-flex-container--row gds-flex-container--left devices-column-gap"
            : "gds-flex-container--column gds-flex-container--top devices-row-gap"
        },
        _vm._l(_vm.config.options, function(option, index) {
          return _c(
            "label",
            {
              key: index,
              staticClass:
                "gds-flex-container gds-flex-container--top gds-flex-container--left gds-font-demi gds-font-size-m devices-border-grey devices-padding-m devices-padding-no-right devices-set-width-329",
              class: {
                "devices-outline-indigo": _vm.selectedValue === option.value
              }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedValue,
                    expression: "selectedValue"
                  }
                ],
                staticClass: "gds-radio gds-secondary-radio",
                attrs: { type: "radio" },
                domProps: {
                  value: option.value,
                  checked: _vm._q(_vm.selectedValue, option.value)
                },
                on: {
                  change: function($event) {
                    _vm.selectedValue = option.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "gds-flex-container gds-flex-container--column gds-flex-container--top devices-row-gap devices-width-80"
                },
                [
                  _vm._v("\n        " + _vm._s(option.text) + "\n        "),
                  option.html
                    ? _c("div", {
                        staticClass: "gds-font-book",
                        domProps: { innerHTML: _vm._s(option.html) }
                      })
                    : _vm._e()
                ]
              )
            ]
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }