<template>
  <div v-if="value" class="gds-space-stack-l">
    <div v-if="label" class="gds-font-demi gds-space-stack-s">{{ label }}</div>
    <div class="gds-font-book">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'SimpleKeyValueText',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
};
</script>
