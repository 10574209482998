var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadstate === "complete"
    ? _c(
        "div",
        {
          staticClass:
            "gds-flex-container gds-flex-container--left gds-space-stack-m"
        },
        [
          _vm.pendingPayments || _vm.scheduledPayments
            ? _c(
                "div",
                { staticClass: "bill-pay-login__blue-pending-circle" },
                [
                  _c("svg", { staticClass: "gds-icon" }, [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-pending"
                      }
                    })
                  ])
                ]
              )
            : _vm.account.amountDue > 0 && !_vm.pastDue
            ? _c("div")
            : _vm.account.amountDue > 0 && _vm.pastDue
            ? _c("div", [
                _c(
                  "svg",
                  {
                    staticClass: "gds-icon gds-icon-warning",
                    attrs: { viewBox: "0 0 32 32" }
                  },
                  [
                    _c("path", {
                      attrs: {
                        fill: "#F9C23A",
                        "fill-rule": "evenodd",
                        d:
                          "M17.017 6.21a2 2 0 0 1 .705.704l9.495 16.069A2 2 0 0 1 25.495 26H6.505a2 2 0 0 1-1.722-3.017l9.495-16.07a2 2 0 0 1 2.74-.703zM16 19.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zM17 12h-2v6h2v-6z"
                      }
                    })
                  ]
                )
              ])
            : _c("div", { staticClass: "bill-pay-login__green-check-cirlce" }, [
                _c(
                  "svg",
                  { staticClass: "bill-pay-login__white-check-icon gds-icon" },
                  [
                    _c("use", {
                      attrs: {
                        "xlink:href":
                          "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                      }
                    })
                  ]
                )
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "gds-font-size-m payment-status-pending" }, [
            _vm.account.amountDue > 0 && _vm.pastDue
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm._f("currency")(_vm.pastDueBalance)) +
                      " of\n      balance is due now."
                  )
                ])
              : _vm.account.amountDue > 0
              ? _c(
                  "span",
                  {
                    staticClass: "gds-clickable",
                    on: {
                      click: function($event) {
                        return _vm.toggleShowDueDate()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm._f("dateDue")(
                            _vm.account.amountDueDate,
                            _vm.showDueDate
                          )
                        ) +
                        _vm._s(
                          _vm.pendingPayments || _vm.scheduledPayments
                            ? ","
                            : ""
                        ) +
                        "\n    "
                    )
                  ]
                )
              : _c("span", [
                  _vm._v(
                    "All paid up" +
                      _vm._s(
                        _vm.pendingPayments || _vm.scheduledPayments ? "," : ""
                      )
                  )
                ]),
            _vm._v(" "),
            _vm.pendingPayments && !_vm.pastDue
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.pendingPayments > 1 ? "payments" : "payment") +
                      " pending."
                  )
                ])
              : _vm.pendingPayments && _vm.pastDue
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.pendingPayments > 1 ? "Payments" : "Payment") +
                      " pending."
                  )
                ])
              : _vm.scheduledPayments && !_vm.pastDue
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.scheduledPayments > 1 ? "payments" : "payment") +
                      " scheduled."
                  )
                ])
              : _vm.scheduledPayments && _vm.pastDue
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.scheduledPayments > 1 ? "Payments" : "Payment") +
                      " scheduled."
                  )
                ])
              : _vm._e()
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }