<template>
  <div class="eicproduct-container">
    <div v-if="loadState === undefined" class="eicproduct__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="eicproduct__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error"
      phonetext="For further help, please call us at" closepath="/electric-vehicle-charger" />
    <div v-if="loadState === 'complete' && isCommercialAccount">
      <EVChargerNotEligible :currentAccount="currentAccount" />
    </div>
    <div v-if="loadState === 'complete' && !isCommercialAccount">
      <!-- SLAT 1 Verify Customer Information -->
      <section id="charger-slat-1" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="eicproduct-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Customer Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="!userIsOTP" class="gds-body-normal gds-space-inline-m">Account: {{ accountNickname }}</div>
                <div v-if="userIsOTP" class="gds-body-normal gds-space-inline-m">Account Number: {{
                    currentAccount.accountNumber
                }}</div>
                <button @click="gotoPage(1)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <!-- SLAT 1 Edit Screen For Account Information -->

          <!-- User Is Guest -->
          <div v-if="currentPage === 1 && userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for
                enrollment in the EV Charger program.</legend>
              <label :key="currentAccount.accountNumber"
                class="eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m">
                <div class="eicproduct__account-box gds-space-stack-l">
                  <span v-if="currentAccount">
                    <div class="row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>

                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </label>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- User Has WWW Account -->
          <div v-if="currentPage === 1 && !userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information for your electric
                vehicle charger.</legend>
              <div class="eicproduct__account-box gds-space-stack-l">
                <span v-if="currentAccount">
                  <div class="row-m">
                    <div class="account-info-row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                    <div v-if="accounts.length > 1" @click="chooseAccount()"
                      class="inline accountInformation eicproduct__edit-account-details">
                      <button class="gds-button gds-text-button choose-account-button">
                        <svg class="eicproduct__edit-icon-inline gds-icon">
                          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                        </svg>
                        <span v-if="accounts.length > 1">Switch Account</span>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
              <button @click="finishPage('page1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 Program Requirements -->
      <section id="charger-slat-2" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="eicproduct-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Verify Program Requirements</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <!-- If one vehicle -->
                <div class="gds-body-normal gds-space-inline-m">{{ productSubmission.vehicles[0].brandModel }}</div>
                <button @click="gotoPage(2)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 2" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <form data-vv-scope="page2">
                <fieldset class="gds-fieldset gds-space-stack-s">
                  <div class="eicproduct-slap__inner-row">
                    <div class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          Your vehicle must be a fully electric vehicle in order to receive a free EV charger.
                          Plug-in hybrid vehicles do not qualify for a free charger.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Is your vehicle 100% electric?</span>
                          <div class="gds-position--relative">
                            <div class="gds-space-stack-ml">
                              <select @change="onChangeIsAllElectric()" v-model="productSubmission.vehicles[0].isAllElectric"
                                class="eicproduct-input-field__select" v-validate="'required'" name="isAllElectric"
                                key="isAllElectric" autofocus>
                                <option value disabled select>Select...</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                        </label>
                        <div v-if="errors.first('isAllElectric', 'page2')" class="validation-error">{{
                            errors.first("isAllElectric", "page2")
                        }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="eicproduct-slap__inner-row">
                    <div class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          Your GMP account address must be listed on your Vermont DMV registration in order to
                          participate in the EV charger program.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Is your electric vehicle registered in Vermont?</span>
                          <div class="gds-space-stack-ml">
                            <select @change="onChangeRegistration()" v-model="productSubmission.vehicles[0].isRegisteredInVt"
                              class="eicproduct-input-field__select" v-validate="'required'"
                              name="VTRegistrationRequired" key="VTRegistrationRequired" autofocus>
                              <option value disabled select>Select...</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          <div v-if="errors.first('VTRegistrationRequired', 'page2')" class="validation-error">{{
                              errors.first("VTRegistrationRequired", "page2")
                          }}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="eicproduct-slap__inner-row">
                    <div class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          Your home must have an internet connection in order to participate in the EV Charger program.
                          A mobile hot spot is not sufficient.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Does your home have a reliable internet
                            connection?</span>
                          <div class="gds-position--relative">

                            <div class="gds-space-stack-ml">
                              <select @change="onChangeInternet" v-model="productSubmission.programEnrollment.inHomeChargerProgram.hasInternet"
                                class="eicproduct-input-field__select" v-validate="'required'" name="hasInternet"
                                key="internetRequired" autofocus>
                                <option value disabled select>Select...</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                        </label>
                        <div v-if="errors.first('hasInternet', 'page2')" class="validation-error">{{
                            errors.first("hasInternet", "page2")
                        }}</div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>

            <ModalEICProducts v-if="showInformationalModal" modalName="informational" :close="closeModal"
              :modalMessage="modalMessage" :modalLink="modalLink" :modalLinkName="modalLinkName" />

            <button @click="finishPage('page2', 3)" class="gds-button gds-compact">
              Next
            </button>
          </div>
        </div>
      </section>
      <!-- SLAT 3 Product Details -->
      <section id="charger-slat-3" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="eicproduct-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Vehicle Details</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <!-- If one vehicle -->
                <div class="gds-body-normal gds-space-inline-m">{{ productSubmission.vehicles[0].brandModel }}</div>
                <button @click="gotoPage(3)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 3" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="eicproduct-slap__inner-row">
                <div>
                  <form data-vv-scope="page3">
                    <fieldset class="gds-fieldset gds-space-stack-s">
                      <h1 class="gds-display-1 gds-space-stack-l">Vehicle Information</h1>
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Vehicle VIN (17 Digits)</span>
                        <div class="gds-position--relative">
                          <the-mask v-model="productSubmission.vehicles[0].vinNumber" :mask="'XXXXXXXXXXXXXXXXX'"
                            v-validate="'required|length:17'" type="text" name="VIN" key="VIN" id="vin"
                            class="eicproduct-input-field__input gds-input-field__input" placeholder="Type here..."
                            ref="focuser2" />
                          <div v-if="errors.first('VIN', 'page3')" class="validation-error">{{ errors.first("VIN",
                              "page3")
                          }}</div>
                        </div>
                      </label>
                      <br />
                      <br />
                      <div class="row gds-space-stack-ml">
                        <div class="flex-item margin-right">
                          <label class="gds-input-field gds-space-stack-l">
                            <span class="gds-input-field__label">Vehicle Make and Model</span>
                            <select :disabled="disableBrandModel"
                              @change="parseVehicle(productSubmission.vehicles[0].brandModel);"
                              v-model="productSubmission.vehicles[0].brandModel" v-validate="'required'"
                              name="make and model" key="make and model"
                              class="eicproduct-input-field-l__select gds-input-field" autofocus>
                              <option value disabled selected>Select Make/Model</option>
                              <option v-for="(model, i) in filteredVehicleModels" :key="i" :value="model">{{ model |
                                  formatVehicleModel()
                              }}</option>
                            </select>
                            <div v-if="errors.first('make and model', 'page3')" class="validation-error">{{
                                errors.first("make and model", "page3")
                            }}</div>
                          </label>
                        </div>
                        <div class="flex-item">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Model Year (YYYY)</span>
                            <the-mask v-model="productSubmission.vehicles[0].year"
                              v-validate="`required|numeric|min_value: 2000|max_value: ${parseInt(new Date().getFullYear() + 5)}`"
                              :mask="'####'" name="year" key="year" class="gds-input-field__input gds-space-stack-s"
                              type="tel" autocomplete="off" @input="$emit('touched', true);" placeholder="YYYY" />
                            <div v-if="errors.first('year', 'page3')" class="eicproduct-validation-error">{{
                                errors.first("year", "page3")
                            }}</div>
                          </label>
                        </div>
                      </div>

                      <span class="gds-input-field__label">New or Used Vehicle</span>
                      <div class="gds-space-stack-l">
                        <div
                          class="gds-flex-container gds-flex-container--left gds-flex-container--space-between radioContainer">
                          <label class="gds-radio">
                            <input v-model="productSubmission.vehicles[0].preOwned" :value="false" type="radio"
                              name="new or used" v-validate="'required'" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">
                              <b>New Vehicle</b>
                            </span>
                          </label>
                          <label class="gds-radio">
                            <input v-model="productSubmission.vehicles[0].preOwned" :value="true" type="radio"
                              name="new or used" />
                            <span class="gds-radio__faux"></span>
                            <span class="gds-radio__label">
                              <b>Used Vehicle</b>
                            </span>
                          </label>
                        </div>
                        <div v-if="errors.first('new or used', 'page3')" class="eicproduct-validation-error">{{
                            errors.first("new or used", "page3")
                        }}</div>
                      </div>
                      <div class="row-l gds-space-stack-l">
                        <div class="flex-item margin-right gds-space-stack-ml">
                          <label @click.prevent class="gds-input-field">
                            <span class="gds-input-field__label">Purchase Date</span>
                            <div class="eicproduct-purchasedate__date-box">
                              <div @click="$refs.datePicker.showCalendar()"
                                class="my-account-billing-report__filtration-date-icon">
                                <svg class="gds-icon">
                                  <use
                                    xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                                </svg>
                              </div>
                              <datepicker ref="datePicker" v-model="productSubmission.vehicles[0].dateOfPurchase"
                                name="date of purchase" key="date of purchase" v-validate="'required'"
                                format="MMMM d, yyyy" :typeable="true" placeholder="Select Date..."
                                :disabled-dates="disableFutureDates"
                                input-class="export-data__date my-account-billing-report__filtration-date-text" />
                            </div>
                            <div v-if="errors.first('date of purchase', 'page3')" class="eicproduct-validation-error">{{
                                errors.first("date of purchase", "page3")
                            }}</div>
                          </label>
                        </div>
                        <div id="dealership" class="flex-item">
                          <label class="gds-input-field">
                            <span class="gds-input-field__label">Dealership (If private sale, enter "Private
                              Sale")</span>
                            <input @keyup="selectDealership($event)" v-model="productSubmission.vehicles[0].dealerName"
                              @input="$emit('touched', true); filterDealerships();" @change="filterDealerships()"
                              v-validate="'required'" name="dealership" key="dealership"
                              class="gds-input-field__input gds-space-stack-s" type="text" autocomplete="off"
                              placeholder="Type Here..." />
                            <div v-if="errors.first('dealership', 'page3')" class="eicproduct-validation-error">{{
                                errors.first("dealership", "page3")
                            }}</div>
                          </label>
                          <div class="typeAhead-container" v-if="filteredDealerships.length > 0">
                            <div class="typeAhead-card gds-space-stack-m"
                              :class="{ 'active-dealership typeAhead-card gds-space-stack-m': currentDealership === dealer.id }"
                              v-for="(dealer) in filteredDealerships" :key="dealer.id"
                              @click="setDealership(dealer.name)">
                              <div class="typeAhead-text">{{ dealer.name }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <button @click="finishPage('page3', 4)" class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- SLAT 4 Upload Documents -->
      <section id="charger-slat-4" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 4" class="eicproduct-slat__number-with-circle">
                <span>4</span>
              </div>
              <div v-if="currentPage > 4" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">
                  Upload Documents
                </div>
                <modal-document-checklist v-if="selectedFile" :uploadDocumentList="uploadDocumentList"
                  :visible="showDocumentChecklist" :select="onSelect" :save="onSave" :selectedFile="selectedFile"
                  :close="closeModal" :documentTypes="documentTypes" />
                <ModalEICProducts modalName="fileLoadTooBig" v-if="showFileLoadTooBigModal" :close="closeModal"
                  :modalMessage="modalMessage" />
                <ModalEICProducts modalName="missingDocument" v-if="showMissingDocumentsModal" :close="closeModal"
                  modalTitle="You’re missing a required document" modalMessage="Please upload a copy of the following:"
                  :listItems="missingDocuments" />
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 4" class="
                  eicproduct-slat__header-summary
                  gds-flex-container gds-flex-container
                ">
                <div v-if="documents && documents.length > 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.documents.length} Files Uploaded` }}
                </div>
                <div v-if="documents && documents.length === 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.documents.length} File Uploaded` }}
                </div>
                <button @click="gotoPage(4)" class="
                    eicproduct-slat__header-edit-button
                    gds-button gds-round gds-secondary
                  ">
                  Edit
                </button>
              </div>
              <div v-if="currentPage < 4" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div class="slat-title-wrapper">
                  <form>
                    <div id="fileUploader">
                      <File-Uploader :uploadDocumentList="uploadDocumentList" :docTypes="documentTypes"
                        @file-list-changed="updateFileList"></File-Uploader>
                    </div>
                  </form>
                </div>
                <button @click="finishPage('page4', 5)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 5 EV Charger Communication -->
      <section id="charger-slat-5" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 5" class="eicproduct-slat__number-with-circle">
                <span>5</span>
              </div>
              <div v-if="currentPage > 5" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">EV Charger Communication</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 5" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <!-- If one vehicle -->
                <div class="gds-body-normal gds-space-inline-m"></div>
                <button @click="gotoPage(5)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 5" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 5" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <form data-vv-scope="page5">
                <fieldset class="gds-fieldset gds-space-stack-s">
                  <div class="eicproduct-slap__inner-row">
                    <div class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          In order to identify how to best connect your EV charger to the internet, we have a few more
                          questions.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Do you have a WiFi (wireless) network in your
                            home?</span>
                          <div class="gds-space-stack-ml">
                            <select @change="onChangeWifiNetwork($event)" v-model="productSubmission.programEnrollment.inHomeChargerProgram.hasWifiNetwork"
                              class="eicproduct-input-field__select" v-validate="'required'" name="wifiNetworkRequired"
                              key="wifiNetworkRequired" autofocus>
                              <option value disabled select>Select...</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          <div v-if="errors.first('wifiNetworkRequired', 'page5')" class="validation-error">{{
                              errors.first("wifiNetworkRequired", "page5")
                          }}</div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="eicproduct-slap__inner-row">
                    <div v-if="productSubmission.programEnrollment.inHomeChargerProgram.hasWifiNetwork === 'yes'" class="row-s">
                      <div class="gds-space-stack-m">
                        <div class="eicproducts-required-text">
                          The WiFi signal must be strong enough to reach the location where your charger will be
                          installed/located.
                        </div>
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Is there a strong, reliable WiFi signal where the EV
                            charger will be located?</span>
                          <div class="gds-position--relative">

                            <div class="gds-space-stack-ml">
                              <select 
                              v-model="productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger"
                                class="eicproduct-input-field__select" v-validate="'required'" name="wifiSignalRequired"
                                key="wifiSignalRequired" autofocus>
                                <option value disabled select>Select...</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                        </label>
                        <div v-if="errors.first('wifiSignalRequired', 'page5')" class="validation-error">{{
                            errors.first("wifiSignalRequired", "page5")
                        }}</div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
            <div v-if="showInformationalModal">
              <modal-informational modalType="informational" :modalMessage="modalMessage" :modalLink="modalLink" @close-modal="closeModal">
              </modal-informational>
            </div>
            <button @click="finishPage('page5', 6)" class="gds-button gds-compact">Next</button>
          </div>
        </div>
      </section>

      <!-- SLAT 6 Contact & Mailing Address -->
      <section id="charger-slat-6" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 6" class="eicproduct-slat__number-with-circle">
                <span>6</span>
              </div>
              <div v-if="currentPage > 6" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Contact Info & Submit</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 6" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div class="gds-body-normal gds-space-inline-s">Mailing Address: {{
                    productSubmission.account.mailingAddress | addressFormat({ seperator: ", " })
                }}</div>
                <button @click="gotoPage(6)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 6" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 6" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page6">
                  <div>
                    <div class="gds-space-stack-m">
                      <p class="eicproduct-title">How should we contact you if we need additional info to complete your
                        enrollment in the EV Charger program?</p>
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Email Address</span>
                        <input v-model="productSubmission.emailAddress" @input="$emit('touched', true)"
                          v-validate="'required|email'" name="email address" key="email address"
                          class="eicproduct-input-field gds-input-field__input" type="email" placeholder="Your email"
                          autocomplete="email" ref="email" />
                        <div v-if="errors.first('email address', 'page6')" class="validation-error">{{
                            errors.first("email address", "page6")
                        }}</div>
                      </label>
                    </div>
                    <label class="gds-input-field">
                      <span class="gds-input-field__label">Phone Number</span>
                      <div class="gds-space-stack-xl">
                        <the-mask v-model="productSubmission.contactNumber" :mask="'+1 (###) ###-####'"
                          @input.native="touched = true" v-validate="'required|min:10'" data-vv-validate-on="input"
                          :name="'phone number'" :key="'phone number'"
                          class="eicproduct-input-field gds-input-field__input" type="tel"
                          placeholder="Your phone number" />
                        <div v-if="errors.first('phone number', 'page6')" class="validation-error">{{
                            errors.first("phone number", "page6")
                        }}</div>
                      </div>
                    </label>
                    <div v-if="!isAlreadyEbillEnrolled">
                      <div class="gds-body-small gds-space-stack-l">
                        <span class="gds-display-1 gds-space-stack-s">Would you like to go paperless?</span>
                      </div>
                      <div class="gds-body-small gds-space-stack-l slat3-body">
                        You can reduce your carbon footprint even more,
                        by switching to paperless billing! Paperless monthly energy
                        statements cut carbon and clutter while saving trees.
                      </div>
                      <div class="gds-space-stack-ml">
                        <!-- Faux file picker - wraps it in a label and hides the input -->
                        <label class="gds-checkbox gds-checkbox-thin">
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEnroll" type="checkbox" name />
                          <span class="gds-checkbox__faux"></span>
                          <span class="gds-font-size-ml">
                            <b>Yes!</b> - Enroll me in paperless billing so I can reduce my carbon footprint even more.
                          </span>
                        </label>
                      </div>
                      <div v-if="productSubmission.programEnrollment.ebill.ebillEnroll" class="gds-space-stack-ml">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Account email address</span>
                          <input v-model="productSubmission.programEnrollment.ebill.ebillEmailAddress"
                            v-validate="'required|email'" @input="$emit('touched', true)" name="account email address"
                            key="account email address" class="eicproduct-input-field gds-input-field__input"
                            type="email" placeholder="Your email" autocomplete="email" ref="email" />
                          <div v-if="errors.first('account email address', 'page6')" class="validation-error">{{
                              errors.first("account email address", "page6")
                          }}</div>
                        </label>
                      </div>
                    </div>
                    <br />  
                  </div>
                </form>
                <button v-if="!pendingSubmit" @click="handleSubmit" type="submit" :disabled="pendingSubmit"
                  class="`eicproduct__method-next-btn gds-button gds-compact`">
                  Submit Request
                </button>
                <button v-if="pendingSubmit" class="gds-button [modifier class] gds-loading">
                  <span class="gds-button__text">Submit Request</span>
                  <figure class="gds-button__icon"></figure>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ErrorBag } from "vee-validate";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import Datepicker from 'vuejs-datepicker';
import { vehicleRegistration, proofOfPurchase } from "../constants.js";
import EVChargerNotEligible from "./EVChargerNotEligible.vue";
import FileUploader from "../FileUploader.vue";
import ModalEICProducts from "../ModalEICProducts.vue";


export default {
  name: "EVChargerFlow",
  components: {
    Datepicker,
    EVChargerNotEligible,
    FileUploader,
    ModalEICProducts
  },
  data() {
    return {
      NO_VT_REGISTRATION_MESSAGE: 'Thank you for your interest in our Home Charger offering.  In order to qualify for the Level 2 charger we require that the EV be registered with the Vermont DMV at your GMP account address.',
      NO_INTERNET_MESSAGE: 'Thank you for your interest in the EV charger program. Your home must have a reliable internet connection in order to enroll.',
      NOT_ELECTRIC_VEHICLE_MESSAGE: 'Thank you for your interest in our Home Charger offering. This offer is provided to customers that have an All Electric Vehicle. Given that your EV is a Plug in Hybrid Electric Vehicle, we are not able to provide you with a home charger. \n\nShould you want to participate in the EV charging rates we have you can purchase a compatible charger and register it with GMP.  The list of compatible chargers can be found here: ',
      NOT_ELECTRIC_VEHICLE_LINK: 'https://greenmountainpower.com/rebates-programs/electric-vehicles/in-home-ev-charger',
      NOT_ELECTRIC_VEHICLE_LINK_NAME: 'Compatible Vehicles',
      modalMessage: null,
      modalLink: null,
      modalLinkName: null,

      disableFutureDates: {
        from: new Date(),
      },
      disablePastDates: {
        to: new Date()
      },
      isKeyboarding: false,
      loadState: undefined,
      lastYear: new Date().getFullYear() - 1,
      pendingSubmit: undefined,
      touched: false,
      pendingSave: undefined,
      isAlreadyEbillEnrolled: undefined,
      isMobile: undefined,
      isLevel2Eligible: true,
      isCommercialAccount: undefined,

      currentDealership: 0,

      totalFileSize: undefined,
      maxFileLoadSize: undefined,

      stateList: ["VT", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "WA", "WV", "WI", "WY"],
      provinceList: ["QC", "AB", "BC", "MB", "NB", "NL", "NS", "ON", "PE", "SK", "NT", "NU", "YT"],

      dealerships: [],
      filteredDealerships: [],
      totalSavings: 0,

      vehicleModels: undefined,
      disableBrandModel: undefined,

      vehicleRegistration: vehicleRegistration,
      proofOfPurchase: proofOfPurchase,

      showDocumentChecklist: false,
      showMissingDocumentsModal: false,
      showInformationalModal: false,
      showProofOfPurchaseInfoModal: false,
      showFileLoadTooBigModal: false,

      productSubmission: {
        submissionType: "electricVehicleCharger",
        numberOfUnitsRequested: "1",
        account: {
          accountNumber: undefined,
          firstName: undefined,
          lastName: undefined,
          premiseAddress: {
            street1: undefined,
            street2: null,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined
          },
          mailingAddress: {
            street1: null,
            street2: null,
            city: null,
            state: null,
            zip: null,
            country: null
          }
        },
        vehicles: [{
          year: undefined,
          brandModel: undefined,
          engineType: 'All Electric',
          vinNumber: undefined,
          dateOfPurchase: undefined,
          dealerName: undefined,
          preOwned: undefined,
          purchasePrice: 0,
          isRegisteredInVt: '',
          isAllElectric: '',
        }],
        rebate: {
          rebatePayeeName: undefined,
          confirmationEmailAddress: undefined,
          contactPhoneNumber: undefined,
          rebateDeliveryMethod: undefined,
          rebateMailingAddress: {
            street1: undefined,
            street2: undefined,
            city: undefined,
            state: undefined,
            zip: undefined,
            country: undefined
          },
          rebateMailingAddressOverride: false,
          stateOfVermontRebate: {
            preApprovalNumber: null,
            expirationDate: null
          },
          householdIncome: {
            numberInHousehold: 1,
            grossHouseholdIncome: 53000
          }
        },
        programEnrollment: {
          inHomeChargerProgram: {
            enrollInHomeChargerProgram: true,
            hasWifiNetwork: '',
            hasInternet: '',
            hasWifiSignalAtCharger: false,
          },
          ebill: {
            ebillEnroll: false,
            ebillEmailAddress: null
          }
        },
        emailAddress: null,
        contactNumber: null,
      },

      selectedFile: undefined,
      file: undefined,
      documents: [],
      documentTypes: [],
      missingDocuments: [],
      selectedDocumentType: undefined,

      uploadDocumentList: [
        {
          documentName: 'vehicleRegistration',
          displayName: 'Vemont Vehicle Registration',
          error: undefined,
          file: null,
          fileName: null,
          fileSize: null,
        }
      ]
    };
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    accountNickname() {
      return this.currentAccount.nickname != null ? this.currentAccount.nickname : this.currentAccount.accountNumber;
    },
    filteredVehicleModels() {
      let filteredVehicleModels = [];
      for (let i = 0; i < this.vehicleModels.length; i++) {
        if (this.vehicleModels[i].includes(this.productSubmission.vehicles[0].engineType)) {
          filteredVehicleModels.push(this.vehicleModels[i]);
        }
      }
      return filteredVehicleModels;
    },
  },
  filters: {
    formatVehicleModel(vehicle) {
      const brandModel = vehicle.split(/-(.+)/)[1].trim();
      return `${brandModel}`;
    }
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (this.currentPage === 3) {
        this.isKeyboarding = true;
      } else {
        this.isKeyboarding = false;
      }
    });
  },
  async mounted() {
    if (this.isCommercialAccountCheck()) {
      return;
    }

    this.initializePageSettings();

    try {
      this.loadProductSubmissionFromLocalStorage();
      await this.fetchInitialData();
      this.updateEngineTypeState();
      this.loadState = "complete";
    } catch (err) {
      this.handleError(err);
    }

    this.setupTimeouts();
  },
  methods: {
    toTitleCase(str) {
      if(!str) { return str; }
      return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },

    isCommercialAccountCheck() {
      if (this.currentAccount.customerClass === "C") {
        this.isCommercialAccount = true;
        this.loadState = "complete";
        return true;
      }
      return false;
    },

    initializePageSettings() {
      this.checkLevel2Eligibility();
      this.$store.commit("setLastValidPage", "page1");
      document.addEventListener("click", this.hideTypeAhead);
      this.setProductSubmissionAccount();
    },

    checkLevel2Eligibility() {
      this.isLevel2Eligible = !this.currentAccount.serviceAgreements.some(
        (agreement) => agreement.rate === "N06" || agreement.rate === "06"
      );
    },

    loadProductSubmissionFromLocalStorage() {
      const localStorageProductSubmissionObj = this.getWithExpiry("localProductSubmission") || undefined;
      if (
        localStorageProductSubmissionObj &&
        localStorageProductSubmissionObj.submissionType === "electricVehicleCharger"
      ) {
        this.productSubmission = localStorageProductSubmissionObj;
      }
    },

    async fetchInitialData() {
      this.loadState = undefined;
      await Promise.all([
        this.checkIfOnEBill(),
        this.getVehicleModels(),
        this.getDealerships(),
        this.getEICMeta(this.productSubmission.submissionType),
      ]);
    },

    updateEngineTypeState() {
      const engineType = this.productSubmission?.vehicles?.[0]?.engineType;
      this.disableBrandModel = !engineType;
    },

    handleError(err) {
      DumpError("EVChargerFlow loggedin load error", err);
      this.loadState = "error";
    },

    setupTimeouts() {
      setTimeout(() => {
        this.slatFocus(1);
      }, 1000);

      setTimeout(() => {
        this.resetState();
      }, 1000 * 60 * 60 * 6); // flush local storage & reload page after 6 hours
    },

    async checkIfOnEBill() {
      const status = await GMPAPI.GetBillingStatus(this.currentAccount.accountNumber);
      if (status.billRouteInformation.isEBill) {
        this.isAlreadyEbillEnrolled = true;
      }
      //return true;
    },
    /** Go to a page number without validation */
    gotoPage(page) {
      this.isKeyboarding = false;
      this.$router.push({ path: "/form/" + `${page}/` });
      this.slatFocus(page);
    },
    chooseAccount() {
      this.isKeyboarding = false;
      this.$router.push({ path: "/account" });
    },
    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        var elem = document.getElementById(`charger-slat-${page}`);
        elem.scrollIntoView();
      }, 100);
    },
    hideTypeAhead() {
      this.filteredDealerships = [];
      this.currentDealership = 0;
    },
    setProductSubmissionAccount() {
      this.productSubmission.account.accountNumber = this.currentAccount.accountNumber;
      this.productSubmission.account.firstName = `${this.toTitleCase(this.currentAccount.firstName)}`;
      this.productSubmission.account.lastName = `${this.toTitleCase(this.currentAccount.lastName)}`;

      this.productSubmission.account.premiseAddress = this.currentAccount.address;
      this.productSubmission.account.mailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.contactNumber = this.currentAccount.phone;
      this.productSubmission.emailAddress = this.currentAccount.emailAddress;
    },
    /** Validate the current form, and if successful, go to a page number */
    async finishPage(pageName, nextPage) {
      localStorage.removeItem("localProductSubmission")
      this.setWithExpiry("localProductSubmission", this.productSubmission, (1000 * 60 * 60 * 6)); // 6 hours
      let currentPage = nextPage - 1;

      if (await this.$validator.validateAll(pageName)) {
        switch (pageName) {
          case "page1":
            break;

          case "page2":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();

            if (this.productSubmission.vehicles[0].isAllElectric === 'no') {
              this.showInformationalModal = true;
              this.modalMessage = this.NOT_ELECTRIC_VEHICLE_MESSAGE;
              this.modalLink = this.NOT_ELECTRIC_VEHICLE_LINK;
              this.modalLinkName = 'Compatible Chargers';
            }
            else if (this.productSubmission.vehicles[0].isRegisteredInVt === 'no') {
              this.showInformationalModal = true;
              this.modalMessage = this.NO_VT_REGISTRATION_MESSAGE;
              this.modalLink = '';
            }
            else if (this.productSubmission.programEnrollment.inHomeChargerProgram.hasInternet === 'no') {
              this.showInformationalModal = true;
              this.modalMessage = this.NO_INTERNET_MESSAGE;
              this.modalLink = '';
            } else {
              this.showInformationalModal = false;
              this.modalMessage = '';
              this.modalLink = '';
            }
            break;

          case "page3":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;

          case "page4":
            this.totalFileSize = 0;
            this.missingDocuments.length = 0;

            if (this.documents && this.documents.length > 0) {
              for (let i = 0; i < this.documents.length; i++) {

                if (this.documents[i].file) {
                  this.totalFileSize += this.documents[i].file.size;
                }
              }

              if (this.totalFileSize > this.maxFileLoadSize) {
                this.showFileLoadTooBigModal = true;
                this.modalMessage = `Your total file size is ${this.totalFileSize}. Please make sure the file size is less than ${this.maxFileLoadSize}.`;
              }

              let index = this.documents.findIndex(doc => doc.documentName === vehicleRegistration);
              if (this.documents[index].file === null || this.documents[index].file === undefined) {
                this.modalMessage = "You’re missing a required document";
                this.showMissingDocumentsModal = true;
                this.missingDocuments.push('Vermont Vehicle Registration');
              }
            } else {
              this.modalMessage = "You’re missing a required document";
              this.showMissingDocumentsModal = true;
              this.missingDocuments.push('Vermont Vehicle Registration');
            }
            break;

          case "page5":
            this.errors.clear(pageName);
            if (!this.errors) this.errors = new ErrorBag();
            break;
        }
        if (this.errors.any(pageName) || this.showInformationalModal) {
          this.slatFocus(currentPage);
        } else {
          this.$store.commit("setLastValidPage", pageName);
          this.gotoPage(nextPage);
        }
      }
    },
    async getVehicleModels() {
      const vehicleModels = await GMPAPI.GetEVModels();
      vehicleModels.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      })
      this.vehicleModels = vehicleModels;
    },
    async getEICMeta(productType) {
      const metadata = await GMPAPI.GetEICMeta(productType);
      this.maxFileLoadSize = metadata.maxFileUploadSize;
    },
    async getDealerships() {
      const dealerships = await GMPAPI.GetEVDealerships();
      dealerships.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      })

      let decodedDealerships = [];
      for (let i = 0; i < dealerships.length; i++) {
        var txt = document.createElement("textarea");
        txt.innerHTML = dealerships[i];
        decodedDealerships.push(txt.value);
      }
      this.dealerships = decodedDealerships;
    },
    filterDealerships() {
      let filteredDealerships;
      for (let i = 0; i < this.dealerships.length; i++) {
        filteredDealerships = this.dealerships.filter(dealer => {
          return dealer.toLowerCase().startsWith(this.productSubmission.vehicles[0].dealerName.toLowerCase());
        })
      }
      this.filteredDealerships = [];
      for (let i = 0; i < filteredDealerships.length; i++) {
        this.filteredDealerships.push({
          id: i,
          name: filteredDealerships[i]
        })
      }
    },
    setDealership(dealership, i) {
      if (dealership) {
        this.filteredDealerships = [];
        this.productSubmission.vehicles[0].dealerName = dealership;
      }
    },
    selectDealership(event) {
      const typeAhead = document.querySelector(".typeAhead-container")
      if (typeAhead) {
        if (event.keyCode === 38 && this.currentDealership > 0) {
          this.currentDealership--;
          typeAhead.children[this.currentDealership].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        } else if (event.keyCode === 40 && this.currentDealership < this.filteredDealerships.length - 1) {
          this.currentDealership++;
          typeAhead.children[this.currentDealership].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });;
        } else if (event.keyCode === 13) {
          this.productSubmission.vehicles[0].dealerName = this.filteredDealerships[this.currentDealership].name;
          this.hideTypeAhead();
        }
      }
    },
    parseVehicle(vehicle) {
      this.productSubmission.vehicles[0].brandModel = vehicle;
      const engineType = vehicle.split(/-(.+)/)[0].trim();
      this.productSubmission.vehicles[0].engineType = engineType;
    },
    closeModal(modalType) {
      if (modalType === 'informational') {
        this.showInformationalModal = false;
      }
      if (modalType === 'missingDocument') {
        this.showMissingDocumentsModal = false;
        this.gotoPage(4);
      }
      if (modalType === 'documentChecklist') {
        if (this.selectedDocumentTypes) {
          const array = this.documentTypes.filter(type => !this.selectedDocumentTypes.includes(type));
          this.documentTypes = array;
        }
        this.selectedFile = undefined;
        this.showDocumentChecklist = false;
      }
      if (modalType === 'fileLoadTooBig') {
        this.showFileLoadTooBigModal = false;
        this.gotoPage(3);
      }
    },
    onChangeRegistration() {
      if (this.productSubmission.vehicles[0].isRegisteredInVt === 'no') {
        this.showInformationalModal = true;
        this.modalMessage = this.NO_VT_REGISTRATION_MESSAGE;
        this.modalLink = '';
        this.modalLinkName = '';
      } else {
        this.showInformationalModal = false;
      }
    },
    onChangeInternet() {
      if (this.productSubmission.programEnrollment.inHomeChargerProgram.hasInternet === 'no') {
        this.showInformationalModal = true;
        this.modalMessage = this.NO_INTERNET_MESSAGE
        this.modalLink = '';
        this.modalLinkName = '';
      } else {
        this.showInformationalModal = false;
      }
    },
    onChangeIsAllElectric() {
      if (this.productSubmission.vehicles[0].isAllElectric === 'no') {
        this.showInformationalModal = true;
        this.modalMessage = this.NOT_ELECTRIC_VEHICLE_MESSAGE
        this.modalLink = this.NOT_ELECTRIC_VEHICLE_LINK;
        this.modalLinkName = 'Compatible Chargers';
      } else {
        this.showInformationalModal = false;
        this.modalLink = null;
        this.modalLinkName = null;
      }
    },
    onChangeWifiNetwork(evt) {
      this.productSubmission.programEnrollment.inHomeChargerProgram.hasWifiNetwork = evt.target.value;
    },
    setWifiSignalValues() {
      if (this.productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger === 'yes') {
        this.productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger = true;
      } else {
        this.productSubmission.programEnrollment.inHomeChargerProgram.hasWifiSignalAtCharger = false;
      }
    },
    updateFileList(evt) {
      this.documents = evt;
    },
    onSave() {
      this.showDocumentChecklist = false;
      this.Save();
    },
    async handleSubmit() {
      try {

        const validationResult = await this.$validator.validateAll('page6');
        if (!validationResult) {
          return;
        }

        this.pendingSubmit = true;
        this.productSubmission.vehicles[0].vinNumber = this.productSubmission.vehicles[0].vinNumber.toUpperCase();
        if (this.productSubmission.vehicles[0].preOwned) {
          this.productSubmission.vehicles[0].preOwned = 'Yes';
        } else {
          this.productSubmission.vehicles[0].preOwned = 'No';
        }

        this.setWifiSignalValues();
             
        // Filter file list so that duplicate files are not included in request
        const uniqueFiles = this.documents.filter((document, index, self) =>
          index === self.findIndex((d) => (
             d.file.name === document.file.name
           ))
        );

        const formdata = new FormData();
        // Append JSON data to FormData instance
        formdata.append('productSubmission', new Blob([JSON.stringify(this.productSubmission)], { type: "application/json" }));

        // Append uploaded files to FormData instance
        for (let i = 0; i < uniqueFiles.length; i++) {
          formdata.append('files', uniqueFiles[i].file);
        }

        localStorage.removeItem("localProductSubmission")
        await GMPAPI.SubmitEVCharger(this.productSubmission.account.accountNumber, formdata);
        this.$router.push({ path: "/complete" });
      } catch (err) {
        DumpError("Electric Vehicle Charger Submission error", err);
        if (err.response && err.response.data) {
          let code = err.response.data.status;
          if (err.response.data.message) {
            console.error(err.response.data.message);
            code = err.response.data.code;
            this.$router.push({ path: "/error/" + code });
          } else {
            this.$router.push({ path: "/error/" + code });
          }
        } else {
          this.$router.push({ path: "/error/" });
        }
      }
    },
    clearModal() {
      this.showDocumentChecklist = false;
    },
    setWithExpiry(key, value, ttl) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },
    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    resetState() {
      localStorage.removeItem("localProductSubmission");
      window.location.reload(true);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>