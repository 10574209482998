export default {
  methods: {
    scrollToTop() {
      setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 300);
    },
    scrollToElement(elementId) {
      if (!elementId) {
        console.warn('Invalid element target for scrolling to.')
        return;
      }

      const elem = document.getElementById(elementId);
      elem.scrollIntoView();
    },
  }
};
