<template>
  <div class="my-account__login-page-wrap">
    <!-- This page should be reached with a query string of "?redirect=[url]".
    The user will be redirected upon login.
    For example: transit-login?redirect=/account-->

    <section class="my-account__login-form-wrap">
      <div class="gds-display-2 gds-space-stack-m">{{ destinationTitle }}</div>
      <div v-if="loadState === undefined" class="table-loading" />
      <flow-error v-if="loadState === 'error'" name="Logging in" state="error" phonetext="To report an outage or make a payment by phone call us at" />
      <flow-error v-if="loadState === 'maintenance'" name="Logging in" state="maintenance" phonetext="To report an outage or make a payment by phone call us at" />
      <div v-if="loadState === 'complete' && userIsLoggedIn">
          <p style="font-weight: bold;">You are already logged in. Do you want to log out, or continue?</p>
          <button @click="Logout()" type="button" class="gds-button gds-secondary gds-space-inline-m"><span class="gds-button__text">Log out</span></button>
          <button @click="Continue()" type="button" class="gds-button gds-space-inline-m"><span class="gds-button__text">Continue</span></button>
        </div>
      <div v-if="loadState === 'complete' && !userIsLoggedIn">
        <div class="gds-font-size-l gds-space-stack-m">
          <div v-if="!isGuest" class="gds-space-stack-m" v-html="destinationDescription"></div>
          <div v-if="isGuest" class="gds-space-stack-m" v-html="destinationDescriptionGuestLogin"></div>
        </div>

        <div v-if="allowGuestLogin" class="my-account__login-form-wrap__guest-login-toggle-container">
          <div v-if="!isGuest" class="gds-font-size-l gds-space-stack-m">
          <span @click="renderGuestForm()">No account? <span class="link-appearance" title="Login using your account number and phone last four">Log in as a guest!</span></span>
          </div>
          <div v-if="isGuest" class="gds-font-size-l gds-space-stack-m">
          <span @click="renderLoginForm()">Have an account? <span class="link-appearance" title="Login using your email and password">Log in!</span></span>
          </div>
        </div>

        <!-- START pay by phone and mail -->
        <div v-if="showPayByPhoneAndMail" class="gds-font-size-s gds-space-stack-s">
          <div><strong>Pay By Phone</strong> (844) 551-4550</div>
          <div><strong>Pay By Mail</strong></div>
          <div>Green Mountain Power</div>
          <div>P.O. Box 1325</div>
          <div>Williston VT 05495-1325</div>
        </div>
        <!-- END pay by phone and mail -->

        <!-- START Login as a Guest -->
        <form v-if="isGuest || isOnetimePaymentRoute" class="global-panel__form" @submit.prevent="GuestLogin()">
          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Account Number</span>
              <input
                v-model="accountNumber"
                v-mask="'###########'"
                v-validate="'required|numeric|min:10|max:11'"
                name="account number"
                key="account number"
                class="gds-input-field__input"
                type="tel"
              />
              <div v-if="errors.first('account number')" class="validation-error">{{ errors.first("account number") }}</div>
            </label>
          </fieldset>

          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Phone Number (Last 4 Digits)</span>
              <input v-model="phone" v-mask="'####'" v-validate="'required|numeric|min:4|max:4'" name="phone number" key="phone number" class="gds-input-field__input" type="tel" />
              <div v-if="errors.first('phone number')" class="validation-error">{{ errors.first("phone number") }}</div>
            </label>
          </fieldset>

          <div class="gds-space-stack-l">
            <div v-if="errorloginGuest" class="gds-helper-text-error">{{errorloginGuest}}</div>
          </div>

          <div class="gds-space-stack-l">
            <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
              <span class="gds-button__text">Log In</span>
            </button>
          </div>

          <div class="global-panel__form-helpers">
            <p class="gds-align--text-center gds-body-small gds-link gds-link--bold">
              Don't have an account?
              <a href="/account/create">Get Started</a>
            </p>
          </div>
        </form>
        <!-- END Login as a Guest -->
        <!-- START Normal Login -->
        <form v-if="!isGuest && !isOnetimePaymentRoute" class="global-panel__form" @submit.prevent="Login()">
          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Username or Email</span>
              <input
                v-model="username"
                class="gds-input-field__input"
                id="username"
                v-validate="'required'"
                type="text"
                spellcheck="false"
                autocapitalize="none"
                autocomplete="username"
                required
                aria-required="true"
              />
            </label>
          </fieldset>

          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorlogin }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Password</span>
              <widget-password-input v-model="password" autocomplete="current-password" />
            </label>
          </fieldset>

          <div class="global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l">
            <div class="global-login-panel__form-helper">
              <label class="gds-checkbox gds-checkbox-thin">
                <input v-model="stayloggedin" type="checkbox" id="stay-logged-in" />
                <span class="gds-checkbox__faux"></span>
                <span class="gds-checkbox__label gds-display-00 gds-text-grey">Stay Logged In</span>
              </label>
            </div>

            <div class="global-login-panel__form-helper">
              <a href="/account/forgot" class="gds-display-00 gds-link gds-link--bold">Forgot Password?</a>
            </div>
          </div>

          <div class="gds-space-stack-l">
            <div v-if="errorlogin" id="submission_error_non_guest" class="gds-helper-text-error">{{errorlogin}}</div>
          </div>

          <div class="gds-space-stack-l">
            <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button">
              <span class="gds-button__text">Log In</span>
            </button>
          </div>

          <div class="global-panel__form-helpers">
            <p class="gds-align--text-center gds-body-small gds-link gds-link--bold">
              Don't have an account?
              <a href="/account/create">Get Started</a>
            </p>
          </div>
        </form>
        <!-- END Normal Login -->
      </div>
    </section>
  </div>
</template>

<script>
import { DumpError } from "../../utilities";
import WidgetPasswordInput from "../WidgetPasswordInput";
import { GetComponentStatus } from '../../services/statuspage';

export default {
  name: "PageTransitLogin",
  components: {
    WidgetPasswordInput,
  },
  props: {
    isChildComponent: Boolean
  },
  data() {
    return {
      isGuest: false,
      stayloggedin: true,
      pendingLogin: false,
      allowGuestLogin: false,
      userIsLoggedIn: false,
      isOnetimePaymentRoute: false,
      showPayByPhoneAndMail : false,
      destination: undefined,
      errorlogin: undefined,
      errorloginGuest: undefined,
      phone: '',
      accountNumber: '',
      username: undefined,
      password: undefined,
      headerId: undefined,
      loadState: undefined,
      errorWrongNumber: undefined,
      destinationTitle: 'My Account',
      routerDestinationOTP: undefined,
      destinations: require("./destinations.json"),
      destinationDescription: 'Log in to view your account information.'
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.user;
    },
    isRestrictedRebateUser() {
      return !!this.$store.state?.user?.userinfo?.groups?.find(item => item.name === 'RESTRICTED_REBATE_USER');
    }
  },
  async mounted() {
    this.userIsLoggedIn = !!(this.currentUser && this.currentUser.apitoken);
    this.getTransitDestination();
    this.getMatchingPathConfig();

    const $loginButtons = document.querySelectorAll('.login-container .header-login .header-login-button');

    if ($loginButtons) {
      $loginButtons.forEach(($loginButton) => $loginButton.style.display = 'none');
    }

    // Check for service outages
    const status = await GetComponentStatus("MyAccount");
    if (status === "operational") {
      this.loadState = "complete";
    } else {
      this.loadState = status;
    }
  },
  methods: {
    renderLoginForm() {
      this.isGuest = undefined
    },
    getTransitDestination() {
      const query = window.location.search;
      const params = new URLSearchParams(query);
      this.destination = params.get('redirect');
    },
    adjustHeaderForChildComponent(headerId) {
      const $header = document.getElementById(headerId);

      if ($header) {
        $header.style.marginBottom = 0;
      }
    },
    renderGuestForm() {
      this.isGuest = true;
      this.phone = '';
      this.accountNumber = '';
      this.pendingLogin = false;
      this.errorloginGuest = undefined;
      this.errorWrongNumber = undefined;
    },
    FindMatchingPathConfig(path) {
      return this.destinations.find(destination => destination.paths.includes(path));
    },
    async Continue() {
      if (this.destination && this.destination.startsWith('/')) {
        window.location.href = this.destination;
      } else {
        window.location.href = '/account';
      }
    },
    async Logout() {
      try {
        await this.$store.dispatch("LogOut");
        window.location.href = "/";
      } catch (err) {
        DumpError("Logout error", err);
      }
    },
    async Login() {
      this.errorlogin = false;
      this.pendingLogin = true;
      try {
        await this.$store.dispatch("LoginUser", { username: this.username, password: this.password, stayloggedin: this.stayloggedin });

        if (this.destination) {
          window.location.href = this.destination;
        } else if (this.routerDestination) {
          if (!this.isRestrictedRebateUser) {
            this.$router.push({ path: this.routerDestination });
          } else {
            this.$router.push({ path: '/electric-vehicle-rebate'});
          }
        }

      } catch (err) {
        if (err.response && err.response.data && err.response.data.errorCode === "AUTHENTICATION_FAILURE") {
          this.errorlogin = "The email or username and password provided are incorrect.";
        } else if (err.response && err.response.data && err.response.data.errorCode === "ACCOUNT_LOCKOUT_FAILURE") {
          this.errorlogin = err.response.data.message;
        } else {
          this.errorlogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    },
    async GuestLogin() {
      if (!await this.$validator.validateAll()) return;
      this.errorloginGuest = undefined;
      this.errorWrongNumber = false;
      this.pendingLogin = true;
      try {
        await this.$store.dispatch("LoginOTP", { accountNumber: this.accountNumber, phoneLast: this.phone });
        if (this.destination) {
          window.location.href = this.destination;
        } else if (this.routerDestinationOTP) {
          this.$router.push({ path: this.routerDestinationOTP });
        }

      } catch (err) {
        DumpError("Onetime login error", err);
        if (err.response && err.response.data && err.response.data.errorCode === "INVALID_ACCOUNT_PHONE") {
          this.errorWrongNumber = true;
          this.errorloginGuest = "The account number or phone number provided is invalid."
        } else {
          this.errorloginGuest = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    },
    getMatchingPathConfig() {
      let matchingPathConfig = this.FindMatchingPathConfig(this.destination);

      if (!matchingPathConfig) {
        matchingPathConfig = this.FindMatchingPathConfig(window.location.pathname);
      }

      if (!matchingPathConfig) {
        return;
      }

      this.destinationTitle = matchingPathConfig.title;
      this.allowGuestLogin = matchingPathConfig.allowGuestLogin;
      this.destinationDescription = matchingPathConfig.description;
      this.destinationDescriptionGuestLogin = matchingPathConfig.descriptionGuestLogin;
      this.showPayByPhoneAndMail = matchingPathConfig.showPayByPhoneAndMail;
      this.isOnetimePaymentRoute = matchingPathConfig.isOnetimePaymentRoute;

      if (matchingPathConfig.routerDestination) {
        this.routerDestination = matchingPathConfig.routerDestination;
      }

      if (matchingPathConfig.routerDestinationOTP) {
        this.routerDestinationOTP = matchingPathConfig.routerDestinationOTP;
      }

      if (matchingPathConfig.headerId) {
        this.headerId = matchingPathConfig.headerId;

        if (this.isChildComponent) {
          this.adjustHeaderForChildComponent(this.headerId);
        }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>