<template>
  <div class="make-payment-container">
    <div id="top-target" class="eicproduct-success-fail">
      <div class="gds-flex-container gds-space-stack-s">
        <div class="card__data-container--large-text">
          <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/pay-plan.svg" alt="" />
        </div>
      </div>
      <div class="gds-space-stack-l">
        <div class="gds-align--text-center">
          <p>Thanks for your interest in the Electric Panel System Upgrade program.The account you entered indicates you're a commercial customer.</p>
          <p>Please reach out to the GMP Energy Innovation Team at <a href="mailto:energyinnovationteam@greenmountainpower.com">energyinnovationteam@greenmountainpower.com</a> so that we can help you directly with your application.</p>
        </div>
      </div>
      <div class="bill-pay-success__button-wrap gds-align--text-center">
        <button @click="ReturnToSender('/')" type="button" class="bill-pay-success__button gds-button">Done</button>
      </div>
    </div>
  </div>
</template>

<script>
import { GMPPhoneNumber } from "../../../environment";
import { ReturnToSender } from "../../../utilities";

export default {
  name: "ElectricPanelNotEligible",
  props: {
    currentAccount: Object,
  },
  data() {
    return {
      ReturnToSender,
      GMPPhoneNumber,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>