var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      { staticClass: "bill-pay-success-fail", attrs: { id: "top-target" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "gds-space-stack-l" }, [
          _c("div", { staticClass: "gds-align--text-center" }, [
            _vm.arrangementStatus &&
            _vm.arrangementStatus.isEligible &&
            !_vm.arrangementStatus.isEnrolled
              ? _c(
                  "a",
                  {
                    staticClass:
                      "gds-card__button gds-button gds-round gds-secondary",
                    attrs: { href: "/arrange-payment" }
                  },
                  [_vm._v("Set Up Payment Arrangement")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.arrangementStatus &&
            !_vm.arrangementStatus.isEligible &&
            !_vm.arrangementStatus.isEnrolled &&
            !_vm.arrangementStatus.ineligibleReason
              ? _c("div", { staticClass: "gds-text-grey gds-font-size-m" }, [
                  _vm._v(
                    "You are not eligible for payment arrangements at this time."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.arrangementStatus &&
            !_vm.arrangementStatus.isEligible &&
            !_vm.arrangementStatus.isEnrolled &&
            _vm.arrangementStatus.ineligibleReason
              ? _c("div", { staticClass: "gds-text-grey gds-font-size-m" }, [
                  _vm._v(_vm._s(_vm.arrangementStatus.ineligibleReason))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.arrangementStatus && _vm.arrangementStatus.isEnrolled
              ? _c("div", { staticClass: "gds-text-grey gds-font-size-m" }, [
                  _vm._v(
                    "Thank you for using the Past Due Quick Setup. Your payment arrangement installments are already included in your monthly bill."
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "bill-pay-success__button-wrap gds-align--text-center"
          },
          [
            _c(
              "button",
              {
                staticClass: "bill-pay-success__button gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.ReturnToSender("/account")
                  }
                }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-flex-container gds-space-stack-s" }, [
      _c("div", { staticClass: "card__data-container--large-text" }, [
        _c("img", {
          staticClass: "my-account__payment-plan-icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/pay-plan.svg",
            alt: " "
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }