import GMPAPI from "../../services/gmpapi";
import { addSeconds, addMinutes, isBefore, parseISO, differenceInDays } from "date-fns";
import { AnalyticsSetProperty } from "../../services/analytics";
import { PrettyAccountType } from "../../utilities";

const WALLETS_CACHE_EXPIRY_MINUTES = 30;
const PAYMENT_HISTORY_EXPIRY_MINUTES = 30;
const ACCOUNT_STATUS_EXPIRY_MINUTES = 30;
const RECURRING_STATUS_EXPIRY_MINUTES = 30;
const BILLING_STATUS_EXPIRY_MINUTES = 30;
const BILLING_PERIODS_EXPIRY_MINUTES = 30;

function _findAccountByNumber (state, accountNumber) {
  for (let accountIndex = 0; accountIndex < state.accounts.length; accountIndex++) {
    if (state.accounts[accountIndex].accountNumber === accountNumber) {
      return accountIndex;
    }
  }
  return undefined;
}

export default {
  namespaced: false,
  state: {
    apitoken: undefined,
    apitokenExpires: undefined,
    refreshtoken: undefined,
    userinfo: undefined,
    accounts: undefined,
    currentAccount: undefined,
    rememberme: false,
    ghostAccounts: undefined,
    lastUserRefresh: undefined,
    lastValidPage: undefined,
    testDriveUsage: false,
    isNetMeteredAccount: undefined
  },
  // Actions are for asynchronous tasks. Do something, then commit a mutation
  actions: {
    /** Use the GMP api to login a username, and retrieve / save the user data and access token */
    async LoginUser({ commit, dispatch }, { username, password, stayloggedin, captchaToken }) {
      // Wipe any OTP login first
      commit("clearPayment");
      // Choose to save in local vs session
      commit("setRememberme", stayloggedin);


      const { access_token, refresh_token, expires_in } = await GMPAPI.GetTokenUsername(username, password, stayloggedin);
      commit("setRefresh", refresh_token);
      commit("setToken", access_token);
      commit("setApiTokenExpires", addSeconds(new Date(), expires_in));
      commit("setLoginType", "LoginUser");
      AnalyticsSetProperty({ customerLoginStatus: "logged-in" });
      await dispatch("FetchUserInfo");
    },

    /** Determine if the current account is net-metered */
    async setIsNetMeteredAccount({ commit, state }) {
      commit("setIsNetMeteredAccount", undefined);
      let summary = await GMPAPI.GetUsageSummary(state.currentAccount.accountNumber);
      summary = summary || {};
      const summaryIsNetMetered = summary.isNetMetered;
      commit("setIsNetMeteredAccount", typeof summaryIsNetMetered === 'undefined' ? false :summaryIsNetMetered);
    },

    /** Attempt to get a new auth token using the refresh token */
    async LoginWithRefresh({ commit, state }) {

      const { access_token, expires_in } = await GMPAPI.GetRefreshedToken(state.refreshtoken, state.rememberme);
      console.log("User auth refreshed");
      commit("setToken", access_token);
      commit("setApiTokenExpires", addSeconds(new Date(), expires_in));
      commit("setLoginType", "LoginWithRefresh");
    },

    /** Use the GMP api to login a username, and retrieve / save the user data and access token */
    async LoginOTP({ commit, dispatch }, { accountNumber, phoneLast, captchaToken }) {
      // Wipe any OTP login first
      commit("clearPayment");
      // Choose to save in local vs session
      commit("setRememberme", false);
      const { access_token, expires_in } = await GMPAPI.GetTokenOTP(accountNumber, phoneLast, captchaToken);
      commit("setRefresh", undefined);
      commit("setToken", access_token);
      commit("setApiTokenExpires", addSeconds(new Date(), expires_in));
      commit("setLoginType", "LoginOTP");
      await dispatch("FetchUserInfo");
    },

    /** Use the GMP api to login a username, and retrieve / save the user data and access token */
    async LoginPhone({ commit, dispatch }, { phoneNumber, captchaToken }) {
      // Wipe any OTP login first
      commit("clearPayment");
      // Choose to save in local vs session
      commit("setRememberme", false);
      const { access_token, expires_in } = await GMPAPI.GetTokenPhone(phoneNumber, captchaToken);
      commit("setRefresh", undefined);
      commit("setToken", access_token);
      commit("setApiTokenExpires", addSeconds(new Date(), expires_in));
      commit("setLoginType", "LoginPhone");
      await dispatch("FetchUserInfo");
    },

    async CreateUser({ commit, dispatch }, body) {
      // Wipe any OTP login first
      commit("clearPayment");
      // Since you don't get to choose, we'll assume "don't remember me"
      commit("setRememberme", false);
      const { access_token, expires_in } = await GMPAPI.CreateUser(body);
      commit("setRefresh", undefined);
      commit("setToken", access_token);
      commit("setApiTokenExpires", addSeconds(new Date(), expires_in));
      await dispatch("FetchUserInfo");
    },

    /** Attempt to login with the cookie (we can't see it because it is httponly) */
    async AttemptCookieLogin({ commit, dispatch }) {
      // Wipe any OTP login first
      commit("clearPayment");
      // Choose to save in local vs session
      commit("setRememberme", false);
      try {
        await dispatch("FetchUserInfo");
      } catch (err) {
        // Cookie login not working
        await dispatch("LogOut");
        throw err;
      }
    },

    async FetchGhostingAccount({ commit }, accountNumber) {
      const accounts = await GMPAPI.GetAccounts([accountNumber]);
      if (!accounts || accounts.length === 0) {
        throw new Error("No matching accounts found");
      }
      accounts[0].photoUrl = "/wp-content/themes/gmptwentynineteen/assets/images/ghosting.png";
      if (!accounts[0].amountDueDate) accounts[0].amountDue = 0;
      commit("pushGhostAccount", accounts[0]);
      commit("setCurrentAccount", accounts[0]);
    },

    /** Update the current user's data, accounts, and account info */
    async FetchUserInfo({ commit, state }) {
      const userinfo = await GMPAPI.GetCurrentUser();
      let accounts = [];
      // Only pull info if the user has any accounts (ghosting users may have none)
      // Note that energyAccounts can be either undefined or empty array
      if (userinfo.customData.energyAccounts && userinfo.customData.energyAccounts.length) {
        // Filter out any non-numeric account numbers (obscured, obtained during phone login but can't be queried)
        const accountNumbers = userinfo.customData.energyAccounts.map(item => item.accountNumber).filter(item => item.match(/^\d*$/));
        if (accountNumbers.length > 0) {
          accounts = await GMPAPI.GetAccounts(accountNumbers);
          // Attach user data to user accounts
          for (const energyAccount of userinfo.customData.energyAccounts) {
            const account = accounts.find(item => item.accountNumber === energyAccount.accountNumber);
            if (account) {
              account.isPrimary = energyAccount.isPrimary;
              account.nickname = energyAccount.nickname;
              account.photoUrl = energyAccount.photoUrl;
              if (!account.photoUrl) account.photoUrl = "/wp-content/themes/gmptwentynineteen/assets/images/default-account.png";
              if (!account.amountDueDate) account.amountDue = 0;
            } else {
              console.error("Unknown user account number: " + energyAccount.accountNumber);
            }
          }
        } else {
          // Must be dealing with obscured accts
          accounts = userinfo.customData.energyAccounts;
        }
      }

      //accounts.sort((a, b) => a.nickname.localeCompare(b.nickname));

      let selectedAccount;
      if (accounts.length) {
        // If nothing else, select the first account
        // and adjust array so primary account is first item
        selectedAccount = accounts[0];
        // Then try to select the "primary" account
        for (let index = 0; index < accounts.length; index++) {
          if (accounts[index].isPrimary) {
            selectedAccount = accounts[index];
            accounts.splice(index, 1);
            accounts.unshift(selectedAccount);
            break;
          }
        }

        // If there was a previously selected account, select that one
        if (state.currentAccount) {
          const currentNum = state.currentAccount.accountNumber;
          for (const account of accounts) {
            if (account.accountNumber === currentNum) {
              selectedAccount = account;
            }
          }
        }
      }
      // Refresh all ghosted accounts
      if (state.ghostAccounts && state.ghostAccounts.length && state.currentAccount) {
        try {
          const ghosts = await GMPAPI.GetAccounts(state.ghostAccounts.map(item => item.accountNumber));
          for (const ghosted of ghosts) {
            ghosted.photoUrl = "/wp-content/themes/gmptwentynineteen/assets/images/ghosting.png";
            if (!ghosted.amountDueDate) ghosted.amountDue = 0;
          }
          commit("setGhostAccounts", ghosts);
        } catch (err) {
          console.error("Unable to refresh ghosted accts");
        }

        // Finally, if a ghosted account was selected, pick that one
        if (state.currentAccount) {
          const currentNum = state.currentAccount.accountNumber;
          for (const account of state.ghostAccounts) {
            if (account.accountNumber === currentNum) {
              selectedAccount = account;
            }
          }
        }
      }
      commit("setUser", userinfo);
      commit("setAccounts", accounts);
      commit("setCurrentAccount", selectedAccount);
      commit("setLastRefresh");
    },

    async LogOut({ commit, state }) {
      commit("setUser", undefined);
      commit("setAccounts", undefined);
      commit("clearGhostAccounts");
      commit("setCurrentAccount", undefined);
      commit("setRememberme", false);
      commit("clearPayment");
      commit("setLoginDrawerVisible", false);
      commit("setAccountSwitcherVisible", false);
      const waiters = [GMPAPI.LogoutCookie()];
      if (state.refreshtoken) {
        waiters.push(GMPAPI.RevokeRefreshToken(state.refreshtoken));
      }
      await Promise.all(waiters);
      commit("setToken", undefined);
      commit("setRefresh", undefined);
      commit("setApiTokenExpires", undefined);

      // Removes all cookies associated with login
      window.localStorage.removeItem("gmp-vue");
      window.sessionStorage.clear();
    },
  },
  // Changes the state
  mutations: {
    setIsNetMeteredAccount (state, value) {
      state.isNetMeteredAccount = value;
    },
    setTestDriveUsage(state, value) {
      state.testDriveUsage = value;
    },
    setLoginType(state, type) {
      state.loginType = type;
    },
    setToken(state, token) {
      state.apitoken = token;
    },
    setRefresh(state, token) {
      state.refreshtoken = token;
    },
    setUser(state, userinfo) {
      state.userinfo = userinfo;
    },
    setAccounts(state, accounts) {
      state.accounts = accounts;
    },
    setCurrentAccount(state, account) {
      state.currentAccount = account;
      AnalyticsSetProperty({ customerType: PrettyAccountType(account) });
    },
    setRecurringStatus(state, payload) {
      const accountIndex = _findAccountByNumber(state, payload.accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].recurringStatus = payload;
      state.accounts[accountIndex].recurringStatus.expiryDate = addMinutes(Date.now(), RECURRING_STATUS_EXPIRY_MINUTES)
    },
    clearRecurringStatus(state, accountNumber) {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].recurringStatus = undefined;
    },
    setAccountStatus(state, payload) {
      const accountIndex = _findAccountByNumber(state, payload.accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].status = payload;
      state.accounts[accountIndex].status.expiryDate = addMinutes(Date.now(), ACCOUNT_STATUS_EXPIRY_MINUTES)
    },
    setBillingStatus(state, payload) {
      const accountIndex = _findAccountByNumber(state, payload.accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].billing = payload;
      state.accounts[accountIndex].billing.expiryDate = addMinutes(Date.now(), BILLING_STATUS_EXPIRY_MINUTES)
    },
    clearBillingStatus(state, accountNumber) {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].billing = undefined;
    },
    setWallets(state, payload) {
      const accountIndex = _findAccountByNumber(state, payload.accountNumber);
      if (accountIndex === undefined) { return; }
      const clonedPayload = JSON.parse(JSON.stringify(payload));
      state.accounts[accountIndex].wallets = clonedPayload;
      state.accounts[accountIndex].wallets.expiryDate = addMinutes(Date.now(), WALLETS_CACHE_EXPIRY_MINUTES)
    },
    clearWallets(state, accountNumber) {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].wallets = undefined;
    },
    setBillingPeriods(state, payload) {
      const accountIndex = _findAccountByNumber(state, payload.accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].billingPeriods = payload;
      state.accounts[accountIndex].billingPeriods.expiryDate = addMinutes(Date.now(), BILLING_PERIODS_EXPIRY_MINUTES);
    },
    setPaymentHistory(state, payload) {
      const accountIndex = _findAccountByNumber(state, payload.accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].paymentHistory = payload;
      state.accounts[accountIndex].paymentHistory.expiryDate = addMinutes(Date.now(), PAYMENT_HISTORY_EXPIRY_MINUTES)
    },
    setRememberme(state, remember) {
      state.rememberme = remember;
    },
    clearGhostAccounts(state) {
      state.ghostAccounts = undefined;
    },
    pushGhostAccount(state, account) {
      if (!state.ghostAccounts) {
        state.ghostAccounts = [account];
      } else {
        state.ghostAccounts.unshift(account);
        if (state.ghostAccounts.length > 5) {
          state.ghostAccounts.pop();
        }
      }
    },
    setGhostAccounts(state, accounts) {
      state.ghostAccounts = accounts;
    },
    setLastRefresh(state) {
      state.lastUserRefresh = new Date().toISOString();
    },
    clearLastRefresh(state) {
      state.lastUserRefresh = undefined;
    },
    setApiTokenExpires(state, date) {
      if (date) {
        state.apitokenExpires = date.toISOString();
      } else {
        state.apitokenExpires = undefined;
      }
    },
    setLastValidPage(state, page) {
      state.lastValidPage = page;
    },
    clearPaymentHistory(state, accountNumber) {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      state.accounts[accountIndex].paymentHistory = undefined;
    }
  },
  getters: {
    getTestDriveUsage(state) {
      return state.testDriveUsage;
    },
    getWallets: (state) => (accountNumber) => {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      let currentAccount = state.accounts[accountIndex];

      if (
        !currentAccount ||
        !currentAccount.wallets ||
        !currentAccount.wallets.wallets ||
        !currentAccount.wallets.expiryDate ) {
          return undefined;
      }

      if (isBefore(parseISO(currentAccount.wallets.expiryDate), Date.now())) {
        return undefined;
      }

      return currentAccount.wallets;
    },
    getPaymentHistory: (state) => (accountNumber, daysBackToSearch) => {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }

      const currentAccount = state.accounts[accountIndex];
      if (
        !currentAccount ||
        !currentAccount.paymentHistory ||
        !currentAccount.paymentHistory.paymentHistory ||
        !currentAccount.paymentHistory.expiryDate ) {
          return undefined;
      }

      if (isBefore(parseISO(currentAccount.paymentHistory.expiryDate), Date.now())) {
        return undefined;
      }

      let currentAccountPaymentHistory = currentAccount.paymentHistory;
      if (
        currentAccountPaymentHistory.daysBackToSearch &&
        daysBackToSearch == currentAccountPaymentHistory.daysBackToSearch ) {
          return currentAccountPaymentHistory.paymentHistory;
      }

      if (daysBackToSearch < currentAccountPaymentHistory.daysBackToSearch) {
        const filteredPayments = currentAccountPaymentHistory.paymentHistory.filter(payment => {
          const paymentDate = new Date(payment.paymentEnteredDate);
          const daysBack = differenceInDays(Date.now(), paymentDate);
          return daysBack <= daysBackToSearch;
        })
        return filteredPayments;
      }

      return undefined;
    },
    getAccountStatus: (state) => (accountNumber) => {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      let currentAccount = state.accounts[accountIndex];

      if (
        !currentAccount ||
        !currentAccount.status ||
        !currentAccount.status.accountStatus ||
        !currentAccount.status.expiryDate ) {
          return undefined;
      }

      if (isBefore(parseISO(currentAccount.status.expiryDate), Date.now())) {
        return undefined;
      }

      return currentAccount.status.accountStatus;
    },
    getBillingStatus: (state) => (accountNumber) => {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      let currentAccount = state.accounts[accountIndex];

      if (
        !currentAccount ||
        !currentAccount.billing ||
        !currentAccount.billing.billingStatus ||
        !currentAccount.billing.expiryDate ) {
          return undefined;
      }

      if (isBefore(parseISO(currentAccount.billing.expiryDate), Date.now())) {
        return undefined;
      }

      return currentAccount.billing.billingStatus;
    },
    getRecurringStatus: (state) => (accountNumber) => {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      let currentAccount = state.accounts[accountIndex];

      if (
        !currentAccount ||
        !currentAccount.recurringStatus ||
        !currentAccount.recurringStatus.recurringStatus ||
        !currentAccount.recurringStatus.expiryDate ) {
          return undefined;
      }

      if (isBefore(parseISO(currentAccount.recurringStatus.expiryDate), Date.now())) {
        return undefined;
      }

      return currentAccount.recurringStatus.recurringStatus;
    },
    getBillingPeriods: (state) => (accountNumber) => {
      const accountIndex = _findAccountByNumber(state, accountNumber);
      if (accountIndex === undefined) { return; }
      let currentAccount = state.accounts[accountIndex];

      if (
        !currentAccount ||
        !currentAccount.billingPeriods ||
        !currentAccount.billingPeriods.billingPeriods ||
        !currentAccount.billingPeriods.expiryDate ) {
          return undefined;
      }

      if (isBefore(parseISO(currentAccount.billingPeriods.expiryDate), Date.now())) {
        return undefined;
      }

      return currentAccount.billingPeriods.billingPeriods;
    }
  }
}