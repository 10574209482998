var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-page__my-impact" },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.deviceStatusHistories
        ? _vm._l(_vm.deviceStatusHistories, function(deviceStatusHistory) {
            return _c("DeviceStatusHistory", {
              key: deviceStatusHistory.deviceId,
              attrs: {
                "device-status-history": deviceStatusHistory,
                device: _vm.findDeviceById(deviceStatusHistory.deviceId)
              }
            })
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-container gds-space-stack-l" }, [
      _c("h3", { staticClass: "gds-font-medium gds-space-stack-s" }, [
        _vm._v("Status History")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }