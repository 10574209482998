<template>
  <div class="gds-space-stack-l devices-radio-group-formatted-html">
    <div class="gds-flex-container gds-flex-container--column gds-flex-container--stretch devices-row-gap devices-max-width-my-account">
      <div class="gds-font-demi gds-font-size-l gds-space-stack-m">
        {{ config.title || '' }}
      </div>
    </div>
    <div
      class="devices-radio-group-formatted-html__labels-container gds-flex-container gds-flex-container--stretch"
      :class="isDesktopView ? 'gds-flex-container--row gds-flex-container--left devices-column-gap' : 'gds-flex-container--column gds-flex-container--top devices-row-gap'"
    >
      <label
        v-for="(option, index) in config.options"
        :key="index"
        class="gds-flex-container gds-flex-container--top gds-flex-container--left gds-font-demi gds-font-size-m devices-border-grey devices-padding-m devices-padding-no-right devices-set-width-329"
        :class="{ 'devices-outline-indigo' : selectedValue === option.value }"
      >
        <input
          v-model="selectedValue"
          class="gds-radio gds-secondary-radio"
          type="radio"
          :value="option.value"
        >
        <span class="gds-flex-container gds-flex-container--column gds-flex-container--top devices-row-gap devices-width-80">
          {{ option.text }}
          <div
            v-if="option.html"
            class="gds-font-book"
            v-html="option.html"
          />
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import MediaQueryMixin from '../../../../../mixins/MediaQueryMixin';

export default {
  name: 'RadioGroupFormattedHtml',
  mixins: [MediaQueryMixin],
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedValue: this.config.selectedValue || undefined,
    };
  },
  computed: {
    isDesktopView() {
      return this.deviceIsExtraLarge
      || this.deviceIsExtraExtraLarge
      || this.deviceExceedsExtraExtraLarge;
    },
  },
  watch: {
    selectedValue: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      immediate: true,
    },
    config: {
      handler(newVal) {
        this.selectedValue = newVal.selectedValue;
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
</style>
