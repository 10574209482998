var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Rebates",
              state: "error",
              phonetext: "To make a payment by phone call us at",
              closepath: "/electric-panel"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.routeloading || _vm.loadState === undefined
        ? _c("div", [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" ? _c("router-view") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "eicproduct__page-loader" }, [
      _c("img", {
        staticClass: "eicproduct__page-loader--svg",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }