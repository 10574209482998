var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "subpage-dashboards subpage-dashboard--user-profile" },
    [
      _c("h1", { staticClass: "my-account__title gds-space-stack-l" }, [
        _vm._v("Add / Manage Accounts")
      ]),
      _vm._v(" "),
      _vm.numLinkedAccounts > _vm.accountWarningCount
        ? _c("banner-one-action", {
            attrs: {
              bannerColor: "blue",
              message:
                "You have " +
                _vm.numLinkedAccounts +
                " accounts linked to this profile. Having a large number of accounts may cause performance issues. Please consider removing unused accounts or creating a new profile to add more."
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.adding
        ? _c("div", [
            _c(
              "section",
              {
                staticClass:
                  "my-account__section-with-bottom-border gds-space-stack-l"
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "gds-font-size-xl gds-font-demi gds-space-inline-m"
                      },
                      [_vm._v("Linked Accounts")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "gds-button gds-compact gds-round",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.adding = true
                          }
                        }
                      },
                      [_vm._v("Add Account")]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "user-profile__linked-accounts",
                class: { "gds-loading-full": _vm.refreshing }
              },
              _vm._l(_vm.accounts, function(account) {
                return _c("widget-account-row", {
                  key: account.accountNumber,
                  attrs: { account: account },
                  on: { setprimary: _vm.SetPrimary }
                })
              }),
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.adding
        ? _c(
            "section",
            {
              staticClass:
                "my-account__full-width-section user-profile__add-account-section"
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.AddAccount()
                    }
                  }
                },
                [
                  _c("fieldset", { staticClass: "gds-fieldset" }, [
                    _c("label", { staticClass: "gds-input-field" }, [
                      _c("span", { staticClass: "gds-input-field__label" }, [
                        _vm._v("Your GMP Account Number*")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newAccountNumber,
                            expression: "newAccountNumber"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|numeric|length:10,11",
                            expression: "'required|numeric|length:10,11'"
                          },
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###########",
                            expression: "'###########'"
                          }
                        ],
                        key: "account number",
                        staticClass: "gds-input-field__input",
                        attrs: {
                          maxlength: "11",
                          name: "account number",
                          type: "text",
                          placeholder: "e.g. 123456789"
                        },
                        domProps: { value: _vm.newAccountNumber },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newAccountNumber = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _vm.errors.first("account number")
                      ? _c("div", { staticClass: "validation-error" }, [
                          _vm._v(_vm._s(_vm.errors.first("account number")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-m" },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Account Nickname (Optional)")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newNickname,
                              expression: "newNickname"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:30",
                              expression: "'max:30'"
                            }
                          ],
                          key: "account nickname",
                          staticClass: "gds-input-field__input",
                          attrs: {
                            maxlength: "30",
                            name: "account nickname",
                            type: "text",
                            placeholder: "e.g. My Home"
                          },
                          domProps: { value: _vm.newNickname },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newNickname = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.errors.first("account nickname")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(_vm._s(_vm.errors.first("account nickname")))
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-m" },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Phone (Last 4 Digits)*")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newPhone,
                              expression: "newPhone"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|length:4",
                              expression: "'required|length:4'"
                            }
                          ],
                          key: "phone number",
                          staticClass: "gds-input-field__input",
                          attrs: {
                            maxlength: "4",
                            name: "phone number",
                            type: "tel",
                            placeholder: "e.g.5678"
                          },
                          domProps: { value: _vm.newPhone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newPhone = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.errors.first("phone number")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(_vm._s(_vm.errors.first("phone number")))
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { attrs: { id: "captcha-container" } })
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errorAdd
                    ? _c(
                        "div",
                        { staticClass: "form-message-box gds-space-stack-m" },
                        [_vm._v(_vm._s(_vm.errorAdd))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "gds-button gds-secondary gds-compact gds-space-inline-s",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.adding = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass:
                        "gds-button gds-compact gds-space-stack-s gds-space-inline-m",
                      class: { "gds-loading": _vm.pendingAdd },
                      attrs: { disabled: _vm.pendingAdd, type: "submit" }
                    },
                    [
                      _c("span", { staticClass: "gds-button__text" }, [
                        _vm._v("Add")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "gds-flex-container gds-flex-container--space-between gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-font-size-xl gds-font-demi" }, [
          _vm._v("Add Account to Your Profile")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }