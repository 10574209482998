var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "usage-dashboard__widget-usage-trend" }, [
    _c("div", { staticClass: "header" }, [
      _c("h2", [_vm._v("Usage Trend")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass:
            "usage-dashboard__circle-arrow-right gds-button gds-button-circle gds-button-outline",
          attrs: { href: "/account/usage/detail/graph" }
        },
        [
          _c("svg", { staticClass: "gds-icon" }, [
            _c("use", {
              attrs: {
                "xlink:href":
                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"
              }
            })
          ])
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "body" }, [
      _vm.subtextKwh && _vm.subtext
        ? _c("div", [
            _c(
              "span",
              {
                staticClass: "usage-dashboard__widget-usage-trend--subtextKwh"
              },
              [_vm._v(_vm._s(_vm.subtextKwh))]
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "usage-dashboard__widget-usage-trend--subtext" },
              [_vm._v(_vm._s(_vm.subtext))]
            ),
            _vm._v(" "),
            _vm.selectedBarMonth
              ? _c(
                  "span",
                  {
                    staticClass: "usage-dashboard__widget-usage-trend--subtext"
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/account/usage/detail/graph" +
                            "?startDate=" +
                            _vm.selectedBarMonth +
                            "&resolution=daily"
                        }
                      },
                      [_vm._v("View")]
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loadState === "complete"
      ? _c("div", [
          _c("div", {
            ref: "widgetUsageTrend",
            staticClass: "gds-position--absolute",
            attrs: { id: "usage-dashboard__widget-usage-trend--chart" }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "my-account__usage-loading" })
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "unavailable"
      ? _c("div", { staticClass: "error-message" }, [
          _vm._v(
            "\n    Usage information is not available for this account.\n  "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "error"
      ? _c("div", { staticClass: "error-message" }, [
          _vm._v("\n    Unable to load, please try again later.\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }