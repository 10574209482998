export const IMPACT_UNITS = Object.freeze({
  PERCENT: 'percent',
  HOURS: 'hours',
  KWH: 'kWh',
  USD: 'usd',
});

export const IMPACT_KEYS = Object.freeze({
  BATTERY_BACKUP_POWER_PROVIDED: 'battery_backup_power_provided',
  BATTERY_CURRENT_CHARGE: 'battery_current_charge',
  EV_HOURS_CHARGED: 'ev_hours_charged',
  GALLON_OF_GAS_EQUIVALENT: 'gallon_of_gas_equivalent',
  AVOIDED_POWER_DISRUPTIONS: 'avoided_power_disruptions',
  PEAK_EVENTS_PARTICIPATED_IN: 'peak_events_participated_in',
  BATTERY_ENERGY_TO_GRID_DURING_PEAK_EVENTS: 'battery_energy_to_grid_during_peak_events',
  EV_CHARGING_TOTAL: 'ev_charging_total',
  EV_MPGE: 'ev_mpge',
  BATTERY_CURRENT_ENERGY_REMAINING: 'battery_current_energy_remaining',
});

export const STATUS_HISTORY_KEYS = Object.freeze({
  CONNECTION_OK: 'connection-ok',
  PARTICIPATED_ENERGY_EVENT: 'participated-energy-event',
  OPTEDOUT_ENERGY_EVENT: 'optedout-energy-event',
  DEVICE_OFFLINE: 'device-offline',
});

export const API_DATE_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ssXXX';

export const INSTALLATION_LOCATION_KEYS = Object.freeze({
  SOMEWHERE_ELSE: 'somewhere-else',
  PRIMARY_RESIDENCE: 'primary-residence',
});

export const FORM_TYPES = Object.freeze({
  ADD_DEVICE_NEW: 'add-device-new',
  ADD_DEVICE_BYOD: 'add-device-byod',
  SERVICE_REQUEST: 'service-request',
});
