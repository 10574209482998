<template>
  <div ref="usageHistoryContainer" class="widget-daily-consumption">
    <div class="header">
    <h2>Past 7 Days</h2>
    <!-- GRID-084 - FOR ROLLBACK, SWAP THE TWO SECTIONS BELOW -->
    <!-- START GRID-084 - new usage navigation -->
    <a  href="/account/usage/detail/table" class="usage-dashboard__circle-arrow-right gds-button gds-button-circle gds-button-outline">
        <svg class="gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
        </svg>
    </a>
    <!-- END GRID-084 - new usage navigation -->
    <!-- START GRID-084 - old usage-new navigation -->
    <!-- <a  href="/account/usage-new/detail/table" class="usage-dashboard__circle-arrow-right gds-button gds-button-circle gds-button-outline">
        <svg class="gds-icon">
          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right" />
        </svg>
    </a> -->
    <!-- END GRID-084 - old usage-new navigation -->
    </div>
    <div class="widget-daily-consumption__data-container usage-table">
      <template v-if="loadState === 'complete'">
        <div class="heading-row">
          <div class="header-cell date static-size-s">Date</div>
          <div v-if="!isNetMetered" class="header-cell grow-basis-20">Consumption</div>
          <div v-if="isNetMetered" class="header-cell grow-basis-20">Total Home Consumption</div>
          <div v-if="isNetMetered" class="header-cell grow-basis-20">Total Generation</div>
          <div class="header-cell conditions static-size-m">Avg. Temp.</div>
        </div>
        <template v-for="usage of dailyUsageData">
          <Widget-Daily-Consumption-Table-Row class="table-row" :key="usage.date.getTime()"
            :usage="usage"
            :isNetMetered="isNetMetered"
            :hasGenerationRecordsPresent="hasGenerationRecordsPresent"
            :maxUsageValue="maxUsageValue" />
        </template>
      </template>
    </div>
    <div v-if="!loadState" class="widget-daily-consumption__spinner"></div>
    <div v-if="loadState === 'unavailable'" class="error-message">Usage information is not available for this account.</div>
    <div v-if="loadState === 'error'" class="error-message">Unable to load, please try again later.</div>
  </div>
</template>

<script>
import { subDays } from 'date-fns';
import GMPAPI from "../../../../services/gmpapi";
import usageTableUtil from "../../../mixins/UsageTableUtil";
import { ToServerDateTruncate, DumpError } from '../../../../utilities';
import WidgetDailyConsumptionTableRow from './WidgetDailyConsumptionTableRow';

export default {
  name: "WidgetDailyConsumption",
  mixins: [ usageTableUtil ],
  components: {
    WidgetDailyConsumptionTableRow
  },
  data() {
    return {
      isNetMetered: false,
      loadState: undefined,
      maxUsageValue: undefined,
      dailyUsageData: undefined,
      hasGenerationRecordsPresent: undefined,
    }
  },
  async mounted() {
    this.setDatesAndRefreshData();
  },
  watch: {
    currentAccount() {
      this.setDatesAndRefreshData();
    },
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  methods: {
    resetData() {
      this.loadState = undefined;
      this.dailyUsageData = [];
    },
    getDailyUsageInReverseChronological(usageData) {
      return usageData.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    setDatesAndRefreshData() {
      const endDate = subDays(new Date(), 1);
      const startDate = subDays(endDate, 6);
      this.refreshData(this.currentAccount.accountNumber, startDate, endDate);
    },
    handleRefreshError(err) {
      if (err.response && err.response.status === 404) {
        this.loadState = "unavailable";
        return;
      }
      this.loadState = 'error';
      DumpError("Error getting usage data.", err);
    },
    getDailyUsageWithWeatherIcon() {
      const dailyUsageWithWeatherIcon = this.dailyUsageData.map(item => {
          if (item && (item.temperature || item.darkskyTemperature)) {
            return { ...item, weatherIconImageUrl: this.getWeatherIcon(item.weatherIcon) };
          }

          return { ...item, weatherIconImageUrl: "" };
      });

      return dailyUsageWithWeatherIcon;
    },
    async refreshData(accountNumber, startDate, endDate) {
      try {
        this.resetData();
        const response = await GMPAPI.GetPeriodUsage(accountNumber, "daily", ToServerDateTruncate(startDate), ToServerDateTruncate(endDate));
        this.dailyUsageData = response.intervals[0].values;

        this.isNetMetered = response.intervals[0].values.some(values => 'totalEnergyUsed' in values);

        this.dailyUsageData = await this.getDailyUsageWithWeatherIcon();

        this.dailyUsageData = this.getDailyUsageInReverseChronological(this.dailyUsageData);
        this.hasGenerationRecordsPresent = this.checkForUsageRecordType(this.dailyUsageData, this.GENERATION);
        this.maxUsageValue = this.findMaxUsageValue(this.dailyUsageData);
        this.loadState = 'complete';

  

      } catch (err) {
        this.handleRefreshError(err);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>