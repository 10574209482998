<template>
  <div
    class="tooltip-wrapper"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
    ref="wrapper"
  >
    <slot />
  </div>
</template>

<script>
import { TOOLTIP_ANCHOR, TOOLTIP_ID } from '../constants';

const OVERLAP = 10;
const OUT_OF_BOUNDS_PADDING = 10;

export default {
  name: 'TooltipTemplate',
  mounted() {
    this.initializeTooltipAnchor();
  },
  methods: {
    /**
     * Dynamically creates and displays a tooltip anchored to an element with the ID "tooltip-anchor".
     * The content of the tooltip is generated from a Vue slot named "hover-content". If no content
     * is provided, a default tooltip message is displayed. The tooltip is positioned relative to a
     * wrapper element, ensuring it remains visible within the viewport.
     *
     * @return {void} This method does not return any value.
     */
    showTooltip() {
      this.$nextTick(() => {
        const tooltipAnchor = document.getElementById(TOOLTIP_ANCHOR);
        if (!tooltipAnchor) return;

        const wrapper = this.$refs.wrapper;
        if (!wrapper) return;

        const wrapperRect = wrapper.getBoundingClientRect();
        const tooltipElement = this.getOrCreateTooltipElement(tooltipAnchor);
        this.setTooltipContent(tooltipElement, this.$slots["hover-content"]);
        this.positionTooltip(tooltipElement, wrapperRect);
      });
    },
    hideTooltip() {
      const tooltipAnchor = document.getElementById(TOOLTIP_ANCHOR);
      if (!tooltipAnchor) return;

      const tooltipContent = document.getElementById(TOOLTIP_ID);
      if (tooltipContent) {
        tooltipAnchor.removeChild(tooltipContent);
      }
    },
    beforeDestroy() {
      this.hideTooltip();
    },
    initializeTooltipAnchor() {
      const tooltipAnchor = document.getElementById(TOOLTIP_ANCHOR);
      if (!tooltipAnchor) {
        const newAnchor = document.createElement("div");
        newAnchor.id = TOOLTIP_ANCHOR;
        document.body.appendChild(newAnchor);
      }
    },
    positionTooltip(tooltipContent, wrapperRect) {
      const tooltipRect = tooltipContent.getBoundingClientRect();
      const { top, left } = this.calculateTooltipPosition(wrapperRect, tooltipRect);

      tooltipContent.style.top = `${top}px`;
      tooltipContent.style.left = `${left}px`;
    },
    /**
     * Ensures horizontally that it is never out of viewport.
     * Determines vertical positioning (above or below) based on
     * where there is more real estate on the screen.
     *
     * @param wrapperRect
     * @param tooltipRect
     * @returns {{top: number, left: number}}
     */
    calculateTooltipPosition(wrapperRect, tooltipRect) {
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let left = wrapperRect.left + wrapperRect.width / 2 - tooltipRect.width / 2;
      if (left < OUT_OF_BOUNDS_PADDING) {
        left = OUT_OF_BOUNDS_PADDING;
      } else if (left + tooltipRect.width > viewportWidth) {
        left = viewportWidth - tooltipRect.width - OUT_OF_BOUNDS_PADDING;
      }

      const spaceAbove = wrapperRect.top - tooltipRect.height;
      const spaceBelow = viewportHeight - wrapperRect.bottom - tooltipRect.height;
      const top = spaceAbove > spaceBelow && spaceAbove > 0
        ? wrapperRect.top + window.scrollY - tooltipRect.height + OVERLAP
        : wrapperRect.bottom + window.scrollY - OVERLAP;

      return { top, left };
    },
    getOrCreateTooltipElement(tooltipAnchor) {
      let tooltipContent = document.getElementById(TOOLTIP_ID);
      if (!tooltipContent) {
        tooltipContent = document.createElement("div");
        tooltipContent.id = TOOLTIP_ID;
        tooltipContent.className = "tooltip-position tooltip-styling";
        tooltipAnchor.appendChild(tooltipContent);
      }
      tooltipContent.innerHTML = "";
      return tooltipContent;
    },
    setTooltipContent(tooltipElement, slotContent) {
      if (!slotContent || slotContent.length === 0) {
        tooltipElement.innerHTML = "Default Tooltip Content";
      } else {
        const hoverContentContainer = document.createElement("div");
        tooltipElement.appendChild(hoverContentContainer);

        new this.$root.constructor({
          el: hoverContentContainer,
          parent: this,
          render: (h) => h("div", slotContent),
        });
      }
    },
  },
};
</script>
