<template>
  <div class="gds-space-stack-l gds-flex-container gds-flex-container--left devices-gap-2 devices-background-off-white devices-border-radius devices-padding-m devices-max-width-my-account">
    <img
      class="gds-logo devices-border-radius devices-width-25"
      :src="device.imageURL"
      :alt="device.deviceTypeDescription"
    >
    <div>
      <div class="gds-font-demi gds-font-size-xl gds-space-stack-s">{{ device.deviceName }}{{ device.deviceTypeDescription }}</div>
      <div v-if="device.deviceId" class="gds-font-book">Serial No. {{ device.deviceId }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectedDeviceSummary',
  props: {
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>
