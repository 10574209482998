<template>
  <div class="gmp-devices-page__my-impact">
    <div class="header-container gds-space-stack-l">
      <h3 class="gds-font-medium gds-space-stack-s">Status History</h3>
    </div>
    <template v-if="deviceStatusHistories">
      <DeviceStatusHistory
        v-for="deviceStatusHistory in deviceStatusHistories"
        :key="deviceStatusHistory.deviceId"
        :device-status-history="deviceStatusHistory"
        :device="findDeviceById(deviceStatusHistory.deviceId)"
      />
    </template>
  </div>
</template>

<script>

import {
  endOfDay, format, startOfDay, subDays,
} from 'date-fns';
import DeviceStatusHistory from './devicestatushistory/DeviceStatusHistory.vue';
import GMPAPI from '../../../../../services/gmpapi';
import { API_DATE_FORMAT } from '../../constants';

const NUMBER_OF_DAYS_TO_SHOW = 90;

export default {
  name: 'DeviceStatusHistoryList',
  components: {
    DeviceStatusHistory,
  },
  props: {
    devices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      deviceStatusHistories: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  async mounted() {
    await this.initializeStatusHistoryData();
  },
  methods: {
    async initializeStatusHistoryData() {
      const apiStartDate = format(
        startOfDay(subDays(new Date(), NUMBER_OF_DAYS_TO_SHOW - 1)),
        API_DATE_FORMAT,
      );

      const apiEndDate = format(
        endOfDay(new Date()),
        API_DATE_FORMAT,
      );

      this.deviceStatusHistories = await GMPAPI.GetDevicesStatusHistory(this.currentAccount.accountNumber, apiStartDate, apiEndDate);
    },
    findDeviceById(deviceId) {
      return this.devices.find((device) => device.deviceId === deviceId);
    },
  },
};
</script>
