import { InitialCaps } from '../../../../../utilities';
import FormViewModel from '../../shared/viewmodels/FormViewModel';

export default class AddDeviceFormViewModel extends FormViewModel {
  updatePrimaryResidenceHtml(currentAccount) {
    const { address } = currentAccount || {};
    if (!address) return '';

    let {
      city,
      street1,
    } = address;

    const {
      zip,
      state,
    } = address;

    city = InitialCaps(city);
    street1 = InitialCaps(street1);

    this.primaryResidenceHtml = `
    <div>${street1 || ''}</div>
    <div>${city || ''}, ${state || ''} ${zip || ''}</div>`;

    if (this.radioButtonConfigInstallationLocation
      && this.radioButtonConfigInstallationLocation.options
      && this.radioButtonConfigInstallationLocation.options[0]
    ) {
      this.radioButtonConfigInstallationLocation.options[0].html = this.primaryResidenceHtml;
    }

    this.primaryResidenceLocation = `${city || ''} ${state || ''}`;

    return this.primaryResidenceHtml;
  }
}
