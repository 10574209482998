<template>
  <div class="gmp-devices-radio-group-device-type gds-space-stack-l">
    <div class="gds-font-demi gds-font-size-xl gds-space-stack-m">
      {{ config.title || '' }}
    </div>
    <div
      class="gds-flex-container gds-flex-container--left gds-flex-container--stretch gds-flex-container--wrap devices-gap-1"
    >
      <label
        v-for="(option, index) in config.options"
        :key="index"
        class="gds-flex-container gds-flex-container--column gds-flex-container--top gds-font-demi gds-font-size-m devices-border-grey devices-set-width-152 devices-padding-m"
        :class="{ 'devices-outline-indigo' : selectedValue === option.value }"
      >
        <input
          v-model="selectedValue"
          class="gds-radio gds-secondary-radio"
          type="radio"
          :value="option.value"
        >
        <img
          v-if="option.value === 'ev-charger'"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-ev-outline.svg"
          alt="Outline of an EV charger"
        >
        <img
          v-if="option.value === 'home-battery'"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-house-outline.svg"
          alt="Outline of a house"
        >
        <img
          v-if="option.value === 'something-else'"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-plug-outline.svg"
          alt="Outline of a plug"
        >
        <img
          v-if="option.value === 'not-sure'"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/illustration-more-outline.svg"
          alt="Outline of a circle with three dots in it"
        >
        {{ option.text }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioGroupDeviceType',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedValue: this.config.selectedValue || undefined,
    };
  },
  watch: {
    selectedValue(newVal) {
      this.$emit('input', newVal);
    },
  },
};
</script>
