import GMPAPI from '../../../../../services/gmpapi';
import { DumpError } from '../../../../../utilities';

const ENROLLED = 'Enrolled';
const NOT_ENROLLED = 'Opt Out';

export default class ManageParticipationModalViewModel {
  constructor(event, device, closeModal, onManageParticipationUpdate) {
    this.pendingSave = false;
    this.newOptOut = undefined;

    this.event = event;
    this.device = device;
    this.closeModal = closeModal;
    this.onManageParticipationUpdate = onManageParticipationUpdate;

    this.selectConfigEnrollment = {
      width: '150px',
      borderRadius: '0',
      selectedValue: this.event.optedOut,
      options: [
        { label: ENROLLED, value: false },
        { label: NOT_ENROLLED, value: true },
      ],
    };
  }

  get newOptOutDefined() {
    return typeof this.newOptOut !== 'undefined';
  }

  get enrollmentStatus() {
    return this.event.optedOut ? NOT_ENROLLED : ENROLLED;
  }

  onOptionSelectedEnrollment(event) {
    this.newOptOut = event;
  }

  async UpdateDeviceEventEnrollment(accountNumber) {
    this.pendingSave = true;

    try {
      const postData = { optOut: this.newOptOut };
      await GMPAPI.UpdateDeviceEventEnrollment(accountNumber, this.device.deviceId, this.event.eventId, postData);

      this.pendingSave = false;
      this.onManageParticipationUpdate();
    } catch (err) {
      DumpError('ManageParticipationModalViewModel', err);
      this.pendingSave = false;
    }
  }
}
