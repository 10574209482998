var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "gds-display-1 gds-space-stack-m" }, [
        _vm._v(_vm._s(_vm.headingTitle))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "gds-body-small gds-space-stack-l" },
        [
          _vm._v(
            "\n    Please take photos or scans of the documents and upload by clicking on “Select File to Upload”.\n    "
          ),
          _vm.uploadDocumentList.length > 1 && _vm.allowCombined
            ? _c("div", [
                _vm._v(
                  "\n      If some of your documents are combined in into a single file, you can\n      "
                ),
                _c("span", [
                  _c(
                    "label",
                    {
                      ref: "focuser4",
                      staticClass: "gds-file",
                      attrs: { tabindex: "-1" }
                    },
                    [
                      _c("input", {
                        ref: "combinedFilePicker",
                        attrs: {
                          type: "file",
                          accept: "image/png, image/jpeg, application/pdf",
                          tabindex: "-1"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.setSelectedDocumentType(
                              $event,
                              -1,
                              "combined"
                            )
                          },
                          change: function($event) {
                            return _vm.filePicked($event)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "greenBoldText" }, [
                        _vm._v("Upload a Combined File.")
                      ])
                    ]
                  )
                ]),
                _vm._v(
                  " You’ll be prompted to note which document(s) you’re uploading.\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "accepted-file-types" }, [
            _vm._v("Accepted file types: jpeg/jpg, pdf, png")
          ]),
          _vm._v(" "),
          _vm.showW9InfoModal
            ? _c("ModalEICProducts", {
                attrs: {
                  modalName: "w9Info",
                  close: _vm.closeModal,
                  modalMessage:
                    "Please make sure you selected a box in item 3 and that you've signed your W9."
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showDocumentChecklist
            ? _c(
                "div",
                [
                  _vm.selectedFile
                    ? _c("modal-document-checklist", {
                        attrs: {
                          visible: _vm.showDocumentChecklist,
                          uploadDocumentList: _vm.uploadDocumentList,
                          selectedFile: _vm.selectedFile,
                          documentTypes: _vm.getDocumentTypes()
                        },
                        on: {
                          "close-modal": _vm.closeModal,
                          "document-types-changed": _vm.documentTypeChanged
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.documentList, function(uploadDocument, i) {
        return _c("div", { key: uploadDocument.documentName + i }, [
          _c("div", { staticClass: "file-uploader" }, [
            _c("div", { staticClass: "fileupload__number-with-circle" }, [
              _c("span", [_vm._v(_vm._s(i + 1))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "fileupload-title" }, [
              _vm._v(_vm._s(uploadDocument.displayName))
            ]),
            _vm._v(" "),
            _c(
              "label",
              {
                staticClass:
                  "gds-file gds-flex-container gds-flex-container--left item",
                attrs: { tabindex: "-1" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$refs.filePicker.click()
                  }
                }
              },
              [
                _c("div", [
                  _c("input", {
                    ref: "filePicker",
                    refInFor: true,
                    attrs: {
                      tabindex: "-1",
                      type: "file",
                      accept: "image/png, image/jpeg, application/pdf"
                    },
                    on: {
                      change: function($event) {
                        return _vm.filePicked($event)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "gds-card__button gds-button gds-round gds-secondary card__account-profile--settings",
                    attrs: {
                      to: "/settings",
                      disabled: uploadDocument.file === null ? false : true
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.setSelectedDocumentType(
                          $event,
                          i,
                          uploadDocument.documentName
                        )
                      }
                    }
                  },
                  [_vm._v("\n            Select File to Upload\n        ")]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uploaded-files-wrap" }, [
            uploadDocument.error === "docTypeNotSupported"
              ? _c("div", { staticClass: "validation-error" }, [
                  _vm._v(
                    " .doc or .docx filetypes are\n        not supported. Please upload a different file."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            uploadDocument.error === "zeroFileSize"
              ? _c("div", { staticClass: "validation-error" }, [
                  _vm._v(
                    "The file size is zero. Please upload\n        a different file."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !uploadDocument.error && uploadDocument.fileName
              ? _c("div", { staticClass: "uploaded-files-card" }, [
                  _c("div", { staticClass: "uploaded-files" }, [
                    _vm._v(_vm._s(uploadDocument.fileName))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "fileSize" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("fileSizeFormat")(uploadDocument.fileSize)
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "card-controls" } }, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.removeFile(i)
                          }
                        }
                      },
                      [
                        _c("svg", { staticClass: "gds-icon close-icon" }, [
                          _c("use", {
                            attrs: {
                              "xlink:href":
                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                            }
                          })
                        ])
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("hr", { staticStyle: { color: "#d8d8d8" } })
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }