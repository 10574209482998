var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gmp-devices-page__devices-list__device" },
    [
      _vm.device && _vm.device.deviceId
        ? [
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "DeviceDetail",
                    params: { id: _vm.device.deviceId, device: _vm.device }
                  }
                }
              },
              [
                _c("div", { staticClass: "devices-card" }, [
                  _c(
                    "section",
                    {
                      staticClass:
                        "devices-card__image-container devices-background-blue-gradient"
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.device.imageURL,
                          alt: _vm.generateAltText(_vm.device)
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "section",
                    {
                      staticClass:
                        "devices-card__text-container devices-padding-m"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "devices-color-black gds-font-size-l gds-font-medium"
                        },
                        [_vm._v(_vm._s(_vm.device.deviceName))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "gds-font-size-s gds-text-grey gds-space-stack-s"
                        },
                        [_vm._v(_vm._s(_vm.device.deviceTypeDescription))]
                      ),
                      _vm._v(" "),
                      _vm.device.loading
                        ? _c("div", {
                            staticClass: "table-loading device-card-loading"
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.device.loading && _vm.statusIcon
                        ? _c(
                            "div",
                            { staticClass: "status-icon-container" },
                            [
                              _vm.statusIcon.isEventInProgress
                                ? [_c("EventInProgressIndicator")]
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.statusIcon.isImage
                                ? _c("span", [
                                    _c("img", {
                                      staticClass: "status-icon",
                                      attrs: {
                                        alt: "Status Icon",
                                        src: _vm.statusIcon.url
                                      }
                                    })
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gds-text-grey devices-font-size-xs devices-font-uppercase"
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.device.statusMessage.message)
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ])
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      !_vm.device || !_vm.device.deviceId
        ? [
            _c("router-link", { attrs: { to: "/devices/add-device" } }, [
              _c(
                "section",
                { staticClass: "devices-card add-a-device padding-l" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "gds-button-circle add-icon gds-flex-container"
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            width: "32",
                            height: "35",
                            fill: "none"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              fill: "#202020",
                              "fill-rule": "evenodd",
                              d:
                                "M17 16.5V7.1h-2v9.4H6v2.1h9V28h2v-9.4h9v-2h-9Z",
                              "clip-rule": "evenodd"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "devices-color-black gds-font-size-l gds-font-medium"
                    },
                    [_vm._v("Add a Device")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "gds-desktop-only gds-font-size-m gds-text-grey gds-align--text-center devices-width-60"
                    },
                    [
                      _vm._v(
                        "\n          Connect a battery or EV charger you own, or get one from GMP.\n        "
                      )
                    ]
                  )
                ]
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }