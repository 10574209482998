import formConfigurations from './FormConfigurationsNew.json';
import AddDeviceFormViewModel from '../shared/AddDeviceFormViewModel';

const EV_CHARGER = 'ev-charger';
const HOME_BATTERY = 'home-battery';
const URL_BASE = window.location.origin;

export default class AddNewViewModel extends AddDeviceFormViewModel {
  constructor() {
    super();
    this.headerConfig = undefined;
    this.selectedDevice = undefined;

    Object.assign(this, formConfigurations);

    this.formData = {
      deviceType: undefined,
    };

    this.supportedDeviceTypes = [EV_CHARGER, HOME_BATTERY];
  }

  get isSupportedDevice() {
    const { selectedDeviceType } = this;
    return AddDeviceFormViewModel.isDefined(selectedDeviceType) && this.supportedDeviceTypes.includes(selectedDeviceType);
  }

  get evChargerLinkUrl() {
    return `${URL_BASE}${this.evChargerLinkUrlPath}`;
  }

  get homeBatteryLinkUrl() {
    return `${URL_BASE}${this.homeBatteryLinkUrlPath}`;
  }

  get evChargerSelected() {
    return this.isSupportedDevice && this.selectedDeviceType === EV_CHARGER;
  }

  get homeBatterySelected() {
    return this.isSupportedDevice && this.selectedDeviceType === HOME_BATTERY;
  }

  updatePageHeader() {
    this.headerConfig = { ...formConfigurations.headerConfigDefault };
  }

  formDataWatchHandler(newValue) {
    Object.keys(newValue).forEach((key) => {
      if (key === 'deviceType') {
        this.selectedDeviceType = newValue[key];
      }
    });
  }
}
