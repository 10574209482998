var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-account__login-page-wrap account-creation" },
    [
      _vm.currentPage === "invalid-logout"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c("div", { staticClass: "gds-display-1 gds-space-stack-l" }, [
                _vm._v("You must log out to create a new account.")
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "welcome"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "gds-align--text-center gds-space-stack-ml",
                  class:
                    _vm.welcomeFirstName.length > 20
                      ? "gds-display-1"
                      : "gds-display-2"
                },
                [_vm._v("Welcome " + _vm._s(_vm.welcomeFirstName) + "!")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "gds-font-size-l gds-space-stack-l gds-align--text-center"
                },
                [
                  _vm._v(
                    "Welcome to Green Mountain Power. Your online account is ready to set up. Please follow the next steps to get started."
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "global-panel__form" }, [
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.ClickNext()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Set Up My Account")
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "create-account"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c("div", { staticClass: "gds-display-2 gds-space-stack-l" }, [
                _vm._v("Create Account")
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "global-panel__form onboarding-form",
                  attrs: { "data-vv-scope": "page-create-account" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("First Name *")]
                          ),
                          _vm._v(" "),
                          _c("the-mask", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:50",
                                expression: "'required|max:50'"
                              }
                            ],
                            key: "page-create-account first name",
                            staticClass: "gds-input-field__input",
                            attrs: {
                              maxlength: "50",
                              name: "first name",
                              type: "text",
                              autocomplete: "given-name",
                              tokens: _vm.noNumbersNoSpecialCharacters,
                              mask:
                                "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",
                              "aria-invalid": _vm.errors.first(
                                "first name",
                                "page1"
                              )
                                ? true
                                : false
                            },
                            model: {
                              value: _vm.newUser.firstName,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "firstName", $$v)
                              },
                              expression: "newUser.firstName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.first("first name", "page-create-account")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "first name",
                                  "page-create-account"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Last Name *")]
                          ),
                          _vm._v(" "),
                          _c("the-mask", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:50",
                                expression: "'required|max:50'"
                              }
                            ],
                            key: "page-create-account last name",
                            staticClass: "gds-input-field__input",
                            attrs: {
                              maxlength: "50",
                              name: "last name",
                              type: "text",
                              autocomplete: "family-name",
                              tokens: _vm.noNumbersNoSpecialCharacters,
                              mask:
                                "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk",
                              "aria-invalid": _vm.errors.first(
                                "last name",
                                "page1"
                              )
                                ? true
                                : false
                            },
                            model: {
                              value: _vm.newUser.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "lastName", $$v)
                              },
                              expression: "newUser.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.first("last name", "page-create-account")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "last name",
                                  "page-create-account"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Your GMP Account Number *")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.accountNumber,
                              expression: "newUser.accountNumber"
                            },
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "###########",
                              expression: "'###########'"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric|length:10,11",
                              expression: "'required|numeric|length:10,11'"
                            }
                          ],
                          key: "page-create-account account number",
                          staticClass: "gds-input-field__input",
                          attrs: {
                            maxlength: "11",
                            name: "account number",
                            type: "text",
                            required: "",
                            "aria-required": "true"
                          },
                          domProps: { value: _vm.newUser.accountNumber },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newUser,
                                "accountNumber",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.errors.first("account number", "page-create-account")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "account number",
                                  "page-create-account"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Account Nickname (Optional)")]
                          ),
                          _vm._v(" "),
                          _c("the-mask", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:30",
                                expression: "'max:30'"
                              }
                            ],
                            key: "page-create-account account nickname",
                            staticClass: "gds-input-field__input",
                            attrs: {
                              maxlength: "30",
                              name: "account nickname",
                              type: "text",
                              tokens: _vm.nicknameAllowedCharacters,
                              mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC"
                            },
                            model: {
                              value: _vm.newUser.accountNickname,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "accountNickname", $$v)
                              },
                              expression: "newUser.accountNickname"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.first(
                        "account nickname",
                        "page-create-account"
                      )
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "account nickname",
                                  "page-create-account"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Phone (Last 4 Digits) *")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.phone,
                              expression: "newUser.phone"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric|min:4|max:4",
                              expression: "'required|numeric|min:4|max:4'"
                            }
                          ],
                          key: "page-create-account phone number",
                          staticClass: "gds-input-field__input",
                          attrs: {
                            maxlength: "4",
                            name: "phone number",
                            type: "tel",
                            required: "",
                            "aria-required": "true"
                          },
                          domProps: { value: _vm.newUser.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newUser,
                                "phone",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors.first("phone number", "page-create-account")
                          ? _c("div", { staticClass: "validation-error" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.errors.first(
                                    "phone number",
                                    "page-create-account"
                                  )
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { attrs: { id: "captcha-container" } })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errorNext
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-message-box form-message-box--full-width gds-space-stack-l"
                        },
                        [_vm._v(_vm._s(_vm.errorNext))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gds-flex-container gds-space-stack-m" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button",
                          class: { "gds-loading": _vm.pendingNext },
                          attrs: { disabled: _vm.pendingNext, type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ClickNext()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Next")
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "set-email"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c("div", { staticClass: "gds-display-2 gds-space-stack-l" }, [
                _vm._v("Set Your Email and Password")
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "global-panel__form onboarding-form",
                  attrs: { "data-vv-scope": "page-set-email" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Email Address *")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.email,
                              expression: "newUser.email"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email|max:74",
                              expression: "'required|email|max:74'"
                            }
                          ],
                          key: "page-set-email email address",
                          staticClass: "gds-input-field__input",
                          attrs: {
                            name: "email address",
                            type: "email",
                            spellcheck: "false",
                            autocapitalize: "none",
                            autocomplete: "email",
                            required: "",
                            "aria-required": "true",
                            tokens: _vm.allowedCharactersNoSpaces,
                            mask:
                              "cccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccccc"
                          },
                          domProps: { value: _vm.newUser.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newUser,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.errors.first("email address", "page-set-email")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "email address",
                                  "page-set-email"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    {
                      staticClass:
                        "gds-fieldset onboarding__password-requirements-fieldset"
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Password *")]
                          ),
                          _vm._v(" "),
                          _c("widget-password-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial.continues",
                                value:
                                  "required|length:7,30|hasupperandlower|hasnumber",
                                expression:
                                  "'required|length:7,30|hasupperandlower|hasnumber'",
                                modifiers: { initial: true, continues: true }
                              }
                            ],
                            key: "page-set-email password",
                            ref: "password",
                            attrs: {
                              "data-vv-validate-on": "input",
                              name: "password"
                            },
                            model: {
                              value: _vm.newUser.password1,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "password1", $$v)
                              },
                              expression: "newUser.password1"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("password-requirements", {
                        attrs: {
                          validationErrors: _vm.errors,
                          scope: "page-set-email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Confirm Password *")]
                          ),
                          _vm._v(" "),
                          _c("widget-password-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|confirmed:password",
                                expression: "'required|confirmed:password'"
                              }
                            ],
                            key: "page-set-email password confirmation",
                            attrs: {
                              "data-vv-validate-on": "input",
                              name: "password confirmation"
                            },
                            model: {
                              value: _vm.newUser.password2,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "password2", $$v)
                              },
                              expression: "newUser.password2"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.first(
                        "password confirmation",
                        "page-set-email"
                      )
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "password confirmation",
                                  "page-set-email"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errorNext === "DUPLICATE_EMAIL"
                    ? _c("div", [_vm._m(2), _vm._v(" "), _vm._m(3)])
                    : _vm.errorNext === "DUPLICATE_EMAIL_AND_ACCOUNT"
                    ? _c("div", [_vm._m(4), _vm._v(" "), _vm._m(5)])
                    : _vm.errorNext
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-message-box form-message-box--full-width gds-space-stack-l"
                        },
                        [_vm._v(_vm._s(_vm.errorNext))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "onboarding-form__two-button-wrap gds-flex-container gds-flex-container--nowrap"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button gds-secondary",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ClickBack()
                            }
                          }
                        },
                        [_vm._v("Back")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "gds-button",
                          class: { "gds-loading": _vm.pendingNext },
                          attrs: { disabled: _vm.pendingNext, type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ClickNext()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Next")
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "already-set-up"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _vm._m(6),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass:
                    "gds-align--text-center gds-space-stack-ml gds-display-2"
                },
                [_vm._v("Welcome back!")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "gds-font-size-l gds-space-stack-l gds-align--text-center"
                },
                [
                  _vm._v(
                    "You've already set up your account. Please log in to continue."
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "global-panel__form" }, [
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.ClickNext()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Log In")
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "bad-link"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _vm._m(7),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass:
                    "gds-align--text-center gds-space-stack-ml gds-display-1"
                },
                [_vm._v("There was a problem setting up your account.")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "gds-font-size-l gds-space-stack-l gds-align--text-center"
                },
                [
                  _vm._v(
                    "The link provided may have expired or be invalid. If you copied the link, please try again. Otherwise set up your account manually."
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "global-panel__form" }, [
                _c(
                  "button",
                  {
                    staticClass: "gds-button",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.ClickNext()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "gds-button__text" }, [
                      _vm._v("Go to Manual Setup")
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "verify-account"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "gds-display-2 gds-space-stack-l gds-align--text-center"
                },
                [_vm._v("Please verify your account information")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "gds-font-size-l gds-space-stack-l gds-align--text-center"
                },
                [
                  _vm._v(
                    "This is the account information we have on file for you. Does this look correct?"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "onboarding__account-row gds-space-stack-l" },
                [
                  _c("div", {
                    staticClass:
                      "onboarding__account-row--profile-pic small-round-img"
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "address gds-linebreaks gds-font-demi" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("addressFormat")(_vm.magicLink.address, {
                              separator: "\n",
                              skipFirst: false
                            })
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("Acct #: " + _vm._s(_vm.newUser.accountNumber))
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errorNext
                ? _c(
                    "div",
                    {
                      staticClass:
                        "form-message-box form-message-box--full-width gds-space-stack-l"
                    },
                    [_vm._v(_vm._s(_vm.errorNext))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-space-stack-m global-panel__form" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "gds-button account-creation__button-margin",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.ClickNext()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "gds-button__text" }, [
                        _vm._v("Yes, Looks Correct")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "gds-button gds-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.ClickBack()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "gds-button__text" }, [
                        _vm._v("This Doesn't Look Right")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "set-email-magic"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c("div", { staticClass: "gds-display-2 gds-space-stack-l" }, [
                _vm._v("Set Your Email and Password")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
                _vm._v("\n      Already have a GMP Online Account?\n      "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "gds-link gds-link--bold",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function($event) {
                        _vm.errorNext = undefined
                        _vm.GoToPage("login-magic")
                      }
                    }
                  },
                  [_vm._v("Sign In to Continue")]
                )
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "global-panel__form onboarding-form",
                  attrs: { "data-vv-scope": "page-set-email-magic" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Email Address *")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.email,
                              expression: "newUser.email"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            }
                          ],
                          key: "page-set-email-magic email address",
                          staticClass: "gds-input-field__input",
                          attrs: {
                            name: "email address",
                            type: "email",
                            spellcheck: "false",
                            autocapitalize: "none",
                            autocomplete: "email",
                            required: "",
                            "aria-required": "true"
                          },
                          domProps: { value: _vm.newUser.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newUser,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.errors.first("email address", "page-set-email-magic")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "email address",
                                  "page-set-email-magic"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    {
                      staticClass:
                        "gds-fieldset onboarding__password-requirements-fieldset"
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Password *")]
                          ),
                          _vm._v(" "),
                          _c("widget-password-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.initial.continues",
                                value:
                                  "required|length:7,30|hasupperandlower|hasnumber",
                                expression:
                                  "'required|length:7,30|hasupperandlower|hasnumber'",
                                modifiers: { initial: true, continues: true }
                              }
                            ],
                            key: "page-set-email-magic password",
                            ref: "password",
                            attrs: {
                              "data-vv-validate-on": "input",
                              name: "password"
                            },
                            model: {
                              value: _vm.newUser.password1,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "password1", $$v)
                              },
                              expression: "newUser.password1"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("password-requirements", {
                        attrs: {
                          validationErrors: _vm.errors,
                          scope: "page-set-email-magic"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Confirm Password *")]
                          ),
                          _vm._v(" "),
                          _c("widget-password-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|confirmed:password",
                                expression: "'required|confirmed:password'"
                              }
                            ],
                            key: "page-set-email-magic password confirmation",
                            attrs: {
                              "data-vv-validate-on": "input",
                              name: "password confirmation"
                            },
                            model: {
                              value: _vm.newUser.password2,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "password2", $$v)
                              },
                              expression: "newUser.password2"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.first(
                        "password confirmation",
                        "page-set-email-magic"
                      )
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "password confirmation",
                                  "page-set-email-magic"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errorNext === "DUPLICATE_EMAIL"
                    ? _c("div", [
                        _vm._m(8),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "gds-helper-text gds-space-stack-m"
                              },
                              [
                                _vm._v(
                                  "\n            Are you trying to add this service account to your existing user profile?\n            "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "gds-link",
                                    attrs: { href: "javascript:void(0);" },
                                    on: {
                                      click: function($event) {
                                        _vm.errorNext = undefined
                                        _vm.GoToPage("login-magic")
                                      }
                                    }
                                  },
                                  [_vm._v("Log In to Continue")]
                                ),
                                _vm._v(
                                  ' and go to "Linked Accounts" in the username menu.\n          '
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm.errorNext === "DUPLICATE_EMAIL_AND_ACCOUNT"
                    ? _c("div", [_vm._m(9), _vm._v(" "), _vm._m(10)])
                    : _vm.errorNext
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-message-box form-message-box--full-width gds-space-stack-l"
                        },
                        [_vm._v(_vm._s(_vm.errorNext))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gds-flex-container gds-space-stack-m" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button",
                          class: { "gds-loading": _vm.pendingNext },
                          attrs: { disabled: _vm.pendingNext, type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ClickNext()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Next")
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "login-magic"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c("div", { staticClass: "gds-display-2 gds-space-stack-l" }, [
                _vm._v("Log in to Continue")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
                _c("div", { staticClass: "gds-space-stack-s" }, [
                  _vm._v(
                    "If you already have an online account, log in to continue setup. Don't have a GMP Online Account?"
                  )
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "gds-link gds-link--bold",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function($event) {
                        _vm.errorNext = undefined
                        _vm.GoToPage("set-email-magic")
                      }
                    }
                  },
                  [_vm._v("Create a new account")]
                )
              ]),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "global-panel__form onboarding-form",
                  attrs: { "data-vv-scope": "page-login-magic" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "fieldset",
                    { staticClass: "gds-fieldset gds-space-stack-s" },
                    [
                      _c("label", { staticClass: "gds-input-field" }, [
                        _c("span", { staticClass: "gds-input-field__label" }, [
                          _vm._v("Username or Email")
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newUser.email,
                              expression: "newUser.email"
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          key: "page-login-magic username or email",
                          staticClass: "gds-input-field__input",
                          attrs: {
                            name: "username or email",
                            type: "text",
                            spellcheck: "false",
                            autocapitalize: "none",
                            autocomplete: "email",
                            required: "",
                            "aria-required": "true"
                          },
                          domProps: { value: _vm.newUser.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.newUser,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _vm.errors.first("username or email", "page-login-magic")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "username or email",
                                  "page-login-magic"
                                )
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "fieldset",
                    {
                      staticClass: "gds-fieldset gds-space-stack-m",
                      class: {
                        "gds-has-error": _vm.errors.first(
                          "password",
                          "page-login-magic"
                        )
                      }
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "gds-input-field" },
                        [
                          _c(
                            "span",
                            { staticClass: "gds-input-field__label" },
                            [_vm._v("Password")]
                          ),
                          _vm._v(" "),
                          _c("widget-password-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            key: "page-login-magic password",
                            attrs: {
                              autocomplete: "current-password",
                              name: "password"
                            },
                            model: {
                              value: _vm.newUser.password1,
                              callback: function($$v) {
                                _vm.$set(_vm.newUser, "password1", $$v)
                              },
                              expression: "newUser.password1"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.errors.first("password", "page-login-magic")
                        ? _c("div", { staticClass: "validation-error" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first("password", "page-login-magic")
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "global-panel__form-helpers gds-flex-container gds-flex-container--space-between gds-space-stack-l"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "global-login-panel__form-helper" },
                        [
                          _c(
                            "label",
                            { staticClass: "gds-checkbox gds-checkbox-thin" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.stayloggedin,
                                    expression: "stayloggedin"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.stayloggedin)
                                    ? _vm._i(_vm.stayloggedin, null) > -1
                                    : _vm.stayloggedin
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.stayloggedin,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.stayloggedin = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.stayloggedin = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.stayloggedin = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "gds-checkbox__faux" }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "gds-checkbox__label gds-display-00 gds-text-grey"
                                },
                                [_vm._v("Stay Logged In")]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(11)
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errorNext === "AUTHENTICATION_FAILURE"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-message-box form-message-box--full-width gds-space-stack-l"
                        },
                        [
                          _vm._v(
                            "The email or username and password provided are incorrect."
                          )
                        ]
                      )
                    : _vm.errorNext
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-message-box form-message-box--full-width gds-space-stack-l"
                        },
                        [_vm._v(_vm._s(_vm.errorNext))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "gds-flex-container gds-space-stack-m" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button",
                          class: { "gds-loading": _vm.pendingNext },
                          attrs: { disabled: _vm.pendingNext, type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ClickNext()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Log In")
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "add-service-account"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "gds-display-2 gds-space-stack-l gds-align--text-center"
                },
                [_vm._v("Add Service Account")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
                _vm._v("\n      You're signed in as\n      "),
                _c(
                  "span",
                  { staticClass: "gds-font-demi gds-email-word-break" },
                  [_vm._v(_vm._s(_vm.userInfo.email))]
                ),
                _vm._v(
                  " and we can link this service account to your user profile. If you'd like to create a new user, log out below.\n    "
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "onboarding__account-row gds-space-stack-l" },
                [
                  _c("div", {
                    staticClass:
                      "onboarding__account-row--profile-pic small-round-img"
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "address gds-linebreaks gds-font-demi" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("addressFormat")(_vm.magicLink.address, {
                              separator: "\n",
                              skipFirst: false
                            })
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("Acct #: " + _vm._s(_vm.newUser.accountNumber))
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errorNext
                ? _c(
                    "div",
                    {
                      staticClass:
                        "form-message-box form-message-box--full-width gds-space-stack-l"
                    },
                    [_vm._v(_vm._s(_vm.errorNext))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-space-stack-m global-panel__form" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "gds-button account-creation__button-margin gds-space-stack-m",
                      class: { "gds-loading": _vm.pendingNext },
                      attrs: { disabled: _vm.pendingNext, type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.ClickNext()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "gds-button__text" }, [
                        _vm._v("Link Account")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "gds-button gds-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.ClickBack()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "gds-button__text" }, [
                        _vm._v("Log Out")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "external-linked"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap account-creation" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "gds-display-2 gds-space-stack-l gds-align--text-center"
                },
                [_vm._v("We've successfully linked the service account")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "gds-font-size-l gds-space-stack-l" }, [
                _vm._v(
                  "The service account was successfully linked to your user profile. On the next page we'll set up a few things for the account."
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "onboarding__account-row gds-space-stack-l" },
                [
                  _c("div", {
                    staticClass:
                      "onboarding__account-row--profile-pic small-round-img"
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "address gds-linebreaks gds-font-demi" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("addressFormat")(
                              _vm.currentAccount.address,
                              { separator: "\n", skipFirst: false }
                            )
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "Acct #: " + _vm._s(_vm.currentAccount.accountNumber)
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.errorNext
                ? _c(
                    "div",
                    {
                      staticClass:
                        "form-message-box form-message-box--full-width gds-space-stack-l"
                    },
                    [_vm._v(_vm._s(_vm.errorNext))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "gds-space-stack-m global-panel__form" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "gds-button account-creation__button-margin gds-space-stack-m",
                      class: { "gds-loading": _vm.pendingNext },
                      attrs: { disabled: _vm.pendingNext, type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.ClickNext()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "gds-button__text" }, [
                        _vm._v("Next")
                      ])
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "enrollment"
        ? _c(
            "section",
            { staticClass: "my-account__login-form-wrap enrollment" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "enrollment__headline gds-display-2 gds-align--text-center gds-space-stack-l"
                },
                [_vm._v("Let's set up a few things for your account.")]
              ),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "global-panel__form onboarding-form",
                  attrs: { "data-vv-scope": "page-enrollment" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "enrollment-panel__flex gds-flex-container gds-flex-container--top gds-space-stack-xl"
                    },
                    [
                      _vm._m(12),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "enrollment-panel__flex--text" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "enrollment-panel__subheader gds-space-stack-s"
                            },
                            [_vm._v("Service Notifications")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gds-text-grey gds-space-stack-m" },
                            [
                              _vm._v(
                                "\n            Choose the notifications you'd like to receive. Notifications will be sent to\n            "
                              ),
                              _c("span", { staticClass: "gds-font-demi" }, [
                                _vm._v(_vm._s(_vm.userInfo.email))
                              ]),
                              _vm._v(
                                ", you can make changes later on the notifications page.\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "notifications__row-item-notify-types"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "enrollment-panel__flex--text__fieldset gds-fieldset",
                                  attrs: { role: "group" }
                                },
                                _vm._l(_vm.enrollment.alerttypes, function(
                                  alerttype
                                ) {
                                  return _c(
                                    "label",
                                    {
                                      key: alerttype.alertTypeId,
                                      staticClass: "gds-checkbox"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: alerttype.subscribed,
                                            expression: "alerttype.subscribed"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            alerttype.subscribed
                                          )
                                            ? _vm._i(
                                                alerttype.subscribed,
                                                null
                                              ) > -1
                                            : alerttype.subscribed
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = alerttype.subscribed,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    alerttype,
                                                    "subscribed",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    alerttype,
                                                    "subscribed",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                alerttype,
                                                "subscribed",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "gds-checkbox__faux"
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "gds-checkbox__label" },
                                        [_vm._v(_vm._s(alerttype.name))]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gds-font-demi gds-space-stack-s" },
                            [_vm._v("Add a Cell Phone for Alerts (Optional)")]
                          ),
                          _vm._v(" "),
                          _c(
                            "fieldset",
                            {
                              staticClass:
                                "gds-fieldset gds-space-stack-s gds-space-inline-default"
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "gds-input-field" },
                                [
                                  _c("the-mask", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "min:10",
                                        expression: "'min:10'"
                                      }
                                    ],
                                    key: "page-enrollment phone number",
                                    staticClass:
                                      "enrollment--input-field gds-input-field__input",
                                    attrs: {
                                      mask: "+1 (###) ###-####",
                                      name: "phone number",
                                      type: "tel"
                                    },
                                    model: {
                                      value: _vm.enrollment.alertPhone,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.enrollment,
                                          "alertPhone",
                                          $$v
                                        )
                                      },
                                      expression: "enrollment.alertPhone"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.errors.first(
                                "phone number",
                                "page-enrollment"
                              )
                                ? _c(
                                    "div",
                                    { staticClass: "validation-error" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "phone number",
                                            "page-enrollment"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "enrollment-panel__flex gds-flex-container gds-flex-container--top gds-space-stack-l"
                    },
                    [
                      _vm._m(13),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "enrollment-panel__flex--text" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "enrollment-panel__subheader gds-space-stack-s"
                            },
                            [_vm._v("Enroll in Paperless Billing")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "gds-text-grey gds-space-stack-m" },
                            [
                              _vm._v(
                                "With paperless billing we'll deliver your bill electronically each month."
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.enrollment.alreadyPaperless
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "enrollment__complete-checklist--row"
                                },
                                [
                                  _c("img", {
                                    staticClass: "gds-icon",
                                    attrs: {
                                      src:
                                        "/wp-content/themes/gmptwentynineteen/assets/images/check-circle-small-cropped@3x.png"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "gds-font-demi gds-font-size-l"
                                    },
                                    [_vm._v("Paperless Billing is Turned On")]
                                  )
                                ]
                              )
                            : _c(
                                "fieldset",
                                {
                                  staticClass:
                                    "gds-fieldset gds-fieldset--no-left-padding"
                                },
                                [
                                  _c("label", { staticClass: "gds-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.enrollment.enrollPaperless,
                                          expression:
                                            "enrollment.enrollPaperless"
                                        }
                                      ],
                                      attrs: { type: "radio" },
                                      domProps: {
                                        value: true,
                                        checked: _vm._q(
                                          _vm.enrollment.enrollPaperless,
                                          true
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.enrollment,
                                            "enrollPaperless",
                                            true
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "gds-radio__faux"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "gds-radio__label" },
                                      [_vm._v("Turn on Paperless Billing")]
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("label", { staticClass: "gds-radio" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.enrollment.enrollPaperless,
                                          expression:
                                            "enrollment.enrollPaperless"
                                        }
                                      ],
                                      attrs: { type: "radio" },
                                      domProps: {
                                        value: false,
                                        checked: _vm._q(
                                          _vm.enrollment.enrollPaperless,
                                          false
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.enrollment,
                                            "enrollPaperless",
                                            false
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {
                                      staticClass: "gds-radio__faux"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "gds-radio__label" },
                                      [
                                        _vm._v(
                                          "No thanks, send my bill in the mail."
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errorNext
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "form-message-box form-message-box--full-width gds-space-stack-l"
                        },
                        [_vm._v(_vm._s(_vm.errorNext))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "enrollment-panel__next-button-wrap gds-space-stack-m"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "gds-button",
                          class: { "gds-loading": _vm.pendingNext },
                          attrs: { disabled: _vm.pendingNext, type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.ClickNext()
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "gds-button__text" }, [
                            _vm._v("Done")
                          ])
                        ]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage === "complete"
        ? _c(
            "section",
            {
              staticClass:
                "my-account__login-form-wrap account-creation enrollment-complete"
            },
            [
              _c("div", { staticClass: "enrollment-complete--inner" }, [
                _vm._m(14),
                _vm._v(" "),
                _c(
                  "h2",
                  { staticClass: "gds-align--text-center gds-space-stack-l" },
                  [_vm._v("Set Up Completed")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "enrollment__complete-checklist gds-space-stack-l"
                  },
                  [
                    _vm.userCreated
                      ? _c(
                          "div",
                          {
                            staticClass: "enrollment__complete-checklist--row"
                          },
                          [
                            _c("img", {
                              staticClass: "gds-icon",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-without-shadow.svg"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-font-demi gds-font-size-l" },
                              [_vm._v("User Created")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.accountLinked
                      ? _c(
                          "div",
                          {
                            staticClass: "enrollment__complete-checklist--row"
                          },
                          [
                            _c("img", {
                              staticClass: "gds-icon",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-without-shadow.svg"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-font-demi gds-font-size-l" },
                              [_vm._v("Account Added")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.enrollment.enabledNotifications
                      ? _c(
                          "div",
                          {
                            staticClass: "enrollment__complete-checklist--row"
                          },
                          [
                            _c("img", {
                              staticClass: "gds-icon",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/icon-small-green-check-without-shadow.svg"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-font-demi gds-font-size-l" },
                              [_vm._v("Notifications Enabled")]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.enrollment.enabledPaperless
                      ? _c(
                          "div",
                          {
                            staticClass: "enrollment__complete-checklist--row"
                          },
                          [
                            _c("img", {
                              staticClass: "gds-icon",
                              attrs: {
                                src:
                                  "/wp-content/themes/gmptwentynineteen/assets/images/check-circle-small-cropped@3x.png"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-font-demi gds-font-size-l" },
                              [_vm._v("Paperless Billing Turned On")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm._m(15)
              ]),
              _vm._v(" "),
              _vm._m(16)
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/creation-success.svg",
          alt: "welcome"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-font-size-l gds-space-stack-m" }, [
      _vm._v("\n      Already have an account?\n      "),
      _c("a", { attrs: { href: "/account" } }, [_vm._v("Log In")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "form-message-box form-message-box--full-width gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-helper-text-error gds-space-stack-m" }, [
          _vm._v("A user with this email already exists.")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-helper-text gds-space-stack-m" }, [
          _vm._v(
            "\n            Are you trying to add this service account to your existing user profile?\n            "
          ),
          _c(
            "a",
            {
              staticClass: "gds-link",
              attrs: { href: "/user-profile/accounts" }
            },
            [_vm._v("Log In to Continue")]
          ),
          _vm._v(
            ' and go to "Linked Accounts" in the username menu.\n          '
          )
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "form-message-box form-message-box--full-width gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-helper-text-error gds-space-stack-m" }, [
          _vm._v("A user with this email already exists.")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-helper-text gds-space-stack-m" }, [
          _vm._v(
            "\n            Do you need help logging in to your account?\n            "
          ),
          _c(
            "a",
            { staticClass: "gds-link", attrs: { href: "/account/forgot" } },
            [_vm._v("Forgot Password")]
          ),
          _vm._v(".\n          ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/creation-success.svg",
          alt: "welcome"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/not-authorized.svg",
          alt: "welcome"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "form-message-box form-message-box--full-width gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-helper-text-error gds-space-stack-m" }, [
          _vm._v("A user with this email already exists.")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "form-message-box form-message-box--full-width gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-helper-text-error gds-space-stack-m" }, [
          _vm._v("A user with this email already exists.")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "form-message-box form-message-box--blue form-message-box--full-width gds-space-stack-l"
      },
      [
        _c("div", { staticClass: "gds-helper-text gds-space-stack-m" }, [
          _vm._v(
            "\n            Do you need help logging in to your account?\n            "
          ),
          _c(
            "a",
            { staticClass: "gds-link", attrs: { href: "/account/forgot" } },
            [_vm._v("Forgot Password")]
          ),
          _vm._v(".\n          ")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-login-panel__form-helper" }, [
      _c(
        "a",
        {
          staticClass: "gds-display-00 gds-link gds-link--bold",
          attrs: { href: "/account/forgot" }
        },
        [_vm._v("Forgot Password?")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "enrollment-panel__flex--icon enrollment-panel__flex--icon-plug"
      },
      [
        _c("img", {
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/outages-notifications.svg"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "enrollment-panel__flex--icon enrollment-panel__flex--icon-paperless"
      },
      [
        _c("img", {
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/paperless-on-alt.svg"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bill-pay-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/creation-success.svg",
          alt: "set up complete"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "global-panel__form" }, [
      _c("a", { staticClass: "gds-button", attrs: { href: "/account" } }, [
        _vm._v("Continue to My Account")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "enrollment-complete--footer" }, [
      _c("div", { staticClass: "gds-font-size-l gds-align--text-center" }, [
        _vm._v(
          "\n        If you have additional service accounts with GMP you can\n        "
        ),
        _c(
          "a",
          {
            staticClass: "gds-link gds-link--bold",
            attrs: { href: "/user-profile/accounts" }
          },
          [_vm._v("add another account")]
        ),
        _vm._v(".\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }