import DataStore from "../../../store";
import GMPAPI from "../../../services/gmpapi";

import { createLoginRoute } from "../EicLoginRoute";
import EICProductsAccount from "../EICProductsAccount.vue";
import EVRebateFlow from "./EVRebateFlow.vue";
import EVRebateComplete from "./EVRebateComplete.vue";
import EVRebateFatalError from "./EVRebateFatalError.vue";

export const EVRebateRouterOptions = {
  mode: "history",
  base: "/electric-vehicle-rebate",
  // When going to a page, scroll to the slat id
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          selector: to.params.page ? ("#pay-slat-" + to.params.page) : "#top-target",
          offset: { x: 0, y: 12 },
        });
      }, 50);
    });
  },
  routes: [
    { path: "/", redirect: "/account" },
    createLoginRoute(),
    {
      path: "/account",
      component: EICProductsAccount,
      props: { returnToSender: '/electric-vehicle-rebate' },
      beforeEnter: async (to, from, next) => {
        try {
          DataStore.commit("setRouteLoading", true);
          await GMPAPI.GetCurrentUser();
          // We have auth, but may not have user info
          if (!DataStore.state.user.userinfo) {
            try {
              await DataStore.dispatch("AttemptCookieLogin");
              next();
            } catch (err) {
              next("/login");
            }
          } else {
            next();
          }
        } catch (err) {
          next("/login");
        } finally {
          DataStore.commit("setRouteLoading", false);
        }
      }
    },
    {
      path: "/form/:page",
      component: EVRebateFlow,
      beforeEnter: (to, from, next) => {
        let lastValidPage;
        if (DataStore.state.user.lastValidPage) {
          lastValidPage = parseInt(DataStore.state.user.lastValidPage.slice(-1));
        }
        const page = Number(to.params.page);
        if (!DataStore.state.user.currentAccount) {
          next("/account");
        } else if (page > lastValidPage) {
          next(`/form/${lastValidPage}/`)
        } else {
          next();
        }
      },
      beforeResolve: async () => { }
    },
    {
      path: "/complete",
      component: EVRebateComplete,
      beforeEnter: (to, from, next) => {
        if (false) {
          next("/account");
        } else {
          next();
        }
      }
    },
    { path: "/error/:code?", component: EVRebateFatalError },
    { path: "*", redirect: "/" },
  ],
};

export function EVRebateRouterInit(router) {
  // No external init needed
}