<template>
  <div class="subpage-dashboards subpage-billing-payment--dashboard">
    <h1 class="my-account__title subpage-dashboards--left-spacer">Billing &amp; Payment</h1>
    <div class="my-account__tab-nav gds-flex-container gds-flex-container--left">
      <router-link to="/billing" class="my-account__tab-nav-item" >Overview</router-link>
      <router-link v-if="!isGroupNetMetered" to="/report" class="my-account__tab-nav-item" >Billing Report</router-link>
      <router-link to="/preferences" class="my-account__tab-nav-item" >Billing Preferences</router-link>
      <router-link to="/saved-payments" class="my-account__tab-nav-item" >Saved Payments</router-link>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "SubpageBillingPayment",
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    subTab() {
      return this.$route.path;
    },
    isGroupNetMetered() {
      return this.$store.state.user.currentAccount.groupNetMetered;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>