<template>
  <TooltipTemplate>
    <section class="device-daily-status-card">
      <div class="device-daily-status-card__date-container">
        <span class="date">{{ abbreviatedDate }}</span>
        <span class="day-of-week">{{ dayOfWeek }}</span>
      </div>
      <div class="device-daily-status-card__icon-container">
        <img
          v-if="status.dailyOverallKey === STATUS_HISTORY_KEYS.CONNECTION_OK"
          class="devices-icon-size-m"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-status-check-normal-green.svg"
          alt="Green check mark"
        >
        <img
          v-if="status.dailyOverallKey === STATUS_HISTORY_KEYS.DEVICE_OFFLINE"
          class="devices-icon-size-m"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-status-issue.svg"
          alt="White exclamation point in orange triangle"
        >
        <img
          v-if="status.dailyOverallKey === STATUS_HISTORY_KEYS.PARTICIPATED_ENERGY_EVENT"
          class="devices-icon-size-m"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-status-peak-event.svg"
          alt="Purple leaf"
        >
        <img
          v-if="status.dailyOverallKey === STATUS_HISTORY_KEYS.OPTEDOUT_ENERGY_EVENT"
          class="devices-icon-size-m"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-opt-out-sm-dimmed.svg"
          alt="Grey leaf with minus sign"
        >
      </div>
    </section>

    <template #hover-content>
      <div class="gds-font-demi gds-space-stack-m">{{ tooltipDate }}</div>
      <div
        v-for="(dailyStatus, index) in status.dailyStatuses"
        :key="index"
        class="gds-flex-container gds-flex-container--left gds-flex-container--top gds-font-book gds-font-size-m gds-space-stack-s"
      >
        <img
          v-if="dailyStatus.key === STATUS_HISTORY_KEYS.PARTICIPATED_ENERGY_EVENT"
          class="gds-icon gds-font-size-l gds-space-inline-m devices-color-to-white"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-status-peak-event.svg"
          alt="White leaf"
        >
        <img
          v-if="dailyStatus.key === STATUS_HISTORY_KEYS.OPTEDOUT_ENERGY_EVENT"
          class="gds-icon gds-font-size-l gds-space-inline-m devices-color-to-white"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-opt-out-sm-dimmed.svg"
          alt="White leaf with minus sign"
        >
        <img
          v-if="dailyStatus.key === STATUS_HISTORY_KEYS.CONNECTION_OK || dailyStatus.key === STATUS_HISTORY_KEYS.DEVICE_OFFLINE"
          class="gds-icon gds-font-size-l gds-space-inline-m devices-color-to-white"
          src="/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-status-connection.svg"
          alt="White WiFi symbol with full bars"
        >
        <span v-if="dailyStatus.key === STATUS_HISTORY_KEYS.PARTICIPATED_ENERGY_EVENT">Participated in 1 Energy Event</span>
        <span v-if="dailyStatus.key === STATUS_HISTORY_KEYS.OPTEDOUT_ENERGY_EVENT">Opted out of 1 Energy Event</span>
        <span v-if="dailyStatus.key === STATUS_HISTORY_KEYS.CONNECTION_OK">Connection OK</span>
        <span v-if="dailyStatus.key === STATUS_HISTORY_KEYS.DEVICE_OFFLINE">Device Offline</span>
      </div>
    </template>
  </TooltipTemplate>
</template>

<script>

import { format, parseISO } from 'date-fns';
import { STATUS_HISTORY_KEYS } from '../../../constants';
import TooltipTemplate from '../../../../../shared/tooltip/TooltipTemplate.vue';

export default {
  name: 'DeviceDailyStatusCard',
  components: {
    TooltipTemplate,
  },
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
  computed: {
    STATUS_HISTORY_KEYS() {
      return STATUS_HISTORY_KEYS;
    },
    dayOfWeek() {
      const date = parseISO(this.status.date);
      return format(date, 'EEE');
    },
    abbreviatedDate() {
      const date = parseISO(this.status.date);
      return format(date, 'M/d');
    },
    tooltipDate() {
      const date = parseISO(this.status.date);
      return format(date, 'E. MMM. d, yyyy');
    },
  },
};
</script>
