var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c("div", { staticClass: "gds-space-stack-l" }, [
        _vm.label
          ? _c("div", { staticClass: "gds-font-demi gds-space-stack-s" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "gds-font-book" }, [_vm._v(_vm._s(_vm.value))])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }