var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-devices-page__start" }, [
    _c(
      "div",
      { staticClass: "gds-space-stack-m" },
      [
        _vm.isAddDevicePath
          ? _c("BackButton", { attrs: { route: "/devices" } })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "gds-with-sidebar gds-with-sidebar--reverse" }, [
      _c("section", { staticClass: "not-sidebar" }, [
        _c("h1", { staticClass: "my-account__title gds-space-stack-ml" }, [
          _vm._v(_vm._s(_vm.isAddDevicePath ? "Add a Device" : "Devices"))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gds-space-stack-l" }, [
          _vm._v(
            "Connect a Home Battery or EV Charger with GMP. Save energy and money without disrupting your lifestyle. Choose from an option below:"
          )
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "devices-start-link-container gds-space-stack-l" },
          [
            _vm.routerLinkShouldShowByod
              ? _c(
                  "router-link",
                  {
                    staticClass:
                      "gds-button gds-secondary gds-compact devices-thin-button devices-thin-button gds-space-stack-m",
                    attrs: { to: "/devices/add-device/byod/" }
                  },
                  [
                    _c("img", {
                      staticClass: "gds-icon",
                      attrs: {
                        src:
                          "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg",
                        alt: "Blue house icon"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", {}, [_vm._v("Connect a device you own")]),
                    _vm._v(" "),
                    _c("svg", { staticClass: "gds-icon gds-text-grey" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                        }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hyperlinkShouldShowByod
              ? _c(
                  "a",
                  {
                    staticClass:
                      "gds-button gds-secondary gds-compact devices-thin-button gds-space-stack-m",
                    attrs: { href: _vm.txtAddDeviceBYOD, target: "_blank" }
                  },
                  [
                    _c("img", {
                      staticClass: "gds-icon",
                      attrs: {
                        src:
                          "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-house.svg",
                        alt: "Blue house icon"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Connect a device you own")]),
                    _vm._v(" "),
                    _c("svg", { staticClass: "gds-icon gds-text-grey" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                        }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.routerLinkShouldShowAddNew
              ? _c(
                  "router-link",
                  {
                    staticClass:
                      "gds-button gds-secondary gds-compact devices-thin-button gds-space-stack-m",
                    attrs: { to: "/devices/add-device/new" }
                  },
                  [
                    _c("img", {
                      staticClass: "gds-icon",
                      attrs: {
                        src:
                          "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg",
                        alt: "White plus sign in blue circle icon"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Get a new device installed")]),
                    _vm._v(" "),
                    _c("svg", { staticClass: "gds-icon gds-text-grey" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                        }
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hyperlinkShouldShowAddNew
              ? _c(
                  "a",
                  {
                    staticClass:
                      "gds-button gds-secondary gds-compact devices-thin-button gds-space-stack-m",
                    attrs: { href: _vm.txtAddDeviceNew, target: "_blank" }
                  },
                  [
                    _c("img", {
                      staticClass: "gds-icon",
                      attrs: {
                        src:
                          "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-devices-plus-in-circle.svg",
                        alt: "White plus sign in blue circle icon"
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Get a new device installed")]),
                    _vm._v(" "),
                    _c("svg", { staticClass: "gds-icon gds-text-grey" }, [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-chevron-right"
                        }
                      })
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "devices-start-small-message" }, [
          _c("p", [_vm._v("Not sure or have questions?")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "gds-text-grey",
              attrs: { href: _vm.txtAskEnergySpecialist, target: "_blank" }
            },
            [_vm._v("Speak with a Home Energy Specialist")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sidebar" }, [
      _c("img", {
        staticClass: "devices-border-radius devices-width-100",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/devices/devices-three-in-row.jpg",
          alt: "EV Charger and Home Battery System"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }