var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "hybrid-date-select-control" }, [
    _c(
      "div",
      {
        staticClass: "dropdown-container",
        style: {
          width: _vm.config.width || "auto",
          borderRadius: _vm.config.borderRadius || _vm.borderRadiusDefault
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.toggleDropdown.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "dropdown-selected",
            style: {
              borderRadius: _vm.config.borderRadius || _vm.borderRadiusDefault
            }
          },
          [
            _vm._v("\n      " + _vm._s(_vm.selectedLabel) + "\n      "),
            _c("span", {
              class: ["dropdown-caret", { "caret-open": _vm.dropdownIsOpen }]
            })
          ]
        ),
        _vm._v(" "),
        _vm.dropdownIsOpen
          ? _c(
              "div",
              {
                staticClass: "dropdown-menu",
                class: {
                  "custom-date-range-visible": _vm.customDateRangeVisible
                },
                style: {
                  borderRadius:
                    _vm.config.borderRadius || _vm.borderRadiusDefault
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _vm.customDateRangeVisible
                  ? [
                      _c("date-picker", {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.closeDropdown,
                            expression: "closeDropdown"
                          }
                        ],
                        attrs: {
                          "value-type": "format",
                          range: _vm.useDateRange,
                          multiple: _vm.multiSelctDates,
                          open: _vm.showDateRangePicker,
                          confirm: _vm.useConfirmButton,
                          "append-to-body": _vm.appendToBody
                        },
                        on: { change: _vm.updateValues },
                        model: {
                          value: _vm.dateRange,
                          callback: function($$v) {
                            _vm.dateRange = $$v
                          },
                          expression: "dateRange"
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.customDateRangeVisible
                  ? _vm._l(_vm.config.options, function(option, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          class: [
                            "dropdown-option",
                            {
                              "selected-option":
                                option.value === _vm.selectedValue
                            }
                          ],
                          on: {
                            click: function($event) {
                              return _vm.selectOption(option.value)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(option.label) + "\n        "
                          )
                        ]
                      )
                    })
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }