var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showWidgetHeader
      ? _c("div", { staticClass: "header" }, [
          _c("h2", [_vm._v("When you’re using energy")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "usage-dashboard__circle-arrow-right gds-button gds-button-circle gds-button-outline",
              attrs: { href: "/account/usage/detail/time-of-day" }
            },
            [
              _c("svg", { staticClass: "gds-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"
                  }
                })
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "complete"
      ? _c("div", { class: _vm.chartBodyClass }, [
          _c("div", [
            _c("img", {
              staticClass: "usage-dashboard__widget-time-of-day--icon",
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/" +
                  _vm.fileName,
                alt: _vm.timeRange
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.widgetTextClass }, [
            _vm._v("Most of your energy use was from\n      "),
            _c("span", { staticClass: "gds-text--bold" }, [
              _vm._v(_vm._s(_vm._f("nonBreakingSpaces")(_vm.timeRange)))
            ]),
            _vm._v(".\n    ")
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loadState
      ? _c("div", { staticClass: "my-account__usage-loading" })
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "unavailable"
      ? _c("div", { staticClass: "error-message" }, [
          _vm._v("Usage information is not available for this account.")
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loadState === "error"
      ? _c("div", { staticClass: "error-message" }, [
          _vm._v("Unable to load, please try again later.")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }