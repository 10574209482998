var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eicproduct-container" },
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "eicproduct__page-loader" }, [
            _c("img", {
              staticClass: "eicproduct__page-loader--svg",
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Rebates",
              state: "error",
              phonetext: "For further help, please call us at",
              closepath: "/ebike-rebate"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c("div", [
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "ebike-slat-1" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("1")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    !_vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account: " +
                                                _vm._s(
                                                  _vm.currentAccount.nickname
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account Number: " +
                                                _vm._s(
                                                  _vm.currentAccount
                                                    .accountNumber
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(1)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 1
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 1 && _vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              eBike rebate."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    key: _vm.currentAccount.accountNumber,
                                    staticClass:
                                      "eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct__account-box gds-space-stack-l"
                                      },
                                      [
                                        _vm.currentAccount
                                          ? _c("span", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(1),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("titlecase")(
                                                              "" +
                                                                (_vm
                                                                  .currentAccount
                                                                  .lastName
                                                                  ? _vm
                                                                      .currentAccount
                                                                      .lastName
                                                                  : "") +
                                                                (_vm
                                                                  .currentAccount
                                                                  .firstName &&
                                                                _vm
                                                                  .currentAccount
                                                                  .lastName
                                                                  ? ","
                                                                  : "") +
                                                                " " +
                                                                (_vm
                                                                  .currentAccount
                                                                  .firstName
                                                                  ? _vm
                                                                      .currentAccount
                                                                      .firstName
                                                                  : "")
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(2),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "addressFormat"
                                                            )(
                                                              _vm.currentAccount
                                                                .address,
                                                              {
                                                                separator: ", "
                                                              }
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(3),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentAccount
                                                              .accountNumber
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentPage === 1 && !_vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              eBike rebate."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct__account-box gds-space-stack-l"
                                  },
                                  [
                                    _vm.currentAccount
                                      ? _c("span", [
                                          _c("div", { staticClass: "row-m" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "account-info-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(4),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("titlecase")(
                                                            "" +
                                                              (_vm
                                                                .currentAccount
                                                                .lastName
                                                                ? _vm
                                                                    .currentAccount
                                                                    .lastName
                                                                : "") +
                                                              (_vm
                                                                .currentAccount
                                                                .firstName &&
                                                              _vm.currentAccount
                                                                .lastName
                                                                ? ","
                                                                : "") +
                                                              " " +
                                                              (_vm
                                                                .currentAccount
                                                                .firstName
                                                                ? _vm
                                                                    .currentAccount
                                                                    .firstName
                                                                : "")
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(5),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "addressFormat"
                                                          )(
                                                            _vm.currentAccount
                                                              .address,
                                                            { separator: ", " }
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(6),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentAccount
                                                            .accountNumber
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.accounts.length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline accountInformation eicproduct__edit-account-details",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.chooseAccount()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "gds-button gds-text-button choose-account-button"
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "eicproduct__edit-icon-inline gds-icon"
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.accounts.length > 1
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Switch Account"
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "ebike-slat-2" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("2")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(7)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-m"
                                      },
                                      [
                                        _vm._v(
                                          "Total Savings: $" +
                                            _vm._s(_vm.totalSavings)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(2)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 2
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 2
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "rebate-slap__inner-row" },
                                  [
                                    _c("div", { staticClass: "row-s" }, [
                                      _c(
                                        "form",
                                        { attrs: { "data-vv-scope": "page2" } },
                                        [
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass:
                                                "gds-fieldset gds-space-stack-s"
                                            },
                                            [
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "How many bike or retrofit kit rebates are you applying\n                        for?"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.numberOfBikes,
                                                        expression:
                                                          "numberOfBikes"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression: "'required'"
                                                      }
                                                    ],
                                                    key: "number of bikes",
                                                    staticClass:
                                                      "eicproduct-input-field-l__select gds-input-field",
                                                    attrs: {
                                                      name: "number of bikes",
                                                      autofocus: ""
                                                    },
                                                    on: {
                                                      change: [
                                                        function($event) {
                                                          var $$selectedVal = Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function(o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function(o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                          _vm.numberOfBikes = $event
                                                            .target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        },
                                                        function($event) {
                                                          return _vm.selectNumOfBikes(
                                                            _vm.numberOfBikes
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { disabled: "" },
                                                        domProps: {
                                                          value: undefined
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Select number of bikes or retrofit kits"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 1 }
                                                      },
                                                      [_vm._v("1")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 2 }
                                                      },
                                                      [_vm._v("2")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 3 }
                                                      },
                                                      [_vm._v("3")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 4 }
                                                      },
                                                      [_vm._v("4")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 5 }
                                                      },
                                                      [_vm._v("5")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 6 }
                                                      },
                                                      [_vm._v("6")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 7 }
                                                      },
                                                      [_vm._v("7")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 8 }
                                                      },
                                                      [_vm._v("8")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        domProps: { value: 9 }
                                                      },
                                                      [_vm._v("9")]
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.productSubmission
                                                  .electricBikes,
                                                function(bike, i) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: i,
                                                      staticClass:
                                                        "gds-space-stack-l"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "flex-item margin-right"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "space-between"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "h2",
                                                                {
                                                                  staticStyle: {
                                                                    "align-self":
                                                                      "center"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Bike / Retrofit #" +
                                                                      _vm._s(
                                                                        i + 1
                                                                      )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.numberOfBikes >
                                                              1
                                                                ? _c(
                                                                    "button",
                                                                    {
                                                                      staticClass:
                                                                        "gds-button gds-button-circle",
                                                                      staticStyle: {
                                                                        margin:
                                                                          "3rem"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.removeBike(
                                                                            i
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg",
                                                                        {
                                                                          staticClass:
                                                                            "gds-icon"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "use",
                                                                            {
                                                                              attrs: {
                                                                                "xlink:href":
                                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "legend",
                                                            {
                                                              staticClass:
                                                                "gds-display-0 gds-space-stack-ml"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Is this a new bike or Retrofit kit? (Used\n                          bikes are ineligible)\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-radio"
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .electricBikes[
                                                                        i
                                                                      ]
                                                                        .newOrRetrofit,
                                                                    expression:
                                                                      "productSubmission.electricBikes[i].newOrRetrofit"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "New eBike or Retrofit kit",
                                                                attrs: {
                                                                  value: "new",
                                                                  type: "radio",
                                                                  name:
                                                                    "New eBike or Retrofit kit #" +
                                                                    (i + 1)
                                                                },
                                                                domProps: {
                                                                  checked: _vm._q(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricBikes[
                                                                      i
                                                                    ]
                                                                      .newOrRetrofit,
                                                                    "new"
                                                                  )
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .electricBikes[
                                                                        i
                                                                      ],
                                                                      "newOrRetrofit",
                                                                      "new"
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "gds-radio__faux"
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-radio__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "New eBike"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-radio"
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .electricBikes[
                                                                        i
                                                                      ]
                                                                        .newOrRetrofit,
                                                                    expression:
                                                                      "productSubmission.electricBikes[i].newOrRetrofit"
                                                                  }
                                                                ],
                                                                key:
                                                                  "New eBike or Retrofit kit",
                                                                attrs: {
                                                                  value:
                                                                    "retrofit",
                                                                  type: "radio",
                                                                  name:
                                                                    "New eBike or Retrofit kit #" +
                                                                    (i + 1)
                                                                },
                                                                domProps: {
                                                                  checked: _vm._q(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricBikes[
                                                                      i
                                                                    ]
                                                                      .newOrRetrofit,
                                                                    "retrofit"
                                                                  )
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .electricBikes[
                                                                        i
                                                                      ],
                                                                      "newOrRetrofit",
                                                                      "retrofit"
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "gds-radio__faux"
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-radio__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Retrofit Kit"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "New eBike or Retrofit kit #" +
                                                              (i + 1),
                                                            "page2"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "New eBike or Retrofit kit #" +
                                                                            (i +
                                                                              1),
                                                                          "page2"
                                                                        )
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c("label", [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Dealer name"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricBikes[
                                                                      i
                                                                    ]
                                                                      .dealerName,
                                                                  expression:
                                                                    "productSubmission.electricBikes[i].dealerName"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required",
                                                                  expression:
                                                                    "'required'"
                                                                }
                                                              ],
                                                              key:
                                                                "dealer name",
                                                              staticClass:
                                                                "gds-input-field__input gds-space-stack-m",
                                                              attrs: {
                                                                name:
                                                                  "dealer name for bike " +
                                                                  (i + 1),
                                                                type: "text",
                                                                autocomplete:
                                                                  "off",
                                                                placeholder:
                                                                  "Type Here..."
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .electricBikes[
                                                                    i
                                                                  ].dealerName
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricBikes[
                                                                      i
                                                                    ],
                                                                    "dealerName",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "dealer name for bike " +
                                                                (i + 1),
                                                              "page2"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "eicproduct-validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "dealer name for bike " +
                                                                            (i +
                                                                              1),
                                                                          "page2"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Purchase Date"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-purchasedate__date-box gds-space-stack-m"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "my-account-billing-report__filtration-date-icon"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg",
                                                                        {
                                                                          staticClass:
                                                                            "gds-icon"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "use",
                                                                            {
                                                                              attrs: {
                                                                                "xlink:href":
                                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "datepicker",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "validate",
                                                                          rawName:
                                                                            "v-validate",
                                                                          value:
                                                                            "required",
                                                                          expression:
                                                                            "'required'"
                                                                        }
                                                                      ],
                                                                      key:
                                                                        "date of purchase",
                                                                      ref:
                                                                        "datePicker",
                                                                      refInFor: true,
                                                                      attrs: {
                                                                        name:
                                                                          "date of purchase of bike " +
                                                                          (i +
                                                                            1),
                                                                        format:
                                                                          "MMMM d, yyyy",
                                                                        typeable: true,
                                                                        placeholder:
                                                                          "Select Date...",
                                                                        "disabled-dates":
                                                                          _vm.disableDates,
                                                                        "input-class":
                                                                          "export-data__date my-account-billing-report__filtration-date-text"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .productSubmission
                                                                            .electricBikes[
                                                                            i
                                                                          ]
                                                                            .dateOfPurchase,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .electricBikes[
                                                                              i
                                                                            ],
                                                                            "dateOfPurchase",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "productSubmission.electricBikes[i].dateOfPurchase"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "date of purchase of bike " +
                                                                  (i + 1),
                                                                "page2"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.errors.first(
                                                                            "date of purchase of bike " +
                                                                              (i +
                                                                                1),
                                                                            "page2"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Pre-tax purchase price of\n                            " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricBikes[
                                                                          i
                                                                        ]
                                                                          .newOrRetrofit ===
                                                                          "new"
                                                                          ? "eBike"
                                                                          : "Retrofit Kit"
                                                                      ) +
                                                                      "?"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "gds-position--relative gds-space-stack-m"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "gds-input-field__prefix gds-text"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "$"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("money", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "validate",
                                                                        rawName:
                                                                          "v-validate",
                                                                        value:
                                                                          "required|min_value:500|max_value:9999999",
                                                                        expression:
                                                                          "'required|min_value:500|max_value:9999999'"
                                                                      }
                                                                    ],
                                                                    key:
                                                                      "eBike purchase price of bike " +
                                                                      (i + 1),
                                                                    staticClass:
                                                                      "bill-pay-input-field__input gds-input-field__input",
                                                                    attrs: {
                                                                      name:
                                                                        "eBike purchase price of bike " +
                                                                        (i + 1)
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricBikes[
                                                                          i
                                                                        ]
                                                                          .purchasePrice,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .productSubmission
                                                                            .electricBikes[
                                                                            i
                                                                          ],
                                                                          "purchasePrice",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "productSubmission.electricBikes[i].purchasePrice"
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "eBike purchase price of bike " +
                                                                  (i + 1),
                                                                "page2"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          "The minimum purchase price must be $500"
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _vm.productSubmission.electricBikes.length >
                                    0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-button gds-compact",
                                            on: {
                                              click: function($event) {
                                                return _vm.finishPage(
                                                  "page2",
                                                  3
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Next")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "ebike-slat-3" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("3")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-slat__title-wrapper" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__title gds-slat__title"
                                  },
                                  [_vm._v("Upload Documents")]
                                ),
                                _vm._v(" "),
                                _vm.showMissingDocumentsModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "missingDocument",
                                        close: _vm.closeModal,
                                        modalTitle:
                                          "You’re missing a required document",
                                        modalMessage:
                                          "Please upload a copy of the following:",
                                        listItems: _vm.missingDocuments
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showFileLoadTooBigModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "fileLoadTooBig",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Your total file size is " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.totalFileSize
                                          ) +
                                          ". Please make sure the combined size of all uploaded files is less than " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.maxFileLoadSize
                                          ) +
                                          "."
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _vm.documents.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.documents.length +
                                                  "\n              Files Uploaded"
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.documents.length === 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  this.documents.length +
                                                    " File Uploaded"
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(3)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 3
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 3
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "slat-title-wrapper" },
                                      [
                                        _c("form", [
                                          _c(
                                            "div",
                                            { attrs: { id: "fileUploader" } },
                                            [
                                              _c("File-Uploader", {
                                                attrs: {
                                                  uploadDocumentList:
                                                    _vm.uploadDocumentList,
                                                  docTypes: _vm.documentTypes
                                                },
                                                on: {
                                                  "file-list-changed":
                                                    _vm.updateFileList
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page3", 4)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "ebike-slat-4" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("4")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(8)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-s"
                                      },
                                      [
                                        _vm._v(
                                          "Mailing Address: " +
                                            _vm._s(
                                              _vm._f("addressFormat")(
                                                _vm.productSubmission.rebate
                                                  .rebateMailingAddress,
                                                { seperator: ", " }
                                              )
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(4)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 4
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 4
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "form",
                                      { attrs: { "data-vv-scope": "page4" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "gds-space-stack-l" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "gds-input-field"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Please make my check out to:"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebatePayeeName,
                                                      expression:
                                                        "productSubmission.rebate.rebatePayeeName"
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  key: "rebate payee name",
                                                  ref: "focuser4",
                                                  staticClass:
                                                    "eicproduct-input-field gds-input-field__input",
                                                  attrs: {
                                                    name: "rebate payee name",
                                                    type: "text"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate.rebatePayeeName
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .rebate,
                                                          "rebatePayeeName",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.$emit(
                                                          "touched",
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "rebate payee name",
                                                  "page4"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "rebate payee name",
                                                              "page4"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(9),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gds-input-field gds-space-stack-l"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Country")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress
                                                        .country,
                                                    expression:
                                                      "productSubmission.rebate.rebateMailingAddress.country"
                                                  }
                                                ],
                                                class:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .country === "USA"
                                                    ? "eicproduct-input-field__select-country-us gds-input-field gds-space-stack-m"
                                                    : _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress
                                                        .country === "CAN"
                                                    ? "eicproduct-input-field__select-country-ca gds-input-field"
                                                    : "eicproduct-input-field__select-s gds-input-field",
                                                attrs: { autofocus: "" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "country",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      selected: "",
                                                      "data-thumbnail":
                                                        "/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg"
                                                    },
                                                    domProps: { value: "USA" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "United States\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "CAN" } },
                                                  [_vm._v("Canada")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "FOR" } },
                                                  [_vm._v("Foreign")]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.productSubmission.rebate
                                              .rebateMailingAddress.country
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "country",
                                                            "page4"
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Street Address")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productSubmission.rebate
                                                      .rebateMailingAddress
                                                      .street1,
                                                  expression:
                                                    "productSubmission.rebate.rebateMailingAddress.street1"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              key: "street address",
                                              staticClass:
                                                "eicproduct-input-field gds-input-field__input gds-space-stack-m",
                                              attrs: {
                                                name: "street address",
                                                type: "text",
                                                placeholder: "Type Here..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .street1
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "street1",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.$emit(
                                                      "touched",
                                                      true
                                                    )
                                                  }
                                                ]
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.errors.first(
                                              "street address",
                                              "page4"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "streetaddress",
                                                          "page4"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Suite Apt")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productSubmission.rebate
                                                      .rebateMailingAddress
                                                      .street2,
                                                  expression:
                                                    "productSubmission.rebate.rebateMailingAddress.street2"
                                                }
                                              ],
                                              key: "street 2",
                                              staticClass:
                                                "eicproduct-input-field gds-input-field__input gds-space-stack-m",
                                              attrs: {
                                                name: "street 2",
                                                type: "text",
                                                placeholder: "Type Here..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .street2
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "street2",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.$emit(
                                                      "touched",
                                                      true
                                                    )
                                                  }
                                                ]
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row-l" }, [
                                          _c(
                                            "div",
                                            { staticClass: "flex-item-l" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "gds-input-field-flex"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("City")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .city,
                                                        expression:
                                                          "productSubmission.rebate.rebateMailingAddress.city"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression: "'required'"
                                                      }
                                                    ],
                                                    key: "city",
                                                    staticClass:
                                                      "gds-input-field__input",
                                                    attrs: {
                                                      name: "city",
                                                      type: "text",
                                                      placeholder:
                                                        "Type Here..."
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .city
                                                    },
                                                    on: {
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress,
                                                            "city",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.$emit(
                                                            "touched",
                                                            true
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "city",
                                                    "page4"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "eicproduct-validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "city",
                                                                "page4"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-item-s gds-space-stack-m"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .country === "USA",
                                                      expression:
                                                        "productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                                                    }
                                                  ],
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("State")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress
                                                              .state,
                                                          expression:
                                                            "productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-input-field__select gds-input-field",
                                                      on: {
                                                        change: [
                                                          function($event) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .rebate
                                                                .rebateMailingAddress,
                                                              "state",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function($event) {
                                                            return _vm.$emit(
                                                              "touched",
                                                              true
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "",
                                                            disabled: "",
                                                            selected: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Select State..."
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.stateList,
                                                        function(state) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: state,
                                                              domProps: {
                                                                value: state
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(state)
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm
                                                            .productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .state,
                                                          expression:
                                                            "!productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "state",
                                                            "page4"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .country === "CAN",
                                                      expression:
                                                        "productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                                                    }
                                                  ],
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("Province")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress
                                                              .state,
                                                          expression:
                                                            "productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-input-field__select gds-input-field",
                                                      on: {
                                                        change: [
                                                          function($event) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .rebate
                                                                .rebateMailingAddress,
                                                              "state",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function($event) {
                                                            return _vm.$emit(
                                                              "touched",
                                                              true
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            value: "",
                                                            disabled: "",
                                                            selected: ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Select Province..."
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.provinceList,
                                                        function(province) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: province,
                                                              domProps: {
                                                                value: province
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  province
                                                                ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm
                                                            .productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .state,
                                                          expression:
                                                            "!productSubmission.rebate.rebateMailingAddress.state"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "province",
                                                            "page4"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div"),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-item-m" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("Zipcode")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .zip,
                                                        expression:
                                                          "productSubmission.rebate.rebateMailingAddress.zip"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value:
                                                          "required|numeric|length:5",
                                                        expression:
                                                          "'required|numeric|length:5'"
                                                      }
                                                    ],
                                                    key: "zipcode",
                                                    staticClass:
                                                      "gds-input-field__input eicproduct-mailing-zip",
                                                    attrs: {
                                                      name: "zipcode",
                                                      type: "text",
                                                      placeholder:
                                                        "Type Here...",
                                                      autocomplete:
                                                        "postal-code"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .zip
                                                    },
                                                    on: {
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress,
                                                            "zip",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.$emit(
                                                            "touched",
                                                            true
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "zipcode",
                                                    "page4"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "eicproduct-validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "zipcode",
                                                                "page4"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        !_vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .zip &&
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .country === "USA",
                                                      expression:
                                                        "!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'USA'"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "eicproduct-validation-error"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "zip",
                                                        "page4"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        !_vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .zip &&
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .country === "CAN",
                                                      expression:
                                                        "!productSubmission.rebate.rebateMailingAddress.zip && productSubmission.rebate.rebateMailingAddress.country === 'CAN'"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "eicproduct-validation-error"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "canadianZip",
                                                        "page4"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "eicproduct-title gds-space-stack-l"
                                            },
                                            [
                                              _vm._v(
                                                "How should we contact you if we need additional information to process\n                    your rebate:"
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "gds-space-stack-m"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [_vm._v("Email Address")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .confirmationEmailAddress,
                                                        expression:
                                                          "productSubmission.rebate.confirmationEmailAddress"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required|email",
                                                        expression:
                                                          "'required|email'"
                                                      }
                                                    ],
                                                    key: "email address",
                                                    ref: "email",
                                                    staticClass:
                                                      "eicproduct-input-field gds-input-field__input",
                                                    attrs: {
                                                      name: "email address",
                                                      type: "email",
                                                      placeholder: "Your email",
                                                      autocomplete: "email"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .confirmationEmailAddress
                                                    },
                                                    on: {
                                                      input: [
                                                        function($event) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            _vm
                                                              .productSubmission
                                                              .rebate,
                                                            "confirmationEmailAddress",
                                                            $event.target.value
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.$emit(
                                                            "touched",
                                                            true
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "email address",
                                                    "page4"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "eicproduct-validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "email address",
                                                                "page4"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            { staticClass: "gds-input-field" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("Phone Number")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-xl"
                                                },
                                                [
                                                  _c("the-mask", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value:
                                                          "required|min:10",
                                                        expression:
                                                          "'required|min:10'"
                                                      }
                                                    ],
                                                    key: "phone number",
                                                    staticClass:
                                                      "eicproduct-input-field gds-input-field__input",
                                                    attrs: {
                                                      mask: "+1 (###) ###-####",
                                                      "data-vv-validate-on":
                                                        "input",
                                                      name: "phone number",
                                                      type: "tel",
                                                      placeholder:
                                                        "Your phone number"
                                                    },
                                                    nativeOn: {
                                                      input: function($event) {
                                                        _vm.touched = true
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .contactPhoneNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .rebate,
                                                          "contactPhoneNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "productSubmission.rebate.contactPhoneNumber"
                                                    }
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "phone number",
                                                    "page4"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "eicproduct-validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "phone number",
                                                                "page4"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          !_vm.isAlreadyEbillEnrolled
                                            ? _c("div", [
                                                _vm._m(10),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-body-small gds-space-stack-l slat3-body"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      You can reduce your carbon footprint even more,\n                      by switching to paperless billing! Paperless monthly energy\n                      statements cut carbon and clutter while saving trees.\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-ml"
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-checkbox gds-checkbox-thin"
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .programEnrollment
                                                                  .ebill
                                                                  .ebillEnroll,
                                                              expression:
                                                                "productSubmission.programEnrollment.ebill.ebillEnroll"
                                                            }
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                            name: ""
                                                          },
                                                          domProps: {
                                                            checked: Array.isArray(
                                                              _vm
                                                                .productSubmission
                                                                .programEnrollment
                                                                .ebill
                                                                .ebillEnroll
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll,
                                                                  null
                                                                ) > -1
                                                              : _vm
                                                                  .productSubmission
                                                                  .programEnrollment
                                                                  .ebill
                                                                  .ebillEnroll
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEnroll",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEnroll",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill,
                                                                  "ebillEnroll",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          staticClass:
                                                            "gds-checkbox__faux"
                                                        }),
                                                        _vm._v(" "),
                                                        _vm._m(11)
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.productSubmission
                                                  .programEnrollment.ebill
                                                  .ebillEnroll
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "gds-space-stack-ml"
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-input-field"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Account email address"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .ebill
                                                                      .ebillEmailAddress,
                                                                  expression:
                                                                    "productSubmission.programEnrollment.ebill.ebillEmailAddress"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required|email",
                                                                  expression:
                                                                    "'required|email'"
                                                                }
                                                              ],
                                                              key:
                                                                "account email address",
                                                              ref: "email",
                                                              staticClass:
                                                                "eicproduct-input-field gds-input-field__input",
                                                              attrs: {
                                                                name:
                                                                  "account email address",
                                                                type: "email",
                                                                placeholder:
                                                                  "Your email",
                                                                autocomplete:
                                                                  "email"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEmailAddress
                                                              },
                                                              on: {
                                                                input: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill,
                                                                      "ebillEmailAddress",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "touched",
                                                                      true
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            }),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "account email address",
                                                              "page4"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "eicproduct-validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "account email address",
                                                                          "page4"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ])
                                            : _vm._e()
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page4", 5)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "ebike-slat-5" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("5")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(12)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage < 5
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 5
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        ref: "focuser6",
                                        staticClass:
                                          "gds-display-00 gds-space-stack-s"
                                      },
                                      [_vm._v("Your rebate will be mailed to:")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("titlecase")(
                                              _vm.productSubmission.rebate
                                                .rebatePayeeName
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-l"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("addressFormat")(
                                              _vm.productSubmission.rebate
                                                .rebateMailingAddress,
                                              { separator: ", " }
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "gds-space-stack-l" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-display-00 gds-space-stack-s"
                                          },
                                          [_vm._v("Your Rebate Information:")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-small gds-space-stack-s"
                                          },
                                          [
                                            _vm._v(
                                              "Total incentive of $" +
                                                _vm._s(this.totalSavings)
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.productSubmission.electricBikes,
                                          function(bike, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass:
                                                  "gds-body-small gds-space-stack-s"
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "Type: " +
                                                      _vm._s(
                                                        _vm.toTitleCase(
                                                          _vm.productSubmission
                                                            .electricBikes[i]
                                                            .newOrRetrofit
                                                        )
                                                      )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _vm._v(
                                                    "Bike #" +
                                                      _vm._s(i + 1) +
                                                      " in the amount of $" +
                                                      _vm._s(
                                                        _vm.eBikeRebateAmount
                                                      )
                                                  )
                                                ])
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("Household-Income", {
                                          ref: "householdIncome",
                                          attrs: {
                                            productSubmission:
                                              _vm.productSubmission,
                                            metadata: _vm.metadata,
                                            lastYear: _vm.lastYear
                                          },
                                          on: {
                                            "household-income-changed":
                                              _vm.updateHouseholdIncome
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "gds-space-stack-m" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gds-checkbox gds-checkbox-thin"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.regulatoryDataSharing,
                                                  expression:
                                                    "regulatoryDataSharing"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              key: "regulatory data sharing",
                                              attrs: {
                                                type: "checkbox",
                                                name: "regulatory data sharing"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.regulatoryDataSharing
                                                )
                                                  ? _vm._i(
                                                      _vm.regulatoryDataSharing,
                                                      null
                                                    ) > -1
                                                  : _vm.regulatoryDataSharing
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.regulatoryDataSharing,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.regulatoryDataSharing = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.regulatoryDataSharing = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.regulatoryDataSharing = $$c
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "gds-checkbox__faux"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-font-size-ml regulatory-text1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.REGULATORY_REBATE_SHARING_TEXT1
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "gds-font-size-ml regulatory-text2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.REGULATORY_REBATE_SHARING_TEXT2
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.errors.first(
                                              "regulatory data sharing",
                                              "page5"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "regulatory data sharing",
                                                          "page5"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "gds-space-stack-l" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gds-checkbox gds-checkbox-thin"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.fossilFuelReplacementAgreement,
                                                  expression:
                                                    "fossilFuelReplacementAgreement"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              key:
                                                "fossil fuel replacement agreement",
                                              attrs: {
                                                type: "checkbox",
                                                name:
                                                  "fossil fuel replacement agreement"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.fossilFuelReplacementAgreement
                                                )
                                                  ? _vm._i(
                                                      _vm.fossilFuelReplacementAgreement,
                                                      null
                                                    ) > -1
                                                  : _vm.fossilFuelReplacementAgreement
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.fossilFuelReplacementAgreement,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.fossilFuelReplacementAgreement = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.fossilFuelReplacementAgreement = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.fossilFuelReplacementAgreement = $$c
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "gds-checkbox__faux"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-font-size-ml"
                                              },
                                              [
                                                _c("b", [_vm._v("Yes!")]),
                                                _vm._v(
                                                  " Electric " +
                                                    _vm._s(
                                                      _vm.numberOfBikes > 1
                                                        ? "bikes"
                                                        : "bike"
                                                    ) +
                                                    " will be used as a replacement\n                    for\n                    fossil fuel vehicle commuting.\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.errors.first(
                                              "fossil fuel replacement agreement",
                                              "page5"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "fossil fuel replacement agreement",
                                                          "page5"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-button gds-compact",
                                            attrs: {
                                              type: "submit",
                                              disabled:
                                                _vm.pendingSubmit ||
                                                !_vm.fossilFuelReplacementAgreement ||
                                                !_vm.regulatoryDataSharing
                                            },
                                            on: { click: _vm.handleSubmit }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Submit Rebate\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-button [modifier class] gds-loading"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-button__text"
                                              },
                                              [_vm._v("Submit Rebate")]
                                            ),
                                            _vm._v(" "),
                                            _c("figure", {
                                              staticClass: "gds-button__icon"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Customer Information")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your eBike Details")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Contact & Mailing Address")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slat-title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-title" }, [
        _vm._v("Where should we send your rebate check?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-body-small gds-space-stack-l" }, [
      _c("span", { staticClass: "gds-display-1 gds-space-stack-s" }, [
        _vm._v("Would you like to go paperless?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-font-size-ml" }, [
      _c("b", [_vm._v("Yes!")]),
      _vm._v(
        " - Enroll me in paperless billing so I can reduce my carbon footprint even more.\n                        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Review & Submit")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }