<template>
  <div v-if="FF_Devices" class="devices-service-request-form">
    <BackButton v-if="(formVM.isPageOneVisible && !formVM.isLastPage && formVM.loadState) || formVM.isErrorLoadState" :on-link-clicked="routerBack" />
    <BackButton v-if="!formVM.isPageOneVisible && !formVM.isLastPage && formVM.loadState && !formVM.isErrorLoadState" :on-link-clicked="onBackButtonClicked" />
    <div v-if="!formVM.loadState" class="table-loading" />
    <flow-error
      v-if="formVM.isErrorLoadState"
      state="error"
      name="Devices Service Request"
      phonetext="For further assistance, call us at"
    />
    <div>
      <div v-if="formVM.loadState && !formVM.isErrorLoadState" class="form-container gds-space-stack-l devices-max-width-600">
        <form @submit.prevent="submitForm">
          <FormHeader :config="formVM.headerConfig" />
          <!-- START page 1 -->
          <div v-show="formVM.isPageOneVisible">
            <RadioGroupFormattedHtml
              v-model="formVM.formData.selectedDeviceId"
              :config="formVM.radioButtonConfigDevices"
            />
            <TextAreaInputWithHtml
              v-model="formVM.formData.problemSummary"
              :config="formVM.textAreaConfigProblemSummary"
            />
            <div class="gds-space-stack-s gds-font-demi gds-font-size-xl">{{ formVM.contactInfoFormSectionConfig.header }}</div>
            <div class="gds-font-book gds-space-stack-s">{{ formVM.contactInfoFormSectionConfig.subHeader }}</div>
            <div class="gds-space-stack-m">
              <TextInputWithHtml v-model="formVM.formData.contactPhone" :config="formVM.textInputConfigContactPhone" />
            </div>
            <div class="gds-space-stack-m">
              <TextInputWithHtml v-model="formVM.formData.contactEmail" :config="formVM.textInputConfigContactEmail" />
            </div>
          </div>
          <!-- END page 1 -->
          <!-- START page 2 -->
          <div v-show="formVM.isPageTwoVisible">
            <ReviewAndSubmit v-if="formVM.reviewAndSubmitConfig.selectedDevice" :config="formVM.reviewAndSubmitConfig" />
          </div>
          <!-- END page 2 -->
          <!-- START page 3 -->
          <div v-show="formVM.isPageThreeVisible">
            <FormSubmitSuccessMessage :config="formVM.submitSuccessConfig" />
            <ActionButton
              text="Back to Devices Overview"
              :is-custom-secondary="makeChangesIsSecondary"
              :button-clicked="goToOverview"
            />
          </div>
          <!-- END page 3 -->
        </form>
      </div>
    </div>
    <div v-if="formVM.loadState && !formVM.isLastPage && !formVM.isErrorLoadState" class="buttons-container devices-max-width-600">
      <ActionButton
        v-if="formVM.isNextButtonVisible"
        text="Next"
        :disabled="formVM.isNextButtonDisabled"
        :button-clicked="onNextButtonClicked"
      />
      <ActionButton
        v-if="formVM.isSubmitButtonVisible"
        text="Submit"
        :loading="formVM.pendingSave"
        :button-clicked="onSubmitButtonClicked"
      />
      <ActionButton
        v-if="formVM.isSubmitButtonVisible"
        text="Make Changes"
        :is-custom-secondary="makeChangesIsSecondary"
        :disabled="formVM.pendingSave"
        :button-clicked="onBackButtonClicked"
      />
    </div>
  </div>
</template>

<script>
import { isFeatureEnabled } from '../../../../services/featureflags';
import DevicesServiceRequestViewModel from './DevicesServiceRequestViewModel';

import FormSubmitSuccessMessage from './FormSubmitSuccessMessage.vue';
import BackButton from '../shared/components/formcomponents/BackButton.vue';
import FormHeader from '../shared/components/formcomponents/FormHeader.vue';
import ActionButton from '../shared/components/formcomponents/ActionButton.vue';
import ReviewAndSubmit from '../shared/components/formcomponents/ReviewAndSubmit.vue';
import TextInputWithHtml from '../shared/components/formcomponents/TextInputWithHtml.vue';
import TextAreaInputWithHtml from '../shared/components/formcomponents/TextAreaInputWithHtml.vue';
import RadioGroupFormattedHtml from '../shared/components/formcomponents/RadioGroupFormattedHtml.vue';
import UtilitiesMixin from '../../../mixins/UtilitiesMixin';

const DEVICES_PATH = '/devices';

export default {
  name: 'ServiceRequestForm',
  components: {
    BackButton,
    FormHeader,
    ActionButton,
    ReviewAndSubmit,
    TextInputWithHtml,
    TextAreaInputWithHtml,
    RadioGroupFormattedHtml,
    FormSubmitSuccessMessage,
  },
  mixins: [
    UtilitiesMixin,
  ],
  data() {
    return {
      FF_Devices: undefined,
      makeChangesIsSecondary: true,
      formVM: new DevicesServiceRequestViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    currentAccount() {
      if (this.formVM.isLastPage) {
        this.goToOverview();
      } else {
        this.formVM.resetForm();
        this.formVM.getMyDevices(this.currentAccount.accountNumber);
      }
    },
    'formVM.formData': {
      handler(newValue) {
        this.formVM.formDataWatchHandler(newValue);
        this.formVM.updateReviewAndSubmitConfig(this.currentAccount);
      },
      deep: true,
    },
    'formVM.pageNumber': {
      handler(newValue, oldValue) {
        this.formVM.pageNumberWatchHandler(newValue, oldValue, this.currentAccount);
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.checkFeatureFlag();
    if (this.currentAccount && this.currentAccount.accountNumber) {
      await this.formVM.getMyDevices(this.currentAccount.accountNumber);
      this.formVM.updateReviewAndSubmitConfig(this.currentAccount);
    }
  },
  methods: {
    routerBack() {
      this.$router.back();
    },
    goToOverview() {
      this.$router.push(DEVICES_PATH);
    },
    onBackButtonClicked() {
      this.formVM.onBackButtonClicked();
      this.scrollToTop();
    },
    onNextButtonClicked() {
      this.formVM.onNextButtonClicked();
      this.scrollToTop();
    },
    onSubmitButtonClicked() {
      this.formVM.onSubmitButtonClicked();
      setTimeout(() => { this.onNextButtonClicked(); }, 2500);
    },
    async checkFeatureFlag() {
      this.FF_Devices = await isFeatureEnabled('FF_Devices', false);

      if (!this.FF_Devices) {
        window.location = '/account';
      }
    },
  },
};
</script>
