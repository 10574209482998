var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "usage-dashboard__usage-visualization-and-tools--container"
    },
    [
      _c("h2", { staticClass: "gds-space-top-xl gds-space-stack-default" }, [
        _vm._v("\n    Dive deeper into how you use clean energy")
      ]),
      _vm._v(" "),
      _c(
        "p",
        { staticClass: "usage-dashboard___sub-title gds-space-stack-l" },
        [
          _vm._v(
            "GMP’s power supply is 100% carbon free and 80% renewable on an annual basis. Using more electricity lowers costs and carbon for all!"
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "usage-dashboard__usage-visualization-and-tools--card-container"
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.isNonNetMeteredAccount
            ? _c(
                "a",
                {
                  staticClass:
                    "usage-dashboard__usage-visualization-and-tools--nav-card",
                  attrs: { href: "/account/usage/detail/time-of-day" }
                },
                [
                  _c("img", {
                    staticClass:
                      "usage-dashboard__usage-visualization-and-tools--icon",
                    attrs: {
                      src:
                        "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg",
                      alt: "Sun and moon icon"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "usage-dashboard__usage-visualization-and-tools--icon-text"
                    },
                    [_vm._v("Time of Day")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "usage-dashboard__usage-visualization-and-tools--description"
                    },
                    [
                      _vm._v(
                        "When do you use energy the most throughout the day."
                      )
                    ]
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _vm._m(3)
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-dashboard__usage-visualization-and-tools--nav-card",
        attrs: { href: "/account/usage/detail/graph" }
      },
      [
        _c("img", {
          staticClass: "usage-dashboard__usage-visualization-and-tools--icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg",
            alt: "Graph chart icon"
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--icon-text"
          },
          [_vm._v("Graph")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--description"
          },
          [_vm._v("View your usage as a bar graph.")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-dashboard__usage-visualization-and-tools--nav-card",
        attrs: { href: "/account/usage/detail/table" }
      },
      [
        _c("img", {
          staticClass: "usage-dashboard__usage-visualization-and-tools--icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg",
            alt: "Table chart icon"
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--icon-text"
          },
          [_vm._v("Table")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--description"
          },
          [_vm._v("View your usage as a set of rows.")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-dashboard__usage-visualization-and-tools--nav-card",
        attrs: { href: "/account/usage/detail/performance" }
      },
      [
        _c("img", {
          staticClass: "usage-dashboard__usage-visualization-and-tools--icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg",
            alt: "Guage icon"
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--icon-text"
          },
          [_vm._v("Performance")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--description"
          },
          [_vm._v("Compare your usage between billing periods.")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "usage-dashboard__usage-visualization-and-tools--nav-card",
        attrs: { href: "/account/usage/detail/compare" }
      },
      [
        _c("img", {
          staticClass: "usage-dashboard__usage-visualization-and-tools--icon",
          attrs: {
            src:
              "/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg",
            alt: "Box with arrows pointing toward each other icon"
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--icon-text"
          },
          [_vm._v("Compare")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass:
              "usage-dashboard__usage-visualization-and-tools--description"
          },
          [_vm._v("Compare usage over different time periods.")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }