var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.$attrs.url && _vm.$attrs.route && !_vm.$attrs.buttonClicked
      ? _c(
          "div",
          [
            _c(
              "router-link",
              {
                staticClass: "gds-button gds-button-text devices-back-button",
                attrs: { to: _vm.route }
              },
              [
                _c("ActionButton", {
                  attrs: {
                    text: _vm.$attrs.text,
                    "css-class": _vm.$attrs.cssClass,
                    "is-custom-secondary": _vm.$attrs.isCustomSecondary,
                    loading: _vm.$attrs.loading,
                    disabled: _vm.$attrs.disabled,
                    "button-clicked": function() {}
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.$attrs.url & !_vm.$attrs.route && _vm.$attrs.buttonClicked
      ? _c("div", [
          _c(
            "div",
            { on: { click: _vm.$attrs.buttonClicked } },
            [
              _c("ActionButton", {
                attrs: {
                  text: _vm.$attrs.text,
                  "css-class": _vm.$attrs.cssClass,
                  "is-custom-secondary": _vm.$attrs.isCustomSecondary,
                  loading: _vm.$attrs.loading,
                  disabled: _vm.$attrs.disabled,
                  "button-clicked": _vm.$attrs.buttonClicked
                }
              })
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$attrs.url && !_vm.$attrs.route && !_vm.$attrs.buttonClicked
      ? _c("div", [
          _c(
            "a",
            { attrs: { href: _vm.$attrs.url } },
            [
              _c("ActionButton", {
                attrs: {
                  text: _vm.$attrs.text,
                  "css-class": _vm.$attrs.cssClass,
                  "is-custom-secondary": _vm.$attrs.isCustomSecondary,
                  loading: _vm.$attrs.loading,
                  disabled: _vm.$attrs.disabled,
                  "button-clicked": function() {}
                }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }