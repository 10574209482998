var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "usage-dashboard__widget-monthly-consumption",
      attrs: { id: "usage-dashboard__widget-monthly-consumption" }
    },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", [
          _vm.isNetMetered
            ? _c("h2", { staticClass: "gds-space-stack-default" }, [
                _vm._v("Monthly Total Home Consumption")
              ])
            : _c("h2", { staticClass: "gds-space-stack-m" }, [
                _vm._v("Monthly Consumption")
              ]),
          _vm._v(" "),
          _vm.isNetMetered
            ? _c("p", { staticClass: "usage-dashboard___sub-title" }, [
                _vm._v("Energy Consumed from Grid and Self-Generation")
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "gds-button gds-button-circle gds-button-outline",
              attrs: { href: "/account/usage/detail/graph" }
            },
            [
              _c("svg", { staticClass: "gds-icon" }, [
                _c("use", {
                  attrs: {
                    "xlink:href":
                      "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-arrow-right"
                  }
                })
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.loadState === "error"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "error" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "maintenance"
            ? _c("flow-error", {
                attrs: { name: "Usage information", state: "maintenance" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "unavailable"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "unavailable",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.loadState === "empty"
            ? _c("flow-error", {
                attrs: {
                  name: "Usage information",
                  state: "nodata",
                  img:
                    "/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.loadState === "complete"
        ? _c(
            "div",
            {
              staticClass:
                "usage-dashboard__widget-monthly-consumption__chart_container"
            },
            [
              _vm.binsTop
                ? _c("usage-chart", {
                    ref: "chartTop",
                    attrs: {
                      usageData: _vm.binsTop,
                      layers: _vm.visibleLayerNames,
                      height: "300px"
                    },
                    on: { click: _vm.chartDoubleClickHandler }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "gds-desktop-only gds-button gds-button-text gds-compact gds-button-underline ",
        attrs: { href: "/account/usage/detail/graph" }
      },
      [
        _c("div", { staticClass: "gds-button-nowrap" }, [
          _vm._v("\n      View Detailed Graph\n      ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }