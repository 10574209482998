<template>
  <div class="subpage-report--dashboard subpage-dashboards--left-spacer">
    <div v-if="!billingStats && !isAdminLite" class="account-billingStats-loading" />
    <!-- Yearly Totals -->
    <section v-if="billingStats && !isAdminLite" class="my-account__full-width-section gds-space-stack-xl">
      <h2 id="yearly-totals" class="gds-display-1 gds-space-stack-l">Yearly Totals</h2>
      <div class="my-account-billing-report__row gds-flex-container gds-flex-container--left">
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="current-year-bills">
            {{ billingStats.thisYear.year }}
            Bills
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="yearly-totals current-year-bills">{{ billingStats.thisYear.billTotal | currency(true) }}</div>
        </div>
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="current-year-payments">
            {{ billingStats.thisYear.year
            }}
            Payments
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="yearly-totals current-year-payments">{{ billingStats.thisYear.paymentTotal | currency(true) }}</div>
        </div>
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="previous-year-bills">
            {{ billingStats.lastYear.year }}
            Bills
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="yearly-totals previous-year-bills">{{ billingStats.lastYear.billTotal | currency(true) }}</div>
        </div>
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="previous-year-payments">
            {{ billingStats.lastYear.year
            }}
            Payments
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="yearly-totals previous-year-payments">{{ billingStats.lastYear.paymentTotal | currency(true) }}</div>
        </div>
      </div>
    </section>
    <!-- Seasonal Averages -->
    <section v-if="billingStats" class="my-account__full-width-section gds-space-stack-xl">
      <h2 id="seasonal-averages" class="gds-display-1 gds-space-stack-l">Seasonal Averages</h2>
      <div class="my-account-billing-report__row gds-flex-container gds-flex-container--left">
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="winter-avg">
            Winter
            <span class="gds-font-book">(Dec, Jan, Feb)</span>
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="seasonal-averages winter-avg">{{ billingStats.winterAverage | currency(true) }}</div>
        </div>
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="spring-avg">
            Spring
            <span class="gds-font-book">(Mar, Apr, May)</span>
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="seasonal-averages spring-avg">{{ billingStats.springAverage | currency(true) }}</div>
        </div>
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="summer-avg">
            Summer
            <span class="gds-font-book">(Jun, July, Aug)</span>
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="seasonal-averages summer-avg">{{ billingStats.summerAverage | currency(true) }}</div>
        </div>
        <div class="my-account-billing-report__col3">
          <div class="my-account-billing-report__data-label" id="fall-avg">
            Fall
            <span class="gds-font-book">(Sept, Oct, Nov)</span>
          </div>
          <div class="my-account-billing-report__large-data" aria-labelledby="seasonal-averages fall-avg">{{ billingStats.fallAverage | currency(true) }}</div>
        </div>
      </div>
    </section>

    <!-- Billing Report -->
    <section v-if="!FF_CustomBillReport" class="my-account__full-width-section gds-space-stack-xl">
      <h2 class="gds-display-1 gds-space-stack-l">Detailed Report</h2>
      <div class="my-account-billing-report__data-tables-wrapper">
        <p>
          View and customize a spreadsheet of all bill charges, quantities, and rates for the dates you choose. The
          detailed report will open in a new window.
        </p>
        <a
          :href="EnvironmentConstants.BillReportURL + '/?accountNumber=' + currentAccount.accountNumber"
          target="_blank"
          class="gds-button gds-round gds-space-stack-l"
        >Launch Detailed Report</a>
      </div>
    </section>

    <!-- Billing Report -->
    <section v-if="FF_CustomBillReport" class="my-account__full-width-section gds-space-stack-xl">
      <flow-error v-if="apiError" name="Bill Report" state="error" phonetext="For assistance call us at" :showBackground="false" />
      <div v-if="!apiError">
        <h2 class="gds-display-1 gds-space-stack-l">Generate Detailed Report</h2>
        <div class="my-account-billing-report__detailed-report-wrapper">
          <div class="gds-space-stack-l">
            <p>
              Generate a spreadsheet of all bill charges, quantities, and rates for the dates you choose. After you run
              the
              report you can download or view it in your browser.
            </p>
          </div>
          <div class="gds-space-stack-l">
            <label>
              <h3 class="gds-display-0 gds-space-stack-m">Report Timeframe</h3>
              <select
                :disabled="pendingRates || pendingReport"
                v-model="reportTimeFrame"
                @change="handleReportTimeframeChange"
                v-validate="'required'"
                name="report time frame"
                key="report time frame"
                class="eicproduct-input-field-l__select gds-input-field"
                autofocus
              >
                <option :value="undefined" disabled>Select timeframe for report</option>
                <option :value="'12-months'">Past 12 Months</option>
                <option :value="'24-months'">Past 24 Months</option>
                <option :value="'custom-range'">Select Range</option>
              </select>
            </label>
          </div>
          <div v-if="showDatePicker">
            <div class="gds-space-stack-l">
              <label @click.prevent class="gds-input-field">
                <span class="gds-input-field__label">Start Date</span>
                <div class="export-data__filtration-date-box">
                  <div class="my-account-billing-report__filtration-date-icon">
                    <svg class="gds-icon">
                      <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                    </svg>
                  </div>
                  <datepicker
                    @input="handleBillDetailsReportStartDateChange"
                    ref="startDatePicker"
                    v-model="billDetailsReportStartDate"
                    name="bill report start date"
                    key="bill report start date"
                    :v-validate="showDatePicker ? 'required' : null"
                    :typeable="true"
                    placeholder="Select Date..."
                    :disabled-dates="disableFutureDates"
                    minimum-view="month"
                    maximum-view="year"
                    input-class="gds-font-size-l export-data__date"
                  />
                </div>
                <div v-if="errors.first('bill report start date')" class="rebate-validation-error">
                  {{
                  errors.first('bill report start date')
                  }}
                </div>
              </label>
            </div>
            <div class="gds-space-stack-l">
              <label v-if="billDetailsReportStartDate" @click.prevent class="gds-input-field gds-space-stack-l">
                <span class="gds-input-field__label">End Date</span>
                <div class="export-data__filtration-date-box">
                  <div class="my-account-billing-report__filtration-date-icon">
                    <svg class="gds-icon">
                      <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar" />
                    </svg>
                  </div>
                  <datepicker
                    ref="endDatePicker"
                    v-model="billDetailsReportEndDate"
                    @input="handleCustomRangeBillingRates"
                    name="bill report end date"
                    key="bill report end date"
                    :v-validate="showDatePicker ? 'required' : null"
                    :typeable="true"
                    placeholder="Select Date..."
                    :disabled-dates="{
                      to: addMonths(new Date(billDetailsReportStartDate), 1)
                    }"
                    minimum-view="month"
                    maximum-view="year"
                    input-class="gds-font-size-l export-data__date"
                  />
                </div>
                <div v-if="errors.first('bill report end date')" class="rebate-validation-error">
                  {{
                  errors.first('bill report end date')
                  }}
                </div>
              </label>
            </div>
          </div>
          <div v-show="showReportContents && !selectedRangeError" class="gds-space-stack-xl">
            <label>
              <h3 class="gds-display-0 gds-space-stack-m">Report Contents</h3>
              <select
                :class="{ 'gds-loading':pendingRates || pendingReport }"
                :disabled="pendingRates || pendingReport"
                @change="handleReportContentChange"
                v-model="reportContent"
                v-validate="'required'"
                name="report time frame"
                class="eicproduct-input-field-l__select gds-input-field"
                autofocus
              >
                <option :value="undefined" disabled>Select contents of report</option>
                <option :value="'full-report'">All Rates, Charges, and Quantities</option>
                <option :value="'custom-report'">Custom Report</option>
              </select>
            </label>
          </div>
        </div>

        <!-- Custom Report -->
        <section v-if="showCustomReportContent && reportTimeFrame" class="my-account__full-width-section gds-space-stack-xl">
          <h3 class="gds-display-0 gds-space-stack-m">Values to Include</h3>
          <div class="report-items-flex-container gds-space-stack-xl">
            <div class="global-login-panel__form-helper my-account-billing-report__custom-report-items">
              <label class="gds-checkbox gds-checkbox-thin">
                <input @click="handleValueSelection" type="checkbox" value="quantity" />
                <span class="gds-checkbox__faux"></span>
                <span class="gds-font-size-m gds-text-grey">Billed Quantity (kWh, kW)</span>
              </label>
            </div>
            <div class="global-login-panel__form-helper my-account-billing-report__custom-report-items">
              <label class="gds-checkbox gds-checkbox-thin">
                <input @click="handleValueSelection" type="checkbox" value="amount" />
                <span class="gds-checkbox__faux"></span>
                <span class="gds-font-size-m gds-text-grey">Billed Amount ($)</span>
              </label>
            </div>
          </div>
          <h3 class="gds-display-0 gds-space-stack-m">Rates, Products & Programs to Include</h3>
          <div v-if="billingRates" class="report-items-flex-container">
            <div v-for="billingRate of billingRates" :key="billingRate" class="global-login-panel__form-helper my-account-billing-report__custom-report-items">
              <label class="gds-checkbox gds-checkbox-thin">
                <input @click="handleRateCodeSelection" type="checkbox" :value="billingRate" />
                <span class="gds-checkbox__faux"></span>
                <span class="gds-font-size-m gds-text-grey">{{ billingRate }}</span>
              </label>
            </div>
          </div>
          <div v-if="!billingRates">
            <div v-if="pendingRates">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg" class="eicproduct__file-uploader-loader--svg" />
            </div>
            <div v-if="!pendingRates">Please select a valid timeframe.</div>
          </div>
        </section>
        <button
          :disabled="pendingRates || pendingReport || !reportTimeFrame || !reportContent || !selectedRates.length || !selectedValues.length"
          v-if="!showFileViewer && !selectedRangeError"
          @click="runReport"
          :class="{ 'gds-loading': pendingReport || pendingRates }"
          class="my-account-billing-report__custom-report-button gds-button gds-round gds-space-stack-l">
          {{ pendingReport || pendingRates ? ' ' : 'Run Report'}}
        </button>
        <div v-if="selectedRangeError" class="my-account__custom-report-container">
          <div class="my-account__custom-report__icon-light-bulb my-account__custom-report-flex-item gds-flex-container">
            <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/icon-light-bulb.svg" alt="View or download report" />
          </div>
          <div class="my-account-billing-report__custom-report-error-text">
            No Data Found for the selected range. Please select a valid date range.
          </div>
        </div>
        <div v-if="showFileViewer" class="my-account__custom-report-container">
          <div class="my-account__custom-report__icon-light-bulb my-account__custom-report-flex-item">
            <img class="my-account__payment-plan-icon" src="/wp-content/themes/gmptwentynineteen/assets/images/icon-light-bulb.svg" alt="View or download report" />
          </div>
          <div v-if="!viewReportError" class="my-account__custom-report-flex-item">
            <div class="gds-display-0 gds-space-stack-l gds-align--text-center">
              Your detailed report for {{ this.billDetailsReportStartDate |
              dateFull
              }}-{{ this.billDetailsReportEndDate | dateFull }} is ready.
            </div>
            <div class="my-account__custom-report-button-container">
              <div @click="viewReport" class="gds-align--text-center my-account__custom-report-button">
                <a class="gds-card__button gds-button gds-round gds-secondary">{{ pendingReport ? null : 'View' }}</a>
              </div>
              <div @click="downloadReport()" class="gds-align--text-center my-account__custom-report-button">
                <a class="gds-card__button gds-button gds-round gds-secondary" :class="{ 'gds-loading': pendingReport }">
                  {{ pendingReport ? null : 'Download CSV' }}
                </a>
              </div>
            </div>
          </div>
          <div v-if="viewReportError" class="my-account__custom-report-flex-item">
            <div class="my-account-billing-report__custom-report-error-text gds-space-stack-l">
              There was an error getting your report. Please try again.
            </div>
            <div @click="RefreshData()" class="gds-align--text-center my-account__custom-report-button">
              <a class="gds-card__button gds-button gds-round gds-secondary">
                Go Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import { EnvironmentConstants } from "../../../environment";
import { GetBillingRates, GetFormattedBillingInfo, DownloadBillingReport, GetTransposedBillReport } from '../../../services/billing';
import Datepicker from "vuejs-datepicker";
import { ToServerDateTruncateHours, DownloadFile, DumpError } from '../../../utilities';
import { isFeatureEnabled } from "../../../services/featureflags";
import { addMonths } from "date-fns";

export default {
  name: "SubpageReport",
  components: {
    Datepicker,
  },
  data() {
    return {
      FF_CustomBillReport: undefined,
      EnvironmentConstants,
      billingReport: undefined,
      reportContents: undefined,
      billingStats: undefined,
      billingRates: undefined,
      billDetailsReportStartDate: undefined,
      billDetailsReportEndDate: undefined,
      reportTimeFrame: undefined,
      reportContent: undefined,
      selectedRates: [],
      selectedValues: [],
      showCustomReportContent: undefined,
      showFileViewer: undefined,
      showDatePicker: undefined,
      disableFutureDates: {
        from: new Date(),
      },
      loading: undefined,
      pendingReport: undefined,
      pendingRates: undefined,
      apiError: undefined,
      viewReportError: undefined,
      selectedRangeError: undefined,
      showReportContents: undefined
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
  },
  created() {
    this.checkIfGroupNetMetered();
  },
  async mounted() {
    this.getConfigs();

    if (!this.isAdminLite) {
      this.RefreshData();
    }
  },
  methods: {
    async getConfigs() {
      this.FF_CustomBillReport = await isFeatureEnabled('FF_CustomBillReport', false);
    },
    checkIfGroupNetMetered() {
      if (this.$store.state.user.currentAccount.groupNetMetered) {
        this.$router.push('/account');
      }
    },
    async RefreshData() {
      this.resetCustomReportState();
      try {
        const calculated = await GetFormattedBillingInfo(this.currentAccount.accountNumber);
        this.billingStats = calculated;
      } catch (err) {
        DumpError("Get Formatted Billing Info error", err);
        this.apiError = true;
      }
    },
    resetCustomReportState() {
      this.billingStats = undefined;
      this.billDetailsReportStartDate = undefined;
      this.billDetailsReportEndDate = undefined;
      this.billingRates = undefined;
      this.reportTimeFrame = undefined;
      this.reportContent = undefined;
      this.pendingReport = undefined;
      this.pendingRates = undefined;
      this.showFileViewer = undefined;
      this.viewReportError = undefined;
      this.apiError = undefined;
      this.selectedRangeError = undefined;
      this.showDatePicker =undefined;
      this.disableFutureDates = undefined;
      this.showReportContents = undefined;
    },
    handleReportTimeframeChange() {
      this.showFileViewer = undefined;
      this.billDetailsReportStartDate = undefined;
      this.billDetailsReportEndDate = undefined;
      this.billingRates = undefined;
      this.reportContent = undefined;
      this.showCustomReportContent = undefined;
      this.selectedRangeError = undefined;
      this.showReportContents = undefined;

      const endDate = new Date();
      if (this.reportTimeFrame === 'custom-range') {
        this.showDatePicker = true;
        return;
      }
      this.showDatePicker = false;
      let years = this.reportTimeFrame === '12-months' ? 1 : 2;
      this.showReportContents = true;
      this.billDetailsReportStartDate = new Date(new Date().setFullYear(endDate.getFullYear() - years));
      this.billDetailsReportEndDate = endDate
      this.getBillingRates(this.billDetailsReportStartDate, this.billDetailsReportEndDate);
    },
    async getBillingRates(startDate, endDate) {
      this.pendingRates = true;
      try {
        let billingRates = await GetBillingRates(this.currentAccount.accountNumber, startDate, endDate);
        this.billingRates = billingRates;
      } catch (err) {
        DumpError("Get Billing Rates error", err);
        this.selectedRangeError = true;
      }

      this.pendingRates = undefined;
    },
    async handleCustomRangeBillingRates() {
      this.pendingRates = true;
      this.selectedRangeError = undefined;
      this.showReportContents = true;
      try {
        const billingRates = await GetBillingRates(this.currentAccount.accountNumber, this.billDetailsReportStartDate, this.billDetailsReportEndDate);
        this.billingRates = billingRates;
      } catch (err) {
        DumpError("Get Billing Rates error", err);
        this.showReportContents = undefined;
        this.selectedRangeError = true;
      }

      this.pendingRates = undefined;
    },
    async handleReportContentChange() {
      this.selectedValues = [];
      this.selectedRates = [];
      if (this.reportContent === 'custom-report') {
        this.showCustomReportContent = true;
      } else if (this.reportContent === 'full-report') {
        this.showCustomReportContent = undefined;
        if (!this.billingRates) {
          await this.handleCustomRangeBillingRates();
        }
        this.selectedValues.push('quantity')
        this.selectedValues.push('amount')
        for (let i = 0; i < this.billingRates.length; i++) {
          this.selectedRates.push(this.billingRates[i])
        }
      } else {
        this.showCustomReportContent = undefined;
      }
    },
    handleRateCodeSelection(evt) {
      const checked = evt.target.checked;
      const rateCode = evt.target.value;
      if (checked) {
        if (!this.selectedRates.includes(rateCode)) {
          this.selectedRates.push(rateCode)
        }
      } else {
        if (this.selectedRates.includes(rateCode)) {
          this.selectedRates.splice(this.selectedRates.indexOf(rateCode), 1);
        }
      }
    },
    handleValueSelection(evt) {
      const checked = evt.target.checked;
      const column = evt.target.value;
      if (checked) {
        if (!this.selectedValues.includes(column)) {
          this.selectedValues.push(column)
        }
      } else {
        if (this.selectedValues.includes(column)) {
          this.selectedValues.splice(this.selectedValues.indexOf(column));
        }
      }
    },
    async runReport() {
      this.reportTimeFrame = undefined;
      this.reportContent = undefined;
      this.pendingReport = true;
      const data = {
        includeColumns: this.selectedValues,
        startDate: ToServerDateTruncateHours(this.billDetailsReportStartDate),
        endDate: ToServerDateTruncateHours(this.billDetailsReportEndDate),
        includeRates: this.selectedRates
      }
      try {
        this.billingReport = await GetTransposedBillReport(this.currentAccount.accountNumber, data);
        const contents = await DownloadBillingReport(this.currentAccount.accountNumber, data);
        this.reportContents = contents;
        this.showFileViewer = true;
      } catch (err) {
        DumpError("Error running bill report", err)
        this.viewReportError = true;
      }

      this.pendingReport = undefined;
    },
    async viewReport() {
      if (this.billingReport) {
        this.$router.push({ name: "viewReport", params: { billingReport: this.billingReport, rates: this.billingRates } });
      }
    },
    async downloadReport() {
      const startDate = ToServerDateTruncateHours(this.billDetailsReportStartDate);
      const endDate = ToServerDateTruncateHours(this.billDetailsReportEndDate);
      const filename = "BillingReport_" + startDate + "-" + endDate + ".csv";
      DownloadFile(filename, this.reportContents)
    },
    handleBillDetailsReportStartDateChange() {
      this.selectedRangeError = undefined;
      this.showReportContents = undefined;
      if (this.billDetailsReportEndDate) {
        this.billDetailsReportEndDate = undefined;
      }
    },
    addMonths(date, amt) {
      return addMonths(date, amt);
    }
  },
  watch: {
    currentAccount: {
      deep: true,
      async handler(newVal) {
        if (newVal) {
          this.checkIfGroupNetMetered();
        }
        this.RefreshData(); 
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>