<template>
  <transition name="global-account-panel__wrappers" enter-active-class="global-account-panel__wrappers-enter-active"
    leave-active-class="global-account-panel__wrappers-leave-active"
    enter-from-class="global-account-panel__wrappers-enter" leave-to-class="global-account-panel__wrappers-leave-to">
    <div v-if="loading === true" class="bill-pay__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="bill-pay__page-loader--svg" />
    </div>
    <div v-if="isExpanded && !isRestrictedRebateUser" v-click-outside="Close" class="global-panel global-panel__left">
      <FocusLock>
        <div class="global-panel__inner-scroll">

          <button @click="Close()" class="close gds-button gds-button-circle gds-secondary">
            <span class="gds-visibility--hide-visually">Close</span>
            <svg class="gds-icon">
              <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small" />
            </svg>
          </button>
          <p class="gds-display-2 gds-space-stack-m">Switch Account</p>
          <p class="gds-space-stack-l">Select the account you'd like to view.</p>
          <div v-if="isAdmin || isAdminLite" class="gds-space-stack-l">
            <div class="global-panel__account-type-header gds-font-demi gds-text-grey">Account Ghosting</div>
            <form @submit.prevent="SelectGhostAccount" class="gds-space-stack-ml">
              <input v-model="searchText" v-validate="'required|numeric|length:10'" v-mask="'##########'"
                class="ghost__search-input quick-ghost__search-input gds-input-field__input" name="search text"
                type="text" placeholder="Enter 10 digit account number" />
              <span @click="SelectGhostAccount" class="quick-ghost-input-field"></span>
            </form>
            <div v-if="errorSearch" class="validation-error">{{ errorSearch }}</div>
            <div v-if="errorSelect" class="validation-error">{{ errorSelect }}</div>

            <div v-for="account of ghostAccounts" :key="account.accountNumber" @click="SelectAccount(account)"
              @keyup.enter="SelectAccount(account); errorSelect = undefined" class="global-panel__account-row"
              tabindex="0">
              <div v-if="account.photoUrl"
                :style="{ backgroundImage: 'url(' + GetResizedAccountPhoto(account.photoUrl) + ')' }"
                class="small-round-img"></div>
              <div v-if="!account.photoUrl" class="small-round-img"></div>
              <div>
                <p class="gds-display-0 gds-space-stack-s">{{ account | accountName }}</p>
                <div>{{ account.address | addressFormat({ separator: "\n", skipFirst: true }) }}
                </div>
                <span>Acct. # {{ account.accountNumber }}</span>
              </div>
            </div>
            <div class="global-panel__account-link">
              <a href="/admin/ghosting" class="gds-display-0 gds-link gds-link--bold">Search Accounts</a>
            </div>
            <div v-if="ghostAccounts && ghostAccounts.length > 0" class="global-panel__account-link">
              <button @click="ClearRecent()" type="button" class="gds-button gds-text-button">
                <span class="gds-button__text">Clear Recent Searches</span>
              </button>
            </div>
          </div>
          <div v-if="!isAdmin && !isAdminLite" class="gds-space-stack-m">
            <div v-for="account of accounts" :key="account.accountNumber" @click="SelectAccount(account)"
              @keyup.enter="SelectAccount(account)" class="global-panel__account-row" tabindex="0">
              <div v-if="account.photoUrl"
                :style="{ backgroundImage: 'url(' + GetResizedAccountPhoto(account.photoUrl) + ')' }"
                class="small-round-img"></div>
              <div v-if="!account.photoUrl" class="small-round-img"></div>
              <div>
                <p class="gds-display-0 gds-space-stack-s">{{ account | accountName }}</p>
                <div>{{ account.address | addressFormat({ separator: "\n", skipFirst: true }) }}
                </div>
                <span>Acct. # {{ account.accountNumber }}</span>
                <div v-if="account.isPrimary">
                  <span class="global-panel__primary-badge">Primary</span>
                </div>
              </div>
            </div>
            <div class="global-panel__account-link">
              <a href="/user-profile/accounts" class="gds-display-0 gds-link gds-link--bold">Add / Manage Accounts</a>
            </div>
          </div>
        </div>
      </FocusLock>
    </div>
  </transition>
</template>

<script>
import { GetResizedAccountPhoto, DumpError } from "../utilities";

export default {
  name: "GlobalAccountPanel",
  data() {
    return {
      GetResizedAccountPhoto,
      errorSearch: undefined,
      errorSelect: undefined,
      searchText: undefined,
      loading: undefined,
    };
  },
  computed: {
    isExpanded() {
      return this.$store.state.layout.accountswitchervisible;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    ghostAccounts() {
      return this.$store.state.user.ghostAccounts;
    },
    isAdmin() {
      return !!this.$store.state.user.userinfo.isAdmin;
    },
    isAdminLite() {
      return !!this.$store.state.user.userinfo.isAdminLite;
    },
    isRestrictedRebateUser() {
      return !!this.$store.state?.user?.userinfo?.groups?.find(item => item.name === 'RESTRICTED_REBATE_USER');
    },
  },
  methods: {
    Close() {
      this.$store.commit("setAccountSwitcherVisible", false);
    },
    SelectAccount(account) {
      this.$store.commit("setCurrentAccount", account);
      this.Close();

      const fullPath = window.location.pathname;
      const accountPath = '/account';
      const usageUnavailablePath = '/account/usage-unavailable';

      const usageShouldShow = !account.resilientHome && fullPath.indexOf(usageUnavailablePath) > -1;
      const usageShouldNotShow = account.resilientHome && fullPath.indexOf(`${accountPath}/usage`) > -1;

      if (usageShouldNotShow) {
        window.location.href = usageUnavailablePath;
      } else if (usageShouldShow) {
        window.location.href = accountPath;
      }
    },
    ClearRecent() {
      this.$store.commit("clearGhostAccounts");
    },
    async SelectGhostAccount() {
      if (this.searchText.match(/^\d{10}$/)) {
        try {
          this.pendingSelect = true;
          this.errorSelect = undefined;
          this.loading = true;
          await this.$store.dispatch("FetchGhostingAccount", this.searchText);
          window.location.href = "/account";
        } catch (err) {
          this.errorSelect = "Unable to select that account";
          DumpError("Ghosting select error", err);
        } finally {
          this.pendingSelect = false;
          this.loading = undefined;
        }
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>