<template>
  <section
    class="devices-column-gap-xxl"
  >
    <div v-for="(schedeule, schedeuleIndex) of peakRateSchedule" :key="schedeuleIndex">
      <div class="gds-font-demi gds-space-stack-s">{{ schedeule.rateScheduleDescription }}</div>
      <ul>
        <li
          v-for="(scheduleLine, scheduleLineIndex) of schedeule.scheduleLines"
          :key="scheduleLineIndex"
          class="gds-font-book gds-space-inset-squish-m devices-padding-left-half"
        >
          {{ scheduleLine }}
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PeakRateSchedule',
  props: {
    peakRateSchedule: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped></style>
