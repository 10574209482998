<template>
  <div class="subpage-dashboards subpage-dashboard--user-profile">
    <h1 class="my-account__title gds-space-stack-l">Add / Manage Accounts</h1>
    <banner-one-action
      v-if="numLinkedAccounts > accountWarningCount"
      bannerColor="blue"
      :message="`You have ${numLinkedAccounts} accounts linked to this profile. Having a large number of accounts may cause performance issues. Please consider removing unused accounts or creating a new profile to add more.`"
    ></banner-one-action>

    <div v-if="!adding">
      <section class="my-account__section-with-bottom-border gds-space-stack-l">
        <div class="gds-flex-container gds-flex-container--space-between gds-space-stack-l">
          <div class="gds-font-size-xl gds-font-demi gds-space-inline-m">Linked Accounts</div>
          <button @click="adding = true" type="button" class="gds-button gds-compact gds-round">Add Account</button>
        </div>
      </section>

      <section class="user-profile__linked-accounts" :class="{ 'gds-loading-full': refreshing }">
        <widget-account-row v-for="account of accounts" :key="account.accountNumber" :account="account" @setprimary="SetPrimary" />
      </section>
    </div>

    <section v-if="adding" class="my-account__full-width-section user-profile__add-account-section">
      <div class="gds-flex-container gds-flex-container--space-between gds-space-stack-l">
        <div class="gds-font-size-xl gds-font-demi">Add Account to Your Profile</div>
        
      </div>
      <form @submit.prevent="AddAccount()">
        <fieldset class="gds-fieldset">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Your GMP Account Number*</span>
            <input
              v-model="newAccountNumber"
              v-validate="'required|numeric|length:10,11'"
              v-mask="'###########'"
              maxlength="11"
              name="account number"
              key="account number"
              class="gds-input-field__input"
              type="text"
              placeholder="e.g. 123456789"
            />
          </label>
          <div v-if="errors.first('account number')" class="validation-error">{{ errors.first("account number")}}</div>
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-m">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Account Nickname (Optional)</span>
            <input
              v-model="newNickname"
              v-validate="'max:30'"
              maxlength="30"
              name="account nickname"
              key="account nickname"
              class="gds-input-field__input"
              type="text"
              placeholder="e.g. My Home"
            />
          </label>
          <div v-if="errors.first('account nickname')" class="validation-error">{{ errors.first("account nickname")}}</div>
        </fieldset>
        <fieldset class="gds-fieldset gds-space-stack-m">
          <label class="gds-input-field">
            <span class="gds-input-field__label">Phone (Last 4 Digits)*</span>
            <input
              v-model="newPhone"
              v-validate="'required|length:4'"
              maxlength="4"
              name="phone number"
              key="phone number"
              class="gds-input-field__input"
              type="tel"
              placeholder="e.g.5678"
            />
          </label>
          <div v-if="errors.first('phone number')" class="validation-error">{{ errors.first("phone number")}}</div>
          <div id="captcha-container"></div>
        </fieldset>
        <div v-if="errorAdd" class="form-message-box gds-space-stack-m">{{errorAdd}}</div>
        <button @click="adding = false" type="button" class="gds-button gds-secondary gds-compact gds-space-inline-s">Cancel</button>
        <button :disabled="pendingAdd" :class="{ 'gds-loading': pendingAdd }" type="submit" class="gds-button gds-compact gds-space-stack-s gds-space-inline-m">
          <span class="gds-button__text">Add</span>
        </button>
      </form>
    </section>
  </div>
</template>

<script>
import GMPAPI from "../../services/gmpapi";
import BannerOneAction from "../BannerOneAction.vue";
import WidgetAccountRow from "./WidgetAccountRow";
import { AccountWarningCount } from "../../environment";
import { DumpError, ValidateCaptcha } from '../../utilities';
import { isFeatureEnabled } from "../../services/featureflags";

export default {
  name: "SubpageManageAccounts",
  components: {
    BannerOneAction,
    WidgetAccountRow,
  },
  data() {
    return {
      adding: false,
      refreshing: false,

      newAccountNumber: undefined,
      newNickname: undefined,
      newPhone: undefined,

      errorAdd: undefined,
      pendingAdd: false,
      accountWarningCount: AccountWarningCount,
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userinfo;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    numLinkedAccounts() {
      if (this.$store.state.user.accounts) {
        return this.$store.state.user.accounts.length;
      } else {
        return 0;
      }
    },
  },
  async mounted() {
    try {
      this.isOnboardingFeatureEnabled = await isFeatureEnabled('FF_Onboarding', false);
    } catch (err) {
      this.isOnboardingFeatureEnabled = false;
      DumpError('Error reading feature flag', err);
    }
    await this.RefreshAccounts();
  },
  methods: {
    async SetPrimary(account) {
      // Want to start the overlay early
      this.refreshing = true;
      try {
        await GMPAPI.SetPrimary(this.userInfo.username, account.accountNumber);
        await this.RefreshAccounts()
      } finally {
        this.refreshing = false;
      }
    },
    async RefreshAccounts() {
      this.refreshing = true;
      try {
        await this.$store.dispatch("FetchUserInfo");
      } finally {
        this.refreshing = false;
      }
    },
    async AddAccount() {
      this.errorAdd = undefined;

      if (await this.$validator.validateAll()) {
        this.pendingAdd = true;
        try {
          // WEB-705 - START
          // To enable captchaToken, swap comments below
          // let captchaToken = await ValidateCaptcha();
          // if(!captchaToken) {
          //   throw { code: 999 };
          // }

          let captchaToken = undefined;
          // WEB-705 - END

          if (this.newAccountNumber.length > 10) {
            this.newAccountNumber = this.newAccountNumber.slice(0, 10);
          }

          await GMPAPI.AddUserAccount(this.userInfo.username, this.newAccountNumber, this.newPhone, this.newNickname || undefined, captchaToken);
          this.adding = false;
          await this.RefreshAccounts();

          if (this.isOnboardingFeatureEnabled) {
            window.location = "/account/create?enroll=" + this.newAccountNumber;
          } else {
            window.location = "/account?account=" + this.newAccountNumber;
          } 
       } catch (err) {
          DumpError("Add account error", err);
          let code;
          if (err.response && err.response.data) {
            code = err.response.data.code;
            if (err.response.data.message) {
              console.error(code + ": " + err.response.data.message);
            }
          }
          if(!code && err.code) {
            code = err.code;
          }
          switch (code) {
            case 40:
              this.errorAdd = "The account and phone number combination could not be verified.";
              break;
            case 999:
            case 15:
              this.errorAdd = "Bot detection failed. Please refresh the page and try again. If the problem persists, please contact us.";
              break;
            default:
              this.errorAdd = "There was an unexpected error adding your account.";
              break;
          }
        } finally {
          this.pendingAdd = false;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>