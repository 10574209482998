var render = function() {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "devices-action-button-wrapper",
      class: ((_obj = {}), (_obj[_vm.cssClass] = _vm.cssClass), _obj)
    },
    [
      !_vm.disabled
        ? _c(
            "button",
            {
              staticClass:
                "gds-button gds-secondary devices-action-button devices-border-none devices-color-white",
              class:
                ((_obj$1 = {
                  "gds-loading": _vm.loading,
                  "is-custom-secondary": _vm.isCustomSecondary
                }),
                (_obj$1[_vm.cssClass] = _vm.cssClass),
                _obj$1),
              on: { click: _vm.buttonClicked }
            },
            [!_vm.loading ? _c("span", [_vm._v(_vm._s(_vm.text))]) : _vm._e()]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.disabled
        ? _c(
            "button",
            {
              staticClass: "gds-button gds-secondary devices-border-none",
              attrs: { disabled: "" }
            },
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }