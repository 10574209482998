<template>
  <div>
    <BackButton v-if="formVM.pageNumber === 1" :route="'/devices/add-device'" />
    <BackButton v-if="formVM.pageNumber > 1 && !formVM.deadEndBrandAndModel" :on-link-clicked="onBackButtonClicked" />
    <div class="form-container gds-space-stack-l">
      <form @submit.prevent="preventFormSubmission">
        <FormHeader v-if="formVM.headerConfig" :config="formVM.headerConfig" />
        <!-- START page 1 -->
        <div v-show="formVM.isPageOneVisible">
          <RadioGroupDeviceType
            v-model="formVM.formData.deviceType"
            :config="formVM.radioButtonConfigDeviceType"
          />
          <div v-if="formVM.selectedDeviceType">
            <div v-if="formVM.evChargerSelected">
              <div class="gds-font-book gds-space-stack-m">{{ formVM.evChargerInfoText }}</div>
              <LinkButton :text="formVM.evChargerLinkText" :url="formVM.evChargerLinkUrl" />
            </div>
            <div v-if="formVM.homeBatterySelected">
              <div class="gds-font-book gds-space-stack-m">{{ formVM.homeBatteryInfoText }}</div>
              <LinkButton :text="formVM.homeBatteryLinkText" :url="formVM.homeBatteryLinkUrl" />
            </div>
            <div v-if="!formVM.isSupportedDevice" v-html="formVM.nonSupportedDeviceTypeWarning" />
          </div>
        </div>
        <!-- END page 1 -->
      </form>
    </div>
  </div>
</template>

<script>
import AddNewViewModel from './AddNewViewModel';
import LinkButton from '../../shared/components/LinkButton.vue';
import BackButton from '../../shared/components/formcomponents/BackButton.vue';
import FormHeader from '../../shared/components/formcomponents/FormHeader.vue';
import RadioGroupDeviceType from '../../shared/components/formcomponents/RadioGroupDeviceType.vue';

const DEVICES_PATH = '/devices';

export default {
  name: 'AddDeviceNew',
  components: {
    BackButton,
    FormHeader,
    LinkButton,
    RadioGroupDeviceType,
  },

  data() {
    return {
      formVM: new AddNewViewModel(),
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    currentAccount() {
      this.$router.push(DEVICES_PATH);
    },
    'formVM.formData': {
      handler(newValue) {
        this.formVM.formDataWatchHandler(newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.formVM.updatePageHeader();
  },
  methods: {
    preventFormSubmission() {
      // no op
    },
  },
};
</script>
