var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gmp-modal" }, [
    _c(
      "div",
      {
        staticClass:
          "my-account__modal-box no-scroll-modal manage-participation-modal"
      },
      [
        _c(
          "div",
          { staticClass: "event-participation" },
          [
            _c(
              "h3",
              { staticClass: "device-detail-section-title gds-space-stack-ml" },
              [_vm._v("Event Participation")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close gds-button gds-button-circle gds-secondary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [
                _c("svg", { staticClass: "gds-icon close-icon" }, [
                  _c("use", {
                    attrs: {
                      "xlink:href":
                        "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                    }
                  })
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "event-details gds-flex-container gds-flex-container--left gds-flex-container--top"
              },
              [
                !_vm.vm.event.optedOut
                  ? _c("img", {
                      staticClass: "participation-icon",
                      attrs: {
                        src:
                          "/wp-content/themes/gmptwentynineteen/assets/images/devices/icon-device-peak-event-lg.svg",
                        alt: "Peak event icon"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "data-point gds-space-stack-m" }, [
                    _c("strong", [_vm._v("Device:")]),
                    _vm._v(_vm._s(_vm.device.deviceName))
                  ]),
                  _vm._v(" "),
                  _vm.event.inProgress && _vm.event.timeRemaining
                    ? _c(
                        "div",
                        { staticClass: "data-point gds-space-stack-m" },
                        [
                          _c("strong", [_vm._v("Time Remaining:")]),
                          _vm._v(_vm._s(_vm.event.timeRemaining))
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.event.dateRangeForManageParticipationModal
                    ? _c(
                        "div",
                        { staticClass: "data-point gds-space-stack-m" },
                        [
                          _c("strong", [_vm._v("Scheduled Time:")]),
                          _vm._v(
                            _vm._s(
                              _vm.event.dateRangeForManageParticipationModal
                            )
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "data-point gds-space-stack-m" }, [
                    _c("strong", [_vm._v("Event Participation:")]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.vm.enrollmentStatus))])
                  ])
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "gds-space-stack-m" },
              [
                _c("div", { staticClass: "data-point gds-space-stack-m" }, [
                  _vm._v("Change Event Participation")
                ]),
                _vm._v(" "),
                _c("DevicesSelectControl", {
                  attrs: {
                    config: _vm.vm.selectConfigEnrollment,
                    "option-selected": _vm.onOptionSelectedEnrollment
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("ActionButton", {
              attrs: {
                disabled: !_vm.vm.newOptOutDefined,
                text: "Save",
                "css-class": "manage-participation-modal",
                loading: _vm.vm.pendingSave,
                "button-clicked": _vm.onSaveButtonClicked
              }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }