var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gds-space-stack-l" }, [
    _c(
      "div",
      {
        staticClass:
          "\n    gds-space-stack-l\n    gds-flex-container\n    devices-max-width-600\n    gds-flex-container--left\n    devices-service-request-form__success-message"
      },
      [
        _c(
          "span",
          {
            staticClass:
              "devices-service-request-form__success-message__success-icon"
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "32",
                  height: "32",
                  viewBox: "0 0 32 32",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  staticStyle: {
                    fill: "color(display-p3 0.0000 0.2784 0.2157)",
                    "fill-opacity": "1"
                  },
                  attrs: {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M10 14L8 16L13.5 22L24 12L22 10L13.5 18L10 14Z",
                    fill: "#004737"
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "gds-font-book" }, [
          _vm._v(_vm._s(_vm.config.successMessage))
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gds-space-stack-s gds-font-demi gds-font-size-xl" },
      [_vm._v(_vm._s(_vm.config.header))]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "gds-space-stack-s devices-max-width-600" }, [
      _vm._v(_vm._s(_vm.config.subHeader))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }