<template>
  <div id="top-target" class="bill-pay-login__main gds-flex-container">
    <nav class="bill-pay-login__nav">
      <div class="outage-login__nav__inner-link-wrap">
        <router-link to="/login" class="bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button">Login</router-link>
        <router-link to="/login-account" class="bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button">Report with Account Number</router-link>
        <router-link to="/login-phone" class="bill-pay-login__nav-item bill-pay-login__nav-item--mobile-button">Report with Phone Number</router-link>
      </div>
      <div class="bill-pay-login__phone-address-section-desktop">
        <!-- I broke this out to a component so we don't have to have the same code 6 places -->
        <manual-reporting-box />
      </div>
    </nav>

    <div class="bill-pay-login__login-type-container">
      <section class="bill-pay-login__one-time-section">
        <h2 class="bill-pay-login__main-headline gds-display-2">Report an Outage with your Account Number</h2>
        <p class="gds-body-normal">
          You can also report an outage by
          <router-link to="/login">Logging In</router-link>&nbsp;or by looking up your account by
          <router-link to="/login-phone">Phone Number</router-link>.
        </p>
        <div v-if="errorWrongNumber" class="form-message-box form-message-box--full-width gds-space-stack-l">
          We were unable to locate an account that matched the information you provided. You can also report an outage by phone at
          <span class="gds-nobreak">{{GMPPhoneNumber}}.</span>
        </div>
        <form class="bill-pay-login__form" @submit.prevent="Login()">
          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Account Number</span>
              <input
                v-model="accountNumber"
                v-mask="'###########'"
                v-validate="'required|numeric|min:10|max:11'"
                name="account number"
                key="account number"
                class="gds-input-field__input"
                type="tel"
              />
              <div v-if="errors.first('account number')" class="validation-error">{{ errors.first("account number")}}</div>
            </label>
          </fieldset>

          <fieldset class="gds-fieldset gds-space-stack-m" :class="{ 'gds-has-error': errorWrongNumber }">
            <label class="gds-input-field">
              <span class="gds-input-field__label">Phone Number (Last 4 Digits)</span>
              <input v-model="phone" v-mask="'####'" v-validate="'required|numeric|min:4|max:4'" name="phone number" key="phone number" class="gds-input-field__input" type="tel" />
              <div v-if="errors.first('phone number')" class="validation-error">{{ errors.first("phone number")}}</div>
            </label>
            <div id="captcha-container"></div>
          </fieldset>

          <div class="gds-space-stack-l">
            <div v-if="errorLogin" id="one-time-submission_error" class="gds-helper-text-error">{{errorLogin}}</div>
          </div>

          <div class="gds-space-stack-l">
            <button :disabled="pendingLogin" :class="{ 'gds-loading': pendingLogin }" type="submit" class="gds-button gds-orange">
              <span class="gds-button__text">Next</span>
            </button>
          </div>
        </form>
      </section>
      <section class="bill-pay-login__phone-address-section-mobile">
        <manual-reporting-box />
      </section>
    </div>
  </div>
</template>

<script>
import ManualReportingBox from "./ManualReportingBox";
import { GMPPhoneNumber } from "../../environment"
import { DumpError, ValidateCaptcha } from "../../utilities";

export default {
  name: "ReportOutageLoginAccount",
  components: {
    ManualReportingBox,
  },
  data() {
    return {
      accountNumber: "",
      phone: "",
      errorLogin: undefined,
      errorWrongNumber: undefined,
      pendingLogin: false,
      GMPPhoneNumber,
    };
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    async Login() {
      if (!await this.$validator.validateAll()) return;
      this.errorLogin = undefined;
      this.errorWrongNumber = false;
      this.pendingLogin = true;
      try {
      // WEB-705 - START
      // To enable captchaToken, swap comments below
      // let captchaToken = await ValidateCaptcha();
      // if(!captchaToken) {
      //   throw { code: 999 };
      // }

      let captchaToken = undefined;
      // WEB-705 - END

        await this.$store.dispatch("LoginOTP", { accountNumber: this.accountNumber, phoneLast: this.phone, captchaToken: captchaToken });
        this.$router.push({ path: "/" });
      } catch (err) {
        DumpError("Account login error", err);
        if (err.response && err.response.data && err.response.data.errorCode === "INVALID_ACCOUNT_PHONE") {
          this.errorWrongNumber = true;
        } else if (err.code === 999 || (err.response && err.response.data && err.response.data.code === 15)) {
          this.errorLogin = "Bot detection failed. Please refresh the page and try again. If the problem persists, please contact us.";
        } else {
          this.errorLogin = "There was an error logging in. Please try again later.";
        }
      } finally {
        this.pendingLogin = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>