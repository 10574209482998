var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eicproduct-container" },
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "eicproduct__page-loader" }, [
            _c("img", {
              staticClass: "eicproduct__page-loader--svg",
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Rebates",
              state: "error",
              phonetext: "For further help, please call us at",
              closepath: "/electric-panel"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && _vm.isCommercialAccount
        ? _c(
            "div",
            [
              _c("ElectricPanelNotEligible", {
                attrs: { currentAccount: _vm.currentAccount }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && !_vm.isCommercialAccount
        ? _c("div", [
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-1" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("1")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    !_vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentAccount.nickname
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account Number: " +
                                                _vm._s(
                                                  _vm.currentAccount
                                                    .accountNumber
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(1)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 1
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 1 && _vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              electric panel grant submission."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    key: _vm.currentAccount.accountNumber,
                                    staticClass:
                                      "eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct__account-box gds-space-stack-l"
                                      },
                                      [
                                        _vm.currentAccount
                                          ? _c("span", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(1),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("titlecase")(
                                                              _vm.currentAccount
                                                                .lastName +
                                                                ", " +
                                                                _vm
                                                                  .currentAccount
                                                                  .firstName
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(2),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "addressFormat"
                                                            )(
                                                              _vm.currentAccount
                                                                .address,
                                                              {
                                                                separator: ", "
                                                              }
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(3),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentAccount
                                                              .accountNumber
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentPage === 1 && !_vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              electric panel submission."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct__account-box gds-space-stack-l"
                                  },
                                  [
                                    _vm.currentAccount
                                      ? _c("span", [
                                          _c("div", { staticClass: "row-m" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "account-info-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(4),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("titlecase")(
                                                            _vm.currentAccount
                                                              .lastName +
                                                              ", " +
                                                              _vm.currentAccount
                                                                .firstName
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(5),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "addressFormat"
                                                          )(
                                                            _vm.currentAccount
                                                              .address,
                                                            { separator: ", " }
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(6),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentAccount
                                                            .accountNumber
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.accounts.length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline accountInformation eicproduct__edit-account-details",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.chooseAccount()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "gds-button gds-text-button choose-account-button"
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "eicproduct__edit-icon-inline gds-icon"
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.accounts.length > 1
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Switch Account"
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-2" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("2")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(7)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(2)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 2
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 2
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "rebate-slap__inner-row" },
                                  [
                                    _c("div", [
                                      _c(
                                        "form",
                                        { attrs: { "data-vv-scope": "page2" } },
                                        [
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass:
                                                "gds-fieldset gds-space-stack-s"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-m"
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-input-field"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Have you already secured an electrician for your\n                          electrical\n                          upgrades?"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex-item margin-right"
                                                            },
                                                            [
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork
                                                                          .hasElectrician,
                                                                      expression:
                                                                        "productSubmission.electricalWork.hasElectrician"
                                                                    },
                                                                    {
                                                                      name:
                                                                        "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "eicproduct-input-field-l__select gds-input-field",
                                                                  attrs: {
                                                                    name:
                                                                      "electrician secured",
                                                                    autofocus:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal = Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function(
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function(
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .productSubmission
                                                                            .electricalWork,
                                                                          "hasElectrician",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleChangeHasElectrician()
                                                                      }
                                                                    ]
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          "",
                                                                        value:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Select..."
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "yes"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Yes"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "no"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "No"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "electrician secured",
                                                        "page2"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "electrician secured",
                                                                    "page2"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-m"
                                                },
                                                [
                                                  _vm.hasElectrician
                                                    ? _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field gds-space-stack-l"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Please enter the name of the Electrician that you are\n                          working with."
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .electricalWork
                                                                    .electricanName,
                                                                expression:
                                                                  "productSubmission.electricalWork.electricanName"
                                                              },
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "gds-input-field__input gds-space-stack-m",
                                                            attrs: {
                                                              name:
                                                                "electrician",
                                                              type: "text",
                                                              autocomplete:
                                                                "off",
                                                              placeholder:
                                                                "Type Here..."
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .electricalWork
                                                                  .electricanName
                                                            },
                                                            on: {
                                                              keyup: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectElectrician(
                                                                  $event
                                                                )
                                                              },
                                                              input: [
                                                                function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricalWork,
                                                                    "electricanName",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function(
                                                                  $event
                                                                ) {
                                                                  _vm.$emit(
                                                                    "touched",
                                                                    true
                                                                  )
                                                                  _vm.filterElectricians(
                                                                    $event
                                                                  )
                                                                }
                                                              ],
                                                              change: function(
                                                                $event
                                                              ) {
                                                                return _vm.filterElectricians(
                                                                  $event
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _vm.filters
                                                            .electrician &&
                                                          _vm.filters
                                                            .electrician
                                                            .length > 0
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "typeAhead-container"
                                                                },
                                                                _vm._l(
                                                                  _vm.filters
                                                                    .electrician,
                                                                  function(
                                                                    electrician
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          electrician.id,
                                                                        staticClass:
                                                                          "typeAhead-card gds-space-stack-m",
                                                                        class: {
                                                                          "active-dealership typeAhead-card gds-space-stack-m":
                                                                            _vm.currentElectrician ===
                                                                            electrician.id
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.setElectrician(
                                                                              electrician
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "typeAhead-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                electrician.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "electrician",
                                                            "page2"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "electrician",
                                                                        "page2"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _vm._v(" "),
                                              !_vm.hasElectrician
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-m"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-input-field"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Do you need help finding a qualified electrician?"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "gds-space-stack-l"
                                                            },
                                                            [
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork
                                                                          .needsElectricianHelp,
                                                                      expression:
                                                                        "productSubmission.electricalWork.needsElectricianHelp"
                                                                    },
                                                                    {
                                                                      name:
                                                                        "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "eicproduct-input-field__select",
                                                                  attrs: {
                                                                    name:
                                                                      "need help finding electrician",
                                                                    autofocus:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork,
                                                                        "needsElectricianHelp",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          "",
                                                                        value:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Select..."
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "yes"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Yes"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "no"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "No"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "need help finding electrician",
                                                            "page2"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "need help finding electrician",
                                                                        "page2"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-l"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Select one or more of the options below that best describe\n                        the\n                        reasons for upgrading your electrical system."
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.upgradeReasonItems,
                                                    function(item) {
                                                      return _c(
                                                        "div",
                                                        { key: item },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-checkbox gds-checkbox-flex gds-space-stack-l"
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .electricalWork
                                                                        .reasonForPanelUpgrade,
                                                                    expression:
                                                                      "productSubmission.electricalWork.reasonForPanelUpgrade"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  name:
                                                                    "upgrade reasons"
                                                                },
                                                                domProps: {
                                                                  value: item,
                                                                  checked: Array.isArray(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricalWork
                                                                      .reasonForPanelUpgrade
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork
                                                                          .reasonForPanelUpgrade,
                                                                        item
                                                                      ) > -1
                                                                    : _vm
                                                                        .productSubmission
                                                                        .electricalWork
                                                                        .reasonForPanelUpgrade
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork
                                                                          .reasonForPanelUpgrade,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c = $$el.checked
                                                                        ? true
                                                                        : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v = item,
                                                                        $$i = _vm._i(
                                                                          $$a,
                                                                          $$v
                                                                        )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .electricalWork,
                                                                            "reasonForPanelUpgrade",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .electricalWork,
                                                                            "reasonForPanelUpgrade",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork,
                                                                        "reasonForPanelUpgrade",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "gds-checkbox__faux eic-checkbox__faux-width"
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(item)
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "upgrade reasons",
                                                    "page2"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "upgrade reasons",
                                                                "page2"
                                                              )
                                                            ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "gds-space-stack-l"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Select one or more of the options below that describe the\n                        planned\n                        electrical upgrades."
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.workScopeItems,
                                                    function(item) {
                                                      return _c(
                                                        "div",
                                                        { key: item },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-checkbox gds-checkbox-flex"
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .electricalWork
                                                                        .selectedWorkItems,
                                                                    expression:
                                                                      "productSubmission.electricalWork.selectedWorkItems"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  name:
                                                                    "planned electrical upgrades"
                                                                },
                                                                domProps: {
                                                                  value: item,
                                                                  checked: Array.isArray(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricalWork
                                                                      .selectedWorkItems
                                                                  )
                                                                    ? _vm._i(
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork
                                                                          .selectedWorkItems,
                                                                        item
                                                                      ) > -1
                                                                    : _vm
                                                                        .productSubmission
                                                                        .electricalWork
                                                                        .selectedWorkItems
                                                                },
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    var $$a =
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork
                                                                          .selectedWorkItems,
                                                                      $$el =
                                                                        $event.target,
                                                                      $$c = $$el.checked
                                                                        ? true
                                                                        : false
                                                                    if (
                                                                      Array.isArray(
                                                                        $$a
                                                                      )
                                                                    ) {
                                                                      var $$v = item,
                                                                        $$i = _vm._i(
                                                                          $$a,
                                                                          $$v
                                                                        )
                                                                      if (
                                                                        $$el.checked
                                                                      ) {
                                                                        $$i <
                                                                          0 &&
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .electricalWork,
                                                                            "selectedWorkItems",
                                                                            $$a.concat(
                                                                              [
                                                                                $$v
                                                                              ]
                                                                            )
                                                                          )
                                                                      } else {
                                                                        $$i >
                                                                          -1 &&
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .electricalWork,
                                                                            "selectedWorkItems",
                                                                            $$a
                                                                              .slice(
                                                                                0,
                                                                                $$i
                                                                              )
                                                                              .concat(
                                                                                $$a.slice(
                                                                                  $$i +
                                                                                    1
                                                                                )
                                                                              )
                                                                          )
                                                                      }
                                                                    } else {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .electricalWork,
                                                                        "selectedWorkItems",
                                                                        $$c
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", {
                                                                staticClass:
                                                                  "gds-checkbox__faux eic-checkbox__faux-width"
                                                              }),
                                                              _vm._v(" "),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(item)
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.errors.first(
                                                    "planned electrical upgrades",
                                                    "page2"
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "validation-error"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "planned electrical upgrades",
                                                                "page2"
                                                              )
                                                            ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("2", 3)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-3" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("3")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(8)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(3)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 3
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 3
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "form",
                                      { attrs: { "data-vv-scope": "page3" } },
                                      [
                                        _c("div", { staticClass: "row-s" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "gds-space-stack-m"
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Do you own the property where the electrical work will be\n                        done?"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .householdInfo
                                                                  .isAccountHolderPropertyOwner,
                                                              expression:
                                                                "productSubmission.householdInfo.isAccountHolderPropertyOwner"
                                                            },
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "eicproduct-input-field__select",
                                                          attrs: {
                                                            name:
                                                              "property owner",
                                                            autofocus: ""
                                                          },
                                                          on: {
                                                            change: [
                                                              function($event) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  _vm
                                                                    .productSubmission
                                                                    .householdInfo,
                                                                  "isAccountHolderPropertyOwner",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                              _vm.handleIsPropertyContactChange
                                                            ]
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                disabled: "",
                                                                value: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Select..."
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("option", [
                                                            _vm._v("Yes")
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("option", [
                                                            _vm._v("No")
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.errors.first(
                                                "property owner",
                                                "page3"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "property owner",
                                                            "page3"
                                                          )
                                                        ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Property Owner Contact"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .productSubmission
                                                                .propertyOwner
                                                                .contactName,
                                                            expression:
                                                              "productSubmission.propertyOwner.contactName"
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: "required",
                                                            expression:
                                                              "'required'"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "gds-input-field__input",
                                                        attrs: {
                                                          name:
                                                            "property owner contact",
                                                          type: "text",
                                                          placeholder:
                                                            "Type Here..."
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .propertyOwner
                                                              .contactName
                                                        },
                                                        on: {
                                                          input: [
                                                            function($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm
                                                                  .productSubmission
                                                                  .propertyOwner,
                                                                "contactName",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.$emit(
                                                                "touched",
                                                                true
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.errors.first(
                                                "property owner contact",
                                                "page3"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "property owner contact",
                                                            "page3"
                                                          )
                                                        ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "gds-input-field__label"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Is the property being upgraded a single or multi-family home?"
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .productSubmission
                                                                  .electricalWork
                                                                  .housingType,
                                                              expression:
                                                                "productSubmission.electricalWork.housingType"
                                                            },
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "eicproduct-input-field__select",
                                                          attrs: {
                                                            name:
                                                              "housing type",
                                                            autofocus: ""
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                _vm
                                                                  .productSubmission
                                                                  .electricalWork,
                                                                "housingType",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                disabled: "",
                                                                value: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Select..."
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("option", [
                                                            _vm._v(
                                                              "single family"
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("option", [
                                                            _vm._v(
                                                              "multi-family"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.errors.first(
                                                "housing type",
                                                "page3"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "housing type",
                                                            "page3"
                                                          )
                                                        ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isMultiFamilyHome
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-input-field"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "How many moderate income housing units will be\n                        upgraded?"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricalWork
                                                                      .numberOfModerateIncomeHousingUnits,
                                                                  expression:
                                                                    "productSubmission.electricalWork.numberOfModerateIncomeHousingUnits"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required",
                                                                  expression:
                                                                    "'required'"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "eicproduct-input-field__select",
                                                              attrs: {
                                                                name:
                                                                  "numberOfModerateIncomeHousingUnits",
                                                                "data-vv-as":
                                                                  "Number of Moderate Income Housing Units"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal = Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricalWork,
                                                                    "numberOfModerateIncomeHousingUnits",
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    disabled:
                                                                      "",
                                                                    selected: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Select a value"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.numberOptions,
                                                                function(n) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: n,
                                                                      domProps: {
                                                                        value: n
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            n
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.errors.first(
                                                "moderate income housing units",
                                                "page3"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "eicproduct-validation-error",
                                                      staticStyle: {
                                                        "max-width": "80%"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "moderate income housing units",
                                                            "page3"
                                                          )
                                                        ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.isMultiFamilyHome
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-input-field"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "How many low income housing units will be\n                        upgraded?"
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricalWork
                                                                      .numberOfLowIncomeHousingUnits,
                                                                  expression:
                                                                    "productSubmission.electricalWork.numberOfLowIncomeHousingUnits"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required",
                                                                  expression:
                                                                    "'required'"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "eicproduct-input-field__select",
                                                              attrs: {
                                                                name:
                                                                  "numberOfLowIncomeHousingUnits",
                                                                "data-vv-as":
                                                                  "Number of Low Income Housing Units"
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal = Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .electricalWork,
                                                                    "numberOfLowIncomeHousingUnits",
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    disabled:
                                                                      "",
                                                                    selected: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Select a value"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.numberOptions,
                                                                function(n) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: n,
                                                                      domProps: {
                                                                        value: n
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            n
                                                                          ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.errors.first(
                                                "low income housing units",
                                                "page3"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "eicproduct-validation-error",
                                                      staticStyle: {
                                                        "max-width": "80%"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "low income housing units",
                                                            "page3"
                                                          )
                                                        ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _vm._m(9),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gds-space-stack-ml"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [_vm._v("Country")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .account
                                                            .mailingAddress
                                                            .country,
                                                        expression:
                                                          "productSubmission.account.mailingAddress.country"
                                                      }
                                                    ],
                                                    class:
                                                      _vm.productSubmission
                                                        .account.mailingAddress
                                                        .country === "USA"
                                                        ? "eicproduct-input-field__select-country-us gds-input-field"
                                                        : _vm.productSubmission
                                                            .account
                                                            .mailingAddress
                                                            .country === "CAN"
                                                        ? "eicproduct-input-field__select-country-ca gds-input-field"
                                                        : "eicproduct-input-field__select-s gds-input-field",
                                                    attrs: { autofocus: "" },
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .account
                                                            .mailingAddress,
                                                          "country",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          selected: "",
                                                          "data-thumbnail":
                                                            "/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg"
                                                        },
                                                        domProps: {
                                                          value: "USA"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "United States\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "CAN" }
                                                      },
                                                      [_vm._v("Canada")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "FOR" }
                                                      },
                                                      [_vm._v("Foreign")]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                !_vm.productSubmission.account
                                                  .mailingAddress.country
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "country",
                                                                "page3"
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gds-space-stack-ml"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [_vm._v("Street Address")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productSubmission
                                                          .account
                                                          .mailingAddress
                                                          .street1,
                                                      expression:
                                                        "productSubmission.account.mailingAddress.street1"
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "eicproduct-input-field gds-input-field__input",
                                                  attrs: {
                                                    name: "street address",
                                                    type: "text",
                                                    placeholder: "Type Here..."
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.productSubmission
                                                        .account.mailingAddress
                                                        .street1
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .account
                                                            .mailingAddress,
                                                          "street1",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.$emit(
                                                          "touched",
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "street address",
                                                  "page3"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "street address",
                                                              "page3"
                                                            )
                                                          ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "gds-space-stack-ml"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [_vm._v("Suite Apt")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productSubmission
                                                          .account
                                                          .mailingAddress
                                                          .street2,
                                                      expression:
                                                        "productSubmission.account.mailingAddress.street2"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "eicproduct-input-field gds-input-field__input",
                                                  attrs: {
                                                    name: "street 2",
                                                    type: "text",
                                                    placeholder: "Type Here..."
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.productSubmission
                                                        .account.mailingAddress
                                                        .street2
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .account
                                                            .mailingAddress,
                                                          "street2",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.$emit(
                                                          "touched",
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row-l" }, [
                                          _c(
                                            "div",
                                            { staticClass: "flex-item-l" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "gds-input-field-flex"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [_vm._v("City")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .productSubmission
                                                                .account
                                                                .mailingAddress
                                                                .city,
                                                            expression:
                                                              "productSubmission.account.mailingAddress.city"
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value: "required",
                                                            expression:
                                                              "'required'"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "gds-input-field__input",
                                                        attrs: {
                                                          name: "city",
                                                          type: "text",
                                                          placeholder:
                                                            "Type Here..."
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .account
                                                              .mailingAddress
                                                              .city
                                                        },
                                                        on: {
                                                          input: [
                                                            function($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm
                                                                  .productSubmission
                                                                  .account
                                                                  .mailingAddress,
                                                                "city",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.$emit(
                                                                "touched",
                                                                true
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "city",
                                                        "page3"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "city",
                                                                    "page3"
                                                                  )
                                                                ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "flex-item-s gds-space-stack-m"
                                            },
                                            [
                                              _vm.productSubmission.account
                                                .mailingAddress.country ===
                                              "USA"
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-input-field"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [_vm._v("State")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .account
                                                                      .mailingAddress
                                                                      .state,
                                                                  expression:
                                                                    "productSubmission.account.mailingAddress.state"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "eicproduct-input-field__select gds-input-field",
                                                              on: {
                                                                change: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal = Array.prototype.filter
                                                                      .call(
                                                                        $event
                                                                          .target
                                                                          .options,
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          return o.selected
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          var val =
                                                                            "_value" in
                                                                            o
                                                                              ? o._value
                                                                              : o.value
                                                                          return val
                                                                        }
                                                                      )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .account
                                                                        .mailingAddress,
                                                                      "state",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "touched",
                                                                      true
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    disabled:
                                                                      "",
                                                                    selected: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Select State..."
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.stateList,
                                                                function(
                                                                  state
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: state,
                                                                      domProps: {
                                                                        value: state
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          state
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _vm._v(" "),
                                                          !_vm.productSubmission
                                                            .account
                                                            .mailingAddress
                                                            .state
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "state",
                                                                        "page3"
                                                                      )
                                                                    ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.productSubmission.account
                                                .mailingAddress.country ===
                                              "CAN"
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "gds-input-field"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [_vm._v("Province")]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .account
                                                                      .mailingAddress
                                                                      .state,
                                                                  expression:
                                                                    "productSubmission.account.mailingAddress.state"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "eicproduct-input-field__select gds-input-field",
                                                              on: {
                                                                change: [
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal = Array.prototype.filter
                                                                      .call(
                                                                        $event
                                                                          .target
                                                                          .options,
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          return o.selected
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          var val =
                                                                            "_value" in
                                                                            o
                                                                              ? o._value
                                                                              : o.value
                                                                          return val
                                                                        }
                                                                      )
                                                                    _vm.$set(
                                                                      _vm
                                                                        .productSubmission
                                                                        .account
                                                                        .mailingAddress,
                                                                      "state",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  },
                                                                  function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$emit(
                                                                      "touched",
                                                                      true
                                                                    )
                                                                  }
                                                                ]
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    disabled:
                                                                      "",
                                                                    selected: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Select Province..."
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.provinceList,
                                                                function(
                                                                  province
                                                                ) {
                                                                  return _c(
                                                                    "option",
                                                                    {
                                                                      key: province,
                                                                      domProps: {
                                                                        value: province
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          province
                                                                        ) +
                                                                          "\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _vm._v(" "),
                                                          !_vm.productSubmission
                                                            .account
                                                            .mailingAddress
                                                            .state
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "province",
                                                                        "page3"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "flex-item-m" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "gds-input-field"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "gds-input-field__label"
                                                        },
                                                        [_vm._v("Zipcode")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .productSubmission
                                                                .account
                                                                .mailingAddress
                                                                .zip,
                                                            expression:
                                                              "productSubmission.account.mailingAddress.zip"
                                                          },
                                                          {
                                                            name: "validate",
                                                            rawName:
                                                              "v-validate",
                                                            value:
                                                              "required|numeric|length:5",
                                                            expression:
                                                              "'required|numeric|length:5'"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "gds-input-field__input eicproduct-mailing-zip",
                                                        attrs: {
                                                          name: "zipcode",
                                                          type: "text",
                                                          placeholder:
                                                            "Type Here...",
                                                          autocomplete:
                                                            "postal-code"
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .account
                                                              .mailingAddress
                                                              .zip
                                                        },
                                                        on: {
                                                          input: [
                                                            function($event) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                _vm
                                                                  .productSubmission
                                                                  .account
                                                                  .mailingAddress,
                                                                "zip",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.$emit(
                                                                "touched",
                                                                true
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _vm.errors.first(
                                                        "zipcode",
                                                        "page3"
                                                      )
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "validation-error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.errors.first(
                                                                    "zipcode",
                                                                    "page3"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              !_vm.productSubmission.account
                                                .mailingAddress.zip &&
                                              _vm.productSubmission.account
                                                .mailingAddress.country ===
                                                "USA"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "zip",
                                                            "page3"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.productSubmission.account
                                                .mailingAddress.zip &&
                                              _vm.productSubmission.account
                                                .mailingAddress.country ===
                                                "CAN"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "eicproduct-validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "canadianZip",
                                                            "page3"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("3", 4)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _vm.isSingleFamilyHome
              ? _c(
                  "section",
                  {
                    staticClass: "eicproduct-section",
                    attrs: { id: "eicproduct-slat-4" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat gds-slat gds-slat--active"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header gds-slat__header"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "eicproduct-slat__header-left gds-flex-container"
                              },
                              [
                                _vm.currentPage <= 4
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct-slat__number-with-circle"
                                      },
                                      [_c("span", [_vm._v("4")])]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.currentPage > 4
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct-slat__green-check-circle"
                                      },
                                      [
                                        _c("svg", { staticClass: "gds-icon" }, [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._m(10)
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "eicproduct-slat__header-right gds-flex-container"
                              },
                              [
                                _vm.currentPage > 4
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoPage(4)
                                              }
                                            }
                                          },
                                          [_vm._v("Edit")]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.currentPage < 4
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct-slat__lock-icon"
                                      },
                                      [
                                        _c("svg", { staticClass: "gds-icon" }, [
                                          _c("use", {
                                            attrs: {
                                              "xlink:href":
                                                "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.currentPage === 4
                          ? _c(
                              "div",
                              { staticClass: "eicproduct-slat__contents" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__contents-inner"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "gds-space-stack-l" },
                                      [
                                        _c(
                                          "form",
                                          {
                                            attrs: { "data-vv-scope": "page4" }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row-s" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-m"
                                                  },
                                                  [
                                                    !_vm.isPremiseCountyFound
                                                      ? _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "gds-input-field"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "gds-input-field__label"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "What county does your home reside in?"
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "gds-space-stack-ml"
                                                              },
                                                              [
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          _vm
                                                                            .productSubmission
                                                                            .account
                                                                            .premiseAddress
                                                                            .county,
                                                                        expression:
                                                                          "productSubmission.account.premiseAddress.county"
                                                                      },
                                                                      {
                                                                        name:
                                                                          "validate",
                                                                        rawName:
                                                                          "v-validate",
                                                                        value:
                                                                          "required",
                                                                        expression:
                                                                          "'required'"
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "eicproduct-input-field__select",
                                                                    attrs: {
                                                                      name:
                                                                        "county",
                                                                      autofocus:
                                                                        ""
                                                                    },
                                                                    on: {
                                                                      change: [
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .account
                                                                              .premiseAddress,
                                                                            "county",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        _vm.handleCountyChange
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "undefined",
                                                                          disabled:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Select..."
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _vm._l(
                                                                      _vm.countyList,
                                                                      function(
                                                                        county
                                                                      ) {
                                                                        return _c(
                                                                          "option",
                                                                          {
                                                                            key: county
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                county
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.errors.first(
                                                              "county",
                                                              "page4"
                                                            )
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "validation-error"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.errors.first(
                                                                          "county",
                                                                          "page4"
                                                                        )
                                                                      ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "How many people live in your home?"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-space-stack-ml"
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .householdInfo
                                                                        .numberInHousehold,
                                                                    expression:
                                                                      "productSubmission.householdInfo.numberInHousehold"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "eicproduct-input-field__select",
                                                                attrs: {
                                                                  name:
                                                                    "number in household",
                                                                  autofocus: ""
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .householdInfo,
                                                                        "numberInHousehold",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.buildIncomeDropDown()
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        "",
                                                                      value:
                                                                        "undefined"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Select..."
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  _vm.numberInHousehold,
                                                                  function(
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "option",
                                                                      {
                                                                        key: index,
                                                                        domProps: {
                                                                          value: item
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item
                                                                          ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "number in household",
                                                          "page4"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.errors.first(
                                                                      "number in household",
                                                                      "page4"
                                                                    )
                                                                  ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Gross Household Income in " +
                                                                _vm._s(
                                                                  _vm.lastYear
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.selectedIncome,
                                                                expression:
                                                                  "selectedIncome"
                                                              },
                                                              {
                                                                name:
                                                                  "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'"
                                                              }
                                                            ],
                                                            key:
                                                              "gross household income",
                                                            staticClass:
                                                              "eicproduct-input-field__select gds-space-stack-ml",
                                                            attrs: {
                                                              autofocus: "",
                                                              name:
                                                                "gross household income",
                                                              disabled: !_vm
                                                                .productSubmission
                                                                .householdInfo
                                                                .numberInHousehold
                                                                ? true
                                                                : false
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.selectedIncome = $event
                                                                  .target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              }
                                                            }
                                                          },
                                                          _vm._l(
                                                            _vm.incomeAmounts,
                                                            function(
                                                              option,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: index,
                                                                  domProps: {
                                                                    value: option
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      option.incomeOption
                                                                    ) +
                                                                      "\n                            "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "gross household income",
                                                          "page4"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "eicproduct-validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      _vm.errors.first(
                                                                        "gross household income",
                                                                        "page4"
                                                                      )
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "eicproduct__method-next-btn gds-button gds-compact",
                                            on: {
                                              click: function($event) {
                                                return _vm.finishPage("4", 5)
                                              }
                                            }
                                          },
                                          [_vm._v("Next")]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-5" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.supportingDocumentsSlatNumber
                                        )
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-slat__title-wrapper" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__title gds-slat__title"
                                  },
                                  [_vm._v("Supporting Documents")]
                                ),
                                _vm._v(" "),
                                _vm.showFileLoadTooBigModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "fileLoadTooBig",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Your total file size is " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.totalFileSize
                                          ) +
                                          ". Please make sure the combined size of all uploaded files is less than " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.maxFileLoadSize
                                          ) +
                                          "."
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showDuplicateFilenameModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "duplicateFilename",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Each file that is uploaded must have a unique file name."
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _vm.documents.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.documents.length +
                                                  "\n                Files Uploaded"
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.documents.length === 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  this.documents.length +
                                                    " File Uploaded"
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(5)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 5
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 5
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c("div", { staticClass: "gds-space-stack-m" }, [
                              _c(
                                "form",
                                { attrs: { "data-vv-scope": "page5" } },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "gds-input-field" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "gds-input-field__label"
                                        },
                                        [
                                          _vm._v(
                                            "Do you have any documents such as quotes that you would\n                  like to upload?"
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "gds-space-stack-ml" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productSubmission
                                                      .electricalWork
                                                      .haveSupportingDocuments,
                                                  expression:
                                                    "productSubmission.electricalWork.haveSupportingDocuments"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              staticClass:
                                                "eicproduct-input-field__select",
                                              attrs: {
                                                name: "supporting documents",
                                                autofocus: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.productSubmission
                                                      .electricalWork,
                                                    "haveSupportingDocuments",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    disabled: "",
                                                    value: ""
                                                  }
                                                },
                                                [_vm._v("Select...")]
                                              ),
                                              _vm._v(" "),
                                              _c("option", [_vm._v("Yes")]),
                                              _vm._v(" "),
                                              _c("option", [_vm._v("No")])
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.errors.first(
                                            "supporting documents",
                                            "page5"
                                          )
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "validation-error"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errors.first(
                                                        "supporting documents",
                                                        "page5"
                                                      )
                                                    ) + "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "slat-title-wrapper" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slat__contents-inner"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "gds-space-stack-l"
                                              },
                                              [
                                                _vm.haveSupportingDocuments
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "slat-title-wrapper"
                                                      },
                                                      [
                                                        _c("form", [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "fileUploader"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "File-Uploader",
                                                                {
                                                                  ref:
                                                                    "fileUploader",
                                                                  attrs: {
                                                                    uploadDocumentList:
                                                                      _vm.uploadDocumentList,
                                                                    docTypes:
                                                                      _vm.documentTypes,
                                                                    allowCombined: false,
                                                                    headingTitle:
                                                                      "Please provide any supporting documents"
                                                                  },
                                                                  on: {
                                                                    "file-list-changed":
                                                                      _vm.updateFileList
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "eicproduct__method-next-btn gds-button gds-compact",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.finishPage(
                                                          "5",
                                                          6
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Done")]
                                                ),
                                                _vm._v(" "),
                                                _vm.haveSupportingDocuments
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "eicproduct__method-next-btn gds-button gds-compact",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.addFile()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Upload Another File"
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-6" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.reviewAndSubmitSlatNumber)
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(11)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage < 6
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 6
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c("div", [
                                      _vm._m(12),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "gds-input-field" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "gds-space-stack-m"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("Email Address")]
                                              ),
                                              _vm._v(" "),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productSubmission
                                                        .propertyOwner
                                                        .confirmationEmailAddress,
                                                    expression:
                                                      "productSubmission.propertyOwner.confirmationEmailAddress"
                                                  },
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value:
                                                      "required|email|max:74",
                                                    expression:
                                                      "'required|email|max:74'"
                                                  }
                                                ],
                                                ref: "email",
                                                staticClass:
                                                  "eicproduct-input-field gds-input-field__input",
                                                attrs: {
                                                  name: "email address",
                                                  type: "email",
                                                  placeholder:
                                                    "Enter email address",
                                                  autocomplete: "email"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.productSubmission
                                                      .propertyOwner
                                                      .confirmationEmailAddress
                                                },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.productSubmission
                                                          .propertyOwner,
                                                        "confirmationEmailAddress",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.$emit(
                                                        "touched",
                                                        true
                                                      )
                                                    }
                                                  ]
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.first(
                                                "email address",
                                                "page6"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "email address",
                                                            "page6"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { staticClass: "gds-input-field" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "gds-space-stack-m"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "gds-input-field__label"
                                                },
                                                [_vm._v("Phone Number")]
                                              ),
                                              _vm._v(" "),
                                              _c("the-mask", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required|min:10",
                                                    expression:
                                                      "'required|min:10'"
                                                  }
                                                ],
                                                staticClass:
                                                  "eicproduct-input-field gds-input-field__input",
                                                attrs: {
                                                  mask: "+1 (###) ###-####",
                                                  "data-vv-validate-on":
                                                    "input",
                                                  name: "phone number",
                                                  type: "tel",
                                                  placeholder:
                                                    "Your phone number"
                                                },
                                                nativeOn: {
                                                  input: function($event) {
                                                    _vm.touched = true
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.productSubmission
                                                      .propertyOwner
                                                      .contactPhoneNumber,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .propertyOwner,
                                                      "contactPhoneNumber",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "productSubmission.propertyOwner.contactPhoneNumber"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.first(
                                                "phone number",
                                                "page6"
                                              )
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "validation-error"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "phone number",
                                                            "page6"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "gds-space-stack-m" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gds-checkbox gds-checkbox-thin"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.regulatoryDataSharing,
                                                  expression:
                                                    "regulatoryDataSharing"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                name: "regulatory data sharing"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.regulatoryDataSharing
                                                )
                                                  ? _vm._i(
                                                      _vm.regulatoryDataSharing,
                                                      null
                                                    ) > -1
                                                  : _vm.regulatoryDataSharing
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.regulatoryDataSharing,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.regulatoryDataSharing = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.regulatoryDataSharing = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.regulatoryDataSharing = $$c
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass:
                                                "gds-checkbox__faux eic-checkbox__faux-width"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-font-size-ml regulatory-text1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.REGULATORY_REBATE_SHARING_TEXT1
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "gds-font-size-ml regulatory-text2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.REGULATORY_REBATE_SHARING_TEXT2
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.errors.first(
                                              "regulatory data sharing",
                                              "page6"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "regulatory data sharing",
                                                          "page6"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-space-stack-ml eicproduct__method-next-btn gds-button gds-compact",
                                            attrs: {
                                              type: "submit",
                                              disabled:
                                                !_vm.regulatoryDataSharing ||
                                                _vm.errors.any()
                                            },
                                            on: { click: _vm.handleSubmit }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Submit Upgrade Request\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-button [modifier class] gds-loading"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-button__text"
                                              },
                                              [_vm._v("Submit Upgrade Request")]
                                            ),
                                            _vm._v(" "),
                                            _c("figure", {
                                              staticClass: "gds-button__icon"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Account Information")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Electrical Work Description")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Property Information")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slat-title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-title gds-space-stack-l" }, [
        _vm._v(
          "Please update your mailing address if it is different from your home's address?"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Household Income")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Review & Submit")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slat-title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-title gds-space-stack-xl" }, [
        _vm._v(
          "Please verify that your contact information is\n                    accurate in case we need additional information."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }