var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "make-payment-container" }, [
    _c(
      "div",
      { staticClass: "eicproduct-success-fail", attrs: { id: "top-target" } },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "h2",
          {
            staticClass:
              "gds-display-2 gds-align--text-center gds-space-stack-ml"
          },
          [_vm._v("Thank you!")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "gds-font-size-l gds-space-stack-l successBody" },
          [
            _vm._v(
              "We have your received your electric panel grant request and will contact you shortly!"
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "rebate-success__button-wrap gds-align--text-center" },
          [
            _c(
              "button",
              {
                staticClass: "eicproduct-done__button gds-button",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.ReturnToSender()
                  }
                }
              },
              [_vm._v("Done")]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "eicproduct-success__intro-image" }, [
      _c("img", {
        staticClass: "my-account__payment-plan-icon",
        attrs: {
          src:
            "/wp-content/themes/gmptwentynineteen/assets/images/panel-upgrade-funding-success.svg",
          alt: "electric panel submission success"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }