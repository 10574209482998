<template>
  <div>
    <div v-if="loadState === undefined" class="table-loading" />
    <flow-error v-if="loadState === 'error'" name="Information for the specified incident" state="error" />
    <div v-if="loadState === 'complete'">
      <outage-breadcrumbs :incident="incident" />
      <div class="town-incident__row gds-flex-container" :class="{ collapsed: detailCollapsed }">
        <div class="town-incident__column--details incident-details">
          <!-- Note: the isPlanned flag is older and may not be used. Theoretically, planned incidents show up in the planned outage calls instead of here -->
          <div v-if="incident.isPlanned && FF_PlannedOutage" class="gds-flex-container gds-flex-container--left gds-space-stack-m">
            <div class="planned-outage__top-label-and-icon gds-font-size-l">Planned Outage</div>
          </div>
          <div v-else-if="!incident.isPlanned && incidentStatus === 'Resolved'"
            class="gds-flex-container gds-flex-container--left gds-space-stack-m">
            <div class="outage-no-icon"></div>
            <div class="gds-font-size-l">Resolved Outage Incident</div>
          </div>
          <div v-else class="gds-flex-container gds-flex-container--left gds-space-stack-m">
            <div class="outage-yes-icon"></div>
            <div class="gds-font-size-l">Active Incident</div>
          </div>

          <div class="town-incident__street-name gds-text--capitalize gds-font-size-xxl">
            {{ incident.street ? incident.street.toLowerCase() : "" }} {{ incident.counter }}
            <br />
            {{ incident.town }}, VT
          </div>

          <!-- Messages List -->
          <div v-if="messagesExist && FF_OutagesMessaging" class="town-incident__single-incident-wrapper">
            <h5>Messages</h5>
            <!-- When hidden -->
            <section v-if="!expandMessageList" class="outage-messages__detail-button" @click="gotoMessageDetail(messagesArray[0].messageId, incidentid)">
              <div class="outage-messages__flex-heading">
                <span><b>{{ getPreviewText(messagesArray[0].title, 15) }}</b></span>
                <span class="gds-text-grey">{{ formatMessageTime(messagesArray[0].sendAt ? messagesArray[0].sendAt : messagesArray[0].createdAt) }}</span>
              </div>
              <span>{{ getPreviewText(messagesArray[0].fullMessage, 30) }}</span>
              <a v-if="getNumberWords(messagesArray[0].fullMessage) > 30" class="outage-messages__collapsible-menu" title="click to see more">More</a>
            </section>
            <!-- When showing all -->
            <div v-for="message of messagesArray" :key="message.messageId">
              <section v-if="expandMessageList" class="outage-messages__detail-button" @click="gotoMessageDetail(message.messageId, incidentid)">
                <div class="outage-messages__flex-heading">
                  <span><b>{{ getPreviewText(message.title, 15) }}</b></span>
                  <span class="gds-text-grey">{{ formatMessageTime(message.sendAt ? message.sendAt : message.createdAt) }}</span>
                </div>
                <span v-html="getPreviewText(message.fullMessage, 30)"></span>
                <a v-if="getNumberWords(message.fullMessage) > 30" class="outage-messages__collapsible-menu" title="click to see more">More</a>
              </section>
            </div>
            <!-- Toggle hide/show -->
            <button v-if="messagesArray.length > 1" @click="toggleViewMessages()" class="outage-messages__collapsible-menu">{{ expandMessageList ? 'Hide All' : `View All (${ messagesArray.length })` }}</button>
          </div>

          <div class="town-incident__single-incident-wrapper gds-font-size-l gds-font-demi">
            <incident-progress :incident="incident" />
          </div>

          <div v-if="myAffectedAccount" class="town-incident__affected-account">
            <div class="gds-font-size-l gds-font-demi gds-space-stack-m">Your affected location</div>
            <div class="gds-flex-container gds-flex-container--left">
              <img src="/wp-content/themes/gmptwentynineteen/assets/images/icon-home-circle-alert.svg"
                class="town-incident__column--details--home-icon" />
              <div class="gds-linebreaks gds-font-size-m gds-font-demi">{{ myAffectedAccount.address | addressFormat({
                  separator: "\n"
                })
              }}</div>
            </div>
          </div>

          <div v-if="affectedTowns" class="town-incident__affected-towns gds-font-size-l gds-font-demi">
            This {{ incident.isPlanned && FF_PlannedOutage ? 'planned outage' : 'incident' }}
            {{ incidentStatus === 'Resolved' ? "affected" : "is affecting" }}
            {{ incident.customerCount }} customer{{ incident.customerCount > 1 ? 's' : '' }}
            {{ myAffectedAccount ? "(including you)" : '' }}
            in {{ affectedTowns.length > 1 ? (affectedTowns.length + ' towns') : incident.town }}.
          </div>
          <div v-if="affectedTowns && affectedTowns.length >= 1">
            <div v-for="town of affectedTowns" :key="town.name" class="town-incident__affected-towns-list">
              <div class="gds-flex-container gds-flex-container--space-between">
                <router-link :to="{ name: 'Town', params: { townname: town.slug } }">{{ town.name }}</router-link>
                <div class="gds-font-size-m">{{ town.customers }} customer{{ town.customers > 1 ? 's' : '' }}</div>
              </div>
            </div>
          </div>
          <div v-if="incidentStatus === 'Resolved'"
            class="resolved-outage-info-box gds-flex-container gds-flex-container--top">
            <img src="/wp-content/themes/gmptwentynineteen/assets/images/icon-incident-orange.svg"
              class="resolved-incident-icon" />
            <div>
              <div class="gds-font-demi gds-font-size-l gds-space-stack-s">Power Still Out?</div>
              <p>If your power is still out please check your circuit breaker. Sometimes the power is restored, but
                requires restarting the breaker to come back on. If this still does not resolve the issue, please report
                another outage.</p>

              <a href="/report-outage" class="report_outage_btn gds-button gds-round gds-secondary gds-compact">
                <span class="gds-button__text">Report Outage</span>
              </a>
            </div>
          </div>
        </div>
        <div class="town-incident__column--map gds-position--relative">
          <button @click="ToggleCollapsed" :class="{ collapsed: detailCollapsed }"
            class="incident-row-collapse-toggle incident-page"
            :title="(detailCollapsed ? 'View' : 'Close') + ' Incident Details'">
            <span class="incident-row-collapse-toggle--text">View Incident Details</span>
          </button>
          <gmp-map ref="map" kind="incident" :incidentId="incidentid" @centered="isCentered = $event"
            @update="onMapUpdate" />
          <div v-if="!isCentered" @click="Recenter()" :class="{ collapsed: detailCollapsed }" id="recenter-button"
            class="recenter-button incident-recenter gds-button gds-round gds-secondary">Back to incident</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EnvironmentConstants } from "../../environment";
import GMPAPI from "../../services/gmpapi";
import { DumpError, GetOutageStatusLabel, HyphenateTown, InitialCaps, ReplaceHTML } from "../../utilities";
import GmpMap from "./GmpMap.vue";
import IncidentProgress from "./IncidentProgress.vue";
import OutageBreadcrumbs from "./OutageBreadcrumbs.vue";
import { isFeatureEnabled } from "../../services/featureflags";
import { format, parseISO, isToday } from "date-fns";

export default {
  name: "SubpageIncidentView",
  components: {
    GmpMap,
    IncidentProgress,
    OutageBreadcrumbs,
  },
  computed: {
    incidentid() {
      return Number(this.$route.params.incidentid);
    },
    incidentStatus() {
      return GetOutageStatusLabel(this.incident.status.code);
    },
  },
  data() {
    return {
      FF_PlannedOutage: undefined,
      FF_OutagesMessaging: undefined,
      incident: undefined,
      myAffectedAccount: undefined,
      affectedTowns: undefined,
      isCentered: true,
      detailCollapsed: false,
      refreshTimer: undefined,
      loadState: undefined,
      expandMessageList: undefined,
      messagesArray: undefined,
      messagesExist: undefined
    };
  },
  async mounted() {
    this.loadState = undefined;
    await this.getConfigs();
    try {
      // Fire the refresher without the error handler
      await this.RefreshData();
      // Start the refresh timer with the handler
      this.refreshTimer = setTimeout(() => { this.RefreshWithHandler() }, EnvironmentConstants.OutageRefreshIntervalMS);
      this.loadState = "complete";
    } catch (err) {
      DumpError("Incident load error", err);
      this.loadState = "error";
    }
  },
  // If this component is destroyed, stop the refresh timer
  destroyed() {
    clearTimeout(this.refreshTimer);
  },
  methods: {
    async getConfigs() {
      this.FF_PlannedOutage = await isFeatureEnabled('FF_PlannedOutage', false);
      this.FF_OutagesMessaging = await isFeatureEnabled('FF_OutagesMessaging', false);
    },
    // Note that this page needs its own refresh timer (in addition to the one in GmpMap)
    // This is because we poll the specific incident directly, which needs to be refreshed
    async RefreshWithHandler() {
      try {
        await this.RefreshData();
      } catch (err) {
        DumpError("Incident refresh error", err);
      } finally {
        // Let's do this again periodically
        this.refreshTimer = setTimeout(() => { this.RefreshWithHandler() }, EnvironmentConstants.OutageRefreshIntervalMS);
      }
    },
    async RefreshData() {
      const incident = await GMPAPI.GetOneIncident(this.incidentid);
      this.incident = incident;
      if (incident.customersByTown) {
        this.affectedTowns = Object.keys(incident.customersByTown).map(key => {
          return {
            name: InitialCaps(key),
            slug: HyphenateTown(key),
            customers: incident.customersByTown[key]
          }
        });
      } else {
        this.affectedTowns = null;
      }
      try {
        if(this.FF_OutagesMessaging) {
          this.messagesArray = await GMPAPI.GetMessagesByIncidentId(this.incidentid);

          this.messagesExist = true;
          if (!this.messagesArray || !this.messagesArray.length) {
            this.messagesExist = false;
          }
          this.messagesArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }
      } catch (err) {
        this.messagesExist = false;
      }
    },
    onMapUpdate(apiData) {
      if (apiData && apiData.personalData) {
        this.myAffectedAccount = apiData.personalData.find(item => item.incidents && item.incidents.length && item.incidents[0].id === this.incidentid);
      } else {
        this.myAffectedAccount = undefined;
      }
    },
    Recenter() {
      if (this.$refs.map) {
        this.isCentered = true;
        this.$refs.map.ZoomToIncident(this.incidentid);
      }
    },
    async ToggleCollapsed() {
      this.detailCollapsed = !this.detailCollapsed;
      await this.$nextTick();
      if (this.$refs.map) {
        this.$refs.map.Resize();
      }
    },
    /** Use the copy of personalData the map is pulling anyway, rather than pulling it ourselves an extra time */
    onMapUpdate(apiData) {
      if (!apiData || !apiData.personalData) return;
      this.myAffectedAccount = apiData.personalData.find(item => item.incidents && item.incidents.length && item.incidents[0].id === this.incidentid);
    },
    formatMessageTime(timeSent) {
      if(!timeSent) { return ''; }
      let time = parseISO(timeSent);
      let formattedTime = '';
      if (isToday(time)) {
        formattedTime = format(time, 'p')
      } else {
        formattedTime = format(time, 'MMM d')
      }
      return formattedTime;
    },
    getNumberWords(text) {
      if (text) {
        text = ReplaceHTML(text);
        return text.split(' ').length;
      }
    },
    getPreviewText(text, maxWordCount) {
      if (text) {
        text = ReplaceHTML(text);
        const words = text.split(' ');

        if(words.length > maxWordCount) {
          text = words.slice(0, maxWordCount).join(' ') + '...'
        }
      }
      return text;
    },
    gotoMessageDetail(messageId, incidentId) {
      this.$router.push({
        name: 'Message',
        params: {
          incidentid: incidentId,
          messageid: messageId
        }
      });
    },
    toggleViewMessages() {
      this.expandMessageList = !this.expandMessageList;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>