var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eicproduct-container" },
    [
      _vm.loadState === undefined
        ? _c("div", { staticClass: "eicproduct__page-loader" }, [
            _c("img", {
              staticClass: "eicproduct__page-loader--svg",
              attrs: {
                src:
                  "/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "error"
        ? _c("flow-error", {
            attrs: {
              name: "Rebates",
              state: "error",
              phonetext: "For further help, please call us at",
              closepath: "/heat-pump-rebate"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && _vm.isCommercialAccount
        ? _c(
            "div",
            [
              _c("HeatPumpRebateNotEligible", {
                attrs: { currentAccount: _vm.currentAccount }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loadState === "complete" && !_vm.isCommercialAccount
        ? _c("div", [
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-1" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("1")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(0)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    !_vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account: " +
                                                _vm._s(
                                                  _vm.currentAccount.lastName
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.userIsOTP
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-m"
                                          },
                                          [
                                            _vm._v(
                                              "Account Number: " +
                                                _vm._s(
                                                  _vm.currentAccount
                                                    .accountNumber
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(1)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 1
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 1 && _vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              heat pump rebate."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    key: _vm.currentAccount.accountNumber,
                                    staticClass:
                                      "eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "eicproduct__account-box gds-space-stack-l"
                                      },
                                      [
                                        _vm.currentAccount
                                          ? _c("span", [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(1),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("titlecase")(
                                                              _vm.currentAccount
                                                                .lastName +
                                                                ", " +
                                                                _vm
                                                                  .currentAccount
                                                                  .firstName
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(2),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "addressFormat"
                                                            )(
                                                              _vm.currentAccount
                                                                .address,
                                                              {
                                                                separator: ", "
                                                              }
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "accountInformation column"
                                                    },
                                                    [
                                                      _vm._m(3),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.currentAccount
                                                              .accountNumber
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentPage === 1 && !_vm.userIsOTP
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "gds-body-normal gds-space-stack-ml"
                                  },
                                  [
                                    _vm._v(
                                      "Verify your GMP account information to use for your\n              heat pump rebate."
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct__account-box gds-space-stack-l"
                                  },
                                  [
                                    _vm.currentAccount
                                      ? _c("span", [
                                          _c("div", { staticClass: "row-m" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "account-info-row"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(4),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("titlecase")(
                                                            _vm.currentAccount
                                                              .lastName +
                                                              ", " +
                                                              _vm.currentAccount
                                                                .firstName
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(5),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "addressFormat"
                                                          )(
                                                            _vm.currentAccount
                                                              .address,
                                                            { separator: ", " }
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "accountInformation column"
                                                  },
                                                  [
                                                    _vm._m(6),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentAccount
                                                            .accountNumber
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.accounts.length > 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "inline accountInformation eicproduct__edit-account-details",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.chooseAccount()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "gds-button gds-text-button choose-account-button"
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "eicproduct__edit-icon-inline gds-icon"
                                                          },
                                                          [
                                                            _c("use", {
                                                              attrs: {
                                                                "xlink:href":
                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit"
                                                              }
                                                            })
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.accounts.length > 1
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Switch Account"
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "eicproduct__method-next-btn gds-button gds-compact",
                                    on: {
                                      click: function($event) {
                                        return _vm.finishPage("page1", 2)
                                      }
                                    }
                                  },
                                  [_vm._v("Next")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-2" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("2")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(7)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c("div", {
                                      staticClass:
                                        "gds-body-normal gds-space-inline-m"
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(2)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 2
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 2
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "form",
                                  { attrs: { "data-vv-scope": "page2" } },
                                  [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass:
                                          "gds-fieldset gds-space-stack-s"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "eicproduct-slap__inner-row"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "row-s" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-m"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproducts-required-text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        Let's confirm your eligibility for a GMP heat pump rebate.\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Did you or your installer purchase your heat pump from a Vermont, New Hampshire, or New York local distributor?"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-space-stack-ml"
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .purchasedFromDistributor,
                                                                    expression:
                                                                      "productSubmission.purchasedFromDistributor"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "eicproduct-input-field__select",
                                                                attrs: {
                                                                  name:
                                                                    "Purchased from Distributor",
                                                                  autofocus: ""
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm.productSubmission,
                                                                        "purchasedFromDistributor",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleChangePurchasedFromDistributor(
                                                                        $event
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        "",
                                                                      value: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Select..."
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "yes"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Yes"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "no"
                                                                    }
                                                                  },
                                                                  [_vm._v("No")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "notSure"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "I'm not sure"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "Purchased from Distributor",
                                                          "page2"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "eicproduct-validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.errors.first(
                                                                      "Purchased from Distributor",
                                                                      "page2"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Please check your invoice, did you receive a point-of-sale rebate on your purchase?"
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-space-stack-ml"
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .alreadyReceivedRebate,
                                                                    expression:
                                                                      "productSubmission.alreadyReceivedRebate"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "eicproduct-input-field__select",
                                                                attrs: {
                                                                  name:
                                                                    "point of sale rebate",
                                                                  autofocus: ""
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm.productSubmission,
                                                                        "alreadyReceivedRebate",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleChangePOSRebateReceived(
                                                                        $event
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        "",
                                                                      value: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Select..."
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "yes"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Yes"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "no"
                                                                    }
                                                                  },
                                                                  [_vm._v("No")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "notSure"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "I'm not sure"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "point of sale rebate",
                                                          "page2"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "eicproduct-validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.errors.first(
                                                                      "point of sale rebate",
                                                                      "page2"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "gds-input-field"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "gds-input-field__label"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Was your heat pump purchased online or from a retailer outside of Vermont, New Hampshire, or New York? "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "gds-space-stack-ml"
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .purchasedOutsideVermont,
                                                                    expression:
                                                                      "productSubmission.purchasedOutsideVermont"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "eicproduct-input-field__select",
                                                                attrs: {
                                                                  name:
                                                                    "Purchased Outside Vermont",
                                                                  autofocus: ""
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm.productSubmission,
                                                                        "purchasedOutsideVermont",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.handleChangePurchasedOutsideVermont(
                                                                        $event
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      disabled:
                                                                        "",
                                                                      value: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Select..."
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "yes"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Yes"
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "no"
                                                                    }
                                                                  },
                                                                  [_vm._v("No")]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "notSure"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "I'm not sure"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.errors.first(
                                                          "Purchased Outside Vermont",
                                                          "page2"
                                                        )
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "eicproduct-validation-error"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.errors.first(
                                                                      "Purchased Outside Vermont",
                                                                      "page2"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showEligibilityModal
                              ? _c(
                                  "div",
                                  [
                                    _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "rebateAlreadyReceived",
                                        modalMessage: _vm.modalMessage,
                                        close: _vm.closeModal
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "gds-button gds-compact",
                                on: {
                                  click: function($event) {
                                    return _vm.finishPage("page2", 3)
                                  }
                                }
                              },
                              [_vm._v("Next")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-3" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("3")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(8)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 3
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-m"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.productSubmission
                                                .numberOfHeatPumpsSelected
                                            ) +
                                            "\n                "
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.productSubmission
                                                .numberOfHeatPumpsSelected === 1
                                                ? "condenser"
                                                : "condensers"
                                            )
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(3)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 3
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 3
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "rebate-slap__inner-row" },
                                  [
                                    _c("div", { staticClass: "row-s" }, [
                                      _c(
                                        "form",
                                        { attrs: { "data-vv-scope": "page3" } },
                                        [
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass:
                                                "gds-fieldset gds-space-stack-s"
                                            },
                                            [
                                              _c("p", [
                                                _vm._v(
                                                  "In order to be eligible for the income-based heat pump rebate, please provide information about each of the outdoor condensers that were installed at your home."
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "How many Heat Pump condensers were installed at your home?"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.productSubmission
                                                            .numberOfHeatPumpsSelected,
                                                        expression:
                                                          "productSubmission.numberOfHeatPumpsSelected"
                                                      },
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression: "'required'"
                                                      }
                                                    ],
                                                    key: "number of heat pumps",
                                                    staticClass:
                                                      "eicproduct-input-field-l__select gds-input-field",
                                                    attrs: {
                                                      name:
                                                        "number of heat pumps",
                                                      "vee-validate":
                                                        "'reqired'",
                                                      autofocus: ""
                                                    },
                                                    on: {
                                                      change: [
                                                        function($event) {
                                                          var $$selectedVal = Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function(o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function(o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                          _vm.$set(
                                                            _vm.productSubmission,
                                                            "numberOfHeatPumpsSelected",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.handleChangeNumberOfHeatPumps(
                                                            _vm
                                                              .productSubmission
                                                              .numberOfHeatPumpsSelected
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "undefined",
                                                          disabled: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Select number of heat pump condensers"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "1" } },
                                                      [_vm._v("1")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "2" } },
                                                      [_vm._v("2")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "3" } },
                                                      [_vm._v("3")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "4" } },
                                                      [_vm._v("4")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "5" } },
                                                      [_vm._v("5")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "6" } },
                                                      [_vm._v("6")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "7" } },
                                                      [_vm._v("7")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "8" } },
                                                      [_vm._v("8")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      { attrs: { value: "9" } },
                                                      [_vm._v("9")]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "number of heat pumps",
                                                  "page3"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "number of heat pumps",
                                                              "page3"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.productSubmission
                                                  .heatPumpEquipment,
                                                function(item, i) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: i,
                                                      staticClass:
                                                        "gds-space-stack-l"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-flex-container gds-flex-container--space-between"
                                                        },
                                                        [
                                                          _c(
                                                            "h2",
                                                            {
                                                              staticStyle: {
                                                                "align-self":
                                                                  "center"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Heat Pump Condenser #" +
                                                                  _vm._s(i + 1)
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.productSubmission
                                                            .numberOfHeatPumpsSelected >
                                                          1
                                                            ? _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "gds-button gds-button-circle",
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.removeHeatPump(
                                                                        i
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "svg",
                                                                    {
                                                                      staticClass:
                                                                        "gds-icon"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "use",
                                                                        {
                                                                          attrs: {
                                                                            "xlink:href":
                                                                              "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-close-small"
                                                                          }
                                                                        }
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field gds-space-stack-l"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Heat Pump Brand"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .heatPumpEquipment[
                                                                        i
                                                                      ]
                                                                        .manufacturer,
                                                                    expression:
                                                                      "productSubmission.heatPumpEquipment[i].manufacturer"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "Heat Pump Manufacturer " +
                                                                  i,
                                                                staticClass:
                                                                  "gds-input-field__input gds-space-stack-m",
                                                                attrs: {
                                                                  name:
                                                                    "Heat Pump Manufacturer " +
                                                                    i,
                                                                  type: "text",
                                                                  autocomplete:
                                                                    "off",
                                                                  placeholder:
                                                                    "Type Here..."
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .heatPumpEquipment[
                                                                      i
                                                                    ]
                                                                      .manufacturer
                                                                },
                                                                on: {
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectManufacturer(
                                                                      $event,
                                                                      i
                                                                    )
                                                                  },
                                                                  input: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .heatPumpEquipment[
                                                                          i
                                                                        ],
                                                                        "manufacturer",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      _vm.$emit(
                                                                        "touched",
                                                                        true
                                                                      )
                                                                      _vm.filterManufacturers(
                                                                        i
                                                                      )
                                                                    }
                                                                  ],
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.filterManufacturers(
                                                                      i
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _vm.filters
                                                                .manufacturer[
                                                                i
                                                              ] &&
                                                              _vm.filters
                                                                .manufacturer[i]
                                                                .length > 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "typeAhead-container"
                                                                    },
                                                                    _vm._l(
                                                                      _vm
                                                                        .filters
                                                                        .manufacturer[
                                                                        i
                                                                      ],
                                                                      function(
                                                                        manufacturer
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key:
                                                                              manufacturer.id,
                                                                            staticClass:
                                                                              "typeAhead-card gds-space-stack-m",
                                                                            class: {
                                                                              "active-dealership typeAhead-card gds-space-stack-m":
                                                                                _vm.currentManufacturer ===
                                                                                manufacturer.id
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.setManufacturer(
                                                                                  manufacturer.name,
                                                                                  i
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "typeAhead-text"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    manufacturer.name
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "Heat Pump Manufacturer " +
                                                                  i,
                                                                "page3"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.errors
                                                                              .first(
                                                                                "Heat Pump Manufacturer " +
                                                                                  i,
                                                                                "page3"
                                                                              )
                                                                              .replace(
                                                                                /Heat Pump Manufacturer \d+/,
                                                                                "Heat Pump Brand"
                                                                              )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field gds-space-stack-l"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Heat Pump Model"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .heatPumpEquipment[
                                                                        i
                                                                      ].model,
                                                                    expression:
                                                                      "productSubmission.heatPumpEquipment[i].model"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "model " + i,
                                                                staticClass:
                                                                  "gds-input-field__input gds-space-stack-m",
                                                                attrs: {
                                                                  name:
                                                                    "Heat Pump Model " +
                                                                    i,
                                                                  type: "text",
                                                                  autocomplete:
                                                                    "off",
                                                                  placeholder:
                                                                    "Type Here..."
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .heatPumpEquipment[
                                                                      i
                                                                    ].model
                                                                },
                                                                on: {
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectModel(
                                                                      $event,
                                                                      i
                                                                    )
                                                                  },
                                                                  input: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .heatPumpEquipment[
                                                                          i
                                                                        ],
                                                                        "model",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      _vm.$emit(
                                                                        "touched",
                                                                        true
                                                                      )
                                                                      _vm.filterModels(
                                                                        _vm
                                                                          .productSubmission
                                                                          .heatPumpEquipment[
                                                                          i
                                                                        ]
                                                                          .manufacturer,
                                                                        i
                                                                      )
                                                                    }
                                                                  ],
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    _vm
                                                                      .productSubmission
                                                                      .heatPumpEquipment[
                                                                      i
                                                                    ]
                                                                      .manufacturer,
                                                                      i
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _vm.filters.model[
                                                                i
                                                              ] &&
                                                              _vm.filters.model[
                                                                i
                                                              ].length > 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "typeAhead-container"
                                                                    },
                                                                    _vm._l(
                                                                      _vm
                                                                        .filters
                                                                        .model[
                                                                        i
                                                                      ],
                                                                      function(
                                                                        model
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key:
                                                                              model.id,
                                                                            staticClass:
                                                                              "typeAhead-card gds-space-stack-m",
                                                                            class: {
                                                                              "active-dealership typeAhead-card gds-space-stack-m":
                                                                                _vm.currentModel ===
                                                                                model.id
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.setModel(
                                                                                  model,
                                                                                  i
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "typeAhead-text"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    model.name
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "Heat Pump Model " +
                                                                  i,
                                                                "page3"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.errors
                                                                              .first(
                                                                                "Heat Pump Model " +
                                                                                  i,
                                                                                "page3"
                                                                              )
                                                                              .replace(
                                                                                /Heat Pump Model \d+/,
                                                                                "Heat Pump Model"
                                                                              )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field gds-space-stack-l"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Heat Pump Type"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'"
                                                                    },
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .productSubmission
                                                                          .heatPumpEquipment[
                                                                          i
                                                                        ]
                                                                          .heatPumpType,
                                                                      expression:
                                                                        "productSubmission.heatPumpEquipment[i].heatPumpType"
                                                                    }
                                                                  ],
                                                                  key:
                                                                    "Heat Pump Type + i",
                                                                  staticClass:
                                                                    "eicproduct-input-field-l__select gds-input-field gds-space-stack-m",
                                                                  attrs: {
                                                                    name:
                                                                      "Heat Pump Type " +
                                                                      i,
                                                                    autofocus:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: [
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal = Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function(
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function(
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                        _vm.$set(
                                                                          _vm
                                                                            .productSubmission
                                                                            .heatPumpEquipment[
                                                                            i
                                                                          ],
                                                                          "heatPumpType",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      },
                                                                      function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.handleChangeHeatPumpType(
                                                                          i
                                                                        )
                                                                      }
                                                                    ]
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        disabled:
                                                                          "",
                                                                        selected:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Select Heat Pump Type"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.heatPumpTypes,
                                                                    function(
                                                                      heatPumpType,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key: i,
                                                                          domProps: {
                                                                            value: heatPumpType
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              heatPumpType
                                                                            ) +
                                                                              "\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "Heat Pump Type " +
                                                                  i,
                                                                "page3"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.errors
                                                                              .first(
                                                                                "Heat Pump Type " +
                                                                                  i,
                                                                                "page3"
                                                                              )
                                                                              .replace(
                                                                                /Heat Pump Type \d+/,
                                                                                "Heat Pump Type"
                                                                              )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field gds-space-stack-l"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "BTU Size"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'"
                                                                    },
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .productSubmission
                                                                          .heatPumpEquipment[
                                                                          i
                                                                        ]
                                                                          .btuSize,
                                                                      expression:
                                                                        "productSubmission.heatPumpEquipment[i].btuSize"
                                                                    }
                                                                  ],
                                                                  key:
                                                                    "btuSize " +
                                                                    i,
                                                                  staticClass:
                                                                    "eicproduct-input-field-l__select gds-input-field gds-space-stack-m",
                                                                  attrs: {
                                                                    name:
                                                                      "BTU Size " +
                                                                      i,
                                                                    autofocus:
                                                                      ""
                                                                  },
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .heatPumpEquipment[
                                                                          i
                                                                        ],
                                                                        "btuSize",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        disabled:
                                                                          "",
                                                                        selected:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Select BTU Size"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.btuSizes,
                                                                    function(
                                                                      btuSize,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key: i,
                                                                          domProps: {
                                                                            value: btuSize
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              btuSize
                                                                            ) +
                                                                              "\n                              "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "BTU Size " + i,
                                                                "page3"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.errors
                                                                              .first(
                                                                                "BTU Size " +
                                                                                  i,
                                                                                "page3"
                                                                              )
                                                                              .replace(
                                                                                /BTU Size \d+/,
                                                                                "BTU Size"
                                                                              )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "row-l"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Purchase Date"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-purchasedate__date-box gds-space-stack-l"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "my-account-billing-report__filtration-date-icon",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.$refs.datePicker.showCalendar()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg",
                                                                        {
                                                                          staticClass:
                                                                            "gds-icon"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "use",
                                                                            {
                                                                              attrs: {
                                                                                "xlink:href":
                                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "datepicker",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "validate",
                                                                          rawName:
                                                                            "v-validate",
                                                                          value:
                                                                            "required",
                                                                          expression:
                                                                            "'required'"
                                                                        }
                                                                      ],
                                                                      key:
                                                                        "Date Of Purchase " +
                                                                        i,
                                                                      ref:
                                                                        "datePicker",
                                                                      refInFor: true,
                                                                      attrs: {
                                                                        name:
                                                                          "Date Of Purchase " +
                                                                          i,
                                                                        format:
                                                                          "MMMM d, yyyy",
                                                                        typeable: true,
                                                                        placeholder:
                                                                          "Select Date...",
                                                                        "disabled-dates":
                                                                          _vm.disabledDates,
                                                                        "input-class":
                                                                          "export-data__date my-account-billing-report__filtration-date-text"
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.handleChangeDateOfPurchase
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .productSubmission
                                                                            .heatPumpEquipment[
                                                                            i
                                                                          ]
                                                                            .dateOfPurchase,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .heatPumpEquipment[
                                                                              i
                                                                            ],
                                                                            "dateOfPurchase",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "productSubmission.heatPumpEquipment[i].dateOfPurchase"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "Date Of Purchase " +
                                                                  i,
                                                                "page3"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.errors
                                                                              .first(
                                                                                "Date Of Purchase " +
                                                                                  i,
                                                                                "page3"
                                                                              )
                                                                              .replace(
                                                                                /Date Of Purchase \d+/,
                                                                                "Date Of Purchase"
                                                                              )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.preventDefault()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Installation Date"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-purchasedate__date-box gds-space-stack-l"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "my-account-billing-report__filtration-date-icon",
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.$refs.datePicker.showCalendar()
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "svg",
                                                                        {
                                                                          staticClass:
                                                                            "gds-icon"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "use",
                                                                            {
                                                                              attrs: {
                                                                                "xlink:href":
                                                                                  "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-calendar"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "datepicker",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "validate",
                                                                          rawName:
                                                                            "v-validate",
                                                                          value:
                                                                            "required",
                                                                          expression:
                                                                            "'required'"
                                                                        }
                                                                      ],
                                                                      key:
                                                                        "Date Of Installation " +
                                                                        i,
                                                                      ref:
                                                                        "datePicker",
                                                                      refInFor: true,
                                                                      attrs: {
                                                                        name:
                                                                          "Date Of Installation " +
                                                                          i,
                                                                        format:
                                                                          "MMMM d, yyyy",
                                                                        typeable: true,
                                                                        placeholder:
                                                                          "Select Date...",
                                                                        "disabled-dates":
                                                                          _vm.disabledDates,
                                                                        "input-class":
                                                                          "export-data__date my-account-billing-report__filtration-date-text"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .productSubmission
                                                                            .heatPumpEquipment[
                                                                            i
                                                                          ]
                                                                            .dateOfInstallation,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm
                                                                              .productSubmission
                                                                              .heatPumpEquipment[
                                                                              i
                                                                            ],
                                                                            "dateOfInstallation",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "productSubmission.heatPumpEquipment[i].dateOfInstallation"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "Date Of Installation " +
                                                                  i,
                                                                "page3"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.errors
                                                                              .first(
                                                                                "Date Of Installation " +
                                                                                  i,
                                                                                "page3"
                                                                              )
                                                                              .replace(
                                                                                /Date Of Installation \d+/,
                                                                                "Date of Installation"
                                                                              )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-l"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "gds-input-field__label"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Who installed the heat pump?"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .heatPumpEquipment[
                                                                      i
                                                                    ]
                                                                      .installationType,
                                                                  expression:
                                                                    "productSubmission.heatPumpEquipment[i].installationType"
                                                                },
                                                                {
                                                                  name:
                                                                    "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value:
                                                                    "required",
                                                                  expression:
                                                                    "'required'"
                                                                }
                                                              ],
                                                              key:
                                                                "Installation Type " +
                                                                i,
                                                              staticClass:
                                                                "eicproduct-input-field-l__select gds-input-field gds-space-stack-m",
                                                              attrs: {
                                                                name:
                                                                  "Installation Type " +
                                                                  i,
                                                                autofocus: ""
                                                              },
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal = Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .heatPumpEquipment[
                                                                      i
                                                                    ],
                                                                    "installationType",
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "",
                                                                    selected: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Select install type"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "installer"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Professional Installer"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value: "diy"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Installed Myself"
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _vm.errors.first(
                                                            "Installation Type " +
                                                              i,
                                                            "page3"
                                                          )
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "eicproduct-validation-error"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.errors
                                                                          .first(
                                                                            "Installation Type " +
                                                                              i,
                                                                            "page3"
                                                                          )
                                                                          .replace(
                                                                            /Installation Type \d+/,
                                                                            "Installation Type"
                                                                          )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _vm.productSubmission
                                                        .heatPumpEquipment[i]
                                                        .installationType ===
                                                      "installer"
                                                        ? _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field gds-space-stack-l"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Installer"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .heatPumpEquipment[
                                                                        i
                                                                      ]
                                                                        .installer,
                                                                    expression:
                                                                      "productSubmission.heatPumpEquipment[i].installer"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required",
                                                                    expression:
                                                                      "'required'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "Installer " +
                                                                  i,
                                                                staticClass:
                                                                  "gds-input-field__input gds-space-stack-m",
                                                                attrs: {
                                                                  name:
                                                                    "Installer " +
                                                                    i,
                                                                  type: "text",
                                                                  autocomplete:
                                                                    "off",
                                                                  placeholder:
                                                                    "Type Here..."
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .heatPumpEquipment[
                                                                      i
                                                                    ].installer
                                                                },
                                                                on: {
                                                                  keyup: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.selectInstaller(
                                                                      $event,
                                                                      i
                                                                    )
                                                                  },
                                                                  input: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .heatPumpEquipment[
                                                                          i
                                                                        ],
                                                                        "installer",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      _vm.$emit(
                                                                        "touched",
                                                                        true
                                                                      )
                                                                      _vm.filterInstallers(
                                                                        i
                                                                      )
                                                                    }
                                                                  ],
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.filterInstallers(
                                                                      i
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _vm.filters
                                                                .installer[i] &&
                                                              _vm.filters
                                                                .installer[i]
                                                                .length > 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "typeAhead-container"
                                                                    },
                                                                    _vm._l(
                                                                      _vm
                                                                        .filters
                                                                        .installer[
                                                                        i
                                                                      ],
                                                                      function(
                                                                        installer
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          {
                                                                            key:
                                                                              installer.id,
                                                                            staticClass:
                                                                              "typeAhead-card gds-space-stack-m",
                                                                            class: {
                                                                              "active-dealership typeAhead-card gds-space-stack-m":
                                                                                _vm.currentInstaller ===
                                                                                installer.id
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.setInstaller(
                                                                                  installer,
                                                                                  i
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "typeAhead-text"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    installer.name
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    0
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "Installer " +
                                                                  i,
                                                                "page3"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.errors
                                                                              .first(
                                                                                "Installer " +
                                                                                  i,
                                                                                "page3"
                                                                              )
                                                                              .replace(
                                                                                /Installer \d+/,
                                                                                "Heat Pump Installer"
                                                                              )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page3", 4)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-4" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("4")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "gds-slat__title-wrapper" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__title gds-slat__title"
                                  },
                                  [_vm._v("Upload Documents")]
                                ),
                                _vm._v(" "),
                                _vm.selectedFile
                                  ? _c("modal-document-checklist", {
                                      attrs: {
                                        visible: _vm.showDocumentChecklist,
                                        select: _vm.onSelect,
                                        save: _vm.onSave,
                                        selectedFile: _vm.selectedFile,
                                        close: _vm.closeModal,
                                        documentTypes: _vm.documentTypes
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showInformationalModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "informational",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "On your W9 please make sure you have Selected a box in item 3 and have signed it. Please make sure to have the following info on your proof of purchase:",
                                        listItems: [
                                          "Brand and Model of Heat Pump",
                                          "Date of Purchase",
                                          "Your name and address"
                                        ]
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showProofOfPurchaseInfoModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "proofOfPurchaseInfo",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Please make sure you have the following information with your proof of purchase:",
                                        listItems: [
                                          "Your Name",
                                          "Date of purchase",
                                          "Your address",
                                          "Brand and model of Heat Pump"
                                        ]
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showW9InfoModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "w9Info",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Please make sure you selected a box in item 3 and that you've signed your W9."
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showMissingDocumentsModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "missingDocument",
                                        close: _vm.onMissingDocumentsModalClose,
                                        modalTitle:
                                          "You’re missing a required document",
                                        modalMessage:
                                          "Please upload a copy of the following:",
                                        listItems: _vm.missingDocuments
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showFileLoadTooBigModal
                                  ? _c("ModalEICProducts", {
                                      attrs: {
                                        modalName: "fileLoadTooBig",
                                        close: _vm.closeModal,
                                        modalMessage:
                                          "Your total file size is " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.totalFileSize
                                          ) +
                                          ". Please make sure the combined size of all uploaded files is less than " +
                                          this.$options.filters.fileSizeFormat(
                                            _vm.maxFileLoadSize
                                          ) +
                                          "."
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 4
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _vm.documents.length > 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.documents.length +
                                                  "\n                Files Uploaded"
                                              )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.documents.length === 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "gds-body-normal gds-space-inline-s"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  this.documents.length +
                                                    " File Uploaded"
                                                )
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(4)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 4
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 4
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c("form", [
                                      _c(
                                        "div",
                                        { attrs: { id: "fileUploader" } },
                                        [
                                          _c("File-Uploader", {
                                            attrs: {
                                              uploadDocumentList:
                                                _vm.uploadDocumentList,
                                              docTypes: _vm.documentTypes,
                                              allowCombined: false
                                            },
                                            on: {
                                              "file-list-changed":
                                                _vm.updateFileList
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page4", 5)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-5" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("5")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(9)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__header-summary gds-flex-container gds-flex-container"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-normal gds-space-inline-s"
                                      },
                                      [
                                        _vm._v(
                                          "Mailing Address: \n                " +
                                            _vm._s(
                                              _vm._f("addressFormat")(
                                                _vm.productSubmission.rebate
                                                  .rebateMailingAddress,
                                                { seperator: ", " }
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct-slat__header-edit-button gds-button gds-round gds-secondary",
                                        on: {
                                          click: function($event) {
                                            return _vm.gotoPage(5)
                                          }
                                        }
                                      },
                                      [_vm._v("Edit")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage < 5
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 5
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "form",
                                      { attrs: { "data-vv-scope": "page5" } },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "gds-space-stack-l" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "gds-input-field"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Please make my check out to:"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebatePayeeName,
                                                      expression:
                                                        "productSubmission.rebate.rebatePayeeName"
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  key: "rebate payee name",
                                                  ref: "focuser5",
                                                  staticClass:
                                                    "eicproduct-input-field gds-input-field__input",
                                                  attrs: {
                                                    name: "rebate payee name",
                                                    type: "text"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate.rebatePayeeName
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .rebate,
                                                          "rebatePayeeName",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.$emit(
                                                          "touched",
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "rebate payee name",
                                                  "page5"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "rebate payee name",
                                                              "page5"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(10),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Country")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress
                                                        .country,
                                                    expression:
                                                      "productSubmission.rebate.rebateMailingAddress.country"
                                                  }
                                                ],
                                                class:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .country === "USA"
                                                    ? "eicproduct-input-field__select-country-us gds-input-field gds-space-stack-m"
                                                    : _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress
                                                        .country === "CAN"
                                                    ? "eicproduct-input-field__select-country-ca gds-input-field"
                                                    : "eicproduct-input-field__select-s gds-input-field",
                                                attrs: { autofocus: "" },
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "country",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      selected: "",
                                                      "data-thumbnail":
                                                        "/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg"
                                                    },
                                                    domProps: { value: "USA" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "United States\n                    "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "CAN" } },
                                                  [_vm._v("Canada")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "FOR" } },
                                                  [_vm._v("Foreign")]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.productSubmission.rebate
                                              .rebateMailingAddress.country
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.errors.first(
                                                            "country",
                                                            "page5"
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Street Address")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productSubmission.rebate
                                                      .rebateMailingAddress
                                                      .street1,
                                                  expression:
                                                    "productSubmission.rebate.rebateMailingAddress.street1"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              key: "street address",
                                              staticClass:
                                                "gds-space-stack-m eicproduct-input-field gds-input-field__input",
                                              attrs: {
                                                name: "street address",
                                                type: "text",
                                                placeholder: "Type Here..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .street1
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "street1",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.$emit(
                                                      "touched",
                                                      true
                                                    )
                                                  }
                                                ]
                                              }
                                            }),
                                            _vm._v(" "),
                                            _vm.errors.first(
                                              "street address",
                                              "page5"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "street address",
                                                          "page5"
                                                        )
                                                      ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "gds-input-field" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-input-field__label"
                                              },
                                              [_vm._v("Suite Apt")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.productSubmission.rebate
                                                      .rebateMailingAddress
                                                      .street2,
                                                  expression:
                                                    "productSubmission.rebate.rebateMailingAddress.street2"
                                                }
                                              ],
                                              key: "street 2",
                                              staticClass:
                                                "gds-space-stack-m eicproduct-input-field gds-input-field__input",
                                              attrs: {
                                                name: "suite apt",
                                                type: "text",
                                                placeholder: "Type Here..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.productSubmission.rebate
                                                    .rebateMailingAddress
                                                    .street2
                                              },
                                              on: {
                                                input: [
                                                  function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress,
                                                      "street2",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.$emit(
                                                      "touched",
                                                      true
                                                    )
                                                  }
                                                ]
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "fieldset",
                                          {
                                            staticClass:
                                              "row-l gds-fieldset gds-space-stack-l"
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "gds-input-field-flex flex-item-l"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [_vm._v("City")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .rebateMailingAddress
                                                          .city,
                                                      expression:
                                                        "productSubmission.rebate.rebateMailingAddress.city"
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'"
                                                    }
                                                  ],
                                                  key: "city",
                                                  staticClass:
                                                    "gds-input-field__input gds-space-stack-m",
                                                  attrs: {
                                                    name: "city",
                                                    type: "text",
                                                    placeholder: "Type Here..."
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate
                                                        .rebateMailingAddress
                                                        .city
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .rebate
                                                            .rebateMailingAddress,
                                                          "city",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.$emit(
                                                          "touched",
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "city",
                                                  "page5"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "city",
                                                              "page5"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.productSubmission.rebate
                                              .rebateMailingAddress.country ===
                                            "USA"
                                              ? _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "gds-input-field flex-item-l"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gds-input-field__label"
                                                      },
                                                      [_vm._v("State")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .productSubmission
                                                                .rebate
                                                                .rebateMailingAddress
                                                                .state,
                                                            expression:
                                                              "productSubmission.rebate.rebateMailingAddress.state"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "eicproduct-input-field__select gds-input-field gds-space-stack-m",
                                                        on: {
                                                          change: [
                                                            function($event) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                _vm
                                                                  .productSubmission
                                                                  .rebate
                                                                  .rebateMailingAddress,
                                                                "state",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.$emit(
                                                                "touched",
                                                                true
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                              disabled: "",
                                                              selected: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Select State..."
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.stateList,
                                                          function(state) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: state,
                                                                domProps: {
                                                                  value: state
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(state)
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    !_vm.productSubmission
                                                      .rebate
                                                      .rebateMailingAddress
                                                      .state
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "eicproduct-validation-error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "state",
                                                                  "page5"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.productSubmission.rebate
                                              .rebateMailingAddress.country ===
                                            "CAN"
                                              ? _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "gds-input-field flex-item-l"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gds-input-field__label"
                                                      },
                                                      [_vm._v("Province")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm
                                                                .productSubmission
                                                                .rebate
                                                                .rebateMailingAddress
                                                                .state,
                                                            expression:
                                                              "productSubmission.rebate.rebateMailingAddress.state"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "eicproduct-input-field__select gds-input-field gds-space-stack-m",
                                                        on: {
                                                          change: [
                                                            function($event) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                _vm
                                                                  .productSubmission
                                                                  .rebate
                                                                  .rebateMailingAddress,
                                                                "state",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function($event) {
                                                              return _vm.$emit(
                                                                "touched",
                                                                true
                                                              )
                                                            }
                                                          ]
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                              disabled: "",
                                                              selected: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Select Province..."
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.provinceList,
                                                          function(province) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: province,
                                                                domProps: {
                                                                  value: province
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      province
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    ),
                                                    _vm._v(" "),
                                                    !_vm.productSubmission
                                                      .rebate
                                                      .rebateMailingAddress
                                                      .state
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "eicproduct-validation-error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "province",
                                                                  "page5"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "flex-item-m" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "gds-input-field flex-item-l"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "gds-input-field__label"
                                                      },
                                                      [_vm._v("Zipcode")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm
                                                              .productSubmission
                                                              .rebate
                                                              .rebateMailingAddress
                                                              .zip,
                                                          expression:
                                                            "productSubmission.rebate.rebateMailingAddress.zip"
                                                        },
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value:
                                                            "required|numeric|length:5",
                                                          expression:
                                                            "'required|numeric|length:5'"
                                                        }
                                                      ],
                                                      key: "zipcode",
                                                      staticClass:
                                                        "gds-input-field__input eicproduct-mailing-zip gds-space-stack-m",
                                                      attrs: {
                                                        name: "zipcode",
                                                        type: "text",
                                                        placeholder:
                                                          "Type Here...",
                                                        autocomplete:
                                                          "postal-code"
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .rebateMailingAddress
                                                            .zip
                                                      },
                                                      on: {
                                                        input: [
                                                          function($event) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm
                                                                .productSubmission
                                                                .rebate
                                                                .rebateMailingAddress,
                                                              "zip",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                          function($event) {
                                                            return _vm.$emit(
                                                              "touched",
                                                              true
                                                            )
                                                          }
                                                        ]
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.errors.first(
                                                      "zipcode",
                                                      "page5"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "eicproduct-validation-error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "zipcode",
                                                                  "page5"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                !_vm.productSubmission.rebate
                                                  .rebateMailingAddress.zip &&
                                                _vm.productSubmission.rebate
                                                  .rebateMailingAddress
                                                  .country === "USA"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "zip",
                                                              "page5"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.productSubmission.rebate
                                                  .rebateMailingAddress.zip &&
                                                _vm.productSubmission.rebate
                                                  .rebateMailingAddress
                                                  .country === "CAN"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.errors.first(
                                                              "canadianZip",
                                                              "page5"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "gds-space-stack-m" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "eicproduct-title gds-space-stack-l"
                                              },
                                              [
                                                _vm._v(
                                                  "How should we contact you if we need additional information to process\n                    your rebate:"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "gds-input-field"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [_vm._v("Email Address")]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.productSubmission
                                                          .rebate
                                                          .confirmationEmailAddress,
                                                      expression:
                                                        "productSubmission.rebate.confirmationEmailAddress"
                                                    },
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        "required|email|max:74",
                                                      expression:
                                                        "'required|email|max:74'"
                                                    }
                                                  ],
                                                  key: "email address",
                                                  ref: "email",
                                                  staticClass:
                                                    "eicproduct-input-field gds-space-stack-m gds-input-field__input",
                                                  attrs: {
                                                    name: "email address",
                                                    type: "email",
                                                    placeholder:
                                                      "Enter email address",
                                                    autocomplete: "email"
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.productSubmission
                                                        .rebate
                                                        .confirmationEmailAddress
                                                  },
                                                  on: {
                                                    input: [
                                                      function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.productSubmission
                                                            .rebate,
                                                          "confirmationEmailAddress",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.$emit(
                                                          "touched",
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _vm.errors.first(
                                                  "email address",
                                                  "page5"
                                                )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "eicproduct-validation-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.errors.first(
                                                                "email address",
                                                                "page5"
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "gds-input-field"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "gds-input-field__label"
                                                  },
                                                  [_vm._v("Phone Number")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "gds-space-stack-m"
                                                  },
                                                  [
                                                    _c("the-mask", {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value:
                                                            "required|min:10",
                                                          expression:
                                                            "'required|min:10'"
                                                        }
                                                      ],
                                                      key: "phone number",
                                                      staticClass:
                                                        "eicproduct-input-field gds-space-stack-m gds-input-field__input",
                                                      attrs: {
                                                        mask:
                                                          "+1 (###) ###-####",
                                                        "data-vv-validate-on":
                                                          "input",
                                                        name: "phone number",
                                                        type: "tel",
                                                        placeholder:
                                                          "Your phone number"
                                                      },
                                                      nativeOn: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          _vm.touched = true
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.productSubmission
                                                            .rebate
                                                            .contactPhoneNumber,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm
                                                              .productSubmission
                                                              .rebate,
                                                            "contactPhoneNumber",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "productSubmission.rebate.contactPhoneNumber"
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.errors.first(
                                                      "phone number",
                                                      "page5"
                                                    )
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "eicproduct-validation-error"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.errors.first(
                                                                  "phone number",
                                                                  "page5"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            !_vm.isAlreadyEbillEnrolled
                                              ? _c("div", [
                                                  _vm._m(11),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-body-small gds-space-stack-l slat3-body"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      You can reduce your carbon footprint even more,\n                      by switching to paperless billing! Paperless monthly energy\n                      statements cut carbon and clutter while saving trees.\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "gds-space-stack-ml"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "gds-checkbox gds-checkbox-thin"
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll,
                                                                expression:
                                                                  "productSubmission.programEnrollment.ebill.ebillEnroll"
                                                              }
                                                            ],
                                                            attrs: {
                                                              type: "checkbox",
                                                              name: ""
                                                            },
                                                            domProps: {
                                                              checked: Array.isArray(
                                                                _vm
                                                                  .productSubmission
                                                                  .programEnrollment
                                                                  .ebill
                                                                  .ebillEnroll
                                                              )
                                                                ? _vm._i(
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .ebill
                                                                      .ebillEnroll,
                                                                    null
                                                                  ) > -1
                                                                : _vm
                                                                    .productSubmission
                                                                    .programEnrollment
                                                                    .ebill
                                                                    .ebillEnroll
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$a =
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .ebill
                                                                      .ebillEnroll,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c = $$el.checked
                                                                    ? true
                                                                    : false
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v = null,
                                                                    $$i = _vm._i(
                                                                      $$a,
                                                                      $$v
                                                                    )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .programEnrollment
                                                                          .ebill,
                                                                        "ebillEnroll",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .programEnrollment
                                                                          .ebill,
                                                                        "ebillEnroll",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .ebill,
                                                                    "ebillEnroll",
                                                                    $$c
                                                                  )
                                                                }
                                                              }
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "gds-checkbox__faux"
                                                          }),
                                                          _vm._v(" "),
                                                          _vm._m(12)
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.productSubmission
                                                    .programEnrollment.ebill
                                                    .ebillEnroll
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "gds-space-stack-ml"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "gds-input-field"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "gds-input-field__label"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Account email address"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .productSubmission
                                                                        .programEnrollment
                                                                        .ebill
                                                                        .ebillEmailAddress,
                                                                    expression:
                                                                      "productSubmission.programEnrollment.ebill.ebillEmailAddress"
                                                                  },
                                                                  {
                                                                    name:
                                                                      "validate",
                                                                    rawName:
                                                                      "v-validate",
                                                                    value:
                                                                      "required|email",
                                                                    expression:
                                                                      "'required|email'"
                                                                  }
                                                                ],
                                                                key:
                                                                  "account email address",
                                                                ref: "email",
                                                                staticClass:
                                                                  "eicproduct-input-field gds-input-field__input",
                                                                attrs: {
                                                                  name:
                                                                    "account email address",
                                                                  type: "email",
                                                                  placeholder:
                                                                    "Your email",
                                                                  autocomplete:
                                                                    "email"
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    _vm
                                                                      .productSubmission
                                                                      .programEnrollment
                                                                      .ebill
                                                                      .ebillEmailAddress
                                                                },
                                                                on: {
                                                                  input: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        _vm
                                                                          .productSubmission
                                                                          .programEnrollment
                                                                          .ebill,
                                                                        "ebillEmailAddress",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.$emit(
                                                                        "touched",
                                                                        true
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _vm.errors.first(
                                                                "account email address",
                                                                "page5"
                                                              )
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "eicproduct-validation-error"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.errors.first(
                                                                            "account email address",
                                                                            "page5"
                                                                          )
                                                                        )
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "eicproduct__method-next-btn gds-button gds-compact",
                                        on: {
                                          click: function($event) {
                                            return _vm.finishPage("page5", 6)
                                          }
                                        }
                                      },
                                      [_vm._v("Next")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "eicproduct-section",
                attrs: { id: "eicproduct-slat-6" }
              },
              [
                _c(
                  "div",
                  { staticClass: "eicproduct-slat gds-slat gds-slat--active" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "eicproduct-slat__header gds-slat__header"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-left gds-flex-container"
                          },
                          [
                            _vm.currentPage <= 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__number-with-circle"
                                  },
                                  [_c("span", [_vm._v("6")])]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.currentPage > 6
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "eicproduct-slat__green-check-circle"
                                  },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._m(13)
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "eicproduct-slat__header-right gds-flex-container"
                          },
                          [
                            _vm.currentPage < 6
                              ? _c(
                                  "div",
                                  { staticClass: "eicproduct-slat__lock-icon" },
                                  [
                                    _c("svg", { staticClass: "gds-icon" }, [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock"
                                        }
                                      })
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.currentPage === 6
                      ? _c(
                          "div",
                          { staticClass: "eicproduct-slat__contents" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "eicproduct-slat__contents-inner"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "gds-space-stack-l" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        ref: "focuser6",
                                        staticClass:
                                          "gds-display-00 gds-space-stack-s"
                                      },
                                      [_vm._v("Your rebate will be mailed to:")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          "Amount: $" +
                                            _vm._s(_vm.totalRebateAmount)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("titlecase")(
                                              _vm.productSubmission.rebate
                                                .rebatePayeeName
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-s"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productSubmission.rebate
                                              .rebateMailingAddress.street1
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-m"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.productSubmission.rebate
                                              .rebateMailingAddress.city
                                          ) +
                                            "\n                " +
                                            _vm._s(
                                              _vm.productSubmission.rebate
                                                .rebateMailingAddress.state
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.productSubmission.rebate
                                                .rebateMailingAddress.zip
                                            )
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-display-00 gds-space-stack-s"
                                      },
                                      [_vm._v("Your Heat Pump Information:")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "gds-body-small gds-space-stack-l"
                                      },
                                      [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(
                                              "Total Rebate amount is based on installation of: " +
                                                _vm._s(
                                                  _vm.productSubmission
                                                    .numberOfHeatPumpsSelected
                                                ) +
                                                " Heat Pump"
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _vm.productSubmission
                                            .numberOfHeatPumpsSelected === 1
                                            ? _c("span", [_vm._v("Condenser")])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.productSubmission
                                            .numberOfHeatPumpsSelected > 1
                                            ? _c("span", [_vm._v("Condensers")])
                                            : _vm._e()
                                        ])
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "gds-space-stack-m" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "gds-checkbox gds-checkbox-thin"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.regulatoryDataSharing,
                                                  expression:
                                                    "regulatoryDataSharing"
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'"
                                                }
                                              ],
                                              key: "regulatory data sharing",
                                              attrs: {
                                                type: "checkbox",
                                                name: "regulatory data sharing"
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.regulatoryDataSharing
                                                )
                                                  ? _vm._i(
                                                      _vm.regulatoryDataSharing,
                                                      null
                                                    ) > -1
                                                  : _vm.regulatoryDataSharing
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.regulatoryDataSharing,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.regulatoryDataSharing = $$a.concat(
                                                          [$$v]
                                                        ))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.regulatoryDataSharing = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.regulatoryDataSharing = $$c
                                                  }
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "gds-checkbox__faux"
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "gds-font-size-ml regulatory-text1"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.REGULATORY_REBATE_SHARING_TEXT1
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "gds-font-size-ml regulatory-text2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.REGULATORY_REBATE_SHARING_TEXT2
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.errors.first(
                                              "regulatory data sharing",
                                              "page6"
                                            )
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "eicproduct-validation-error"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.errors.first(
                                                          "regulatory data sharing",
                                                          "page6"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    !_vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "eicproduct__method-next-btn gds-button gds-compact",
                                            attrs: {
                                              type: "submit",
                                              disabled:
                                                !_vm.regulatoryDataSharing ||
                                                _vm.errors.any()
                                            },
                                            on: { click: _vm.handleSubmit }
                                          },
                                          [
                                            _vm._v(
                                              "\n                Submit Rebate\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.pendingSubmit
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "gds-button [modifier class] gds-loading"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "gds-button__text"
                                              },
                                              [_vm._v("Submit Rebate")]
                                            ),
                                            _vm._v(" "),
                                            _c("figure", {
                                              staticClass: "gds-button__icon"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Customer Information")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Name on Account")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Service Address")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Account Number")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Eligibility Confirmation")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Your Heat Pump Equipment")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Contact & Mailing Address")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "slat-title-wrapper" }, [
      _c("div", { staticClass: "gds-space-stack-m eicproduct-title" }, [
        _vm._v("Where should we send your rebate check?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-body-small gds-space-stack-l" }, [
      _c("span", { staticClass: "gds-display-1 gds-space-stack-s" }, [
        _vm._v("Would you like to go paperless?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "gds-font-size-ml" }, [
      _c("b", [_vm._v("Yes!")]),
      _vm._v(
        " - Enroll me in paperless billing so I can reduce my carbon footprint even more.\n                        "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "gds-slat__title-wrapper" }, [
      _c("div", { staticClass: "eicproduct-slat__title gds-slat__title" }, [
        _vm._v("Review & Submit")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }